import { configureStore, isRejectedWithValue, MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import authReducer, { userLogout } from "../pages/Auth/authSlice";
import modalReducer from "../components/customModal/modalSlice";
import dialogReducer from "../components/customDialog/dialogSlice";
import { authApi } from "../pages/Auth/authApi";
import { memberApi } from "../pages/MemberManagement/memberApi";
import { settlementApi } from "../pages/SettlementManagement/settlementApi";
import { dispatchApi } from "../pages/DispatchManagement/dispatchApi";
import { atmApi } from "../pages/AlarmTalkManagement/atmApi";
import { notificationApi } from "../pages/NoticeManagement/notificationApi";
import { faqApi } from "../pages/FaqManagement/faqApi";
import { fapCategoryApi } from "../pages/FaqManagement/categoryApi";
import { advertisementApi } from "../pages/AdvertisementManagement/advertisementApi";
import { couponApi } from "../pages/CouponManagement/couponApi";
import { companyApi } from "../pages/CompanyManagement/companyApi";
import { fcApi } from "../pages/FreightCost/fcApi";
import { pointApi } from "../pages/PointManagement/pointApi";
import { carrierPostApi } from "../pages/CarrierPosts/carrierPostApi";
import { popupApi } from "../pages/PopupManagement/popupApi";
import { vehicleRegisApi } from "@/pages/DispatchModify/VehicleRegisApi";
import vehicleReducer from "@/pages/DispatchModify/VehicleRegisSlice";

const rootReducer = {
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  [memberApi.reducerPath]: memberApi.reducer,
  [settlementApi.reducerPath]: settlementApi.reducer,
  [dispatchApi.reducerPath]: dispatchApi.reducer,
  [atmApi.reducerPath]: atmApi.reducer,
  [pointApi.reducerPath]: pointApi.reducer,
  [fcApi.reducerPath]: fcApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [faqApi.reducerPath]: faqApi.reducer,
  [fapCategoryApi.reducerPath]: fapCategoryApi.reducer,
  [advertisementApi.reducerPath]: advertisementApi.reducer,
  [couponApi.reducerPath]: couponApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [carrierPostApi.reducerPath]: carrierPostApi.reducer,
  [popupApi.reducerPath]: popupApi.reducer,
  [vehicleRegisApi.reducerPath]: vehicleRegisApi.reducer,
  vehicleRegis: vehicleReducer,
  modal: modalReducer,
  dialog: dialogReducer,
};
const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    /*       console.log("rtkQueryErrorLogger", action);
     */ // unauthorized
    if (action.payload.status === 401) {
      api.dispatch(userLogout());
      window.location.href = "/login";
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      rtkQueryErrorLogger,
      authApi.middleware,
      popupApi.middleware,
      memberApi.middleware,
      settlementApi.middleware,
      dispatchApi.middleware,
      notificationApi.middleware,
      faqApi.middleware,
      fapCategoryApi.middleware,
      advertisementApi.middleware,
      couponApi.middleware,
      companyApi.middleware,
      atmApi.middleware,
      fcApi.middleware,
      pointApi.middleware,
      carrierPostApi.middleware,
      vehicleRegisApi.middleware
    ]),
});

export default store;
