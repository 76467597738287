import { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import icAdd from "../../../../assets/images/ic_add.svg";
import { useDispatch } from "react-redux";
import { closeDialog, openDialog } from "../../../customDialog/dialogSlice";
import { API_LINK } from "../../../../utils/constants";
import { useCreateCompanyMutation, useUpdateCompanyMutation } from "../../../../pages/CompanyManagement/companyApi";
import Resizer from "react-image-file-resizer";
import imageCompression from "browser-image-compression";

export const RegisterCompany = (props: any) => {
  const { data, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [createCompany] = useCreateCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [review, setReview] = useState("");
  const [imageUrl, setImageUrl] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const activeConfirmBtn = useMemo(
    () => !!name && !!description && !!review && !!imageUrl,
    [name, description, review, imageUrl],
  );

  const uploadImage = async (originalImageFile: File) => {
    try {
      if (originalImageFile?.size <= 100 * 1024) {
        return originalImageFile;
      } else {
        // Resize and compress the image
        // const resizedImage = await resizeImage(originalImageFile, 100, 100); // Resize to desired dimensions
        const compressedImage = await compressImage(originalImageFile, 100); // Compress to <= 100KB
        return base64toFile(compressedImage, originalImageFile?.name, originalImageFile?.type);
      }
    } catch (error) {
      console.error("Image resizing/compression failed:", error);
    }
  };

  const resizeImage = (file: File, maxWidth: number, maxHeight: number) => {
    return new Promise<File>((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        maxWidth, // Maximum width
        maxHeight, // Maximum height
        "JPEG", // Output format
        100, // Quality
        0, // Rotation
        (uri) => {
          resolve(uri as File);
        },
        "blob", // Output type (blob or base64)
      );
    });
  };

  const compressImage = (file: File, maxSizeInKB: number) => {
    return new Promise<string>((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        300, // Maximum width (adjust as needed)
        300, // Maximum height (adjust as needed)
        "JPEG", // Output format (you can change it to JPEG or PNG as needed)
        100, // Quality
        0, // Rotation
        (compressedFile) => {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile as Blob); // Read the compressed file as Data URL
          reader.onload = () => {
            const base64Data = reader.result as string;
            resolve(base64Data); // Resolve with the Base64-encoded string
          };
        },
        "blob", // Output type (blob)
        1,
        1,
      );
    });
  };

  useEffect(() => {
    setName(data?.detail?.id === -1 ? "" : data?.detail?.name ?? "");
    setDescription(data?.detail?.description ?? "");
    setReview(data?.detail?.review ?? "");
    setImageUrl(data?.detail?.imageUrl ?? "");
  }, [data?.detail]);

  const onHandleImageClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadEl: any = document.getElementById("upload");

  const base64toFile = (base64String: string, filename: string, mimeType: string): File => {
    const byteCharacters = atob(base64String.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a File object from the Blob
    return new File([blob], filename, { type: mimeType });
  };

  /* -------------------------------- 파일 변경 처리 -------------------------------- */
  const handleChangeFile = async (event: any) => {
    if (event.target.files.length > 0) {
      const fileName = (event.target.files[0].name || "").toUpperCase();
      const isInValidType = !fileName.endsWith(".JPG") && !fileName.endsWith(".PNG");

      const compressedFile: any = await uploadImage(event.target.files[0]);
      // const compress = await imageCompression(event.target.files?.[0], {
      //   maxSizeMB: 0.1,
      //   maxWidthOrHeight: 800,
      //   useWebWorker: true,
      // });
      // const convertFile = base64toFile(compress, event.target.files[0]?.name, event.target.files[0]?.type);
      // console.log(compress);

      if (event.target.files[0].size > 30720000 || isInValidType) {
        dispatch(
          openDialog({
            type: "info",
            content: t("pls_attach_less_than_30mb_jpg"),
            confirmText: t("dialog_btn_confirm"),
          }),
        );
        event.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append("file", compressedFile);
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          setImageUrl(data.Location);
        });
    }
  };

  const onClickRegister = () => {
    if (activeConfirmBtn) {
      if (data?.detail?.id === -1 || !data?.detail?.id) {
        onRegister();
      } else {
        onUpdate();
      }
    }
  };

  /* ----------------------------------- 등록 ----------------------------------- */
  const onRegister = async () => {
    const res = await createCompany({
      name,
      description,
      review,
      imageUrl,
    }); //@ts-ignore
    showError(res?.error);
  };

  const onUpdate = async () => {
    const res = await updateCompany({
      id: data?.detail?.id,
      name,
      description,
      review,
      imageUrl,
    }); //@ts-ignore
    showError(res?.error);
  };

  const showError = (error: any) => {
    if (error?.data?.message) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeDialog());
          },
        }),
      );
    } else {
      setDescription("");
      setName("");
      setReview("");
      setImageUrl("");
      uploadEl.value = "";
      onCancel();
    }
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div className="register-company-wrap">
      <div className="container">
        <div className="form">
          <div className="form-item">
            <div className="form-item-title">{t("corporate_name")}</div>
            <input
              className="form-input"
              placeholder={t("enter_company_name")}
              maxLength={10}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-item">
            <div className="form-item-title">{t("introduction_text")}</div>
            <input
              className="form-input"
              placeholder={t("enter_company_description")}
              maxLength={10}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="form-item">
            <div className="form-item-title">{t("company_review")}</div>
            <input
              className="form-input"
              placeholder={t("enter_company_review")}
              maxLength={20}
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
          </div>
        </div>
        <div className="image-wrap">
          <div className="add-image" onClick={onHandleImageClick}>
            {imageUrl ? (
              <img className="image" src={imageUrl} />
            ) : (
              <>
                <div className="representative_image">{t("representative_image")}</div>
                <img className="icon-add" src={icAdd} />
              </>
            )}
          </div>
          <input
            onChange={handleChangeFile}
            multiple={false}
            id="upload"
            ref={fileInputRef}
            type="file"
            accept=".jpg,.png"
            hidden
          />
        </div>
        <div className={`btn-register ${activeConfirmBtn ? "active" : ""}`} onClick={onClickRegister}>
          {t("register")}
        </div>
        <div className="btn-cancel" onClick={onCancel}>
          {t("dialog_btn_cancel")}
        </div>
      </div>
    </div>
  );
};
