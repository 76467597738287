import { useUpdateDispatchMutation } from '@/pages/DispatchModify/VehicleRegisApi';
import Memos from '../../commons/memos';
import { getUrlParams } from '@/utils/commonFnc';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/components/customDialog/dialogSlice';
import { useGetOrderDetailQuery } from '@/pages/SettlementManagement/settlementApi';

const HandleMatchingMemo = ({ onClose, data }: any) => {
    const { notes, setInfoDetail } = data;
    console.log(notes)
    const [memoText, setMemoText] = useState<string>(notes || '');
    const idDispatch = getUrlParams("id");
    const dispatch = useDispatch<any>();
    const [updateDispatch, { isLoading }] = useUpdateDispatchMutation();
    const { data: infoData, refetch: handleRefreshDetail } = useGetOrderDetailQuery(
        { id: idDispatch },
        {
            refetchOnMountOrArgChange: true,
            skip: !idDispatch,
        },
    );

    const handleSubmit = () => {
        updateDispatch({ id: idDispatch, data: { notes: memoText } })
            .unwrap()
            .then((res: any) => {
                handleRefreshDetail();
                setInfoDetail(infoData);
                onClose();
                dispatch(
                    openDialog({
                        type: "info",
                        content: '저장되었습니다.',
                        confirmText: '확인',
                    }),
                );
            })
            .catch((error: any) => {
                dispatch(
                    openDialog({
                        type: "info",
                        content: error?.data?.message,
                        confirmText: '확인',
                    }),
                );
            });
    };

    const deleteMemo = () => {
        setMemoText('')
        dispatch(
            openDialog({
                type: "confirm",
                title: '정말 삭제하시겠습니까?' + "\n" + '삭제된 데이터는 복구할 수 없습니다.',
                confirmText: '확인',
                closeText: '취소',
                actionConfirm: () => {
                    updateDispatch({ id: idDispatch, data: { notes: '' } })
                        .unwrap()
                        .then((res: any) => {
                            handleRefreshDetail();
                            setInfoDetail(infoData);
                            onClose();
                            dispatch(
                                openDialog({
                                    type: "info",
                                    content: '삭제되었습니다.',
                                    confirmText: '확인',
                                }),
                            );
                        })
                        .catch((error: any) => {
                            dispatch(
                                openDialog({
                                    type: "info",
                                    content: error?.data?.message,
                                    confirmText: '확인',
                                }),
                            );
                        });
                },
            }),
        );
    };

    return (
        <Memos handleSubmit={handleSubmit} deleteMemo={deleteMemo} onClose={onClose} memoText={memoText} setMemoText={setMemoText} isLoading={isLoading} />
    );
};

export default HandleMatchingMemo;