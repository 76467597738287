import React, { useEffect, useRef, useState } from "react";
import "@/components/customModal/template/kakaoSearchAddress/style.scss";
import { Button, Image, Input } from "antd";
import { useGetListLocationMutation, useGetListLocationWithAddressMutation } from "@/pages/Auth/authApi";
import { getUrlParams } from "@/utils/commonFnc";
import locationImg from "@/assets/images/locationIcon.png"
import arrowUpImg from "@/assets/images/arrowUp.png"
import arrowDownImg from "@/assets/images/arrowDown.png"
import { onFocus } from "@reduxjs/toolkit/dist/query/core/setupListeners";

interface IAddress {
  address_name: string;
  b_code: string;
  h_code: string;
  main_address_no: string;
  mountain_yn: string;
  region_1depth_name: string;
  region_2depth_name: string;
  region_3depth_h_name: string;
  region_3depth_name: string;
  sub_address_no: string;
  x: string;
  y: string;
}

interface IRoadAddress {
  address_name: string;
  building_name: string;
  main_building_no: string;
  region_1depth_name: string;
  region_2depth_name: string;
  region_3depth_name: string;
  road_name: string;
  sub_building_no: string;
  underground_yn: string;
  x: string;
  y: string;
  zone_no: string;
}

interface IPlace {
  address_name: string;
  category_group_code?: string;
  category_group_name?: string;
  category_name?: string;
  distance?: string;
  id?: string;
  phone?: string;
  place_name?: string;
  place_url?: string;
  road_address_name?: string;
  x: string;
  y: string;
  address?: IAddress;
  road_address?: IRoadAddress;
}

//@ts-ignore
const { kakao } = window;

export default function KakaoSearchAddress(props: any) {
  const bottom = useRef<any>(null);
  const submitBtnRef = useRef<any>(null);
  const [getListLocation, { isSuccess, isLoading, data, isUninitialized }] = useGetListLocationMutation();
  const [getListLocationWithAddress, { isSuccess: isSuccessWA, data: dataWA }] =
    useGetListLocationWithAddressMutation();
  const [initMap, setinitMap] = useState(false);
  const [filters, setFilters] = useState<any>({ page: 1, size: 10 });
  const [loadedMap, setloadedMap] = useState(false);
  const { handleConfirm, handleClose, data: dataPopup } = props;
  var map: any, ps: any, infowindow: any, marker: any, geocoder: any;
  const [open, setopen] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchValue, setsearchValue] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [detailAdressInput, setdetailAdressInput] = useState("");
  const [selectedLocation, setselectedLocation] = useState<IPlace | null>();
  const [localData, setlocalData] = useState<any[]>([]);

  const hideDetailUrl = getUrlParams("hideDetail") || dataPopup?.hideDetail;
  const observerTarget = useRef(null);
  const [loadingLocation, setloadingLocation] = useState(false);

  const handleSelectPlace = (place: IPlace) => {
    setFilters({ ...filters, page: 1 });
    setlocalData([]);
    setselectedLocation(place);
    setinitMap(true);
    setloadedMap(false);
  };

  /* ---------------------------------- 주소 검색 --------------------------------- */
  const handleSearch = async () => {
    setFilters({ ...filters, page: 1 });

    if (searchValue?.trim()) {
      setloadingLocation(true);
      const listLocation = await getListLocation({
        analyze_type: "similar",
        page: 1,
        size: filters?.size,
        query: searchValue?.trim(),
      }).unwrap();
      const listLocationWithAddress = await getListLocationWithAddress({
        analyze_type: "similar",
        page: 1,
        size: filters?.size,
        query: searchValue?.trim(),
      }).unwrap();
      setloadingLocation(false);
      setTotal(listLocation?.meta?.pageable_count);
      setShowMore(!listLocation?.meta?.is_end);
      let a = listLocation?.documents;
      let b = listLocationWithAddress?.documents;
      setlocalData((prev) => [
        ...b,
        ...a.filter((val: any) => {
          return !b.find((val2: any) => {
            return val.address_name === val2.address_name;
          });
        }),
      ]);

      setselectedLocation(null);
      setopen(true);
      setloadedMap(false);
      setinitMap(false);
    }
  };

  /* ------------------------ 추가로 로드 (infinity loading) ----------------------- */
  const handleLoadMore = async () => {
    if (searchValue?.trim()) {
      setloadingLocation(true);
      const listLocation = await getListLocation({
        analyze_type: "similar",
        page: filters?.page + 1,
        size: filters?.size,
        query: searchValue?.trim(),
      }).unwrap();
      setloadingLocation(false);
      console.log("listLocation=>", listLocation);
      setFilters({ ...filters, page: filters.page + 1 });

      setTotal(listLocation?.meta?.pageable_count);
      setShowMore(!listLocation?.meta?.is_end);
      let a = listLocation?.documents;
      // let b = listLocationWithAddress?.documents;
      setlocalData((prev) => [...prev, ...a]);
    }
  };

  /* -------------------------------- 디폴트 장소 설정 ------------------------------- */
  const handleFindLocation = () => {
    let localLocation = {
      address_name: "서울 종로구 관훈동 83-2",
      category_group_code: "CE7",
      category_group_name: "카페",
      category_name: "음식점 > 카페",
      distance: "",
      id: "127029191",
      phone: "02-737-5865",
      place_name: "KOTTON SEOUL",
      place_url: "http://place.map.kakao.com/127029191",
      road_address_name: "서울 종로구 인사동12길 17",
      x: "126.984921420242",
      y: "37.5747413144454",
    };
    handleSelectPlace(localLocation);
    setopen(true);
  };

  /* ------------------------------- 지도에서 위치 찾기 ------------------------------- */
  function searchPosition(data: any, x: any, y: any) {
    var fullAddress = data.address ? data.address.address_name : data.address_name;
    var detailAddr = data?.road_address_name
      ? data?.road_address_name
      : data?.road_address
        ? data?.road_address?.address_name + data?.road_address?.building_name
        : "";

    let newPlace: IPlace = {
      address_name: fullAddress,
      road_address_name: detailAddr,
      x: y,
      y: x,
    };

    setselectedLocation(newPlace);
    let sPosition = new kakao.maps.LatLng(x, y);

    let firstContent = detailAddr
      ? `<div style="white-space: nowrap;width: 290px;overflow: hidden;text-overflow: ellipsis;">법정동 주소정보: ${detailAddr} </div>`
      : "";
    let secondContent = fullAddress
      ? `<div  style="white-space: nowrap;width: 290px;overflow: hidden;text-overflow: ellipsis;">지번 주소: ${fullAddress} </div>`
      : "";

    var content =
      '<div  style="padding:10px;min-width:300px;line-height:150%;">' + secondContent + firstContent + "</div>";

    infowindow.setContent(content);
    map.relayout();
    marker.setPosition(sPosition);
    map.setCenter(sPosition);
    infowindow.open(map, marker);
  }

  /* ------------------------------- 지도에서 위치 선택 ------------------------------- */
  function selectPosition(crLocation: IPlace | null | undefined) {
    if (crLocation) {
      console.log("selected address", crLocation);

      let addressArray = crLocation.address_name.split(" ");
      //@ts-ignore
      let sPosition = new kakao.maps.LatLng(parseFloat(crLocation.y), parseFloat(crLocation.x)).toCoords();

      let fullAddress = crLocation?.place_name
        ? crLocation?.address_name + " " + crLocation?.place_name
        : crLocation?.address_name,
        city = "",
        district = "";
      if (addressArray.length >= 2) {
        city = addressArray[0];
        district = addressArray[1];
      }

      console.log("split address:", fullAddress, "city:", city, "district:", district);
      // @ts-ignore
      window["ReactNativeWebView"] &&
        // @ts-ignore
        window["ReactNativeWebView"].postMessage(
          JSON.stringify({
            event: "selectLocation",
            data: {
              fullAddress,
              coorX: sPosition.La,
              coorY: sPosition.Ma,
              lat: crLocation.y,
              lng: crLocation.x,
              detailAddress: detailAdressInput,
            },
          }),
        );
      if (navigator.userAgent.includes("wv")) {
        //Inside the webview
        // @ts-ignore
        // window.ReactNativeWebView.postMessage({ fullAddress, city, district });

        if (Android) {
          //@ts-ignore
          Android.sendJsMsgToWebView(fullAddress, city, district);
        }
      } else if (handleConfirm) {
        //Not a webview
        handleConfirm({
          fullAddress: fullAddress,
          coorX: sPosition.La,
          coorY: sPosition.Ma,
          lat: crLocation.y,
          lng: crLocation.x,
          detailAddress: detailAdressInput,
        });
      }
    }
  }

  useEffect(() => {
    if (kakao && initMap) {
      initLocationMap();
    }

    return () => { };
  }, [selectedLocation, initMap]);

  /* ------------------------------ kakao map 그리기 ----------------------------- */
  const initLocationMap = () => {
    var mapContainer = document.getElementById("map"); // 지도를 표시할 div
    if (selectedLocation?.address_name && mapContainer && !loadedMap) {
      console.log("go here to init map");

      let intPos = new kakao.maps.LatLng(selectedLocation.y || 37.566826, selectedLocation.x || 126.9786567);
      // var mapContainer = document.getElementById("map"); // 지도를 표시할 div
      const mapOption = {
        center: intPos, // 지도의 중심좌표
        level: 3, // 지도의 확대 레벨
      };

      // 지도를 생성합니다
      map = new kakao.maps.Map(mapContainer, mapOption);

      //marker
      marker = new kakao.maps.Marker({
        position: intPos,
        map: map,
      });

      // 장소 검색 객체를 생성합니다
      ps = new kakao.maps.services.Places();

      // 주소-좌표 변환 객체를 생성합니다
      geocoder = new kakao.maps.services.Geocoder();

      // 검색 결과 목록이나 마커를 클릭했을 때 장소명을 표출할 인포윈도우를 생성합니다
      infowindow = new kakao.maps.InfoWindow({ zIndex: 1, anchorSkew: true });

      kakao.maps.event.addListener(marker, "click", function (e: any) {
        if (infowindow.getMap()) {
          infowindow.close();
        } else {
          infowindow.open(map, marker);
        }
      });

      // 지도를 클릭했을 때 클릭 위치 좌표에 대한 주소정보를 표시하도록 이벤트를 등록합니다
      kakao.maps.event.addListener(map, "click", function (mouseEvent: any) {
        geocoder.coord2Address(mouseEvent.latLng.La, mouseEvent.latLng.Ma, function (result: any, status: any) {
          if (status === kakao.maps.services.Status.OK) {
            searchPosition(result[0], mouseEvent.latLng.Ma + "", mouseEvent.latLng.La + "");
          }
        });
      });

      setloadedMap(true);
    }
  };

  return (
    <div className="kakao-search-wp">
      {/* @ts-ignore */}
      {!window["ReactNativeWebView"] && (
        <div className="title-area">
          <div className="confirm-bt" onClick={handleClose}>
            취소
          </div>
          <div className="confirm-title">주소 검색</div>
        </div>
      )}
      <div className="search-text-area flex gap-2.5 items-center">
        <Input
          className="search-tb"
          placeholder="지번 혹은 도로명으로 주소 검색"
          value={searchValue}
          onPressEnter={handleSearch}
          type="text"
          enterKeyHint="done"
          onChange={(e) => {
            setsearchValue(e?.target?.value);
          }}
        />
        <Button
          ref={submitBtnRef}
          className={`w-[80px] h-[50px] bt-search-direct rounded-lg text-[white] ${searchValue?.trim()?.length > 0 ? "bg-[#1f67ad]" : ""
            }`}
          loading={loadingLocation}
          onClick={handleSearch}
        >
          검색
        </Button>
      </div>
      <div className="place-direction" onClick={handleFindLocation}>
        <img src={locationImg} width={20} height={20} className="img-location" />
        <div>지도에서 주소찾기</div>
      </div>

      <div className="recent-place">
        <div>주소록</div>
        <img
          src={`${open ? arrowUpImg : arrowDownImg}`}
          className={`img-expand`}
          onClick={() => {
            if (!selectedLocation?.address_name) {
              setopen(!open);
            }
          }}
        />
      </div>

      <div className="recent-place-list">
        {selectedLocation?.address_name && open && initMap && (
          <>
            <div
              id="map"
              className="map"
              style={{
                height: "220px",
              }}
            ></div>
          </>
        )}
        {selectedLocation?.address_name && open && (
          <div className="item">
            <div className="address selected text-ellipsis">
              {selectedLocation?.address_name} <span>{selectedLocation?.place_name ?? ""}</span>
            </div>
            <div className="contact selected">{selectedLocation?.road_address_name}</div>
          </div>
        )}
        {open &&
          !selectedLocation?.address_name &&
          localData?.map((it: IPlace, key: number) => {
            return (
              <div className="item" key={key} onClick={() => handleSelectPlace(it)}>
                <div className="address text-ellipsis">
                  {it?.address_name} <span>{it?.place_name ?? ""}</span>
                </div>
                <div className="contact">{it?.road_address_name || it?.road_address?.address_name}</div>
              </div>
            );
          })}
        {open && !selectedLocation?.address_name && showMore && (
          <div
            ref={observerTarget}
            id="lastIt"
            onClick={handleLoadMore}
            className="text-center cursor-pointer text-[blue] p-[5px]"
          >
            더보기
          </div>
        )}

        {selectedLocation?.address_name && open && !hideDetailUrl && (
          <div className="detail-address-area">
            <Input
              className="search-tb"
              placeholder="상세주소를 입력해주세요."
              value={detailAdressInput}
              onChange={(e) => {
                setdetailAdressInput(e?.target?.value);
              }}
              onPressEnter={() => {
                if (submitBtnRef?.current) {
                  submitBtnRef?.current?.click();
                }
              }}
            />
          </div>
        )}
      </div>

      <div className="confirm-area">
        <Button
          ref={submitBtnRef}
          className={`confirm-bt-s ${selectedLocation?.address_name ? "selected" : ""}`}
          onClick={() => selectPosition(selectedLocation)}
        >
          입력완료
        </Button>
      </div>
    </div>
  );
}
