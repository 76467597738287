import { FC, useMemo } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { formatNumberToCurrency } from "../../utils/utils";
import { STATUS_WAITING_APPROVAL } from "../../utils/constants";

interface Props {
  data: any;
  fromPage?: "MATCHING_MANAGEMENT" | string;
}

export const ShipperPaymentAmount: FC<Props> = ({ data, fromPage }) => {
  const { t } = useTranslation();

  const isMP = fromPage === "MATCHING_MANAGEMENT";

  return (
    <div className="container-wrap">
      <div className="card-header">
        <div className="header-title">
          {isMP && data?.status === STATUS_WAITING_APPROVAL ? "결제 예정 금액" : t("shipper_payment_amount")}
        </div>
      </div>
      <div className="payment-amount">
        <div>
          <div className="cost-item">
            <div className="cost-title">{t("freight_cost")}</div>
            <div className="cost-value">
              {formatNumberToCurrency(data?.freightCost)} {t("won")}
            </div>
          </div>
          {data?.couponDiscountAmount ? (
            <div className="cost-item">
              <div className="cost-title cost-title--blue">쿠폰 할인</div>
              <div className="cost-value cost-title--blue">
                {new Intl.NumberFormat().format(Number(data?.couponDiscountAmount))} {t("won")}
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.pointDiscountAmount ? (
            <div className="cost-item">
              <div className="cost-title cost-title--blue">포인트 사용</div>
              <div className="cost-value cost-title--blue">
                {new Intl.NumberFormat().format(Number(data?.pointDiscountAmount))} {t("won")}
              </div>
            </div>
          ) : (
            ""
          )}
          {
            !data?.isCash &&
            <div className="cost-item">
              <div className="cost-title">{t("VAT")}</div>
              <div className="cost-value">
                {data?.vat && formatNumberToCurrency(data?.vat)} {t("won")}
              </div>
            </div>
          }
          {isMP && data?.isCash
            ? <div className="cost-item">
              <div className="cost-title">추가 결제</div>
              <div className="cost-value">
                {formatNumberToCurrency(data?.otherFee || 0)} {t("won")}
              </div>
            </div>
            : data?.otherFeeIncludeVAT && Number(data?.otherFeeIncludeVAT) > 0
              ? (
                <div className="cost-item">
                  <div className="cost-title">추가 결제</div>
                  <div className="cost-value">
                    {formatNumberToCurrency(data?.otherFeeIncludeVAT)} {t("won")}
                  </div>
                </div>
              )
              : (
                ""
              )}
          <div className="cost-item">
            <div className="final-cost-title">{t("final_payment_amount")}</div>
            <div className="final-cost">
              {data?.isCash
                ? formatNumberToCurrency(data?.freightCost + data?.otherFee)
                : data?.firstNicepayAmt
                  ? formatNumberToCurrency(data?.firstNicepayAmt + data?.otherFeeIncludeVAT)
                  : formatNumberToCurrency(data?.freightCost + data?.vat + data?.otherFeeIncludeVAT)}
              {t("won")}
            </div>
          </div>
        </div>
        <div>
          <div className="cost-item">
            <div className="cost-title">산재보험료</div>
            <div className="cost-value">
              {data?.laborUnemploymentInsurance && formatNumberToCurrency(data?.laborUnemploymentInsurance)} {t("won")}
            </div>
          </div>
          <div className="cost-item">
            <div className="discount-title">{t("discount_dispatch_king")} </div>
            <div className="cost-value">
              {data?.specialSale && formatNumberToCurrency(data?.specialSale)} {t("won")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
