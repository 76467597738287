import { ChangeEvent, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useGetFreightCostQuery, useUploadFreightCostMutation } from "./fcApi";
import { downloadFileLink, koreanTimezone } from "../../utils/commonFnc";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  openDialog,
} from "../../components/customDialog/dialogSlice";

function FreightCost() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  const [file, setFile] = useState<any>({});
  const [isConfirmUploadVisible, setisConfirmUploadVisible] = useState(false);

  const { data, isLoading } = useGetFreightCostQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const fileUploader = document.getElementById("fileUploader") as any;

  const handleFileChange = (event: ChangeEvent<HTMLInputElement> | any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const [uploadFreightCost] = useUploadFreightCostMutation();

  const handleFileLinkDownload = () => {
    downloadFileLink(data?.fileUrl, data?.fileName);
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append("fileName", file?.name);
        formData.append("file", file);
        const data = await uploadFreightCost(formData).unwrap();
        dispatch(
          openDialog({
            type: "info",
            content: "정상적으로 업로드 완료 되었습니다.",
            confirmText: t("dialog_btn_confirm"),
            actionConfirm: () => {
              dispatch(closeDialog());
            },
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(
          openDialog({
            type: 'info',
            content: (
              <div className="msg-upload-error">
                <p className="msg-upload-error-text">
                  운임 파일 업로드 중 오류가 발생했습니다
                </p>
                <p className="msg-upload-error-text">
                  업로드 파일을 확인해주세요
                </p>
              </div>
            ),
            confirmText: t('dialog_btn_confirm'),
            actionConfirm: () => {
              dispatch(closeDialog());
            },
          })
        );
      } finally {
        setisConfirmUploadVisible(false);
        fileUploader.value = "";
        setFile({});
      }
    }
  };
  return (
    <div className="fc">
      <div className="fc-header">
        <div className="fc-header-title">{t("운임 관리")}</div>
        <div className="fc-header-subtitle">
          <InfoCircleFilled /> {t("fc.sub_title")}
        </div>
      </div>
      <div className="fc-action">
        <div
          className="fc-action-upload"
          onClick={() => inputRef?.current?.click()}
        >
          <input
            id="fileUploader"
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
          />
          {file?.name ? (
            <span className="file-uploaded-name">{file.name}</span>
          ) : (
            <span>{t("fc.input_placeholder")}</span>
          )}
        </div>
        <Button
          className={`btn-post ${file?.name && "btn-post--active"}`}
          onClick={() => file?.name && setisConfirmUploadVisible(true)}
        >
          {t("fc.btn_post")}
        </Button>
      </div>
      <div className="fc-list">
        {/* {[...Array(10)]?.map((i) => {
          return ( */}
        <div className="fc-list-item">
          <div className="file">
            <div className="file-name">{data?.fileName}</div>
            <div className="file-detail">
              <span>{t("fc.registration_date")} : </span>
              <span>{koreanTimezone(data?.createdAt)}</span>
            </div>
          </div>
          <Button className="btn-upload" onClick={handleFileLinkDownload}>
            {t("fc.btn_download")}
          </Button>
        </div>

        {isConfirmUploadVisible && (
          <Modal
            destroyOnClose={true}
            open={isConfirmUploadVisible}
            title=""
            wrapClassName="modal-fc--check"
            onCancel={() => setisConfirmUploadVisible(false)}
            footer={null}
            width={468}
          >
            <div className="confirm-wrapper">
              <div className="confirm-title">
                운임 파일을 업로드 하시겠습니까?
              </div>

              <div className="confirm-action">
                <Button
                  className="confirm-action-btn--cancel"
                  onClick={handleFileUpload}
                >
                  확인
                </Button>
                <Button
                  className="confirm-action-btn--check"
                  onClick={() => setisConfirmUploadVisible(false)}
                >
                  취소
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default FreightCost;
