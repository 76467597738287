import * as React from "react";

const IconRepeat = (props: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span {...props}>
      <svg
        width="1em"
        height="1.0em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.375 15L16.125 17.25L13.875 15"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 16.5L16.125 6.75C16.122 5.75635 15.726 4.80425 15.0234 4.10163C14.3208 3.39901 13.3686 3.00297 12.375 3L11.625 3"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.625 9L7.875 6.75L10.125 9"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.875 7.5L7.875 17.25C7.87797 18.2436 8.27401 19.1958 8.97663 19.8984C9.67925 20.601 10.6314 20.997 11.625 21L12.375 21"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

IconRepeat.displayName = "IconRepeat";

export default IconRepeat;
