import { useEffect, useState } from "react";
import icClose from "../../../../assets/images/ic_close.svg";
import icBusinessRegistration from "../../../../assets/images/ic_business_registration.svg";
import icRepresentativeRegistrationCertificate from "../../../../assets/images/ic_representative_registration_certificate.svg";
import UploadDocument from "../../../uploadDocument";
import "./style.scss";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../modalSlice";
import { useTranslation } from "react-i18next";
import { useApprovalMemberMutation } from "../../../../pages/MemberManagement/memberApi";
import { openDialog } from "../../../customDialog/dialogSlice";

export default function SignUpApproval(props: any) {
  const [info, setInfo] = useState(props.data.info);
  const [businessLicenses, setBusinessLicenses] = useState({
    businessLicense: "",
    representativeRegistrationCertificate: "",
    termsConditions: "",
  });
  const [isShowSubmit, setIsShowSubmit] = useState(true);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [approvalMember] = useApprovalMemberMutation();

  useEffect(() => {
    setInfo(props.data.info);
  }, [props]);

  useEffect(() => {
    setIsShowSubmit(
      !!businessLicenses.businessLicense &&
        !!businessLicenses.representativeRegistrationCertificate
    );
  }, [businessLicenses]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const onApprovalClick = () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_approve_carrier"),
        },
        handleAction: onHandleApprovalClick,
      })
    );
  };

  const onHandleApprovalClick = async () => {
    if (!isShowSubmit) return;
    const params = {
      id: info.id,
      businessLicenses: businessLicenses,
    };
    const res = await approvalMember(params); //@ts-ignore
    const errMsg = res?.error?.data?.message;
    dispatch(closeModal());
    if (!errMsg) {
      const data = Object.assign({}, info);
      data.businessLicenses = businessLicenses;
      data.status = "ACTIVE";
      dispatch(
        openDialog({
          type: "info",
          content: t("approval_completed"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(
              openModal({
                template: "show-shipper-detail-modal",
                width: "649px",
                data: {
                  info: data,
                },
              })
            );
          },
        })
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {},
        })
      );
    }
  };

  return (
    <div className="sign-up-approval-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("sign_up_approval")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="document-note">{t("upload_documents_note")}</div>
        <div className="document-body">
          <div className="doc-row">
            <UploadDocument
              icon={icBusinessRegistration}
              title={t("business_registration")}
              value=""
              onUploaded={(val: string) => {
                setBusinessLicenses({
                  ...businessLicenses,
                  businessLicense: val,
                });
              }}
              onRemove={() => {
                setBusinessLicenses({
                  ...businessLicenses,
                  businessLicense: "",
                });
              }}
            />
            <div className="space"></div>
            <UploadDocument
              icon={icRepresentativeRegistrationCertificate}
              title={t("representative_registration_certificate")}
              value=""
              onUploaded={(val: string) => {
                setBusinessLicenses({
                  ...businessLicenses,
                  representativeRegistrationCertificate: val,
                });
              }}
              onRemove={() => {
                setBusinessLicenses({
                  ...businessLicenses,
                  representativeRegistrationCertificate: "",
                });
              }}
            />
            <div className="space"></div>
          </div>
        </div>
        <div className="footer-wrapper">
          <div
            className={`submit-btn ${isShowSubmit ? "active" : ""}`}
            onClick={onApprovalClick}
          >
            <div className="submit-text">{t("approved")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
