import "./style.scss";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../utils/utils";

export default function DocumentItem({ title, link }: any) {
  const { t } = useTranslation();

  const preview = () => {
    if (link) {
      window.open(link);
    }
  }

  return (
    <div className="document-item">
      <div className="document-wrapper">
        <div className="text-title">{title}</div>
        <div className="text-sub">{link ? `${title}.png` : t("no_files")}</div>
      </div>
      {link && (
        <div className="btn-wrapper">
          <div className="btn">
            <div
              className="btn-text"
              onClick={preview}
            >
              {t("미리보기")}
            </div>
          </div>
          <div className="btn">
            <div
              className="btn-text"
              onClick={() => {
                downloadFile(link);
              }}
            >
              {t("download")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
