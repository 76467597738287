import moment, { Moment } from "moment-timezone";
import { useEffect, useState } from "react";
import { IParams } from "../SalesGraph";
import { DATE_FORMAT_DOT, DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useGetPointsQuery } from "../settlementApi";
import { formatBusinessNumber, formatMoney, formatPhoneNumber } from "../../../utils/utils";
import Table, { ColumnsType } from "antd/lib/table";
import { openDialog } from "../../../components/customDialog/dialogSlice";
import { closeModal } from "../../../components/customModal/modalSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IPointManagement } from "../../PointManagement/@type";
import { getDateFromData, getDateToData } from "../../../utils/commonFnc";

interface ISalesGraphPaymentProps {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface ISalesGraphPaymentParams extends IParams {
  type: "add";
  order?: string;
  providerType?: string;
}

type MemberType = "ALL" | "NORMAL" | "BUSINESS" | "PAY_LATER";

const memberTypes: { label: string; value: MemberType }[] = [
  { label: "전체", value: "ALL" },
  { label: "일반회원", value: "NORMAL" },
  { label: "기업회원", value: "BUSINESS" },
  { label: "후불회원", value: "PAY_LATER" },
];

export default function SalesGraphPayment({ startDate, endDate }: ISalesGraphPaymentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [params, setParams] = useState<ISalesGraphPaymentParams>({
    page: 1,
    limit: DEFAULT_PAGE_SIZE,
    type: "add",
    order: "desc",
  });
  const [memberType, setMemberType] = useState<MemberType>("ALL");

  const { data, error, isFetching } = useGetPointsQuery(params, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    const newParams = { ...params, page: 1 };
    if (startDate && moment(startDate).isValid()) {
      newParams["from"] = getDateFromData(startDate);
    } else {
      delete newParams.from;
    }
    if (endDate && moment(endDate).isValid()) {
      newParams["to"] = getDateToData(endDate);
    } else {
      delete newParams.to;
    }
    setParams(newParams);
  }, [startDate, endDate]);

  useEffect(() => {
    const newParams = { ...params, page: 1 };
    if (memberType === "ALL") {
      delete newParams?.providerType;
    } else {
      newParams["providerType"] = memberType;
    }
    setParams(newParams);
  }, [memberType]);

  useEffect(() => {
    if (error && "status" in error && !isNaN(+error?.status) && +error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  }, [error]);

  const columns: ColumnsType<Partial<IPointManagement>> = [
    {
      title: t("sales_graph_payment_date_and_time"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "11%",
      align: "center",
      render: (createdAt) => (
        <div>
          {moment(createdAt).isValid() && (
            <>
              <span>{moment(createdAt).format(DATE_FORMAT_DOT)}</span>
              <br />
              <span>{moment(createdAt).format("hh:mm A")}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: t("pm.representation_name"),
      dataIndex: "representationName",
      key: "representationName",
      width: "9%",
      align: "center",
      render: (_, record: Partial<IPointManagement>): any => {
        return record?.provider?.representationName ?? "";
      },
    },
    {
      title: t("pm.company"),
      dataIndex: "company",
      key: "company",
      width: "13%",
      align: "center",
      render: (_: any, record: Partial<IPointManagement>): any => {
        if (record?.provider?.type === "NORMAL") {
          return "일반 회원";
        }
        return record?.provider?.company ?? "";
      },
    },
    {
      title: t("pm.business_number"),
      dataIndex: "businessNumber",
      key: "businessNumber",
      width: "15%",
      align: "center",
      render: (_: any, record: Partial<IPointManagement>): any => {
        if (record?.provider?.type === "NORMAL") {
          return "일반 회원";
        }
        return formatBusinessNumber(record?.provider?.businessNumber ?? "");
      },
    },
    {
      title: t("pm.office_number"),
      dataIndex: "officeNumber",
      key: "officeNumber",
      align: "center",
      render: (_: any, record: Partial<IPointManagement>): any => {
        if (record?.provider?.type === "NORMAL") {
          return "일반 회원";
        }
        return formatPhoneNumber(record?.provider?.officeNumber ?? "");
      },
    },
    {
      title: t("sales_graph_shipping_amount"),
      dataIndex: "providerPaymentAmount",
      key: "providerPaymentAmount",
      width: "18%",
      align: "center",
      render: (providerPaymentAmount): any => {
        return <div className="content-right">{`${formatMoney(providerPaymentAmount)}원`}</div>;
      },
    },
    {
      title: t("sales_graph_point_amount"),
      dataIndex: "id",
      key: "point-amount",
      width: "194px",
      align: "center",
      render: (_, record: Partial<IPointManagement>) => {
        return <div className="content-right">{`${formatMoney(record?.point)}원`}</div>;
      },
    },
  ];

  return (
    <>
      <div className="member-filter">
        {memberTypes.map((type) => (
          <span
            className={`member-type ${memberType === type.value ? "active" : ""}`}
            key={type.value}
            onClick={() => setMemberType(type.value)}
          >
            {type.label}
          </span>
        ))}
      </div>

      <Table
        columns={columns}
        dataSource={data?.data}
        loading={isFetching}
        pagination={
          data?.meta?.lastPage > 1
            ? {
                current: params.page,
                total: data?.meta?.total,
                position: ["bottomCenter"],
                pageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: false,
                onChange(page, pageSize) {
                  setParams({ ...params, page, limit: pageSize });
                },
              }
            : false
        }
      />
    </>
  );
}
