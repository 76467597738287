import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import icMore from "../../../../assets/images/ic_more.svg";
import icIronMedal from "../../../../assets/images/ic_iron_medal.svg";
import icBronzeMedal from "../../../../assets/images/ic_bronze_medal.svg";
import icSilverMedal from "../../../../assets/images/ic_silver_medal.svg";
import icGoldMedal from "../../../../assets/images/ic_gold_medal.svg";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import "./style.scss";
import { useGetCarrierQuery } from "../../../../pages/MemberManagement/memberApi";
import {
  useGetOrdersInRequestQuery,
  usePaymentRequestMutation,
  useUnpaymentRequestMutation,
} from "../../../../pages/SettlementManagement/settlementApi";

import { formatMoney } from "../../../../utils/utils";

export default function CarrierDetailSettlements(props: any) {
  let info = props?.data?.info;
  let orderStatus = props?.data?.info?.settlementStatus;
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [paymentRequest] = usePaymentRequestMutation();
  const [unpaymentRequest] = useUnpaymentRequestMutation();

  const { data: dataOrdersInRequest, isSuccess: ordersRequestSuccess } =
    useGetOrdersInRequestQuery(
      {
        id: info?.id,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: false,
      }
    );
  const { data } = useGetCarrierQuery(
    {
      id: info?.carrier.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );
  const [totalCases, setTotalCases] = useState(0);
  useEffect(() => {
    if (ordersRequestSuccess) {
      let totalCases = dataOrdersInRequest?.orders.length;
      setTotalCases(totalCases);
    }
  }, [props, ordersRequestSuccess, dataOrdersInRequest]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const getTextSubmit = () => {
    if (orderStatus) {
      return t("settlement_cancellation");
    } else if (!orderStatus) {
      return t("to_settle");
    } else {
      return "";
    }
  };
  const getTextStatus = () => {
    if (orderStatus) {
      return t("settlement_completed");
    } else if (!orderStatus) {
      return t("waiting_for_settlement");
    } else {
      return "";
    }
  };

  const getMedal = () => {
    const scoreRating = data?.scoreRating ?? 0;
    if (scoreRating > 89) {
      return icGoldMedal;
    } else if (scoreRating > 79) {
      return icSilverMedal;
    } else if (scoreRating > 69) {
      return icBronzeMedal;
    }
    return icIronMedal;
  };

  return (
    <div className="carrier-detail-settlements-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-left">
          <div className="top-left">
            <div className="representative-name">
              <div className="text-title">{info.carrier.username}</div>
              <div className="text-sub">{t("name")}</div>
            </div>
            <div className="more-wrapper"></div>
          </div>
          {info.carrier.status === "ACTIVE" && (
            <div className="manner-wrapper">
              <div className="manner-item">
                <div className="text-sub">{t("manner_score")}</div>
                <div className="text-title">
                  {data?.scoreRating ?? 0}
                  {t("points")}
                </div>
              </div>
              <div className="manner-medal">
                <img className="ic-medal" alt="medal" src={getMedal()} />
              </div>
            </div>
          )}
          <div className="content-item">
            <div className="text-sub">{t("nickname")}</div>
            <div className="text-title">{info.carrier.nickname}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("contact")}</div>
            <div className="text-title">{info.carrier.phone}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("vehicle_number")}</div>
            <div className="text-title">{info.carrier.region}{" "}{info.carrier.licensePlate}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("bank")}</div>
            <div className="text-title">{info.carrier.bank}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("account_number")}</div>
            <div className="text-title">{info.carrier.accountBank}</div>
          </div>
          <div className="content-contact">
            <div className="content-contact-item">
              <div className="text-sub">{t("tonnage")}</div>
              <div className="text-title">{info.carrier.tonnage}</div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("car_type")}</div>
              <div className="text-title">{info.carrier.vehicleType}</div>
            </div>
          </div>
          <div className="content-contact">
            <div className="content-contact-item">
              <div className="text-sub">{t("length")}</div>
              <div className="text-title">
                {info.carrier.length.toFixed(2)}M
              </div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("width")}</div>
              <div className="text-title">{info.carrier.width.toFixed(2)}M</div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("height")}</div>
              <div className="text-title">
                {info.carrier.height.toFixed(2)}M
              </div>
            </div>
          </div>
        </div>
        <div className="content-right">
          <div className="content-right-header">
            <div className="action">
              <div
                onClick={() => {
                  // waiting settlement
                  console.log("xx");
                }}
                className={`action-status ${
                  orderStatus ? "DONE" : "WAITING_PAYMENT"
                }`}
              >
                {getTextStatus()}
              </div>
              <div
                onClick={() => {
                  // to settle ment
                  console.log("xxzzz", orderStatus, info);
                  if (info) {
                    if (orderStatus) {
                      unpaymentRequest({
                        id: info.id,
                      })
                        .unwrap()
                        .then((res) => {
                          props.onClose();
                        })
                        .catch((error) => {
                          dispatch(
                            openDialog({
                              type: "info",
                              content: error?.data?.message,
                              confirmText: t("dialog_btn_confirm"),
                            })
                          );
                        });
                    } else {
                      paymentRequest({
                        id: info.id,
                      })
                        .unwrap()
                        .then((res) => {
                          props.onClose();
                        })
                        .catch((error) => {
                          dispatch(
                            openDialog({
                              type: "info",
                              content: error?.data?.message,
                              confirmText: t("dialog_btn_confirm"),
                            })
                          );
                        });
                    }
                  }
                }}
                className={`action-fetch ${orderStatus}`}
              >
                {getTextSubmit()}
              </div>
            </div>
          </div>
          <div className="content-right-body">
            <div className="item-total">
              <div className="total-cases">
                {t("total")} {totalCases} {t("cases")}{" "}
              </div>
              <div className="total-amount">
                <div className="amount">
                  {formatMoney(parseInt(info?.totalFreightCost))}
                </div>{" "}
                <div className="won">&nbsp;{t("won")}</div>
              </div>
            </div>
            <div className="list">
              {dataOrdersInRequest?.orders.map((order: any) => {
                return (
                  <div className="item-row" key={order.id}>
                    <div className="item-col">
                      <div className="item-title">{t("loading_place")}</div>
                      <div className="item-sub text-ellipsis">
                        {order.loadingPlace} {order.detailLoadingPlace}
                      </div>
                    </div>
                    <div className="item-col">
                      <div className="item-title">{t("unloading_place")}</div>
                      <div className="item-sub text-ellipsis">
                        {order.receiptPlace} {order.detailReceiptPlace}
                      </div>
                    </div>
                    <div className="item-col basis-1 flex-end">
                      <div className="item-title">
                        {formatMoney(
                          parseInt(
                            (parseInt(order.freightCost) -
                              parseInt(order.excludingFee)) *
                              1.1 +
                              ""
                          )
                        )}
                        {t("won")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
