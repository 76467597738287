import { Moment } from "moment-timezone";
import { useEffect, useState } from "react";
import { DATE_FORMAT_DOT, DEFAULT_PAGE_SIZE, STATUS_DONE, STATUS_WAITING_PAYMENT } from "../../../utils/constants";
import { IParams } from "../SalesGraph";
import { useGetOrderQuery } from "../settlementApi";
import { formatNumberToCurrency, formatPhoneNumber, objectToQueryString } from "../../../utils/utils";
import moment from "moment";
import Table, { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../components/customDialog/dialogSlice";
import { closeModal, openModal } from "../../../components/customModal/modalSlice";
import { IOrder } from "../../../utils/types/order";
import { ICarrierSettlement } from "../CarrierSettlement";
import { getDateFromData, getDateToData } from "../../../utils/commonFnc";

interface ISalesGraphSettlementProps {
  startDate: Moment | null;
  endDate: Moment | null;
}

export default function SalesGraphSettlement({ startDate, endDate }: ISalesGraphSettlementProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: DEFAULT_PAGE_SIZE,
  });
  const [arrData, setArrData] = useState<ICarrierSettlement[]>([]);

  const transformParamsGetPoints = (params: IParams) => {
    let paramsFilters = {
      ...params,
      searchAnd: [JSON.stringify({ status: STATUS_DONE })],
      fieldSearchTime: "adminPaidDate",
      order: "desc",
      orderBy: "adminPaidDate",
    };
    return objectToQueryString(paramsFilters);
  };

  useEffect(() => {
    const newParams = { ...params };
    if (startDate && moment(startDate).isValid()) {
      newParams["from"] = getDateFromData(startDate);
    } else {
      delete newParams.from;
    }
    if (endDate && moment(endDate).isValid()) {
      newParams["to"] = getDateToData(endDate);
    } else {
      delete newParams.to;
    }
    newParams["page"] = 1;
    setParams(newParams);
  }, [startDate, endDate]);

  const { data, error, isFetching } = useGetOrderQuery(transformParamsGetPoints(params), {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    if (error && "status" in error && !isNaN(+error?.status) && +error?.status >= 300) {
      setArrData([]);
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  }, [error]);

  useEffect(() => {
    let arr: ICarrierSettlement[] = [];
    data?.data.map((item: IOrder) => {
      let itemData: ICarrierSettlement = {
        id: item.id,
        completeDateAndTime: item?.adminPaidDate,
        name: item.carrier?.name,
        nickname: item.carrier?.nickname,
        contact: formatPhoneNumber(item.carrier?.phone ?? ""),
        vehicleNumber: item.carrier?.licensePlate,
        region: item.carrier?.region,
        bankAccount: item.carrier?.accountBank,
        carrierSettlementAmount: `${formatNumberToCurrency(item?.carrierSettlementAmount)} ${t("won")}`,
        settlementStatus: item.status,
        carrier: item.carrier,
        order: item,
      };
      arr.push(itemData);
    });

    setArrData(arr);
  }, [JSON.stringify(data)]);

  /* ------------------------------- 운송인의 디테일 열기 ------------------------------ */
  const onOpenCarrierDetail = (request: any) => {
    if (request) {
      dispatch(
        openModal({
          template: "show-carrier-detail-settlement-modal",
          width: "818px",
          data: {
            info: request,
          },
        }),
      );
    }
  };

  const columns: ColumnsType<ICarrierSettlement> = [
    {
      title: t("complete_date_and_time"),
      dataIndex: "completeDateAndTime",
      key: "completeDateAndTime",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (completeDateAndTime) => (
        <div>
          {moment(completeDateAndTime).isValid() && (
            <>
              <span>{moment(completeDateAndTime).format(DATE_FORMAT_DOT)}</span>
              <br />
              <span>{moment(completeDateAndTime).format("hh:mm A")}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: "10%",
      ellipsis: true,
      align: "center",
    },
    {
      title: t("nickname"),
      dataIndex: "nickname",
      key: "nickname",
      width: "10%",
      ellipsis: true,
      align: "center",
    },
    {
      title: t("contact"),
      dataIndex: "contact",
      key: "contact",
      width: "13%",
      ellipsis: true,
      align: "center",
    },
    {
      title: t("vehicle_number"),
      dataIndex: "vehicleNumber",
      key: "vehicleNumber",
      width: "15%",
      ellipsis: true,
      align: "center",
      render: (_, record: ICarrierSettlement) => (
        <>
          {record?.region || "-"} {record?.vehicleNumber || "-"}
        </>
      ),
    },
    {
      title: t("account_number"),
      dataIndex: "bankAccount",
      key: "bankAccount",
      width: "12%",
      ellipsis: true,
      align: "center",
    },
    {
      title: t("settlement_request_amount"),
      dataIndex: "carrierSettlementAmount",
      key: "carrierSettlementAmount",
      width: "12%",
      ellipsis: true,
      align: "center",
      render: (value) => <div className="content-right">{value}</div>,
    },
    {
      title: t("settlement_status"),
      dataIndex: "settlementStatus",
      key: "settlementStatus",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (status) => (
        <div
          className={`${
            status === `${STATUS_WAITING_PAYMENT}` ? "pending-approval" : status === `${STATUS_DONE}` ? "approved" : ""
          }`}
        >
          {status === `${STATUS_WAITING_PAYMENT}`
            ? t("issuance_completed")
            : status === `${STATUS_DONE}`
            ? t("settlement_completed")
            : ""}
        </div>
      ),
    },
    {
      title: t("more_information"),
      dataIndex: "carrier",
      key: "carrier",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (_, order: ICarrierSettlement) => (
        <span
          className="more-information"
          onClick={() => {
            onOpenCarrierDetail(order);
          }}
        >
          {t("more_information")}
        </span>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={arrData}
      loading={isFetching}
      pagination={
        data?.meta?.lastPage > 1
          ? {
              current: params.page,
              total: data?.meta?.total,
              position: ["bottomCenter"],
              pageSize: DEFAULT_PAGE_SIZE,
              showSizeChanger: false,
              onChange(page, pageSize) {
                setParams({ ...params, page, limit: pageSize });
              },
            }
          : false
      }
    />
  );
}
