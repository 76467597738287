import "./style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import { useGetOrderQuery, useUpdatePaybackStatusMutation } from "../../../../pages/SettlementManagement/settlementApi";
import {
  DATE_FORMAT_DOT,
  GMT_KO,
  PAYMENT_TABLE_LIMIT,
  STATUS_DONE,
  STATUS_WAITING_PAYMENT,
} from "../../../../utils/constants";
import { formatMoney } from "../../../../utils/utils";
import moment from "moment";
import { getDateFromData } from "../../../../utils/commonFnc";
const SEARCH_PAGE_LIMIT = `page=${1}&limit=${PAYMENT_TABLE_LIMIT}&`;
const SEARCH_ORDER = `order=desc&orderBy=updatedAt`;
const settlementAt = "deliveredAt";
export default function ShipperDetailSettlements(props: any) {
  let info = props?.data?.info;
  let orderStatus = props?.data?.infoStatus;
  let infoDate = props?.data?.infoDate;
  const month = new Date(infoDate).getMonth() + 1;
  const year = new Date(infoDate).getFullYear();
  const nextMonth = moment(new Date(infoDate).setMonth(new Date(infoDate).getMonth() + 1)).format(DATE_FORMAT_DOT);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [updatePaybackStatus] = useUpdatePaybackStatusMutation();
  const SEARCH_AND_SHIPPER = `searchAnd={"providerId":${info?.id}}&fieldSearchTime=${settlementAt}&`;
  const SEARCH_DATE = `from=${encodeURIComponent(getDateFromData(infoDate))}&to=${encodeURIComponent(
    getDateFromData(nextMonth),
  )}&`;
  const SEARCH_PARAMS = SEARCH_PAGE_LIMIT + SEARCH_AND_SHIPPER + SEARCH_DATE + SEARCH_ORDER;

  const {
    data: dataOrder,
    error,
    isSuccess,
  } = useGetOrderQuery(SEARCH_PARAMS, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [totalCases, setTotalCases] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    if (isSuccess) {
      let totalAmount = 0;
      let totalCases = dataOrder?.data.length;
      dataOrder?.data.map((order: any) => {
        totalAmount = totalAmount + Number(order.freightCost) + Number(order.vat);
      });
      setTotalAmount(totalAmount);
      setTotalCases(totalCases);
    }
  }, [props, isSuccess, dataOrder]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const getTextSubmit = () => {
    if (orderStatus === true) {
      return t("settlement_cancellation");
    } else if (orderStatus === false) {
      return t("to_settle");
    } else {
      return "";
    }
  };
  const getTextStatus = () => {
    if (orderStatus === true) {
      return t("settlement_completed");
    } else if (orderStatus === false) {
      return t("waiting_for_settlement");
    } else {
      return "";
    }
  };
  return (
    <div className="shipper-detail-settlements-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-left">
          <div className="top-right">
            <div className="representative-name">
              {/*  <div className="text-title">{info?.representationName}</div>
              <div className="text-sub">{t("representative_name")}</div> */}
            </div>
            <div className="more-wrapper"></div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("company_name")}</div>
            <div className="text-title">{info?.company}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("office_number")}</div>
            <div className="text-title">{info?.officeNumber}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("business_number")}</div>
            <div className="text-title">{info?.businessNumber}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("bank")}</div>
            <div className="text-title">{info?.bank}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("account_number")}</div>
            <div className="text-title">{info?.accountBank}</div>
          </div>
          <div className="content-contact">
            <div className="content-contact-item">
              <div className="text-sub">{t("additional_contact_number")}</div>
              <div className="text-title">
                {info?.companyContacts && info?.companyContacts.length > 0 && info?.companyContacts[0]}
              </div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("additional_contact_number")}</div>
              <div className="text-title">
                {info?.companyContacts && info?.companyContacts.length > 1 && info?.companyContacts[1]}
              </div>
            </div>
          </div>
        </div>
        <div className="content-right">
          <div className="content-right-header">
            <div className="action">
              <div className={`action-status ${orderStatus ? "DONE" : "WAITING_PAYMENT"}`}>{getTextStatus()}</div>
              <div
                onClick={() => {
                  console.log("xxzzz");
                  updatePaybackStatus({
                    provider_id: info?.id,
                    month: month,
                    year: year,
                    status: !orderStatus,
                  })
                    .unwrap()
                    .then((res) => {
                      props.onClose();
                    })
                    .catch((error) => {
                      dispatch(
                        openDialog({
                          type: "info",
                          content: error?.data?.message,
                          confirmText: t("dialog_btn_confirm"),
                        }),
                      );
                    });
                }}
                className={`action-fetch ${orderStatus ? "DONE" : "WAITING_PAYMENT"}`}
              >
                {getTextSubmit()}
              </div>
            </div>
          </div>
          <div className="content-right-body">
            <div className="item-total">
              <div className="total-cases">
                {t("total")} {totalCases}
                {t("cases")}{" "}
              </div>
              <div className="total-amount">
                <div className="amount">{formatMoney(totalAmount)}</div> <div className="won">&nbsp;{t("won")}</div>
              </div>
            </div>
            <div className="list">
              {dataOrder?.data.map((order: any) => {
                return (
                  <div className="item-row" key={order.id}>
                    <div className="item-col">
                      <div className="item-title">{t("loading_place")}</div>
                      <div className="item-sub text-ellipsis">
                        {order.loadingPlace} {order.detailLoadingPlace}
                      </div>
                    </div>
                    <div className="item-col">
                      <div className="item-title">{t("unloading_place")}</div>
                      <div className="item-sub text-ellipsis">
                        {order.receiptPlace} {order.detailReceiptPlace}
                      </div>
                    </div>
                    <div className="item-col basis-1 flex-end">
                      <div className="item-title">
                        {formatMoney(Number(order.freightCost) + Number(order.vat))}
                        {t("won")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
