import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment-timezone";
import { ICoupon } from "./types/coupon";
import { truncate } from "lodash";

/* --------------------------------- 파일 다운로드 -------------------------------- */
export const downloadFile = async (link: string, name?: string) => {
  const filename = name ? name : getFileName(link);
  axios
    .get(link, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};

/* -------------------------------- 파일명 가져오기 -------------------------------- */
export const getFileName = (link: string | undefined) => {
  if (!link) {
    return "";
  }
  const split = link.split("/");
  return split[split.length - 1];
};

/* ---------------------------------- 쉼표 추가 --------------------------------- */
export const addCommas = (num: any, style = ",") => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, style);
};

export const removeNonNumeric = (num: string) => {
  const result = num ? Math.abs(parseInt(num.replace(/[^\d]/g, ""))) : 0;
  return result;
};

/* ---------------------------------- 금액 포맷 --------------------------------- */
export const formatMoney = (text: any) => {
  if (!text) {
    return "0";
  }
  return addCommas(removeNonNumeric(text?.toString()));
};

/* ---------------------------------- 금액 포맷 --------------------------------- */
export const formatNumberToCurrency = (n = 0, toFixed = 0) => {
  n = n || 0;

  let reg = /(\d)(?=(\d{3})+(?:\.\d+)?$)/g;

  let number = parseFloat(n.toString());
  if (parseInt(n.toString(), 10) - number === 0) {
    number = parseInt(n.toString(), 10);
    return number.toString().replace(reg, "$&,");
  } else {
    const num = parseFloat(n.toString()).toFixed(toFixed);
    return num.toString().replace(reg, "$&,");
  }
};

/* ---------------------------------- 월 포맷 ---------------------------------- */
export const coverMonth = (month: any) => {
  let month_cover = month;
  if (month / 10 < 1) {
    //@ts-ignore
    month_cover = `0${month}`;
  }
  return month_cover;
};

/* ------------------------------- 날짜 양식 가져오기 ------------------------------- */
export const getDateFormat = (dateStr: string, format: string = "yyyy.MM.DD") => {
  const date = moment(dateStr);
  if (date?.isValid()) {
    return date.format(format)?.replace("AM", "오전")?.replace("PM", "오후");
  }
  return "";
};

/* ------------------------------ String로 대상 변경 ----------------------------- */
export const objectToQueryString = (obj: any) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(key, item);
        });
      } else {
        params.append(key, value);
      }
    }
  }

  return params.toString();
};

/* -------------------------------- 전화 번호의 포맷 ------------------------------- */
export const formatPhoneNumber = (phoneNumber: string) => {
  if (typeof phoneNumber === "string") {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{1,4})/, "$1-$2-$3");
  }
  return "";
};

export const formatBusinessNumber = (businessNumber: string) => {
  if (typeof businessNumber === "string") {
    return businessNumber.replace(/(\d{3})(\d{2})(\d{1,5})/, "$1-$2-$3");
  }
  return "";
};

export const formatDuration = (seconds: number) => {
  if (typeof seconds !== "number" || seconds < 0) {
    return 0;
  }
  let hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  let duration = hour + " 시간 " + min + " 분";
  if (hour <= 0 && min > 0) {
    duration = min + " 분";
  }

  if (hour <= 0 && min <= 0 && seconds > 0) {
    duration = "1 분";
  }

  if (hour > 0 && min === 0) {
    duration = hour + " 시간";
  }

  return duration;
};

export const checkCouponExpired = (coupon: ICoupon) => {
  if (coupon.status === "EXPIRED") {
    return true;
  }
  // check end date time
  if (moment(coupon.endDate).isValid() && moment(coupon.endDate).endOf("date").diff(moment(), "seconds") <= 0) {
    return true;
  }
  return false;
};

export const truncateFileName = (fileName: string, maxLength: number) => {
  const dotIndex = fileName.lastIndexOf(".");
  const name = fileName.slice(0, dotIndex);
  const extension = fileName.slice(dotIndex + 1);

  const truncatedName = truncate(name, {
    length: maxLength,
    omission: "...",
  });
  return `${truncatedName}.${extension}`;
};
