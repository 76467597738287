/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const advertisementApi = createApi({
  reducerPath: "advertisementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetAdvertisements"],
  endpoints: (builder) => ({
    postAdvertisements: builder.mutation({
      query: (data) => ({
        url: `advertisements/video/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetAdvertisements"],
    }),
    getAdvertisements: builder.query({
      query: (data) => ({
        url: `/advertisements`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetAdvertisements"],
    }),
    createAdvertisements: builder.mutation({
      query: (data) => ({
        url: `advertisements`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetAdvertisements"],
    }),
    deleteAdvertisements: builder.mutation({
      query: (data) => ({
        url: `advertisements/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetAdvertisements"],
    }),
    getVideoAdvertisement: builder.query({
      query: (data) => ({
        url: `advertisements/video/detail`,
        method: "GET",
        params: data,
      }),
      // @ts-ignore
      invalidatesTags: ["GetAdvertisementVideo"],
    }),
    updateVideoAdvertisement: builder.mutation({
      query: (data) => ({
        url: `advertisements/video/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  usePostAdvertisementsMutation,
  useGetAdvertisementsQuery,
  useCreateAdvertisementsMutation,
  useDeleteAdvertisementsMutation,
  useGetVideoAdvertisementQuery,
  useUpdateVideoAdvertisementMutation,
} = advertisementApi;
