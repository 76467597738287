import React, { useState } from "react";
import "./style.scss";
import icClose from "../../../../assets/images/ic_close.svg";
import { useTranslation } from "react-i18next";
import { useGetOrderDetailQuery, useUpdateBillMutation } from "../../../../pages/SettlementManagement/settlementApi";
import { getDateFormat } from "../../../../utils/utils";
import { openDialog, closeDialog } from "../../../customDialog/dialogSlice";
import { CarrierFreightCost } from "../../../orderInfo/carrierFreightCost";
import { ShippingInfo } from "../../../orderInfo/shippingInfo";
import { useDispatch } from "react-redux";
import { closeModal } from "../../modalSlice";
import { Button, Skeleton } from "antd";

const TransportDetail = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateBill] = useUpdateBillMutation();
  const [loadingUpdateBill, setloadingUpdateBill] = useState(false);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const { data, isFetching, refetch } = useGetOrderDetailQuery({ id: props?.data?.id });
  const isCancelDispatch = props?.data?.isCancelDispatch;

  /* --------------------------------- 데이타 전환 --------------------------------- */
  const transformData = (item: any) => {
    if (!item) {
      return {};
    }
    return {
      ...item,
      expectedStartDate: getDateFormat(item.expectedStartDate, "yyyy.MM.DD A hh시 mm분"),
      expectedEndDate: getDateFormat(item.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
      actualStartDate: getDateFormat(item.actualStartDate, "yyyy.MM.DD A hh시 mm분"),
      actualEndDate: getDateFormat(item.actualEndDate, "yyyy.MM.DD A hh시 mm분"),
    };
  };

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBack = () => {
    dispatch(closeModal());
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: !error?.data?.message ? msgSuccess : error?.data?.message,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          if (!error?.data?.message) {
            onBack();
          } else {
            dispatch(closeDialog());
          }
        },
      }),
    );
  };

  const handleEditBill = (id: string, carrierId: string) => {
    dispatch(
      openDialog({
        type: "confirm-bill",
        content: (
          <div className="font-bold">
            <div className="font-bold">발행을 취소 하시겠습니까? 세금 계산서 금액의</div>
            <div className="font-bold">마이너스 금액 수정 세금 계산서가 수정 발행 됩니다.</div>
          </div>
        ),
        confirmText: "닫기",
        closeText: "확인",
        actionConfirm: () => {
          setloadingUpdateBill(true);
          updateBill({
            orderId: id,
            carrierId: carrierId,
          })
            .unwrap()
            .then((res) => {
              console.log("updateBill success", res);
              setloadingUpdateBill(false);
              refetch();
              let errorMsg = res?.error?.ResultMessage;
              if (errorMsg) {
                dispatch(
                  openDialog({
                    type: "info",
                    content: errorMsg,
                    confirmText: "확인",
                  }),
                );
              } else {
                dispatch(closeModal());
                props?.handleAction();
              }
            })
            .catch((error) => {
              console.log("updateBill error", error);
              setloadingUpdateBill(false);
              dispatch(
                openDialog({
                  type: "info",
                  content: error?.data?.message,
                  confirmText: "확인",
                }),
              );
              props?.handleAction();
              refetch();
            });
        },
      }),
    );
  };

  return (
    <div className="sign-up-approval-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("tranport_detail")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className={data?.requestBillStatus === "APPROVED" ? "title-pending" : "title-completed"}>
        {data?.requestBillStatus === "APPROVED" ? "발행 완료" : "발행 대기"}
      </div>
      <div className="content-wrapper">
        {data && !isFetching ? (
          <>
            <div className="shipping-info">
              <ShippingInfo
                data={transformData(data)}
                showContactInfo
                showCarrierInfo={true}
                isShowBadge={props?.data?.fromPage === "settlement-management/carrier-request"}
                isCancelDispatch={isCancelDispatch}
              />
            </div>
            <div className="freight-cost">
              <CarrierFreightCost data={data} />
            </div>

            <div className="button-wrap">
              <div className="base-btn btn-cancel" onClick={onBack}>
                {t("이전")}
              </div>
              {data?.requestBillStatus === "APPROVED" && (
                <Button
                  className="base-btn edit-bill-bt"
                  loading={loadingUpdateBill}
                  onClick={() => handleEditBill(data?.id, data?.carrier?.id)}
                >
                  발행취소
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>
    </div>
  );
};

export default TransportDetail;
