export enum EStatus {
  PUBLIC = "PUBLIC",
  UNPUBLIC = "UNPUBLIC",
}

export interface IPost {
  id: string;
  carrierId?: number;
  title?: string;
  content?: string;
  status?: EStatus;
  createdAt?: Date;
  updatedAt?: Date;
  carrier?: IPostCarrier;
  _count?: IPostComment;
  scoreRating?: number;
}

export interface IPostComment {
  comments?: number;
}

export interface IPostCarrier {
  id?: number;
  phone?: string;
}

export interface IComment {
  id: string;
  postId?: string;
  carrierId?: number;
  content?: string;
  status?: EStatus;
  createdAt?: Date;
  updatedAt?: Date;
  carrier?: ICommentCarrier;
}

export interface ICommentCarrier {
  username?: string;
  nickname?: string;
  scoreRating?: number;
  phone?: string;
}
