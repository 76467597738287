import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useGetProvidersQuery, useGetShippersHistoryQuery } from "./settlementApi";
import { useEffect, useState } from "react";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { PAYBACK_RATE, PER_PAGE } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import NewTable from "../../components/NewTable";
import queryString from "query-string";

interface DataType {
  id: number;
  representationName: string; //representationName
  company: string; //company
  businessNumber: string; //businessNumber
  officeNumber: string; //officeNumber
  totalFreightCost: string; //totalFreightCost
  totalVat: string; //totalFreightCost
  paybackAmount: number; //totalFreightCost * PAYBACK_RATE
}

enum EPayType {
  ALL,
  PRE,
  LATER,
}

export default function SettlementHistoryShipper() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<string>("");
  const [filters, setFilters] = useState<string[]>(["representationName"]);
  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    type: "PAY_LATER",
  });

  const { data, error, isSuccess, isFetching } = useGetProvidersQuery(searchParams, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [arrData, setArrData] = useState<any>([]);

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: params.limit,
      orderBy: "createdAt",
      order: "desc",
      isOrderCompletedAtLeastOne: true,
    };

    let filtersQuery = "";
    if (params.search) {
      _params.keyword = params.search;
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }

    let paramQuery = queryString.stringify(_params);
    paramQuery += `&${filtersQuery}&searchAnd[]=${JSON.stringify({ type: "PAY_LATER" })}`;
    return `${paramQuery}`;
  };

  useEffect(() => {
    setSearchParams(getSearchParams(params));
  }, [params]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      let arr: any = [];
      data?.data.map((item: any) => {
        let itemData: DataType = {
          id: item.id,
          representationName: item.representationName,
          company: item.company,
          businessNumber: item.businessNumber,
          officeNumber: item.officeNumber,
          totalFreightCost: item.totalCost,
          totalVat: item.totalVat,
          paybackAmount: item.totalFreightCost * PAYBACK_RATE,
        };
        arr.push(itemData);
      });
      setArrData(arr);
      setMetaData(data?.meta);
    }

    return () => {};
  }, [data]);

  const columns: ColumnsType<DataType> = [
    {
      title: t("representative_name"),
      dataIndex: "representationName",
      key: "representationName",
      width: "10%",
      ellipsis: true,
    },
    {
      title: t("company_name"),
      dataIndex: "company",
      key: "company",
      width: "20%",
      ellipsis: true,
    },
    {
      title: t("business_number"),
      dataIndex: "businessNumber",
      key: "businessNumber",
      width: "20%",
      ellipsis: true,
    },
    {
      title: t("office_number"),
      dataIndex: "officeNumber",
      key: "officeNumber",
      width: "20%",
      ellipsis: true,
    },
    {
      title: t("total_shipping_amount"),
      dataIndex: `totalFreightCost`,
      key: "totalFreightCost",
      ellipsis: true,
      render: (totalFreightCost, item) => `${new Intl.NumberFormat().format(totalFreightCost)} ${t("원")}`,
    },
    // {
    //   title: t("total_payback_amount"),
    //   dataIndex: "totalFreightCost",
    //   key: "totalFreightCost",
    //   ellipsis: true,
    //   render: (totalFreightCost) =>
    //     `${new Intl.NumberFormat().format(Number(totalFreightCost) * PAYBACK_RATE)} ${t("원")}`,
    // },
    /*    {
      title: t("membership_status"),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div
          className={`${
            status === "ACTIVE"
              ? "approved"
              : status === "INACTIVE"
              ? "pending-approval"
              : ""
          }`}
        >
          {status === "ACTIVE"
            ? t("approved")
            : status === "INACTIVE"
            ? t("waiting_for_approval")
            : t("blocked")}
        </div>
      ),
    }, */
    {
      title: t("more_information"),
      dataIndex: "id",
      key: "id",
      width: "10%",
      ellipsis: true,
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id) => (
        <a
          className="more-information"
          onClick={() => {
            navigate(`/settlement-management/shipper-history/detail?id=${id}`);
          }}
        >
          {t("more_information")}
        </a>
      ),
    },
  ];

  // const filterOptions = [
  //   { label: t("all"), value: EPayType.ALL },
  //   { label: t("선불"), value: EPayType.PRE },
  //   { label: t("후불"), value: EPayType.LATER },
  // ];

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    { label: t("representative_name"), value: "representationName" },
    { label: t("company_name"), value: "company" },
    { label: t("business_number"), value: "businessNumber" },
    { label: t("office_number"), value: "officeNumber" },
  ];

  return (
    <div className="settlement-management-shipper">
      <NewTable
        showDatepicker={false}
        title={"정산 관리"}
        columns={columns}
        allData={arrData}
        metaData={metaData}
        showDateFilter={true}
        // typeOptions={filterOptions}
        searchFilterOptions={searchFilterOptions}
        filters={filters}
        setFilters={setFilters}
        loading={isFetching}
        params={params}
        setParams={setParams}
      />
    </div>
  );
}
