import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACCESS_TOKEN } from "../../utils/constants";
import { authApi } from "./authApi";
import { unregisterServiceWorkerLogout } from "../../subscription";

interface Auth {
  isUserLogin: boolean;
  step: number;
  accessToken: any;
}

const initialState: Auth = {
  isUserLogin: !!localStorage.getItem(ACCESS_TOKEN),
  step: 1,
  accessToken: localStorage.getItem(ACCESS_TOKEN),
};

const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    userLogin: (state: Auth, action: PayloadAction) => {
      state.isUserLogin = true;
    },
    userLogout: (state: Auth, action: PayloadAction) => {
      state.isUserLogin = false;
      unregisterServiceWorkerLogout();
      console.log("Logging out");
    },
  },
  extraReducers: (builder: any) => {
    // login with RTK query
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state: Auth, action: PayloadAction<any>) => {
        state.isUserLogin = true;
        state.accessToken = action.payload.accessToken;
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state: Auth, action: PayloadAction<any>) => {});
    // check provider id
    // builder
    // .addMatcher(
    //   authApi.endpoints.checkProvider.matchFulfilled,
    //   (state: Auth, action: PayloadAction<any>) => {
    //     // state.isUserLogin = true;
    //   }
    // )
    // .addMatcher(
    //   authApi.endpoints.checkProvider.matchRejected,
    //   (state: Auth, action: PayloadAction<any>) => {}
    // );
  },
});

const { reducer, actions } = userInfo;
export const { userLogin, userLogout } = actions;
export default reducer;
