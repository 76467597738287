import icPlus from "../../assets/images/ic_plus.svg";
import i18n from "../../locales/config";
import "./style.scss";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { openDialog } from "../customDialog/dialogSlice";
import { useDispatch } from "react-redux";
import { API_LINK } from "../../utils/constants";

export default function UploadDocument({ title, icon, value = "", onUploaded = null, onRemove = null }: any) {
  const [selectedFile, setSelectedFile] = useState(value);
  const fileInputRef = useRef();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const handleChange = async (event: any) => {
    // do something with event data
    if (event.target.files.length > 0) {
      const fileName = (event.target.files[0].name || "").toUpperCase();
      const isValidType = !fileName.endsWith(".JPG") && !fileName.endsWith(".PNG");
      if (event.target.files[0].size > 30720000 || isValidType) {
        dispatch(
          openDialog({
            type: "info",
            content: t("pls_attach_less_than_30mb_jpg"),
            confirmText: t("dialog_btn_confirm"),
          }),
        );

        return;
      }
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          if (data?.error != null) {
            dispatch(
              openDialog({
                type: "info",
                content: t("have_some_error"),
                confirmText: t("dialog_btn_confirm"),
              }),
            );
          } else {
            setSelectedFile(event.target.files[0].name);
            onUploaded(data.Location);
          }
        });
    }
  };

  /* -------------------------------- 파일명 가져오기 -------------------------------- */
  const getFileName = () => {
    if (selectedFile.length > 11) {
      return selectedFile.substring(0, 11) + "...";
    }
    return selectedFile;
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteClick = () => {
    setSelectedFile("");
    onRemove();
  };

  return (
    <div className="upload-document">
      <div className="document-content">
        <div className="document-icon">
          <img className="icon" alt="icon" src={icon} />
        </div>
        <div className="document-title">{title}</div>
      </div>
      {selectedFile === "" ? (
        <div
          className="choose-document"
          onClick={() => {
            if (fileInputRef && fileInputRef.current) {
              //@ts-ignore
              fileInputRef.current.click();
            }
          }}
        >
          <div className="plus-icon">
            <img className="icon" alt="icon" src={icPlus} />
          </div>
          <div className="attach-doc">{`${i18n.t("attach_document")}`}</div>
          <input
            onChange={handleChange}
            multiple={false}
            //@ts-ignore
            ref={fileInputRef}
            type="file"
            accept=".jpg,.png"
            hidden
          />
        </div>
      ) : (
        <div className="document-footer">
          <div className="file-name">
            <div className="name-text">{getFileName()}</div>
          </div>
          <div className="remove-btn" onClick={onHandleDeleteClick}>
            <div className="remove-text text-nowrap">{`${i18n.t("delete")}`}</div>
          </div>
        </div>
      )}
    </div>
  );
}
