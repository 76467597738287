import { useState } from "react";
import "./style.scss";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";

export default function ApprovePaylaterRight(props: any) {
  const { onClose, handleAction } = props;
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  /* ------------------------------ dialog 확인 취소 ------------------------------ */
  const handleCancel = () => {
    onClose();
  };

  /* -------------------------------- dialog 확인 ------------------------------- */
  const handleConfirm = () => {
    handleAction();
  };

  return (
    <div className="approve-paylater-right-wrapper">
      <div className="body">
        <div className="title">{t("confirm_approve_paylater")}</div>
        <Checkbox value={checked} onChange={onChange}>
          <div className="content">{t("confirm_approve_paylater_desc")}</div>
        </Checkbox>
      </div>
      <div className="footer">
        <div className="buttons">
          <Button type="text" className="btn" onClick={handleCancel}>
            {t("dialog_btn_cancel")}
          </Button>
          <Button
            type="text"
            className={`btn ${!checked ? "disabled" : ""}`}
            disabled={!checked}
            onClick={handleConfirm}
          >
            {t("dialog_btn_confirm")}
          </Button>
        </div>
      </div>
    </div>
  );
}
