import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActionCancel,
  getActionConfirm,
  getDialogCloseText,
  getDialogConfirmText,
  getDialogContent,
  getDialogStatus,
  getDialogTitle,
  getDialogType,
  getBorderRadiusStatus,
  getDialogWidth,
  getIsActionFlip,
} from "../../store/selector/RootSelector";
import { closeDialog } from "./dialogSlice";

import "./style.scss";

export default function CustomDialog() {
  const isOpen = useSelector(getDialogStatus);
  const type = useSelector(getDialogType);
  const width = useSelector(getDialogWidth);
  const isBorderRadius = useSelector(getBorderRadiusStatus);
  const isActionFlip = useSelector(getIsActionFlip);
  const dialogContent = useSelector(getDialogContent);
  const dialogTitle = useSelector(getDialogTitle);
  const dialogConfirmText = useSelector(getDialogConfirmText);
  const dialogCloseText = useSelector(getDialogCloseText);
  const dialogActionConfirm = useSelector(getActionConfirm);
  const dialogActionCancel = useSelector(getActionCancel);
  const dispatch = useDispatch<any>();

  /* -------------------------------- dialog 닫기 ------------------------------- */
  const handleClose = () => {
    if (type === "info") {
      dialogActionConfirm();
    }
    dispatch(closeDialog());
  };

  /* -------------------------------- dialog 확인 ------------------------------- */
  const handleConfirm = () => {
    dialogActionConfirm();
    dispatch(closeDialog());
  };

  /* ------------------------------ dialog 확인 취소 ------------------------------ */
  const handleCancel = () => {
    dialogActionCancel();
    dispatch(closeDialog());
  };

  return (
    <Modal
      title=""
      width={width}
      open={isOpen}
      onCancel={handleClose}
      closable={false}
      okText="Confirm"
      cancelText="Cancel"
      footer={null}
      centered={true}
      zIndex={2000}
      className={`custom-dialog ${isBorderRadius === "no" && "custom-dialog--noBorderRadius"} `}
    >
      {type === "info" ? (
        <div className="dialog-info">
          <div className="content">{dialogContent}</div>
          <div className="footer" onClick={handleConfirm}>
            {dialogConfirmText}
          </div>
        </div>
      ) : type === "info-square" ? (
        <div className="dialog-info-square">
          <div className="content">{dialogContent}</div>
          <div className="footer" onClick={handleConfirm}>
            <span className="btn-confirm">{dialogConfirmText}</span>
          </div>
        </div>
      ) : type === "confirm-bill" ? (
        <div className="dialog-confirm-bill">
          <div className="title">{dialogTitle}</div>
          <div className="content">{dialogContent}</div>
          <div className="btn-wrapper">
            <Button className="bt-confirm" onClick={handleCancel}>
              {dialogConfirmText}
            </Button>
            <Button className="bt-cancel" onClick={handleConfirm}>
              {dialogCloseText}
            </Button>
          </div>
        </div>
      ) : type === "logout" ? (
        <div className="dialog-confirm-logout">
          <div className="body-wrapper">
            <div className="title">{dialogTitle}</div>
            <div className="content">{dialogContent}</div>
          </div>
          <div className="row-wrapper">
            <div className="base-btn" onClick={handleCancel}>
              <div className="btn-text color-cancel">{dialogCloseText}</div>
            </div>
            <div className="base-btn" onClick={handleConfirm}>
              <div className="btn-text">{dialogConfirmText}</div>
            </div>
          </div>
        </div>
      ) : type === "confirm-delete" ? (
        <div className="dialog-confirm-delete">
          <div className="title">{dialogTitle}</div>
          <div className="content">{dialogContent}</div>
          <div className="btn-wrapper">
            <Button className="bt-confirm-delete" onClick={handleConfirm}>
              {dialogConfirmText}
            </Button>
            <Button className="bt-cancel-delete" onClick={handleCancel}>
              {dialogCloseText}
            </Button>
          </div>
        </div>
      ) : type === "confirm-noti" ? (
        <div className="dialog-confirm">
          <div className="title">{dialogTitle}</div>
          <div className="content">{dialogContent}</div>
          <div className="btn-wrapper">
            <Button className="bt-confirm" onClick={isActionFlip ? handleCancel : handleConfirm}>
              {dialogConfirmText}
            </Button>
            <Button className="bt-cancel-noti" onClick={isActionFlip ? handleConfirm : handleCancel}>
              {dialogCloseText}
            </Button>
          </div>
        </div>
      ) : type === "confirm-unnoti" ? (
        <div className="dialog-confirm">
          <div className="title">{dialogTitle}</div>
          <div className="content">{dialogContent}</div>
          <div className="btn-wrapper">
            <Button className="bt-confirm" onClick={isActionFlip ? handleCancel : handleConfirm}>
              {dialogConfirmText}
            </Button>
            <Button className="bt-cancel-noti2" onClick={isActionFlip ? handleConfirm : handleCancel}>
              {dialogCloseText}
            </Button>
          </div>
        </div>
      ) : (
        <div className="dialog-confirm">
          <div className="title">{dialogTitle}</div>
          <div className="content">{dialogContent}</div>
          <div className="btn-wrapper">
            <Button className="bt-confirm" onClick={isActionFlip ? handleCancel : handleConfirm}>
              {dialogConfirmText}
            </Button>
            <Button className="bt-cancel" onClick={isActionFlip ? handleConfirm : handleCancel}>
              {dialogCloseText}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
