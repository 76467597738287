/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  API_LINK,
  STATUS_ARRIVING,
  STATUS_DELIVERED,
  STATUS_DONE,
  STATUS_WAITING_APPROVAL,
  STATUS_WAITING_MATCHING,
  STATUS_WAITING_PAYMENT,
} from "@/utils/constants";
import queryString from "query-string";

export const vehicleRegisApi = createApi({
  reducerPath: "vehicleRegisApisReturn",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token && !headers.has("Authorization")) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    "GetDoneOrders",
    "GetFavoriteOrders",
    "GetApprovalOrders",
    "GetAllOrders",
    "GetOrderDetail",
    "GetFreightCost",
    "GetNotisStatus",
  ],
  endpoints: (builder) => ({
    readNoti: builder.mutation({
      query: (data) => ({
        url: `orders/provider/read-noti`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetAllOrders", "GetNotisStatus"],
    }),
    readAllNotis: builder.mutation({
      query: (data) => ({
        url: `orders/provider/read-noti/all`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetAllOrders", "GetNotisStatus"],
    }),

    calcMoney: builder.mutation({
      query: (data) => {
        const { additionalEndpoints, ...rest } = data;
        const params = queryString.stringify(rest);
        const additionalEndpointsParams = queryString.stringify(
          { additionalEndpoints },
          {
            arrayFormat: "bracket",
          },
        );

        const fullParams = `?${params}&${additionalEndpointsParams}`;
        return {
          url: `freightManagement/cost${fullParams}`,
          method: "GET",
        };
      },
    }),
    uploadFile: builder.mutation({
      query: (data) => ({
        url: `assets/upload`,
        method: "POST",
        body: data,
        headers: { "Content-Type": "multipart/form-data" },
      }),
    }),
    registerDispatch: builder.mutation({
      query: (data) => ({
        url: `orders`,
        method: "POST",
        body: data,
      }),
    }),
    changeOrderStatusProvider: builder.mutation({
      query: (data) => ({
        url: `providers/${data.id}/orders/${data.order_id}/update-status`,
        method: "PATCH",
        body: {
          status: data.status,
          point: data?.point ?? null,
          providerCouponId: data.couponId ?? null,
          otherPaymentMethod: data?.otherPaymentMethod ?? null,
          otherReasonReject: data?.otherReasonReject ?? null,
          orderTid: data?.order_tid ?? null,
          authToken: data?.authToken ?? null,
          ediDate: data?.ediDate ?? null,
          nextAppURL: data?.nextAppURL ?? null,
          payMethod: data?.payMethod ?? null,
        },
      }),
      invalidatesTags: ["GetAllOrders"],
    }),
    updateDispatch: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: "PATCH",
        body: data.data,
      }),
    }),
    providerApprove: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/orders/${data.orderId}/update-status`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetApprovalOrders"],
    }),
    updateFavouriteDispatch: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/orders/${data.orderId}/favourite`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetFavoriteOrders", "GetDoneOrders", "GetOrderDetail"],
    }),
    getVehicleDetail: builder.query({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetOrderDetail"],
    }),
    getVehicleDetailMT: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: "GET",
      }),
    }),
    getVehicleDetailTk: builder.query({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    getAllVehicle: builder.query({
      query: (data) => {
        let params;
        const { searchAnd, ...rest } = data;
        const restParams = queryString.stringify(rest);
        const scParams = queryString.stringify(
          { ["searchAnd"]: searchAnd },
          {
            arrayFormat: "bracket",
          },
        );

        if (!!scParams) {
          params = restParams?.concat(`&${scParams}`);
        } else {
          params = restParams;
        }
        return {
          url: `orders?${params}`,
          method: "GET",
        };
      },
      providesTags: ["GetAllOrders"],
      invalidatesTags: undefined,
    }),
    getNotisStatus: builder.query({
      query: (data) => {
        const query = queryString.stringify(data, { arrayFormat: "bracket" });

        return {
          url: `orders/noti/unread?${query}`,
          method: "GET",
        };
      },
      providesTags: ["GetNotisStatus"],
    }),
    getPartnerList: builder.query({
      query: (data) => {
        return {
          url: `provider-partner/list`,
          method: "GET",
          params: data,
        };
      },
      providesTags: ["GetNotisStatus"],
    }),

    getListDispatchHistory: builder.mutation({
      query: (data) => {
        return {
          url: `orders?searchOr={"status":"${STATUS_ARRIVING}"}&searchOr={"status":"${STATUS_WAITING_MATCHING}"}&searchOr={"status":"${STATUS_WAITING_APPROVAL}"}`,
          method: "GET",
          params: {
            ...data,
            order: "desc",
            orderBy: "createdAt",
            fieldSearchTime: "createdAt",
            searchAnd: `{"providerId": ${data.providerId}}`,
          },
        };
      },
    }),
    getCountOrder: builder.query({
      query: (data) => ({
        url: `providers/${data.providerId}/count-order`,
        method: "GET",
        params: data.params,
      }),
    }),
    getFavoriteVehicle: builder.query({
      query: (data) => {
        const params = queryString.stringify(data);
        return {
          url: `orders?${params}`,
          method: "GET",
          // params: {
          //   page: data.page,
          //   limit: data.limit,
          // },
        };
      },
      providesTags: ["GetFavoriteOrders"],
    }),
    getWaitingApprovalOrder: builder.query({
      query: ({ providerId, ...rest }) => ({
        url: `orders`,
        method: "GET",
        params: {
          searchAnd: `{"status": "WAITING_APPROVAL", "providerId": ${providerId}}`,
          ...rest,
        },
      }),
      providesTags: ["GetApprovalOrders"],
    }),
    getDoneVehicle: builder.query({
      query: (data) => {
        let params;
        const { searchAnd, searchOr, ...rest } = data;
        const restParams = queryString.stringify(rest);
        const scParams = queryString.stringify(
          { ["searchAnd"]: searchAnd },
          {
            arrayFormat: "bracket",
          },
        );

        if (!!scParams) {
          params = `&${restParams}&${scParams}`;
        } else {
          params = restParams;
        }
        return {
          url: `orders?searchOr={"status":"${STATUS_DONE}"}&searchOr={"status":"${STATUS_DELIVERED}"}&searchOr={"status":"${STATUS_WAITING_PAYMENT}"}${params}`,
          method: "GET",
        };
      },
      providesTags: ["GetDoneOrders"],
    }),
    cancelOrder: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/orders/${data.orderId}/update-status`,
        method: "PATCH",
        body: {
          status: "CANCEL",
        },
      }),
    }),
    getDirection: builder.mutation({
      query: (data) => ({
        url: `maps/direction`,
        method: "GET",
        params: data,
      }),
    }),
    updateScoreRatingOrder: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/orders/${data.order_id}/points`,
        method: "POST",
        body: {
          scoreRating: data.scoreRating,
        },
      }),
      invalidatesTags: ["GetOrderDetail"],
    }),
    getTotalDistance: builder.query({
      query: (data) => ({
        url: `carriers/${data.id}/total-distance`,
        method: "GET",
      }),
    }),
    getRouterUrl: builder.query({
      query: (data) => ({
        url: `carriers/order/routerUrl/${data?.id}`,
        method: "GET",
      }),
    }),
    getListOrders: builder.query({
      query: (data) => ({
        url: "orders" + data.searchOr,
        params: {
          limit: 10,
          order: "desc",
          ...data.params,
          searchAnd: `{"providerId": ${data.params.providerId}}`,
        },
      }),
      providesTags: ["GetAllOrders"],
    }),
    checkCost: builder.mutation({
      query: (data: { id: string; point: number | null; providerCouponId: string | null }) => ({
        url: `/orders/${data.id}/preview-cost`,
        method: "POST",
        body: data,
      }),
    }),
    getOrdersDoneVehicle: builder.mutation({
      query: (data) => ({
        url: `orders?searchOr={"status":"${STATUS_DONE}"}&searchOr={"status":"${STATUS_DELIVERED}"}&searchOr={"status":"${STATUS_WAITING_PAYMENT}"}`,
        method: "GET",
        params: data,
      }),
    }),
  }),
});

export const {
  useReadNotiMutation,
  useReadAllNotisMutation,
  useCalcMoneyMutation,
  useRegisterDispatchMutation,
  useUploadFileMutation,
  useGetPartnerListQuery,
  useChangeOrderStatusProviderMutation,
  useGetVehicleDetailQuery,
  useGetAllVehicleQuery,
  useGetCountOrderQuery,
  useGetFavoriteVehicleQuery,
  useUpdateDispatchMutation,
  useUpdateScoreRatingOrderMutation,
  useCancelOrderMutation,
  useGetDoneVehicleQuery,
  useUpdateFavouriteDispatchMutation,
  useGetWaitingApprovalOrderQuery,
  useProviderApproveMutation,
  useGetDirectionMutation,
  useGetTotalDistanceQuery,
  useGetListDispatchHistoryMutation,
  useGetListOrdersQuery,
  useCheckCostMutation,
  useGetVehicleDetailTkQuery,
  useGetRouterUrlQuery,
  useGetNotisStatusQuery,
  useGetVehicleDetailMTMutation,
  useGetOrdersDoneVehicleMutation,
} = vehicleRegisApi;
