import React, { FC, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Modal } from "antd";
import icClose from "../../assets/images/ic_close.svg";
import { useDispatch } from "react-redux";
import { openDialog } from "../customDialog/dialogSlice";
import { closeModal } from "../customModal/modalSlice";
import { MenuOutlined } from "@ant-design/icons";
import {
  useCreateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useEditFaqCategoryMutation,
} from "../../pages/FaqManagement/categoryApi";
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "@hello-pangea/dnd";

interface Props {
  data?: any;
  onBack?: () => void;
  onDragEnd?: any;
}

export const CategoryManagement: FC<Props> = ({ onBack, data, onDragEnd }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [createFaqCaterogy] = useCreateFaqCategoryMutation();
  const [deleteFaqCaterogy] = useDeleteFaqCategoryMutation();
  const [editFaqCategory] = useEditFaqCategoryMutation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [selectId, setSelectId] = useState();
  const [type, setType] = useState<string>();
  const [sortData, setSortData] = useState(data);

  useEffect(() => {
    setSortData(data);
  }, [JSON.stringify(data)]);

  const onCloseModal = () => setShowModal(false);

  /* ------------------------------- 카테고리 기록 추가 ------------------------------- */
  const onAdd = () => {
    setType("Add");
    setName("");
    setShowModal(true);
  };

  /* ------------------------------- 카테고리 기록 수정 ------------------------------- */
  const onEdit = () => {
    if (selectId) {
      setType("Edit");
      const category = data?.find((e: any) => e.id === selectId);
      setName(category.label);
      setShowModal(true);
    }
  };

  /* ------------------------------- 카테고리 기록 삭제 ------------------------------- */
  const onDelete = (id: number) => {
    dispatch(
      openDialog({
        type: "confirm",
        content: t("delete_category_confirm"),
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: async () => {
          const res = await deleteFaqCaterogy({
            id,
          }); //@ts-ignore
          showResult(res?.error, t("deleted"));
        },
      }),
    );
  };

  const onCancel = () => {
    setName("");
    onCloseModal();
  };

  const onSubmit = () => {
    onCloseModal();
    if (name?.length > 2) {
      if (type === "Edit") {
        onEditCategory();
      } else {
        onRegisterCategory();
      }
    }
  };

  /* ------------------------------- 카테고리 하나 등록 ------------------------------- */
  const onRegisterCategory = () => {
    dispatch(
      openDialog({
        type: "confirm",
        content: t("register_category_confirm"),
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: async () => {
          const res = await createFaqCaterogy({
            name,
          }); //@ts-ignore
          showResult(res?.error, t("registed"));
        },
      }),
    );
  };

  /* ------------------------------- 카테고리 하나 수정 ------------------------------- */
  const onEditCategory = () => {
    dispatch(
      openDialog({
        type: "confirm",
        content: t("edit_category_confirm"),
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: async () => {
          const res = await editFaqCategory({
            id: selectId,
            name,
          }); //@ts-ignore
          showResult(res?.error, t("it_is_changed"));
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    if (!error?.data?.message) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
            onCloseModal();
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: error?.data?.message,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
            onCloseModal();
          },
        }),
      );
    }
  };

  /* ------------------------------- 카테고리 이동 기능 ------------------------------- */
  const handleDragDrop = (param: any) => {
    //to optimistic rendering to prevent un-expect behavior.
    const { source, destination } = param;
    let _arr = [...sortData];
    const _item = _arr.splice(source.index, 1)[0];
    _arr.splice(destination.index, 0, _item);
    setSortData(_arr);
    setTimeout(() => {
      onDragEnd(param);
    }, 100);
  };

  const isExceedMaxRecord = (data?.length || 0) >= 7;

  return (
    <div className="category-management">
      <div className="title">{t("category_management")}</div>
      <div className="body">
        <div className="left">
          <div className="category">
            <div className="title">{t("category")}</div>
          </div>
          <div className="category-list">
            <DragDropContext onDragEnd={handleDragDrop}>
              <Droppable droppableId="comments-wrapper">
                {(provided: any, snapshot: any) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {sortData?.map((item: any, index: number) => {
                      return (
                        <Draggable draggableId={item?.id} index={index} key={item?.id}>
                          {(_provided: any, _snapshot: any) => {
                            return (
                              <div
                                ref={_provided.innerRef}
                                data-rbd-draggable-context-id={item?.id}
                                {..._provided.draggableProps}
                                className={`category-item prevent-select ${
                                  selectId && item?.id === selectId ? "active" : ""
                                }`}
                                key={item?.id}
                                onClick={() => setSelectId(item?.id)}
                              >
                                <span className="name">
                                  <MenuOutlined {..._provided.dragHandleProps} />
                                  {item.label}
                                </span>
                                <div className="delete-btn" onClick={() => onDelete(item?.id)}>
                                  {t("delete")}
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="buttons">
            <div className="button modify" onClick={onEdit}>
              {t("modify")}
            </div>
            <div className={`button add ${isExceedMaxRecord ? "button-disabled" : ""}`} onClick={onAdd}>
              {t("add")}
            </div>
          </div>
        </div>
        <div className="right">
          <div className="add-category">{t("add_category")}</div>
          <div className="line" />
          <div className="instruction">{t("add_category_instruction")}</div>
          <div className="cancel-btn-wrap">
            <div className="cancel-btn" onClick={onBack}>
              <div className="title">{t("go_back")}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        title=""
        footer={null}
        centered={true}
        closable={false}
        onCancel={onCancel}
        className="edit-modal"
        width={448}
        style={{
          height: "257px",
        }}
      >
        <div className="edit-modal-container">
          <div className="modal-header">
            <div className="title">{type === "Edit" ? t("edit_category") : t("add_category")}</div>
            <div className="close-btn" onClick={onCloseModal}>
              <img className="ic-close" alt="Close" src={icClose} />
            </div>
          </div>
          <div className="modal-body">
            <div className="edit-wrap">
              <div className="edit-wrap-title">{t("category_name")}</div>
              <input
                className="input-name"
                placeholder={t("please_enter_category_name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                minLength={2}
                maxLength={10}
              />
            </div>
            <div className="btn-wrap">
              <div className={`btn-register ${name?.length < 2 && "btn-register--disabled"}`} onClick={onSubmit}>
                {type === "Edit" ? t("edit") : t("register")}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
