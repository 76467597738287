import "./style.scss";
import { useTranslation } from "react-i18next";
import icClose from "../../../../assets/images/ic_close.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { formatNumberToCurrency, removeNonNumeric } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal } from "../../modalSlice";
import { useUpdatePricesMutation } from "../../../../pages/DispatchManagement/dispatchApi";
import { Input } from "antd";
import { roundToNearest100 } from "../../../../utils/commonFnc";

export default function FareModification({ onClose, data, handleAction }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [freightCost, setFreightCost] = useState(formatNumberToCurrency(data?.freightCost));
  const [vat] = useState(formatNumberToCurrency(data?.vat));
  const [carrierReceiveAmount, setcarrierReceiveAmount] = useState(
    formatNumberToCurrency(
      data?.carrierPaymentAmount || roundToNearest100((1 - data?.excludingFeeRate) * data?.freightCost),
    ),
  );
  const [excludingFeeRate, setExcludingFeeRate] = useState(
    data?.excludingFeeRate ? Math.round(data?.excludingFeeRate * 100) : 0,
  );

  const [shipperPaymentAmount, setshipperPaymentAmount] = useState(
    formatNumberToCurrency(roundToNearest100(removeNonNumeric(freightCost) * 1.1)),
  );

  const [updatePrices] = useUpdatePricesMutation();
  const [activeSave, setActiveSave] = useState<boolean>(false);

  const onHandleClose = () => {
    onClose();
  };

  const onActiveSaveBtn = () => {
    if (!activeSave) {
      setActiveSave(true);
    }
  };

  //   useEffect(() => {
  //     let newShipperPA = formatNumberToCurrency(removeNonNumeric(freightCost) * 1.1);
  //     if (shipperPaymentAmount !== newShipperPA) {
  //       setshipperPaymentAmount(newShipperPA);
  //     }

  //     return () => {};
  //   }, [freightCost]);

  /* -------------------------------- dialog 확인 ------------------------------- */
  const handleConfirm = async () => {
    dispatch(closeModal());
    const body = {
      id: data.id,
      freightCost: removeNonNumeric(freightCost),
      carrierPaymentAmount: removeNonNumeric(carrierReceiveAmount),
      excludingFeeRate: excludingFeeRate / 100,
      excludingFee: Math.round((excludingFeeRate / 100) * removeNonNumeric(freightCost)),
    };
    const res = await updatePrices(body); //@ts-ignore
    const errMsg = res?.error?.data?.message;
    if (!errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: t("save_fare_description"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
            handleAction();
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
            handleAction();
          },
        }),
      );
    }
  };

  const onChangeFeeRate = (e: ChangeEvent<HTMLInputElement>) => {
    if (removeNonNumeric(e.target.value) > 100) {
      setExcludingFeeRate(100);
      setcarrierReceiveAmount("0");
    } else if (removeNonNumeric(e.target.value) > 0) {
      setExcludingFeeRate(removeNonNumeric(e.target.value));

      let newCarrierPayAmt = formatNumberToCurrency(
        roundToNearest100((1 - parseFloat(e.target.value) / 100) * removeNonNumeric(freightCost)),
      );
      if (newCarrierPayAmt !== carrierReceiveAmount) {
        setcarrierReceiveAmount(newCarrierPayAmt);
      }
    } else {
      let newCarrierPayAmt = formatNumberToCurrency(roundToNearest100(removeNonNumeric(freightCost)));
      setcarrierReceiveAmount(newCarrierPayAmt);
      setExcludingFeeRate(0);
    }
    onActiveSaveBtn();
  };

  const onChangeFreightCost = (e: ChangeEvent<HTMLInputElement>) => {
    setFreightCost(formatNumberToCurrency(removeNonNumeric(e.target.value)));
    let newShipperPA = formatNumberToCurrency(roundToNearest100(removeNonNumeric(e.target.value) * 1.1));
    if (shipperPaymentAmount !== newShipperPA) {
      setshipperPaymentAmount(newShipperPA);
    }

    let newCarrierPayAmt = formatNumberToCurrency(
      roundToNearest100((1 - excludingFeeRate / 100) * removeNonNumeric(e.target.value)),
    );
    if (newCarrierPayAmt !== carrierReceiveAmount) {
      setcarrierReceiveAmount(newCarrierPayAmt);
    }
    onActiveSaveBtn();
  };

  const onChangeShipperPaymentAmt = (e: ChangeEvent<HTMLInputElement>) => {
    let newShipperAmt = formatNumberToCurrency(roundToNearest100(removeNonNumeric(e.target.value)));
    if (newShipperAmt !== shipperPaymentAmount) {
      setshipperPaymentAmount(newShipperAmt);
    }
    let newFrCost = Math.round(removeNonNumeric(e.target.value) / 1.1);
    setFreightCost(formatNumberToCurrency(newFrCost));

    let newCarrierPayAmt = formatNumberToCurrency(roundToNearest100((1 - excludingFeeRate / 100) * newFrCost));
    if (newCarrierPayAmt !== carrierReceiveAmount) {
      setcarrierReceiveAmount(newCarrierPayAmt);
    }

    onActiveSaveBtn();
  };

  return (
    <div className="fare-modification-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("modification_of_fare")}</div>
        <div className="close-btn" onClick={onHandleClose}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper column-wrapper">
        <div className="panel-wrapper">
          <div className="panel-header">
            <div className="title-text">{t("shipper_payment_amount")}</div>
          </div>
          <div className="panel-body">
            <div className="column-wrapper mt-26">
              <div className="row-wrapper content-space-between mt-13">
                <div className="cost-title">{t("freight_cost")}</div>
                <input className="input-value" type="text" value={freightCost} onChange={onChangeFreightCost} />
              </div>
              <div className="row-wrapper content-space-between mt-13">
                <div className="cost-title">{t("VAT")}</div>
                <Input suffix="%" className="input-value" readOnly value={"10"} />
              </div>
              <div className="row-wrapper content-space-between mt-13">
                <div className="total-title">{t("final_payment_amount")}</div>
                <input className="input-value" readOnly type="text" value={shipperPaymentAmount} />
              </div>
            </div>
          </div>
        </div>
        <div className="panel-wrapper mt-20">
          <div className="panel-header">
            <div className="title-text">{t("carrier_freight_cost")}</div>
          </div>
          <div className="panel-body">
            <div className="column-wrapper mt-26">
              <div className="row-wrapper content-space-between mt-13">
                <div className="cost-title">{t("shipper_payment_amount")}</div>
                <input
                  className="input-value"
                  type="text"
                  value={shipperPaymentAmount}
                  onChange={onChangeShipperPaymentAmt}
                />
              </div>
              <div className="row-wrapper content-space-between mt-13">
                <div className="cost-title">{`${t("excluding_fees")}(${excludingFeeRate}%)`}</div>
                <Input suffix="%" className="input-value" value={excludingFeeRate} onChange={onChangeFeeRate} />
              </div>
              <div className="row-wrapper content-space-between mt-13">
                <div className="total-title row-wrapper">{t("final_freight_cost")} (부가세 미포함)</div>
                <input className="input-value" readOnly type="text" value={carrierReceiveAmount} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <div className={`submit-btn ${activeSave ? "active" : ""}`} onClick={handleConfirm}>
          <div className="submit-text">{t("save")}</div>
        </div>
      </div>
    </div>
  );
}
