import "./style.scss";
import { Checkbox, Input, Table } from "antd";
import { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from "react";
import icSearch from "../../assets/images/ic_search.svg";
import icFilter from "../../assets/images/ic_filter.svg";
import { useDispatch } from "react-redux";
import { openModal } from "../customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { formatMoney } from "../../utils/utils";
import { useGetCostListQuery } from "../../pages/SettlementManagement/settlementApi";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import debounce from "lodash/debounce";
import { PAGE_SIZE } from "../../utils/constants";

interface Props {
  title: any;
  columns: any;
  allData: any;
  typeOptions: any;
  searchFilterOptions: any;
  loading: any;
  params: any;
  setParams: any;
  filters: any;
  setFilters: any;
  showDateFilter: boolean;
  maxLengthInput: number;
  callbackCurrentTab: any;
  rowSelection: any;
  resetSelect: any;
  metaData: any;
  isTotalVisible: boolean;
  formAction: any;
  isSingleDateFilter: boolean;
  customRangPickerLabel: any;
  perPage: any;
  showDatepicker: boolean;
}

const NewTable = forwardRef((props: Partial<Props> | any, ref) => {
  const {
    title,
    columns,
    allData,
    typeOptions,
    searchFilterOptions = [],
    loading,
    params,
    setParams,
    filters,
    setFilters,
    showDateFilter = false,
    maxLengthInput = 25,
    callbackCurrentTab,
    rowSelection,
    resetSelect,
    metaData,
    isTotalVisible = true,
    formAction = null,
    isSingleDateFilter = false,
    customRangPickerLabel = {},
    showDatepicker = true,
    perPage,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const [searchInput, setSearchInput] = useState<string>("");

  const [fromURL] = useState(location.pathname.includes("/settlement-management/carrier-history"));
  const [fromURLdetail] = useState(location.pathname.includes("/settlement-management/shipper-history/detail"));
  const [totalFreightCost, setTotalFreightCost] = useState(0);
  const [totalCostSettlement, setTotalCostSettlement] = useState(0);
  const [totalCostNonsettlement, setTotalCostNonsettlement] = useState(0);
  const isDispatchManage = useMemo(() => location.pathname.includes("/dispatch-management"), [location.pathname]);

  const { data: dataCostList, isSuccess } = useGetCostListQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setTotalFreightCost(dataCostList.totalFreightCost.toFixed(0));
      setTotalCostSettlement(dataCostList.settlementAmount.toFixed(0));
      setTotalCostNonsettlement(dataCostList.nonSettlementAmount.toFixed(0));
    }
  }, [dataCostList]);

  /* ------------------------------- 데이터 필터링 처리 ------------------------------- */
  const onHandleClickFilter = () => {
    const dataInput = {
      options: searchFilterOptions,
      values: filters,
      showDateFilter: !showDatepicker ? false : !!showDateFilter,
      isSingleDateFilter: isSingleDateFilter,
      customRangPickerLabel: customRangPickerLabel,
    };

    dispatch(
      openModal({
        template: "show-popup-filter-modal",
        data: dataInput,
        handleAction: onHandleAction,
      }),
    );
  };

  /* --------------------------------- 액션들 처리 --------------------------------- */
  const onHandleAction = (data: any) => {
    setFilters?.(data.filters);
    if (showDateFilter) {
      const dataRange = {
        startDate: data?.dateRange?.startDate ?? undefined,
        endDate: data?.dateRange?.endDate ?? undefined,
      };
      setParams?.({
        ...params,
        page: 1,
        ...dataRange,
      });
    }
  };

  /* ------------------------------- delay 시간 변경 ------------------------------ */
  const onChangeDelay = (e: CheckboxChangeEvent) => {
    setParams?.({
      ...params,
      page: 1,
      isDelay: e.target.checked,
    });
  };

  const resetSearchInput = () => {
    setSearchInput("");
  };

  useImperativeHandle(ref, () => ({
    resetSearchInput,
  }));

  const debounceSearch = debounce((e) => {
    setParams?.({
      ...params,
      page: 1,
      search: e.target.value,
    });
  }, 500);

  return (
    <div className="table-management2">
      <div className="title">
        <span>{title}</span> {formAction ? formAction : ""}
      </div>
      <div className="top-content">
        <div className="filter">
          {!!typeOptions &&
            typeOptions.map((opt: any, index: number) => {
              return (
                <div
                  key={opt.value}
                  className={`filter-item ${params.status === opt.value ? "active" : ""}`}
                  onClick={() => {
                    setParams?.({
                      ...params,
                      page: 1,
                      status: opt.value,
                    });
                    callbackCurrentTab?.(opt.value);
                    resetSelect?.();
                  }}
                >
                  {opt.label}
                </div>
              );
            })}
        </div>
        {!fromURLdetail && showDateFilter && (
          <div className="search">
            <div className="search-bar">
              <Input
                allowClear
                size="large"
                placeholder={t("please_enter_a_search_term")}
                prefix={<img alt="Search Text" src={icSearch} />}
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  debounceSearch(e);
                }}
                maxLength={maxLengthInput}
              />
            </div>
            <div className="search-filter">
              <div className="filter-icon" onClick={onHandleClickFilter}>
                <img alt="Search Text" src={icFilter} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="body">
        {fromURL && (
          <>
            <div className="total-top">
              <div className="total-cost">
                <div className="total-item">
                  <div className="item-title">{t("total_freight_cost")}</div>
                  <div className="item-sub">
                    {formatMoney(totalFreightCost)} <div className="won">&nbsp;{t("원")}</div>
                  </div>
                </div>
              </div>
              <div className="settle-amount">
                <div className="total-item">
                  <div className="item-title">{t("settlement_amount")}</div>
                  <div className="item-sub">
                    {formatMoney(totalCostSettlement)} <div className="won">&nbsp;{t("원")}</div>
                  </div>{" "}
                </div>
              </div>
              <div className="non-settle-amount">
                <div className="total-item">
                  <div className="item-title">{t("non_settlement_amount")}</div>
                  <div className="item-sub">
                    {formatMoney(totalCostNonsettlement)} <div className="won">&nbsp;{t("원")}</div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="reset-button">
              <div
                className="button"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t("reset")}
              </div>
            </div>
          </>
        )}

        {isDispatchManage && (
          <div className="delayed-dispatch">
            <Checkbox checked={params?.isDelay} onChange={onChangeDelay}>
              <div className="title">{t("delayed_dispatch")}</div>
            </Checkbox>
          </div>
        )}
        {isTotalVisible && <div className="total-count">등록 : 총 {metaData?.total || 0} 개 </div>}
        <Table
          rowSelection={rowSelection}
          loading={loading}
          locale={{ emptyText: t("no_data") }}
          dataSource={allData}
          columns={columns}
          rowKey="id"
          sticky={{ offsetHeader: 0 }}
          scroll={{
            scrollToFirstRowOnChange: true,
          }}
          pagination={
            metaData?.total >= (perPage || PAGE_SIZE)
              ? {
                  position: ["bottomCenter"],
                  current: params.page || 1,
                  pageSize: perPage || PAGE_SIZE,
                  total: metaData?.total || 0,
                  showSizeChanger: false,
                  onChange: (page: number) => {
                    setParams({ ...params, page: page });
                  },
                }
              : false
          }
        />
      </div>
    </div>
  );
});

export default NewTable;
