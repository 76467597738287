import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import icMore from "../../../../assets/images/ic_more.svg";

import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import DocumentItem from "../../../docItem";
import bag from "../../../../assets/images/bag.svg";
import TextArea from "antd/lib/input/TextArea";
import {
  useGetOrderDetailQuery,
  useUpdateMemoOrderMutation,
  useDeleteMemoOrderMutation,
  useDeleteMemoMutation,
  useCreateMemoMutation,
  useUpdateMemoMutation,
} from "../../../../pages/SettlementManagement/settlementApi";
import { Button } from "antd";
import Memos from "../../commons/memos";

export default function UpdateMemo(props: any) {
  const currentMemo = props?.data?.info;

  const [deleteMemo, { isLoading: deletingMemo }] = useDeleteMemoMutation();
  const [createMemo, { isLoading: creatingMemo }] = useCreateMemoMutation();
  const [updateMemo, { isLoading: updatingMemo }] = useUpdateMemoMutation();
  const [memoText, setMemoText] = useState(currentMemo?.content || '');
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (currentMemo) {
  //     setMemoText(currentMemo?.content || '');
  //   }
  // }, [props]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const onDirectInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMemoText(e.target.value);
  };

  const handleDeleteMemo = (id: string, providerId: string) => {
    if (id) {
      deleteMemo({ memoId: id, providerId: providerId })
        .unwrap()
        .then((res: any) => {
          props.onClose();
          dispatch(
            openDialog({
              type: "info",
              content: t("has_been_deleted"),
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        })
        .catch((error: any) => {
          dispatch(
            openDialog({
              type: "info",
              content: error?.data?.message,
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        });
    }
  };

  const handleCreateMemo = (providerId: string, content: string, month: string, year: string) => {
    if (providerId && content && month && year) {
      createMemo({
        providerId,
        content,
        month,
        year,
      })
        .unwrap()
        .then((res: any) => {
          props.onClose();
          dispatch(
            openDialog({
              type: "info",
              content: t("saved"),
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        })
        .catch((error: any) => {
          dispatch(
            openDialog({
              type: "info",
              content: error?.data?.message,
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        });
    }
  };

  const handleUpdateMemo = (providerId: string, memoId: string, content: string) => {
    if (providerId && content && memoId) {
      updateMemo({
        providerId,
        memoId,
        content,
      })
        .unwrap()
        .then((res: any) => {
          props.onClose();
          dispatch(
            openDialog({
              type: "info",
              content: t("saved"),
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        })
        .catch((error: any) => {
          dispatch(
            openDialog({
              type: "info",
              content: error?.data?.message,
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        });
    }
  };

  const handleSubmitButton = () => {
    if (currentMemo?.id) {
      handleUpdateMemo(currentMemo.providerId, currentMemo.id, memoText);
    } else {
      handleCreateMemo(
        currentMemo.providerId,
        memoText,
        currentMemo.month?.toString(),
        currentMemo.year?.toString(),
      );
    }
  };

  const handleDeleteButton = () => {
    if (memoText.length != 0 && currentMemo?.id)
      dispatch(
        openDialog({
          type: "confirm",
          title: t("are_you_sure_want_to_delete_the_note?") + "\n" + t("deleted_data_cannot_be_recovered."),
          confirmText: t("dialog_btn_confirm"),
          closeText: t("dialog_btn_cancel"),
          actionConfirm: () => {
            handleDeleteMemo(currentMemo?.id, currentMemo?.providerId);
          },
        }),
      );
  }
  return (
    <div className="update-memo-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("memo")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <TextArea
            autoFocus={true}
            className="direct-input"
            allowClear
            onChange={onDirectInputChange}
            value={memoText}
            maxLength={254}
          />
        </div>
        <div className="action">
          <Button
            className={`submit ${memoText == "" ? "disable" : ""}`}
            loading={deletingMemo || creatingMemo || updatingMemo}
            onClick={handleSubmitButton}
          >
            {t("save")}
          </Button>
          <img
            className={`delete ${memoText == "" ? "disable" : ""}`}
            alt="memo"
            src={bag}
            onClick={handleDeleteButton}
          />
        </div>
      </div>
    </div>
    // <Memos handleSubmit={handleSubmitButton} deleteMemo={handleDeleteButton} onClose={props.onClose()} memoText={memoText} setMemoText={setMemoText} isLoading={deletingMemo || creatingMemo || updatingMemo} />
  );
}
