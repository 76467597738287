import "./style.scss";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "./authApi";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { openDialog } from "../../components/customDialog/dialogSlice";
import icLoginText from "../../assets/images/ic_login_text.svg";
import { useTranslation } from "react-i18next";

function Login() {
  const [form] = Form.useForm();
  const userName = Form.useWatch("username", form);
  const password = Form.useWatch("password", form);
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    const res = await login(values);
    //@ts-ignore
    const data = res?.data;
    if (data) {
      const { accessToken, refreshToken } = data;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      if (accessToken && refreshToken) {
        navigate("/member-management/shipper");
      }
    }
    console.log("rp error: ", res);
    //@ts-ignore
    const errorMsg = res?.error?.data?.message;
    if (errorMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: t("please_check_your_account_information"),
          confirmText: t("dialog_btn_confirm"),
        })
      );
    }
  };

  return (
    <div className="user-login">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout={"vertical"}
        form={form}
      >
        <div className="login-title">
          <img alt="title" src={icLoginText}/>
        </div>
        <Form.Item
          label={t("admin_id")}
          name="username"
          className="item-input"
        >
          <Input placeholder={t("pls_enter_your_id")} maxLength={30} />
        </Form.Item>
        <Form.Item
          label={t("admin_password")}
          name="password"
          className="item-input"
        >
          <Input.Password placeholder={t("pls_enter_enter_a_password")} visibilityToggle={false}  maxLength={30} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={`login-form-button ${
              userName && password && !isLoading
                ? "login-normal"
                : "login-disabled"
            }`}
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
export default Login;
