/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";
import queryString from "query-string";

export const fcApi = createApi({
  reducerPath: "fcApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["fc"],
  endpoints: (builder) => ({
    getFreightCost: builder.query({
      query: (data) => ({
        url: `freightManagement/file`,
        method: "GET",
        params: data,
      }),
      providesTags: ["fc"],
    }),
    uploadFreightCost: builder.mutation({
      query: (data) => ({
        url: `freightManagement`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fc"],
    }),
    calcMoney: builder.mutation({
      query: (data) => {
        const { additionalEndpoints, ...rest } = data;
        const params = queryString.stringify(rest);
        const additionalEndpointsParams = queryString.stringify(
          { additionalEndpoints },
          {
            arrayFormat: "bracket",
          },
        );

        const fullParams = `?${params}&${additionalEndpointsParams}`;
        return {
          url: `freightManagement/cost${fullParams}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetFreightCostQuery, useUploadFreightCostMutation, useCalcMoneyMutation } = fcApi;
