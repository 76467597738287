import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icInfo from "../../assets/images/ic_info.svg";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { API_LINK } from "../../utils/constants";
import {
  useCreateAdvertisementsMutation,
  useDeleteAdvertisementsMutation,
  useGetAdvertisementsQuery,
  useGetVideoAdvertisementQuery,
  usePostAdvertisementsMutation,
  useUpdateVideoAdvertisementMutation,
} from "./advertisementApi";
import "./style.scss";
import { closeModal } from "../../components/customModal/modalSlice";
import { Button, Modal } from "antd";
import debounce from "lodash/debounce";

export default function AdvertisementManagement() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewurl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [createAdvertisements] = useCreateAdvertisementsMutation();
  const [deleteAdvertisements] = useDeleteAdvertisementsMutation();
  const { data: videoData, refetch: refetchGetVideoAdvertisement } = useGetVideoAdvertisementQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [updateVideoAdvertisement] = useUpdateVideoAdvertisementMutation();
  const [postAdvertisements, { isLoading: isLoadingPostAdvertisement }] = usePostAdvertisementsMutation();
  const { data, error } = useGetAdvertisementsQuery(
    {
      page: 1,
      limit: 3,
      order: "asc",
      orderBy: "createdAt",
    },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );
  const onCloseModal = () => setPreviewurl("");

  useEffect(() => {
    if (videoData) {
      setVideoUrl(videoData.videoUrl);
    }
  }, [videoData]);

  /* -------------------------------- 동영상 변경 처리 ------------------------------- */
  const onChangeVideo = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoUrl(e.target.value);
    // debounceFn(e.target.value);
  };

  /* ------------------------------ 동영상의 url 업로드 ------------------------------ */
  const updateVideoUrl = (val: string) => {
    if (videoData?.id) {
      updateVideoAdvertisement({
        id: videoData.id,
        videoUrl: val,
      });
    }
  };

  /* -------------------------------- 동영상 저장 처리 ------------------------------- */
  const handleVideoSave = async () => {
    const res = await postAdvertisements({ videoUrl })?.unwrap();
    if (res?.id) {
      refetchGetVideoAdvertisement();
      dispatch(
        openDialog({
          type: "info",
          content: "동영상이 수정 되었습니다.",
          confirmText: "확인",
        }),
      );
    }
  };

  const debounceFn = useCallback(debounce(updateVideoUrl, 1000), [videoData]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = async (event: any) => {
    // do something with event data
    if (event.target.files.length > 0) {
      const fileName = (event.target.files[0].name || "").toUpperCase();
      const isValidType = !fileName.endsWith(".JPG") && !fileName.endsWith(".PNG");
      if (event.target.files[0].size > 30720000 || isValidType) {
        dispatch(
          openDialog({
            type: "info",
            content: t("pls_attach_less_than_30mb_jpg"),
            confirmText: t("dialog_btn_confirm"),
          }),
        );
        return;
      }
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          createAdvertisement(data.Location);
        });
      event.target.value = null;
    }
  };

  /* ---------------------------------- 광고 등록 --------------------------------- */
  const createAdvertisement = async (imageUrl: string) => {
    const res = await createAdvertisements({ imageUrl }); //@ts-ignore
    const errMsg = res?.error?.data?.message;
    if (errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
        }),
      );
    }
  };

  /* --------------------------------- 이미지 삭제 --------------------------------- */
  const deleteImage = async (id: any) => {
    const res = await deleteAdvertisements({ id }); //@ts-ignore
    const errMsg = res?.error?.data?.message;
    if (errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: t("deleted"),
        }),
      );
    }
  };

  /* ---------------------------------- 삭제 처리 --------------------------------- */
  const onClickDelete = (id: any) => {
    dispatch(
      openDialog({
        type: "confirm",
        content: t("confirm_delete"),
        actionConfirm: async () => {
          deleteImage(id);
        },
      }),
    );
  };

  /* -------------------------------- 이미지 미리 보기 ------------------------------- */
  const onClickPreview = (imageUrl: string) => {
    if (imageUrl) {
      setPreviewurl(imageUrl);
    }
  };

  return (
    <div className="advertisement-management">
      <div className="title">배너 관리</div>
      <div className="note">
        <img className="note-img" alt="Search Text" src={icInfo} />
        <div className="note-text">
          등록한 배너는 <span style={{ color: "#000" }}> 운송인App 홈화면 하단에 노출 </span>됩니다.
        </div>
      </div>
      <div className="recommended-text">{t("recommended_note")}</div>
      <div className="mt-24">
        {data?.data?.length > 0 ? (
          <div className="image-wrapper">
            <div className="registed_image" onClick={() => onClickPreview(data?.data[0]?.imageUrl)}>
              <img src={data?.data[0]?.imageUrl} alt="" />
            </div>

            <div className="delete-image" onClick={() => onClickDelete(data?.data[0]?.id)}>
              {t("delete")}
            </div>
          </div>
        ) : (
          <div
            className="image-wrapper"
            onClick={() => {
              if (fileInputRef && fileInputRef.current) {
                //@ts-ignore
                fileInputRef.current.click();
              }
            }}
          >
            <div className="no-image-text">{t("ad_registration")}</div>
          </div>
        )}
      </div>
      <div className="mt-30">
        {data?.data?.length > 1 ? (
          <div className="image-wrapper">
            <div className="registed_image" onClick={() => onClickPreview(data?.data[1]?.imageUrl)}>
              <img src={data?.data[1]?.imageUrl} alt="" />
            </div>
            <div className="delete-image" onClick={() => onClickDelete(data?.data[1]?.id)}>
              {t("delete")}
            </div>
          </div>
        ) : (
          <div
            className="image-wrapper"
            onClick={() => {
              if (fileInputRef && fileInputRef.current) {
                //@ts-ignore
                fileInputRef.current.click();
              }
            }}
          >
            <div className="no-image-text">{t("ad_registration")}</div>
          </div>
        )}
      </div>
      <div className="mt-30">
        {data?.data?.length > 2 ? (
          <div className="image-wrapper">
            <div className="registed_image" onClick={() => onClickPreview(data?.data[2]?.imageUrl)}>
              <img src={data?.data[2]?.imageUrl} alt="" />
            </div>
            <div className="delete-image" onClick={() => onClickDelete(data?.data[2]?.id)}>
              {t("delete")}
            </div>
          </div>
        ) : (
          <div
            className="image-wrapper"
            onClick={() => {
              if (fileInputRef && fileInputRef.current) {
                //@ts-ignore
                fileInputRef.current.click();
              }
            }}
          >
            <div className="no-image-text">{t("ad_registration")}</div>
          </div>
        )}
      </div>
      <input onChange={handleChange} multiple={false} ref={fileInputRef} type="file" accept=".jpg,.png" hidden />
      <div className="video-management">
        <div className="title">{t("video_management")}</div>
        <div className="note">
          <img className="note-img" alt="Search Text" src={icInfo} />
          <div className="note-text">{t("note_video_advertisements")}</div>
        </div>
        <div className="video">
          <input
            className="video-input"
            placeholder={t("insert_link_video")}
            value={videoUrl}
            onChange={onChangeVideo}
          />
          <Button
            disabled={
              !videoUrl || isLoadingPostAdvertisement || (videoData?.videoUrl && videoUrl === videoData.videoUrl)
            }
            className={`btn-video ${
              !videoUrl || isLoadingPostAdvertisement || (videoData?.videoUrl && videoUrl === videoData.videoUrl)
                ? "btn-video--save-disabled"
                : "btn-video--save"
            }`}
            onClick={handleVideoSave}
          >
            저장
          </Button>
        </div>
        <div className="video-instruction">
          동영상 입력 형식 (예시) <u>https://www.youtube.com/embed/4n3bAmAdjXY</u>
        </div>
      </div>
      <Modal
        open={!!previewUrl}
        onCancel={onCloseModal}
        centered
        title=""
        closable={false}
        className="preview-image-modal"
        footer={null}
        // width={549}
      >
        <img src={previewUrl} className="ant-modal-body-img" />
      </Modal>
    </div>
  );
}
