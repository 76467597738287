import { Input, Pagination, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import EditPost from "../../components/editPost";
import PostDetail from "../../components/postDetail";
import { PER_PAGE } from "../../utils/constants";
import icSearch from "../../assets/images/ic_search.svg";
import {
  useCreateNotificationsMutation,
  useDeleteNotificationsMutation,
  useGetNotificationsQuery,
  useUpdateNotificationsMutation,
} from "./notificationApi";
import "./style.scss";
import { debounce, truncate } from "lodash";

export enum ENotice {
  ALL = "ALL",
  PROVIDER = "PROVIDER",
  CARRIER = "CARRIER",
}

export default function NoticeManagement() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [noticeInfo, setNoticeInfo] = useState<any>();
  const [noticeId, setNoticeId] = useState<any>();
  const [allData, setAllData] = useState<any>();
  const [total, setTotal] = useState<number>(0);
  const [activeTab, setActiveTab] = useState(ENotice.ALL);
  const [isShowNext, setIsShowNext] = useState(false);
  const [isShowPrev, setIsShowPrev] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState<ENotice>(ENotice.ALL);
  const initialFilters = {
    page: 1,
    limit: PER_PAGE,
    order: "desc",
    orderBy: "createdAt",
    keyword: "",
    searchOr: [`{"type": "${ENotice.PROVIDER}"}`, `{"type": "${ENotice.CARRIER}"}`],
  };
  const [action, setAction] = useState("List"); //List - Create - Edit - View
  const [deleteNotifications] = useDeleteNotificationsMutation();
  const [createNotifications] = useCreateNotificationsMutation();
  const [updateNotifications] = useUpdateNotificationsMutation();
  const [filters, setFilters] = useState<any>(initialFilters);
  const { data, error } = useGetNotificationsQuery(filters, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [fromType, setFromType] = useState<ENotice | undefined>(undefined);

  useEffect(() => {
    setType(location.pathname.includes("carrier") ? ENotice.CARRIER : ENotice.PROVIDER);
    setAction("List");
    setNoticeId(null);
  }, [location]);

  useEffect(() => {
    if (data && data?.data) {
      let dataMap = data?.data.map((item: any, index: number) => ({
        ...item,
        id: item.id,
        number: index + 1 + ((filters.page || 1) - 1) * 10,
        noticeDate: getDateFormat(item.createdAt),
      }));
      setAllData(dataMap);
      setTotal(data?.meta?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!!noticeId) {
      onModify();
    } else {
      setNoticeInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData, noticeId]);

  /* ------------------------------- 날짜 양식 가져오기 ------------------------------- */
  const getDateFormat = (dateStr: string, format: string = "YYYY.MM.DD") => {
    const date = moment(dateStr);
    if (date.isValid()) {
      return date.format(format);
    }
    return "";
  };

  /* ------------------------------- 선택한 페이지 변경 ------------------------------- */
  const onChangePage = (page: number) => {
    setFilters({ ...filters, page });
  };

  interface DataType {
    id: string;
    number: string;
    title: string;
    noticeDate: string;
    management: string;
    type: ENotice;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t("number"),
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "10%",
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      width: "37%",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (title: string, record: DataType) => {
        return (
          <div
            className="detail-btn"
            onClick={() => {
              setNoticeId(record.id);
              setAction("View");
              setFromType(record?.type);
            }}
          >
            <div className="text-btn">
              {truncate(title, {
                length: 70,
                separator: " ",
              })}
            </div>
          </div>
        );
      },
    },
    {
      title: "구분",
      dataIndex: "type",
      key: "type",
      width: "10%",
      align: "center",
      render(item) {
        return item === ENotice.CARRIER ? "운송인" : "화주";
      },
    },
    {
      title: t("notice_date"),
      dataIndex: "noticeDate",
      key: "noticeDate",
      align: "center",
      width: "18%",
    },
    {
      title: t("management"),
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "25%",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id) => (
        <div className="manager-wrapper row-wrapper content-space-between">
          <div className="base-btn modified-btn" onClick={() => onEdit(id)}>
            {t("modify")}
          </div>
          <div
            className="base-btn delete-btn"
            onClick={() => {
              onDelete(id);
            }}
          >
            {t("delete")}
          </div>
        </div>
      ),
    },
  ];

  /* ---------------------------------- 검색 처리 --------------------------------- */
  const debouncedSearchHandler = useCallback(
    debounce((value) => {
      setFilters({
        ...filters,
        page: 1,
        keyword: value,
      });
    }, 500),
    [filters],
  );

  const searchHandler = (val: any) => {
    setSearch(val.target.value);
    debouncedSearchHandler(val.target.value);
  };

  const onModify = () => {
    const info = allData.filter((val: any) => val.id === noticeId);

    setIsShowNext(info);
    if (info && info.length > 0) {
      setIsShowNext(info[0].number < allData.length);
      setIsShowPrev(info[0].number > 1);
      setNoticeInfo(info[0]);
    } else {
      setAction("List");
      setNoticeInfo(null);
      setIsShowNext(false);
      setIsShowPrev(false);
    }
  };

  /* ------------------------------- 카테고리 기록 삭제 ------------------------------- */
  const onDelete = (id: any) => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_delete_post"),
        },
        handleAction: () => {
          dispatch(closeModal());
          onHandleDeleteAction(id);
        },
      }),
    );
  };

  /* ------------------------------- 카테고리 기록 수정 ------------------------------- */
  const onEdit = (id: any) => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_edit_post"),
        },
        handleAction: () => {
          setNoticeId(id);
          setAction("Edit");
        },
      }),
    );
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteAction = async (id: any) => {
    const params = {
      id: id,
    };
    const res = await deleteNotifications(params); //@ts-ignore
    showResult(res, t("deleted"));
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (res: any, msgSuccess: string, onComplete: any = null) => {
    const errMsg = res?.error?.data?.message;
    if (!errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            if (onComplete) {
              onComplete(res);
            } else {
              if (action !== "List") {
                setAction("List");
                setNoticeId(null);
              }
            }
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
        }),
      );
    }
  };

  const onPost = () => {
    setAction("Add");
  };

  /* ----------------------------------- 저장 ----------------------------------- */
  const onHandleSubmit = async (rest: any) => {
    const { radioVal, ...data } = rest;

    if (isLoading) {
      return;
    } else if (action === "Add") {
      setIsLoading(true);
      if (radioVal === ENotice.ALL) {
        const res = await createNotifications({
          ...data,
          type: ENotice.PROVIDER,
        }); //@ts-ignore
        await createNotifications({
          ...data,
          type: ENotice.CARRIER,
        }); //@ts-ignore
        showResult(res, t("writing_is_complete"), (data) => {
          setNoticeId(data?.data?.id);
          setAction("View");
        });
        setIsLoading(false);
      } else {
        const res = await createNotifications({ ...data, type: radioVal }); //@ts-ignore
        showResult(res, t("writing_is_complete"), (data) => {
          setNoticeId(data?.data?.id);
          setAction("View");
        });
        setIsLoading(false);
      }
    } else if (action === "Edit") {
      setIsLoading(true);
      const res = await updateNotifications({
        ...data,
        id: noticeInfo?.id,
        type: radioVal === ENotice.PROVIDER ? ENotice.PROVIDER : ENotice.CARRIER,
      }); //@ts-ignore
      showResult(res, t("it_is_changed"), (data) => {
        setNoticeId(data?.data?.id);
        setAction("View");
      });
      setIsLoading(false);
    }
  };

  const filterTypeOption = [
    {
      label: "전체",
      value: ENotice.ALL,
      key: ENotice.ALL,
    },
    {
      label: "화주",
      value: ENotice.PROVIDER,
      key: ENotice.PROVIDER,
    },
    {
      label: "운송인",
      value: ENotice.CARRIER,
      key: ENotice.CARRIER,
    },
  ];

  switch (action) {
    case "View":
      return (
        <PostDetail
          fromType={fromType}
          title={noticeInfo?.title}
          date={noticeInfo?.createdAt}
          content={noticeInfo?.content}
          imageUrl={noticeInfo?.imageUrl}
          fileUrls={noticeInfo?.fileUrls ? JSON.parse(noticeInfo?.fileUrls) : []}
          isShowNext={isShowNext}
          isShowPrev={isShowPrev}
          onBack={() => {
            setAction("List");
            setNoticeId(null);
          }}
          onDelete={() => {
            onDelete(noticeId);
          }}
          onEdit={() => {
            setAction("Edit");
          }}
          onNext={() => {
            setNoticeId(allData[noticeInfo.number]?.id);
          }}
          onPrev={() => {
            setNoticeId(allData[noticeInfo.number - 2]?.id);
          }}
        />
      );
    case "Add":
    case "Edit":
      return (
        <EditPost
          pageActionType={action}
          pageType="notice"
          data={noticeInfo}
          titleInfo={noticeInfo?.title}
          contentInfo={noticeInfo?.content}
          titleMaxLength={500}
          contentMaxLength={2000}
          imageUrlInfo={noticeInfo?.imageUrl || ""}
          files={noticeInfo?.fileUrls ? JSON.parse(noticeInfo.fileUrls) : []}
          onClose={() => {
            setAction("List");
            setNoticeId(null);
          }}
          onSubmit={(data: any) => {
            onHandleSubmit(data);
          }}
        />
      );
    default:
      return (
        <div className="notice-management">
          <div className="title">
            {`${t("notice")}`}{" "}
            <div className="search-bar">
              <Input
                allowClear
                value={search}
                size="large"
                placeholder={"검색어(제목)을 입력해주세요"}
                prefix={<img alt="Search Text" src={icSearch} />}
                onChange={searchHandler}
              />
            </div>
          </div>
          <div className="top-content">
            <div className="filter-type">
              {filterTypeOption?.map((i) => {
                return (
                  <div
                    className={`filter-type-item ${activeTab === i?.key && "filter-type-item--active"}`}
                    onClick={() => {
                      setActiveTab(i?.key);
                      const cloneFilter = { ...filters };
                      if (i?.key !== ENotice.ALL) {
                        cloneFilter.searchAnd = [
                          `{"type":"${i?.key === ENotice.PROVIDER ? ENotice.PROVIDER : ENotice.CARRIER}"}`,
                        ];
                        delete cloneFilter.searchOr;
                      } else {
                        delete cloneFilter.searchAnd;
                        cloneFilter.searchOr = [`{"type": "${ENotice.PROVIDER}"}`, `{"type": "${ENotice.CARRIER}"}`];
                      }

                      if (cloneFilter?.keyword?.length === 9) {
                        delete cloneFilter.keyword;
                      }

                      setFilters({ ...cloneFilter, page: 1 });
                    }}
                  >
                    {i.label}
                  </div>
                );
              })}
            </div>
            <div className="post-btn" onClick={onPost}>
              <div className="post-text">{t("post_registration")}</div>
            </div>
          </div>
          <div className="body-content">
            <Table
              locale={{ emptyText: t("no_data") }}
              dataSource={allData}
              pagination={false}
              columns={columns}
              rowKey="id"
              scroll={{
                y: "calc(100vh - 410px)",
                scrollToFirstRowOnChange: true,
              }}
              tableLayout="auto"
            />
            <Pagination
              className="notice-pagination"
              current={filters.page}
              showSizeChanger={false}
              total={total}
              onChange={onChangePage}
              defaultPageSize={PER_PAGE}
            />
          </div>
        </div>
      );
  }
}
