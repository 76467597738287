import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button } from "antd";
import type { InputRef } from "antd";
import React, { useState, useRef, useEffect } from "react";

let index = 0;
const ADD_CUSTOM_DOMAIN = "직접입력";

const CustomSelect = (props: any) => {
  let { options, placeholder, className, width, onChange, defaultValue, value, disabled, hideCustomItem, handleEmail } =
    props;

  const [items, setItems] = useState<any>([]);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  let avaliableDomail = [
    { label: "gmail.com", value: "gmail.com" },
    { label: "icloud.com", value: "icloud.com" },
    { label: "naver.com", value: "naver.com" },
    { label: "kakao.com", value: "kakao.com" },
    { label: "daum.net", value: "daum.net" },
  ];

  useEffect(() => {
    setItems(options ?? []);
  }, [options]);

  /* -------------------------- select component 내 검색 ------------------------- */
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  /* ------------------------ select component 내 옵션 추가 ------------------------ */
  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    console.log("eee", e);

    e.preventDefault();
    setItems([
      ...items,
      name ? { value: name, label: name } : { value: `새 항목 ${index++}`, label: `새 항목 ${index++}` },
    ]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  let finalValue = Array.isArray(value) ? value[0] : value;
  let mode: any = !value ? "tags" : undefined;

  let selectedValue =
    finalValue === ADD_CUSTOM_DOMAIN || !value ? undefined : Array.isArray(value) ? value[value.length - 1] : value;

  /* -------------------------------- 유효한 메일 확인 ------------------------------- */
  const inAvailableMail = (selectedValue: any) => {
    return avaliableDomail.some((item) => item.value === selectedValue);
  };

  const onChangeHandler = (value: string) => {
    let selectedValue = value;
    if (Array.isArray(value) && value.length > 0) {
      selectedValue = value[value.length - 1];
    }
    if (typeof selectedValue === "string" && (inAvailableMail(selectedValue) || selectedValue !== ADD_CUSTOM_DOMAIN)) {
      onChange(selectedValue);
    }

    if (selectedValue === ADD_CUSTOM_DOMAIN) {
      onChange("");
    }
  };

  return (
    <Select
      style={{ width: width ?? 138 }}
      placeholder={placeholder}
      className={className}
      onChange={onChangeHandler}
      defaultValue={defaultValue}
      value={handleEmail ? selectedValue : value ? value : undefined}
      disabled={disabled}
      mode={handleEmail ? mode : undefined}
      dropdownRender={(menu) => (
        <>
          {menu}
          {!hideCustomItem && !handleEmail && (
            <>
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input placeholder="새 항목" ref={inputRef} value={name} onChange={onNameChange} maxLength={255} />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}></Button>
              </Space>
            </>
          )}
        </>
      )}
      options={items}
    />
  );
};

export default CustomSelect;
