import { Input, Select, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import PostDetail from "../../components/postDetail";
import { useCreateFaqsMutation, useDeleteFaqsMutation, useGetFaqsQuery, useUpdateFaqsMutation } from "./faqApi";
import "./style.scss";
import { CategoryManagement } from "../../components/categoryManagement";
import { useGetFaqCategoriesQuery, useSwapFaqCategoryOrderMutation } from "./categoryApi";
import { DATE_FORMAT_DOT, PER_PAGE } from "../../utils/constants";
import { debounce } from "lodash";
import EditPost from "../../components/editPost";

interface DataType {
  id: string;
  number: string;
  title: string;
  registrationDate: string;
  management: string;
  faqCategoryId?: number;
}

export default function FaqManagement({ pageType }: { pageType: "shipper" | "carrier" }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const editPostRef = useRef<any>(null);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const location = useLocation();
  const [faqInfo, setFaqInfo] = useState<any>();
  const [faqId, setFaqId] = useState<any>();
  const [category, setCategory] = useState<number | undefined>(() => {
    if (searchParams.get("category")) {
      return Number(searchParams.get("category"));
    }
    return undefined;
  });
  const [allData, setAllData] = useState<any>();
  const [total, setTotal] = useState<number>(0);
  const [isShowNext, setIsShowNext] = useState(false);
  const [isShowPrev, setIsShowPrev] = useState(false);
  const [type, setType] = useState("PROVIDER");
  const [action, setAction] = useState("List"); //List - Create - Edit - View - Category
  const [deleteFaqs] = useDeleteFaqsMutation();
  const [createFaqs] = useCreateFaqsMutation();
  const [updateFaqs] = useUpdateFaqsMutation();
  const [page, setPage] = useState<number>(1);

  const [searchKeyword, setSearchKeyword] = useState<string>(searchParams.get("keyword") || "");
  const [swapFaqCategoryOrder] = useSwapFaqCategoryOrderMutation();
  const SEARCH_PARAMS = useMemo(() => {
    let SEARCH = `?page=${page}&limit=${PER_PAGE}&order=desc&orderBy=createdAt`;
    SEARCH += `&searchAnd[]=${JSON.stringify({ tag: type })}`;
    if (pageType === "shipper" && category) {
      SEARCH += `&searchAnd[]=${JSON.stringify({ faqCategoryId: +category })}`;
    }
    if (pageType === "carrier" && searchKeyword) {
      SEARCH += `&question=${searchKeyword}`;
    }
    return SEARCH;
  }, [category, page, type, searchKeyword, pageType]);

  const { data, error } = useGetFaqsQuery(SEARCH_PARAMS, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const { data: categoryData, refetch } = useGetFaqCategoriesQuery(
    {
      isGetAll: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  // useEffect(() => {
  //   setCategory("");
  //   setSearchKeyword("");
  // }, [pageType]);

  const categories = useMemo(() => {
    if (categoryData && categoryData?.length) {
      return categoryData.map((e: any) => ({
        value: e.id,
        label: e.name,
        id: e.id,
      }));
    }
    return [];
  }, [categoryData]);

  useEffect(() => {
    setType(location.pathname.includes("carrier") ? "CARRIER" : "PROVIDER");
    setAction("List");
    setFaqId(null);
  }, [location]);

  useEffect(() => {
    if (data && data?.data) {
      let dataMap = data?.data.map((item: any, index: number) => ({
        ...item,
        id: item.id,
        number: index + 1 + (page - 1) * 10,
        registrationDate: moment(item.createdAt)?.local()?.format(DATE_FORMAT_DOT),
      }));
      setAllData(dataMap);
      setTotal(data?.meta?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!!faqId) {
      onModify();
    } else {
      setFaqInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData, faqId]);

  /* ------------------------------- 날짜 양식 가져오기 ------------------------------- */
  const getDateFormat = (dateStr: string, format: string = "YYYY.MM.DD") => {
    const date = moment(dateStr);
    if (date.isValid()) {
      return date.format(format);
    }
    return "";
  };

  const columns: ColumnsType<DataType> = useMemo(() => {
    const cols: ColumnsType<DataType> = [
      {
        title: t("number"),
        dataIndex: "number",
        key: "number",
        align: "center",
        width: "7%",
      },
      {
        title: t("title"),
        dataIndex: "question",
        key: "question",
        width: "40%",
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (title: string, record: DataType) => (
          <div
            className="detail-btn"
            onClick={() => {
              setFaqId(record.id);
              setAction("View");
            }}
          >
            <div className="text-btn">{title}</div>
          </div>
        ),
      },
      {
        title: t("registration_date"),
        dataIndex: "registrationDate",
        key: "registrationDate",
        align: "center",
        width: "20%",
      },
      {
        title: t("management"),
        dataIndex: "id",
        key: "id",
        align: "center",
        width: "20%",
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (id) => (
          <div className="manager-wrapper row-wrapper content-space-between">
            <div
              className="base-btn modified-btn text-nowrap"
              onClick={() => {
                onEdit(id);
              }}
            >
              {t("modify")}
            </div>
            <div
              className="base-btn delete-btn text-nowrap"
              onClick={() => {
                onDelete(id);
              }}
            >
              {t("delete")}
            </div>
          </div>
        ),
      },
    ];

    if (pageType === "shipper") {
      cols.splice(1, 0, {
        title: t("category"),
        dataIndex: "faqCategoryId",
        key: "faqCategoryId",
        align: "center",
        width: "13%",
        render: (_, record: any) => {
          return record?.faqCategory?.name || "";
        },
      });
    }

    return cols;
  }, [pageType]);

  const onModify = () => {
    const info = allData.filter((val: any) => val.id === faqId);
    setIsShowNext(info);
    if (info && info.length > 0) {
      setIsShowNext(info[0].number < allData.length);
      setIsShowPrev(info[0].number > 1);
      setFaqInfo(info[0]);
    } else {
      setAction("List");
      setFaqInfo(null);
      setIsShowNext(false);
      setIsShowPrev(false);
    }
  };

  /* ------------------------------- 카테고리 기록 삭제 ------------------------------- */
  const onDelete = (id: any) => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_delete_post"),
        },
        handleAction: () => {
          dispatch(closeModal());
          onHandleDeleteAction(id);
        },
      }),
    );
  };

  /* ------------------------------- 카테고리 기록 수정 ------------------------------- */
  const onEdit = (id: any) => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_edit_post"),
        },
        handleAction: () => {
          setFaqId(id);
          setAction("Edit");
        },
      }),
    );
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteAction = async (id: any) => {
    const params = {
      id: id,
    };
    const res = await deleteFaqs(params); //@ts-ignore
    showResult(res, t("deleted"));
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (res: any, msgSuccess: string, onComplete: any = null) => {
    const errMsg = res?.error?.data?.message;
    if (!errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            if (onComplete) {
              onComplete(res);
            } else {
              if (action !== "List") {
                setAction("List");
                setFaqId(null);
              }
            }
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
        }),
      );
    }
  };

  const onPost = () => {
    setAction("Add");
  };

  /* ----------------------------------- 저장 ----------------------------------- */
  const onHandleSubmit = async (data: any) => {
    if (action === "Add") {
      const res = await createFaqs({
        question: data.title,
        answer: data.content,
        tag: type,
        imageUrl: data.imageUrl,
        fileUrls: data.fileUrls,
        faqCategoryId: +data.category,
      }); //@ts-ignore
      showResult(res, t("writing_is_complete"), (data) => {
        setFaqId(data?.data?.id);
        setAction("List");
        if (editPostRef.current) {
          editPostRef.current?.clearForm();
        }
      });
    } else if (action === "Edit") {
      const res = await updateFaqs({
        id: faqInfo?.id,
        question: data.title,
        answer: data.content,
        tag: type,
        imageUrl: data.imageUrl,
        fileUrls: data.fileUrls,
        faqCategoryId: data.category,
      }); //@ts-ignore
      showResult(res, t("it_is_changed"), (data) => {
        setFaqId(data?.data?.id);
        setAction("List");
        if (editPostRef.current) {
          editPostRef.current?.clearForm();
        }
      });
    }
  };

  const handleChangeCategory = (value: number) => {
    setPage(1);
    setCategory(value);
  };

  const goToCategoryManage = () => {
    setAction("Category");
  };

  /* ------------------------------- 선택한 페이지 변경 ------------------------------- */
  const onChangePage = (page: number) => {
    setPage(page);
  };

  /* ---------------------------------- 검색 처리 --------------------------------- */
  const debouncedSearchHandler = useCallback(
    debounce((value) => {
      setSearchKeyword(value);
    }, 500),
    [],
  );

  const dragEnded = async (param: any) => {
    try {
      const { destination, draggableId } = param;
      const sourceItemId = categoryData?.find((item: any) => String(item?.id) === draggableId)?.id;
      const destinationItemId = categoryData[destination?.index]?.id;

      const body = {
        categoryId1: sourceItemId,
        categoryId2: destinationItemId,
      };

      const res = await swapFaqCategoryOrder(body)?.unwrap();
      if (res) {
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  switch (action) {
    case "Category":
      return (
        <CategoryManagement
          data={categories?.map((i: any) => ({ ...i, id: `${i.id}` }))}
          onBack={() => {
            setAction("List");
          }}
          onDragEnd={dragEnded}
        />
      );
    case "View":
      return (
        <PostDetail
          faqInfo={faqInfo}
          title={faqInfo?.question}
          date={faqInfo?.createdAt}
          content={faqInfo?.answer}
          imageUrl={faqInfo?.imageUrl}
          fileUrls={faqInfo?.fileUrls ? JSON.parse(faqInfo?.fileUrls) : []}
          isShowNext={isShowNext}
          isShowPrev={isShowPrev}
          onBack={() => {
            setAction("List");
            setFaqId(null);
            setSearchKeyword("");
          }}
          onDelete={() => {
            onDelete(faqId);
          }}
          onEdit={() => {
            setAction("Edit");
          }}
          onNext={() => {
            setFaqId(allData[faqInfo.number]?.id);
          }}
          onPrev={() => {
            setFaqId(allData[faqInfo.number - 2]?.id);
          }}
        />
      );
    case "Add":
    case "Edit":
      return (
        <EditPost
          ref={editPostRef}
          pageType={pageType}
          faqInfo={faqInfo}
          action={action}
          titleInfo={faqInfo?.question}
          contentInfo={faqInfo?.answer}
          titleMaxLength={1000}
          contentMaxLength={5000}
          imageUrlInfo={faqInfo?.imageUrl || ""}
          files={faqInfo?.fileUrls ? JSON.parse(faqInfo.fileUrls) : []}
          options={categories}
          onClose={() => {
            setAction("List");
            setFaqId(null);
          }}
          onSubmit={(data: any) => {
            onHandleSubmit(data);
          }}
        />
      );
    default:
      return (
        <div className="faq-management">
          <div className="header-wrapper">
            <div className="title">{`${t(type === "PROVIDER" ? "shipper_customer_center" : "carrier_faq")}`}</div>
            {pageType === "carrier" && (
              <div className="search-wrapper">
                <Input
                  prefix={<SearchOutlined />}
                  placeholder={"검색어(제목)을 입력해주세요"}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => debouncedSearchHandler(event.target.value)}
                />
              </div>
            )}
          </div>

          <div className="top-content">
            <div>
              {type === "PROVIDER" && (
                <Select
                  className="category-options"
                  options={categories}
                  placeholder={t("all")}
                  onChange={handleChangeCategory}
                  value={category}
                  allowClear={true}
                />
              )}
            </div>
            <div className="right-content">
              {type === "PROVIDER" && (
                <div className="post-btn" onClick={goToCategoryManage}>
                  <div className="post-text">{t("category_management")}</div>
                </div>
              )}
              <div className="post-btn" onClick={onPost}>
                <div className="post-text">{t("post_registration")}</div>
              </div>
            </div>
          </div>
          <div className="body-content">
            <Table
              locale={{ emptyText: t("no_data") }}
              dataSource={allData}
              columns={columns}
              rowKey="id"
              scroll={{
                y: "calc(100vh - 410px)",
                scrollToFirstRowOnChange: true,
              }}
              tableLayout="auto"
              pagination={
                total > PER_PAGE
                  ? {
                      position: ["bottomCenter"],
                      current: page || 1,
                      pageSize: PER_PAGE,
                      total: total,
                      showSizeChanger: false,
                      onChange: onChangePage,
                    }
                  : false
              }
            />
          </div>
        </div>
      );
  }
}
