import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import icMore from "../../../../assets/images/ic_more.svg";
import {
  useDeleteShipperMutation,
  useDownloadBusinessLicensesMutation,
  useGetShipperByIdQuery,
  useUpdateShipperStatusMutation,
} from "../../../../pages/MemberManagement/memberApi";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import "./style.scss";
import DocumentItem from "../../../docItem";
import { Button } from "antd";
import { downloadFileFromBlob } from "../../../../utils/commonFnc";
import { formatPhoneNumber } from "../../../../utils/utils";

export default function ShipperDetail(props: any) {
  const [info, setInfo] = useState(props.data.info);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { data: shipperDetail, isSuccess } = useGetShipperByIdQuery(
    { id: props.data.info?.id },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  const [updateShipperStatus] = useUpdateShipperStatusMutation();
  const [deleteShipper] = useDeleteShipperMutation();
  const [downloadBusinessLicenses] = useDownloadBusinessLicensesMutation();

  useEffect(() => {
    if (props?.data?.info) {
      setIsShowDelete(false);
      setInfo(shipperDetail);
    }
  }, [props, shipperDetail]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const onHandleMoreClick = () => {
    setIsShowDelete(!isShowDelete);
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteClick = async () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_delete_member"),
          dialogContent: t("cancel_dispatch_note"),
        },
        handleAction: onHandleDeleteAction,
      }),
    );
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteAction = async () => {
    const params = {
      id: info.id,
    };
    const res = await deleteShipper(params); //@ts-ignore
    showResult(res?.error, t("deletion_is_complete"));
  };

  const onHandleBlockClick = async () => {
    const params = {
      id: info.id,
      status: "BLOCK", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateShipperStatus(params); //@ts-ignore
    showResult(res?.error, t("member_is_blocked"));
  };

  const onHandleUnblockClick = async () => {
    const params = {
      id: info.id,
      status: "ACTIVE", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateShipperStatus(params); //@ts-ignore
    showResult(res?.error, t("member_is_released"));
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    if (!error?.data?.message) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: error?.data?.message,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  };

  /* ---------------------------------- 메모 저장 --------------------------------- */
  const onHandleSubmitClick = () => {
    if (info?.status) {
      if (info?.status === "ACTIVE") {
        dispatch(
          openModal({
            template: "show-confirm-dialog-modal",
            width: "468px",
            data: {
              dialogTitle: t("block_member_confirm"),
            },
            handleAction: onHandleBlockClick,
          }),
        );
      } else if (info?.status === "INACTIVE") {
        dispatch(
          openModal({
            template: "show-sign-up-approval-modal",
            width: "629px",
            data: {
              info: info,
            },
            // handleAction: onHandleApproveClick,
          }),
        );
      } else if (info?.status === "BLOCK") {
        dispatch(
          openModal({
            template: "show-confirm-dialog-modal",
            width: "468px",
            data: {
              dialogTitle: t("unblock_member_confirm"),
            },
            handleAction: onHandleUnblockClick,
          }),
        );
      }
    }
  };

  const onHandleApproveClick = async () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_approve_carrier"),
        },
        // handleAction: onHandleApproveClick,
      }),
    );
  };

  const getTextSubmit = () => {
    if (info?.status) {
      if (info?.status === "ACTIVE") {
        return t("member_block");
      } else if (info?.status === "INACTIVE") {
        return t("sign_up_approval");
      } else {
        return t("unblock");
      }
    }
  };

  const downloadAllHandler = async () => {
    if (info?.id) {
      let res = await downloadBusinessLicenses(info.id);
      if ("data" in res) {
        downloadFileFromBlob(res.data, "businessLicenses.zip");
      }
    }
  };
  console.log(info);

  return (
    <div className="shipper-detail-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-left">
          <div className="top-right">
            <div className="representative-name">
              <div className="text-title">{info?.representationName}</div>
              <div className="text-sub">{t("representative_name")}</div>
            </div>
            <div className="more-wrapper">
              <div className="more-btn" onClick={onHandleMoreClick}>
                <img className="ic-more" alt="More" src={icMore} />
              </div>
              {isShowDelete && (
                <div className="delete-btn" onClick={onHandleDeleteClick}>
                  <div className="delete-text">{t("delete")}</div>
                </div>
              )}
            </div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("company_name")}</div>
            <div className="text-title">{info?.company}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("대표자 연락처")}</div>
            <div className="text-title">{formatPhoneNumber(info?.phoneContact)}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("이메일")}</div>
            <div className="text-title">{info?.email}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("office_number")}</div>
            <div className="text-title">{info?.officeNumber}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("business_number")}</div>
            <div className="text-title">{info?.businessNumber}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("contact_person_name")}</div>
            <div className="text-title">{info?.picName}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("contact_person_number")}</div>
            <div className="text-title">{info?.picPhone}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">잔여포인트</div>
            <div className="text-title">{info?.points}</div>
          </div>
          {info?.status !== "INACTIVE" && (
            <div className="content-contact">
              <div className="content-contact-item">
                <div className="text-sub">{t("additional_contact_number")}</div>
                <div className="text-title">
                  {info?.companyContacts && info?.companyContacts.length > 0 && info?.companyContacts[0]}
                </div>
              </div>
              <div className="content-contact-item">
                <div className="text-sub">{t("additional_contact_number")}</div>
                <div className="text-title">
                  {info?.companyContacts && info?.companyContacts.length > 1 && info?.companyContacts[1]}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="content-right">
          <div className="content-right-header">
            <div className="btn-wrapper">
              <div className={`submit-btn ${info?.status}`} onClick={onHandleSubmitClick}>
                <div className={`submit-text ${info?.status}`}>{info && getTextSubmit()}</div>
              </div>
              <Button className="download-all" onClick={downloadAllHandler}>
                {t("모두 다운로드")}
              </Button>
            </div>
            <div className="text-title">{t("documents_to_be_submitted")}</div>
          </div>
          <div className="content-right-body">
            <DocumentItem
              title={t("business_registration")}
              link={info?.status === "INACTIVE" ? null : info?.businessLicenses?.businessLicense}
            />
            <DocumentItem
              title={t("representative_registration_certificate")}
              link={info?.status === "INACTIVE" ? null : info?.businessLicenses?.representativeRegistrationCertificate}
            />
            {/* <DocumentItem
              title={t("terms_and_conditions_agreement")}
              link={info.businessLicenses?.termsConditions}
            /> */}
            {/* <DocumentItem
              title={t("passbook_copy")}
              link={
                info?.status === "INACTIVE"
                  ? null
                  : info?.businessLicenses?.passbookCopy
              }
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
