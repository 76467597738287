import "./style.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ShippingInfo } from "../../components/orderInfo/shippingInfo";
import { useGetOrderDetailQuery } from "./settlementApi";
import { CarrierFreightCost } from "../../components/orderInfo/carrierFreightCost";
import { useDispatch } from "react-redux";
import { useApprovedRequestbillMutation } from "../DispatchManagement/dispatchApi";
import { closeDialog, openDialog } from "../../components/customDialog/dialogSlice";
import { getDateFormat } from "../../utils/utils";

const TranportDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [approvedRequestBill] = useApprovedRequestbillMutation();
  const params = useParams();
  const { id } = params;

  const { data } = useGetOrderDetailQuery({ id: id });

  /* --------------------------------- 데이타 전환 --------------------------------- */
  const transformData = (item: any) => {
    if (!item) {
      return {};
    }
    return {
      ...item,
      expectedStartDate: getDateFormat(item.expectedStartDate, "yyyy.MM.DD A hh시 mm분"),
      expectedEndDate: getDateFormat(item.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
      actualStartDate: getDateFormat(item.actualStartDate, "yyyy.MM.DD A hh시 mm분"),
      actualEndDate: getDateFormat(item.actualEndDate, "yyyy.MM.DD A hh시 mm분"),
    };
  };

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBack = () => {
    navigate("/settlement-management/carrier-request");
  };

  /* --------------------------------- 계산서 발행 --------------------------------- */
  const onPublish = () => {
    dispatch(
      openDialog({
        type: "confirm",
        title: t("publish_bill_confirm"),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          const res = await approvedRequestBill({
            ids: [id],
          }); //@ts-ignore
          showResult(res?.error, t("issued_2"));
        },
        actionConfirm: () => {
          dispatch(
            openDialog({
              type: "info",
              content: t("canceled"),
              actionConfirm: onBack,
            }),
          );
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: !error?.data?.message ? msgSuccess : error?.data?.message,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          if (!error?.data?.message) {
            onBack();
          } else {
            dispatch(closeDialog());
          }
        },
      }),
    );
  };

  return (
    <div className="page-container">
      <div className="title">{t("tranport_detail")}</div>

      <div className="shipping-info">
        <ShippingInfo data={transformData(data)} showContactInfo showCarrierInfo={true} />
      </div>
      <div className="freight-cost">
        <CarrierFreightCost data={data} />
      </div>

      <div className="button-wrap">
        <div className="base-btn btn-cancel" onClick={onBack}>
          {t("이전")}
        </div>
        {data?.requestBillStatus !== "REQUESTING" && (
          <div className="base-btn disabled">{t("publication_complete")}</div>
        )}
      </div>
    </div>
  );
};

export default TranportDetail;
