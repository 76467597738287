import { useEffect, useState } from "react";
import { useGetPostsQuery } from "../carrierPostApi";
import { DATE_FORMAT_DOT, PER_PAGE } from "../../../utils/constants";
import queryString from "query-string";
import { getDateFromData, getDateToData } from "../../../utils/commonFnc";
import { EStatus, IPost } from "../type";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import NewTable from "../../../components/NewTable";
import moment from "moment";
import { formatPhoneNumber } from "../../../utils/utils";
import "../styles/index.scss";
import { useNavigate } from "react-router-dom";

const CarrierPosts = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    startDate: null,
    endDate: null,
  });
  const [filters, setFilters] = useState<string[]>(["carrierphone", "content"]);
  const [searchParams, setSearchParams] = useState<string>("");
  const navigate = useNavigate();

  const { data, isFetching } = useGetPostsQuery(searchParams, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    setSearchParams(getSearchParams(params));
  }, [params]);

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: params.limit,
      orderBy: "createdAt",
      order: "desc",
      fieldSearchTime: "createdAt",
    };
    if (params.startDate) {
      _params.from = getDateFromData(params.startDate);
    }
    if (params.endDate) {
      _params.to = getDateToData(params.endDate);
    }

    let filtersQuery = "";
    if (params.search) {
      _params.keyword = params.search;
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }
    const paramQuery = queryString.stringify(_params);

    return `${paramQuery}&${filtersQuery}`;
  };

  useEffect(() => {
    setDataSource(data?.data);
    setMetaData(data?.meta);
  }, [data]);

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    { label: t("작성자 아이디"), value: "carrierphone" },
    { label: t("내용"), value: "content" },
  ];

  const columns: ColumnsType<IPost> = [
    {
      title: t("번호"),
      dataIndex: "idx",
      key: "idx",
      width: "5%",
      ellipsis: true,
      align: "center",
      render: (_, record: IPost, index) => {
        const total = metaData?.total || 0;
        const perPage = params?.limit || PER_PAGE;
        const currentPage = params?.page || 1;
        if (total > 0) {
          return metaData?.total - (index + perPage * (currentPage - 1));
        }
        return "";
      },
    },
    {
      title: t("작성자 아이디"),
      dataIndex: "name",
      key: "name",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (_, record: IPost) => formatPhoneNumber(record?.carrier?.phone || ""),
    },
    {
      title: t("내용"),
      dataIndex: "content",
      key: "content",
      width: "45%",
      ellipsis: true,
      align: "center",
      render: (content) => <span>{content}</span>,
    },
    {
      title: t("댓글 수"),
      dataIndex: "comments",
      key: "comments",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (_, record: IPost) => <span>{record?._count?.comments || 0}개</span>,
    },
    {
      title: t("작성 일자"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (_, record: IPost) => <>{moment(record?.createdAt).format(DATE_FORMAT_DOT)}</>,
    },
    {
      title: t("상태 구분"),
      dataIndex: "status",
      key: "status",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (_, record: IPost) => <>{record?.status === EStatus.PUBLIC ? "노출" : "숨김"}</>,
    },
    {
      title: t("상세"),
      dataIndex: "detail",
      key: "detail",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (_, record: IPost) => (
        <span className="detail-btn" onClick={() => navigate(`/carrier/posts/${record.id}`)}>
          상세
        </span>
      ),
    },
  ];

  return (
    <div id="carrier-posts-page">
      <NewTable
        title={t("운송인 게시판 관리")}
        columns={columns}
        allData={dataSource}
        metaData={metaData}
        params={params}
        setParams={setParams}
        loading={isFetching}
        searchFilterOptions={searchFilterOptions}
        filters={filters}
        setFilters={setFilters}
        showDateFilter={true}
        maxLengthInput={10}
      />
    </div>
  );
};

export default CarrierPosts;
