import React, { useEffect, useState } from "react";
import NewTable from "../../components/NewTable";
import { PER_PAGE } from "../../utils/constants";
import { useGetPointsQuery } from "./pointApi";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./style.scss";
import queryString from "query-string";
import { getDateFromData, getDateToData } from "../../utils/commonFnc";
import { formatNumberToCurrency } from "../../utils/utils";

enum EPointType {
  ALL = "all",
  USED = "used",
  ADD = "add",
}

/* ---------------------------------- 시간 표시 --------------------------------- */
export const formatTime = (timestamp: string) => {
  const date = new Date(timestamp);

  // Get individual components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  return (
    <>
      <p>
        {year}.{month}.{day}
      </p>
      <p>
        {formattedHours?.toString()?.padStart(2, "0")}:{minutes} {period}
      </p>
    </>
  );
};

const PointManagement = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    startDate: null,
    endDate: null,
    status: EPointType.ALL,
    order: "DESC",
  });
  const [filters, setFilters] = useState<string[]>(["username"]);
  const [metaData, setMetaData] = useState<any>({});
  const [allData, setAllData] = useState<any>([]);
  const [searchParams, setSearchParams] = useState<string>("");

  const { data, isFetching } = useGetPointsQuery(searchParams, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: params.limit,
      fieldSearchTime: "createdAt",
    };

    if (params.startDate) {
      _params.from = getDateFromData(params.startDate);
    }
    if (params.endDate) {
      _params.to = getDateToData(params.endDate);
    }

    if (params.order) {
      _params.order = params.order;
    }
    if (params.status !== EPointType.ALL) {
      _params.type = params.status;
    }

    let filtersQuery = "";
    if (params.search) {
      _params.keyword = params.search;
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }

    const paramQuery = queryString.stringify(_params);

    return `${paramQuery}&${filtersQuery}`;
  };

  useEffect(() => {
    setSearchParams(getSearchParams(params));
  }, [params]);

  useEffect(() => {
    setAllData(data?.data?.map((item: any) => ({ ...item, id: item?.id?.concat(Math.random()) })) ?? []);

    setMetaData(data?.meta);
  }, [data]);

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = [
    {
      label: t("pm.filter_tab_one"),
      value: EPointType.ALL,
    },
    {
      label: t("pm.filter_tab_two"),
      value: EPointType.USED,
    },
    {
      label: t("pm.filter_tab_three"),
      value: EPointType.ADD,
    },
  ];

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    {
      label: "아이디",
      value: "username",
    },
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "이름/대표자명",
      value: "representationName",
    },
  ];

  const columns: any = [
    // 아이디, 회원구분, 회사명, 이름/대표자명, 결제금액, 사용/지급 포인트, 일시
    {
      title: "아이디",
      dataIndex: "id",
      key: "id",
      align: "center",
      render(_: any, record: any) {
        // if (record?.provider?.type === "NORMAL") {
        //   return "일반 회원";
        // }
        return record?.provider?.username;
      },
    },
    {
      title: "회원구분",
      dataIndex: "type",
      align: "center",
      key: "type",
      render(_: any, record: any) {
        const type = record?.provider?.type;
        return type === "NORMAL"
          ? "일반회원"
          : type === "BUSINESS"
          ? "기업회원"
          : type === "PAY_LATER"
          ? "후불회원"
          : "";
      },
    },
    {
      title: "회사명",
      dataIndex: "company",
      align: "center",
      key: "company",
      render(_: any, record: any) {
        return record?.provider?.company ?? "";
      },
    },
    {
      title: "이름/대표자명",
      align: "center",
      dataIndex: "representationName",
      key: "representationName",
      render: (_: any, record: any): any => {
        return record?.provider?.representationName ?? "";
      },
    },
    {
      title: "결제금액",
      align: "center",
      dataIndex: "firstNicepayAmt",
      key: "firstNicepayAmt",
      render(firstNicepayAmt: any) {
        return (
          <div className="content-right">
            {" "}
            {firstNicepayAmt && firstNicepayAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"}
          </div>
        );
      },
    },
    {
      align: "center",
      title: "사용/지급 포인트",
      dataIndex: "point",
      key: "point",
      render: (value: number) => {
        return (
          <div className="content-right">
            <span className={Number(value) > 0 ? "point-add" : "point-used"}>
              {Number(value) > 0 ? "+" : ""} {formatNumberToCurrency(value)} {t("won")}
            </span>
          </div>
        );
      },
    },
    {
      align: "center",
      title: "일시",
      dataIndex: "createdAt",
      key: "createdAt",
      render(item: any, record: any) {
        return (
          moment(item).isValid() && (
            <div className={Number(record?.point || 0) > 0 ? "point-add" : "point-used"}>
              <span>{moment(item)?.local()?.format("YYYY.MM.DD")}</span> <br />
              <span>{moment(item)?.local()?.format("hh:mm A")}</span>
            </div>
          )
        );
      },
    },
  ];

  return (
    <div className="point-management">
      <NewTable
        title={t("point_usage_history")}
        columns={columns}
        metaData={metaData}
        allData={allData}
        params={params}
        setParams={setParams}
        loading={isFetching}
        typeOptions={typeOptions}
        searchFilterOptions={searchFilterOptions}
        filters={filters}
        setFilters={setFilters}
        showDateFilter={true}
      />
    </div>
  );
};

export default PointManagement;
