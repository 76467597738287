import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCommentDetailQuery, useUpdateCommentStatusMutation } from "../carrierPostApi";
import { Button, Skeleton } from "antd";
import "../styles/commentDetail.scss";
import { formatPhoneNumber } from "../../../utils/utils";
import { DATE_FORMAT_DOT } from "../../../utils/constants";
import moment from "moment-timezone";
import { EStatus } from "../type";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../components/customDialog/dialogSlice";
import { wait } from "../../../utils/commonFnc";

const PostCommentDetail = () => {
  const { t } = useTranslation();
  const { postId, commentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: commentDetail, isFetching } = useGetCommentDetailQuery(
    {
      postId,
      commentId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !postId || !commentId,
    },
  );

  const [updateCarrierCommentStatus] = useUpdateCommentStatusMutation();

  if (!postId || !commentId || !commentDetail) {
    return <>{t("no_data")}</>;
  }

  /* ---------------------------------- 상태 변경 --------------------------------- */
  const toggleStatus = (status: EStatus) => {
    const confirmMessage = status === EStatus.PUBLIC ? "숨김 처리 하시겠습니까?" : "노출 처리 하시겠습니까?";
    const changeStatusSuccess = status === EStatus.PUBLIC ? "숨김 처리 되었습니다." : "노출 처리 되었습니다.";
    dispatch(
      openDialog({
        type: "confirm",
        content: confirmMessage,
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: async () => {
          const res: any = await updateCarrierCommentStatus({
            postId,
            commentId,
            status: status === EStatus.PUBLIC ? EStatus.UNPUBLIC : EStatus.PUBLIC,
          });
          await wait(300);
          dispatch(
            openDialog({
              type: "info",
              content: res?.error?.data?.message || changeStatusSuccess,
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        },
      }),
    );
  };

  return (
    <div id="post-detail-page">
      <div className="post-detail-header">
        <h2 className="title">댓글 상세보기</h2>
        <div className="btn-wrapper">
          <Button className="btn-status" onClick={() => toggleStatus(commentDetail.status)}>
            {commentDetail.status === EStatus.PUBLIC ? "숨김 처리" : "노출 처리"}
          </Button>
        </div>
      </div>
      {isFetching ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          <div className="post-detail-content">
            <div className="post-item">
              <label className="item-label">작성자 아이디: </label>
              <span className="item-value">{formatPhoneNumber(commentDetail?.carrier?.phone)}</span>
            </div>
            <div className="post-item">
              <label className="item-label">작성일자: </label>
              <span className="item-value">
                {moment(commentDetail?.createdAt).isValid() && moment(commentDetail?.createdAt).format(DATE_FORMAT_DOT)}
              </span>
            </div>
            <div className="post-item">
              <label className="item-label">상태 구분: </label>
              <span className="item-value">{commentDetail?.status === EStatus.PUBLIC ? "노출" : "숨김"}</span>
            </div>
            <div className="post-item">
              <label className="item-label">내용: </label>
              <p className="item-value">{commentDetail?.content || ""}</p>
            </div>
          </div>
          <Button className="back-btn" onClick={() => navigate(-1)}>
            이전
          </Button>
        </>
      )}
    </div>
  );
};

export default PostCommentDetail;
