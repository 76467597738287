import icClose from "../../../../assets/images/ic_close.svg";
import { useTranslation } from "react-i18next";
import { useGetOrderDetailQuery, useGetTotalDistanceQuery } from "../../../../pages/SettlementManagement/settlementApi";
import { formatPhoneNumber, getDateFormat } from "../../../../utils/utils";
import { closeModal } from "../../modalSlice";
import { useDispatch } from "react-redux";
import "./style.scss";
import { ShippingInfo } from "../../../orderInfo/shippingInfo";
import { CarrierFreightCost } from "../../../orderInfo/carrierFreightCost";
import { Skeleton } from "antd";

interface AttachFileInfo {
  name: string;
  file: any;
  url: any;
}

const SettlementHistoryOrderDetail = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const { data, isFetching } = useGetOrderDetailQuery(
    { id: props?.data?.id },
    {
      refetchOnMountOrArgChange: true,
      skip: !props?.data?.id,
    },
  );

  const { data: dataTotalDistance } = useGetTotalDistanceQuery(
    {
      id: +data?.carrierId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !data?.carrierId,
    },
  );

  /* --------------------------------- 데이타 전환 --------------------------------- */
  const transformData = (item: any) => {
    if (!item) {
      return {};
    }
    return {
      ...item,
      expectedStartDate: getDateFormat(item.expectedStartDate, "yyyy.MM.DD A hh시 mm분"),
      expectedEndDate: getDateFormat(item.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
      actualStartDate: getDateFormat(item.actualStartDate, "yyyy.MM.DD A hh시 mm분"),
      actualEndDate: getDateFormat(item.actualEndDate, "yyyy.MM.DD A hh시 mm분"),
    };
  };

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBack = () => {
    dispatch(closeModal());
  };

  return (
    <div className="settlement-history-order-detail-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        {data && !isFetching ? (
          <>
            <div className="shipping-info">
              <ShippingInfo data={transformData(data)} showContactInfo showCarrierInfo={true} />
            </div>

            <div className="carrier-info">
              <div className="container-wrap">
                <div className="card-header">
                  <div className="header-title">{t("운송인 정보")}</div>
                </div>
                <div className="card-content">
                  <div className="info-section">
                    <div className="column-info w-20">
                      <div className="item-column">
                        <div className="item-title">운송 기사</div>
                        <div className="item-sub">{data?.carrier?.username || "-"}</div>
                      </div>
                    </div>
                    <div className="column-info w-20">
                      <div className="item-column">
                        <div className="item-title">운송인 연락처</div>
                        <div className="item-sub">{formatPhoneNumber(data?.carrier?.phone) || "-"}</div>
                      </div>
                    </div>
                    <div className="column-info w-20">
                      <div className="item-column">
                        <div className="item-title">차량 정보</div>
                        <div className="item-sub">
                          {t(`${data?.carrier?.tonnage}`) || "-"}
                          {t(`${data?.carrier?.vehicleType}`) || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="column-info w-20">
                      <div className="item-column">
                        <div className="item-title">차량 번호</div>
                        <div className="item-sub">
                          {data?.carrier?.region || "-"} {data?.carrier?.licensePlate || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="column-info w-20">
                      <div className="item-column">
                        <div className="item-title">총 운행거리</div>
                        <div className="item-sub">{Number(dataTotalDistance?.totalDistance).toFixed(2) || 0} km</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="freight-cost">
              <CarrierFreightCost data={data} />
            </div>

            <div className="button-wrap">
              <div className="base-btn btn-cancel" onClick={onBack}>
                {t("이전")}
              </div>
            </div>
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>
    </div>
  );
};

export default SettlementHistoryOrderDetail;
