import { Button, Checkbox, DatePicker, DatePickerProps } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./style.scss";

export default function SearchFilter({ onClose, data, handleAction }: any) {
  const [values, setValues] = useState(data.values);
  const [showDateFilter, setShowDateFilter] = useState(data.showDateFilter);
  const { t } = useTranslation();
  const [dateStart, setDateStart] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    setShowDateFilter(data.showDateFilter);
    setValues(data.values);
  }, [data]);
  useEffect(() => {
    setDateStart(undefined);
    setDateEnd(undefined);
  }, [location.pathname]);

  const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
    setValues(checkedValues);
    const action = {
      type: "checkbox",
      data: checkedValues,
    };
    handleAction(action);
  };

  /* --------------------------------- 시작 일 변경 -------------------------------- */
  const onChangeStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    const action = {
      type: "startDate",
      data: dateString,
    };
    setDateStart(date);
    handleAction(action);
  };

  /* --------------------------------- 마감 일 변경 -------------------------------- */
  const onChangeEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    const action = {
      type: "endDate",
      data: dateString,
    };
    setDateEnd(date);
    handleAction(action);
  };

  return (
    <div className="search-filter-wrapper">
      <div className="title">{t("filtering")}</div>
      <div className="content">
        {showDateFilter && (
          <div className="date-picker-wrapper">
            <div className="start-date-picker">
              <div className="text-title-date">{t("start_date")}</div>
              <DatePicker
                inputReadOnly={true}
                value={dateStart}
                size="middle"
                onChange={onChangeStartDate}
                style={{ width: 212 }}
                placeholder={t("please_select_a_date")}
              />
            </div>
            <div className="space"></div>
            <div className="end-date-picker">
              <div className="text-title-date">{t("end_date")}</div>
              <DatePicker
                inputReadOnly={true}
                value={dateEnd}
                size="middle"
                onChange={onChangeEndDate}
                style={{ width: 212 }}
                placeholder={t("please_select_a_date")}
              />
            </div>
          </div>
        )}
        <Checkbox.Group options={data.options} value={values} onChange={onChangeCheckbox} />
      </div>
      <Button
        className="btn-confirm"
        onClick={() => {
          onClose();
        }}
      >
        {t("dialog_btn_confirm")}
      </Button>
    </div>
  );
}
