import { EOrderType } from '../../utils/types/order';
import './style.scss';
export default function TransportMethodBadge({
  additionalClass,
  orderType = EOrderType.ONE_WAY,
}: {
  additionalClass?: any;
  orderType: EOrderType;
}) {
  return (
    <span className={`transport-method-badge ${orderType} ${additionalClass}`}>
      {orderType === EOrderType.ONE_WAY ? '편도운송' : '왕복운송'}
    </span>
  );
}
