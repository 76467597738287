import Login from "../pages/Auth/Login";
import MemberManagementShipper from "../pages/MemberManagement/MemberManagementShipper";
import MemberManagementCarrier from "../pages/MemberManagement/MemberManagementCarrier";
import DispatchManagement from "../pages/DispatchManagement";
import MatchingManagement from "../pages/MatchingManagement";
import NoticeManagement from "../pages/NoticeManagement";
import FaqManagement from "../pages/FaqManagement";
import AdvertisementManagement from "../pages/AdvertisementManagement";
import SettlementCarrierRequest from "../pages/SettlementManagement/SettlementCarrierRequest";
import SettlementHistoryCarrier from "../pages/SettlementManagement/SettlementHistoryCarrier";
import SettlementHistoryShipper from "../pages/SettlementManagement/SettlementHistoryShipper";
import SettlementHistoryShipperDetail from "../pages/SettlementManagement/SettlementHistoryShipperDetail";
import SettlementHistoryOrderDetail from "../pages/SettlementManagement/SettlementHistoryOrderDetail";
import { CouponManagement } from "../pages/CouponManagement";
import { CompanyManagement } from "../pages/CompanyManagement";
import AlarmTalkManagement from "../pages/AlarmTalkManagement";
import PreviewBill from "../pages/SettlementManagement/PreviewBill";
import TranportDetail from "../pages/SettlementManagement/TranportDetail";
import FreightCost from "../pages/FreightCost";
import PointManagement from "../pages/PointManagement";
import CarrierSettlement from "../pages/SettlementManagement/CarrierSettlement";
import SalesGraph from "../pages/SettlementManagement/SalesGraph";
import CarrierSettlementDetail from "../pages/SettlementManagement/CarrierSettlementDetail";
import CouponManagementDetail from "../pages/CouponManagement/components/detail";
import CreateCoupon from "../components/createCoupon";
import CarrierPosts from "../pages/CarrierPosts/pages";
import PostCommentDetail from "../pages/CarrierPosts/pages/PostCommentDetail";
import PostDetail from "../pages/CarrierPosts/pages/PostDetail";
import PopupManagement from "../pages/PopupManagement";
import SHS2 from "../pages/SettlementManagement/SHS2";
import DispatchModify from "@/pages/DispatchModify";

export const PrivateRoutes = [
  {
    path: "/member-management/shipper",
    element: <MemberManagementShipper type="NORMAL" />,
  },
  {
    path: "/member-management/business_shipper",
    element: <MemberManagementShipper type="BUSINESS" />,
  },
  {
    path: "/member-management/paylater_shipper",
    element: <MemberManagementShipper type="PAY_LATER" />,
  },
  {
    path: "/member-management/carrier",
    element: <MemberManagementCarrier />,
  },
  {
    path: "/dispatch-management",
    element: <DispatchManagement />,
  },
  {
    path: "/modify-dispatch",
    element: <DispatchModify />,
  },
  {
    path: "/matching-management",
    element: <MatchingManagement />,
  },
  {
    path: "/settlement-management/carrier-history",
    element: <SettlementHistoryCarrier />,
  },
  {
    path: "/settlement-management/shipper-history",
    element: <SettlementHistoryShipper />,
  },
  {
    path: "/settlement-management/shipper-history/detail",
    element: <SHS2 />,
  },
  {
    path: "/settlement-management/carrier-history/detail-order",
    element: <SettlementHistoryOrderDetail />,
  },
  {
    path: "/settlement-management/carrier-request",
    element: <SettlementCarrierRequest />,
  },
  {
    path: "/settlement-management/carrier-settlement",
    element: <CarrierSettlement />,
  },
  {
    path: "/settlement-management/carrier-settlement/:id",
    element: <CarrierSettlementDetail />,
  },
  {
    path: "/settlement-management/sales-graph",
    element: <SalesGraph />,
  },
  {
    path: "/settlement-management/carrier-request/preview-bill",
    element: <PreviewBill />,
  },
  {
    path: "/settlement-management/carrier-request/tranport-detail/:id",
    element: <TranportDetail />,
  },
  {
    path: "/notice-management/shipper",
    element: <NoticeManagement />,
  },
  {
    path: "/notice-management/carrier",
    element: <NoticeManagement />,
  },
  {
    path: "/notice-management",
    element: <NoticeManagement />,
  },
  {
    path: "/faq-management/shipper",
    element: <FaqManagement pageType="shipper" key="shipper" />,
  },
  {
    path: "/faq-management/carrier",
    element: <FaqManagement pageType="carrier" key="carrier" />,
  },
  {
    path: "/ads-management",
    element: <AdvertisementManagement />,
  },
  {
    path: "/company-management",
    element: <CompanyManagement />,
  },
  {
    path: "/popup-management",
    element: <PopupManagement />,
  },
  {
    path: "/coupon-management/coupon",
    element: <CouponManagement />,
  },
  {
    path: "/coupon-management/coupon/detail/:id",
    element: <CouponManagementDetail />,
  },
  {
    path: "/coupon-management/coupon/create",
    element: <CreateCoupon />,
  },
  {
    path: "/coupon-management/coupon/update/:id",
    element: <CreateCoupon />,
  },
  {
    path: "/coupon-management/point",
    element: <PointManagement />,
  },
  {
    path: "/freight-cost",
    element: <FreightCost />,
  },
  {
    path: "/alarm-talk-management",
    element: <AlarmTalkManagement />,
  },
  {
    path: "/carrier/posts",
    element: <CarrierPosts />,
  },
  {
    path: "/carrier/posts/:id",
    element: <PostDetail />,
  },
  {
    path: "/carrier/posts/:postId/comments/:commentId",
    element: <PostCommentDetail />,
  },
];

export const PublicRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
];
