import { Button, Checkbox, DatePicker, Form, Input, TimePicker } from "antd";
import { FormInstance, useWatch } from "antd/es/form/Form";
import addIcon from "@/assets/images/addIcon.svg";
import deleteIcon from "@/assets/images/icDelete.svg";
import calendarIcon from "@/assets/images/calendar.png";
import { DATE_FORMAT, TIME_FORMAT } from "@/utils/constants";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { openModal } from "@/components/customModal/modalSlice";
import { useRegisterDisPatch } from "@/context/RegisterContext";
import { RouterKakao } from "@/utils/types/kakao";
import moment from "moment";
import IconRepeat from "@/components/icons/IconRepeat";
import { swapIndex } from "@/utils/commonFnc";
import { useEffect, useState } from "react";
import InputKrPhoneDispatch from "@/components/InputPhoneKorean/InputKrPhoneDispatch";

export interface Props {
  form: FormInstance<any>;
  loading?: boolean;
  method?: string | "one-way" | "two-way";
}

interface FieldItem {
  fullAddress?: string;
  detailAddress?: string;
}

function Step2({ form, loading, method }: Props) {
  const dispatch = useDispatch();
  const { setStartRouters, startRouters } = useRegisterDisPatch();
  const endDate = useWatch("endDate", form);
  // const endTime = useWatch("endTime", form);
  // const isPickUpImmediately = useWatch("isPickUpImmediately", form);
  const [open, setOpen] = useState(false);

  const handleOpenKakaoMap = (index: number) => () => {
    dispatch(
      openModal({
        template: "kakao-search-address",
        actionConfirm: (data: RouterKakao) => {
          let fields: FieldItem[] = [...form.getFieldValue("addresses_step_2")];
          const startsPayload = fields.map((_, idx) => (idx === index ? data : startRouters?.[idx]));
          fields[index] = {
            ...fields[index],
            ...data,
          };

          setStartRouters?.(startsPayload);
          form.setFieldValue(`addresses_step_2`, fields);
          setTimeout(() => {
            form.setFieldValue("isEdit", true);
          }, 500);
        },
      }),
    );
  };

  const handleSwapWaypoint = (index: number) => () => {
    let fields: FieldItem[] = form.getFieldValue("addresses_step_2");
    if (!!fields[index + 1].fullAddress && !!fields[index].fullAddress) {
      const newRouters = swapIndex(startRouters, index, index + 1);
      const newFields = swapIndex(fields, index, index + 1);
      form.setFieldValue("addresses_step_2", newFields);
      setTimeout(() => {
        form.setFieldValue("isEdit", true);
      }, 500);
      return;
    }
  };

  const showImmediately = () => {
    const startTimeInput = document.getElementById("startTime") as HTMLInputElement;
    if (form.getFieldValue("isPickUpImmediately") && startTimeInput.value !== "바로상차") {
      startTimeInput.value = "바로상차";
    }

    const endTimeInput = document.getElementById("endTime") as HTMLInputElement;
    if (form.getFieldValue("isDropDownImmediately") && endTimeInput.value !== "바로하차") {
      endTimeInput.value = "바로하차";
    }
  };

  useEffect(() => {
    showImmediately();
    return () => { };
  });

  return (
    <div className="flex flex-col  space-y-6 step-second ">
      <Form.List name="addresses_step_2" initialValue={[{ fullAddress: "", detailAddress: "" }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div className="grid grid-cols-12" key={field.key}>
                {index === 0 && <div className="col-span-2">주소</div>}
                <div className={cx("flex flex-col  col-span-10 col-start-4 ", {})}>
                  <div
                    className={cx("flex items-center ", {
                      "justify-between": index === 0,
                      "justify-end": index !== 0,
                    })}
                  >
                    {index === 0 && <span className="text-sm text-[#FF5C5C]">{/* 경유지는 하나당 10km 이내 */}</span>}
                    {method !== "two-way" &&
                      (index === 0 ? (
                        <div
                          className="flex items-center space-x-1 cursor-pointer"
                          onClick={() => {
                            if (fields.length < 3) {
                              add({ fullAddress: "", detailAddress: "" });
                            }
                          }}
                        >
                          <img src={addIcon} width={16} height={16} alt="add" />
                          <div className="text-sm text-primary-brandblue">{"경유지 추가"}</div>
                        </div>
                      ) : (
                        <div
                          className="flex items-center space-x-1 cursor-pointer justify-self-end"
                          onClick={() => {
                            remove(index);
                            const newRouter = startRouters.filter((_, ix) => ix !== index);
                            setStartRouters?.(newRouter);
                            setTimeout(() => {
                              form.setFieldValue("isEdit", true);
                            }, 500);
                          }}
                        >
                          <img src={deleteIcon} width={16} height={16} alt="add" />
                          <div className="text-sm text-primary-brandblue">삭제</div>
                        </div>
                      ))}
                  </div>
                  <Form.Item noStyle label="" className="w-full">
                    <div className="flex items-center space-x-2">
                      <Form.Item name={[index, "fullAddress"]} noStyle>
                        <Input
                          className={cx(
                            "rounded-lg border-[1.2px] border-solid bg-white h-[50px] disabled:text-black",
                            {
                              "mt-3": method !== "two-way",
                            },
                          )}
                          placeholder="주소를 입력해주세요"
                          maxLength={255}
                          disabled
                        />
                      </Form.Item>
                      <Button
                        className={cx("h-[50px] bg-primary-brandblue text-white rounded-lg w-[88px]", {
                          "mt-3": method !== "two-way",
                        })}
                        onClick={handleOpenKakaoMap(index)}
                        loading={loading}
                      >
                        검색
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item noStyle label="" name={[index, "detailAddress"]}>
                    <Input
                      placeholder="상세주소를 입력해주세요"
                      className="rounded-lg border-[1.2px] border-solid bg-white h-[50px] mt-3"
                    />
                  </Form.Item>
                  {index !== fields.length - 1 && (
                    <button
                      type="button"
                      className="w-[28px] h-[28px] bg-[#F0FCEE] flex justify-center items-center rounded-md border-none self-center cursor-pointer mt-3"
                      onClick={handleSwapWaypoint(index)}
                    >
                      <IconRepeat className="text-[16px] text-[#53A446] " />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
      <InputKrPhoneDispatch
        className="w-[300px] h-[50px]"
        nameInput="contactSender"
        form={form}
        label="연락처"
        labelCol={{ span: 5 }}
        placeholder="‘-’없이 입력해주세요"
      />
      <Form.Item label="상차 예정 시간" className="start-time" labelCol={{ span: 6 }}>
        <div className="flex gap-[6px]">
          <Form.Item label="" name="startDate" className="w-full start-time-first h-[50px]">
            <DatePicker
              className="border-[1.2px] border-solid h-[50px] rounded-lg  w-[146px]"
              placeholder={"일정 선택"}
              format={DATE_FORMAT}
              suffixIcon={<img src={calendarIcon} width={24} height={24} alt="add" />}
              onChange={(date) => {
                // if (!endTime) {
                //   form.setFieldValue("startDate", moment(date).set("minute", 30));
                // }
                if (!date) {
                  form.setFieldValue("startDate", undefined);
                }
                setTimeout(() => {
                  form.setFieldValue("isEdit", true);
                }, 500);
              }}
              disabledDate={(current) => {
                return current < moment().startOf("day");
              }}
            />
          </Form.Item>
          <Form.Item
            label=""
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.startDate !== currentValues.startDate ||
              prevValues.isPickUpImmediately !== currentValues.isPickUpImmediately
            }
            className="w-full start-time-second"
          >
            {({ getFieldValue }) => (
              <Form.Item name="startTime">
                <TimePicker
                  use12Hours
                  showNow={false}
                  open={open}
                  onOpenChange={setOpen}
                  format={TIME_FORMAT}
                  disabled={!getFieldValue("startDate")}
                  className="border-[1.2px] border-solid h-[50px] rounded-lg  w-[146px]"
                  placeholder="시간 선택"
                  minuteStep={30}
                  onBlur={() => {
                    setTimeout(() => {
                      showImmediately();
                    }, 200);
                  }}
                  onChange={() => {
                    setTimeout(() => {
                      form.setFieldValue("isEdit", true);
                    }, 500);
                  }}
                  disabledTime={(current) => {
                    if (form.getFieldValue("isPickUpImmediately")) {
                      return {
                        disabledHours: () => {
                          const hours: number[] = [];
                          for (let i = 23; i >= 0; i--) {
                            hours.push(i);
                          }
                          return hours;
                        },
                        disabledMinutes: (_) => [0, 30],
                      };
                    }
                    return {
                      disabledHours: () => {
                        let hours: number[] = [];
                        let nowHour = moment(current).hour();
                        for (let i = nowHour; i >= 0; i--) {
                          if (
                            getFieldValue("startDate") &&
                            moment(current).day() === moment(getFieldValue("startDate")).day()
                          ) {
                            hours.push(i);
                          }
                        }
                        return hours;
                      },
                    };
                  }}
                  renderExtraFooter={() => (
                    <div className="flex items-center justify-center">
                      <Form.Item valuePropName="checked" noStyle name="isPickUpImmediately">
                        <Checkbox
                          checked={form.getFieldValue("isPickUpImmediately")}
                          onChange={(event) => {
                            const startTimeInput = document.getElementById("startTime") as HTMLInputElement;
                            if (event.target.checked) {
                              setOpen(false);
                              form.setFieldValue("startTime", undefined);
                              startTimeInput.value = "바로상차";

                              //update every time checked on immidially
                              setTimeout(() => {
                                form.setFieldValue("isEdit", true);
                              }, 500);
                            } else {
                              form.setFieldValue("startTime", undefined);
                            }
                          }}
                        >
                          바로상차
                        </Checkbox>
                      </Form.Item>
                    </div>
                  )}
                  key="pick-up"
                />
              </Form.Item>
            )}
          </Form.Item>
        </div>
      </Form.Item>
    </div>
  );
}
export default Step2;
