import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import "./style.scss";
import { Input, Modal } from "antd";
import { vehicleTonnageOptions, vehicleTypeOptions } from "../../../../utils/constants";
import { useUpdateCarrierMutation } from "../../../../pages/MemberManagement/memberApi";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import { Input as AntdInput } from "antd";
import { checkDecimal, wait } from "../../../../utils/commonFnc";

export default function VehicleManage(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateCarrierData] = useUpdateCarrierMutation();
  const [showTonnage, setShowTonnage] = useState<boolean>(false);
  const [showType, setShowType] = useState<boolean>(false);
  const [form, setForm] = useState({
    tonnage: props?.data?.info?.tonnage,
    vehicleType: props?.data?.info?.vehicleType,
    length: props?.data?.info?.length,
    width: props?.data?.info?.width,
    height: props?.data?.info?.height,
  });

  const openTonnageModal = () => setShowTonnage(true);
  const closeTonnageModal = () => setShowTonnage(false);
  const openTypeModal = () => setShowType(true);
  const closeTypeModal = () => setShowType(false);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const onChangeForm = (key: string, value: any) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeVehicleData = async () => {
    if (inValidForm) {
      return;
    }

    const { length, width, height, ...rest } = form;

    const formatForm = {
      ...rest,
      length: +length,
      width: +width,
      height: +height,
    };

    const res: any = await updateCarrierData({
      id: props?.data?.info?.id,
      ...formatForm,
    }); //@ts-ignore

    dispatch(closeModal());
    await showResult(res?.error, t("data_saved"));
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    await wait(300);
    if (!error?.data?.message) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: async () => {
            await wait(300);
            dispatch(
              openModal({
                template: "show-carrier-detail-modal",
                width: "649px",
                data: {
                  info: props?.data?.info,
                },
              }),
            );
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: error?.data?.message,
          confirmText: t("dialog_btn_confirm"),
        }),
      );
    }
  };

  const inValidForm = useMemo(() => {
    const { length, width, height } = form;
    return !checkDecimal(length) || !checkDecimal(width) || !checkDecimal(height);
  }, [form]);

  return (
    <div className="vehicle-data-manage-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("vehicle_data_manage")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="form">
          <div className="form-row">
            <div className="title">{t("tonnage")}</div>
            <div className="select" onClick={openTonnageModal}>
              {form.tonnage}
            </div>
          </div>
          <div className="form-row">
            <div className="title">{t("car_type")}</div>
            <div className="select" onClick={openTypeModal}>
              {form.vehicleType}
            </div>
          </div>
          <div className="form-row">
            <div className="title">{`${t("length")}(m)`}</div>
            <Input
              placeholder={t("enter_number_only")}
              className="form-input"
              value={form.length}
              onChange={(e) => {
                if (checkDecimal(e.target.value) || e.target.value === "") {
                  onChangeForm("length", e.target.value);
                }
              }}
              suffix="m"
            />
          </div>
          <div className="form-row">
            <div className="title">{`${t("width")}(m)`}</div>
            <AntdInput
              placeholder={t("enter_number_only")}
              className="form-input"
              value={form.width}
              onChange={(e) => {
                if (checkDecimal(e.target.value) || e.target.value === "") {
                  onChangeForm("width", e.target.value);
                }
              }}
              suffix="m"
            />
          </div>
          <div className="form-row">
            <div className="title">{`${t("height")}(m)`}</div>
            <Input
              placeholder={t("enter_number_only")}
              className="form-input"
              value={form.height}
              onChange={(e) => {
                if (checkDecimal(e.target.value) || e.target.value === "") {
                  onChangeForm("height", e.target.value);
                }
              }}
              suffix="m"
            />
          </div>
        </div>

        <div className="btn-wrap">
          <div className={`save-btn ${inValidForm ? "disabled" : ""}`} onClick={onChangeVehicleData}>
            {t("save")}
          </div>
        </div>
      </div>
      <Modal
        open={showTonnage}
        title=""
        closable={false}
        footer={null}
        onCancel={closeTonnageModal}
        centered={true}
        className="select-modal"
        width={218}
      >
        <div className="select-modal-container">
          <div className="select-wrap">
            {vehicleTonnageOptions.map((option) => (
              <div
                className={`select-option ${option === form.tonnage ? "selected" : ""}`}
                key={option}
                onClick={() => onChangeForm("tonnage", option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        open={showType}
        title=""
        onCancel={closeTypeModal}
        closable={false}
        footer={null}
        centered={true}
        className="select-modal"
        width={218}
      >
        <div className="select-modal-container vehicle-type">
          <div className="select-wrap">
            {vehicleTypeOptions.map((option) => (
              <div
                className={`select-option ${option === form.vehicleType ? "selected" : ""}`}
                key={option}
                onClick={() => onChangeForm("vehicleType", option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
