/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";
import queryString from "query-string";

export const popupApi = createApi({
  reducerPath: "popupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetPopups"],
  endpoints: (builder) => ({
    getPopup: builder.query({
      query: (data) => {
        let params;
        const { searchAnd, target, status, ...rest } = data;
        const restParams = queryString.stringify(rest);

        const scParams = queryString.stringify(
          { ["searchAnd"]: searchAnd },
          {
            arrayFormat: "bracket",
          },
        );

        if (!!scParams) {
          params = restParams?.concat(`&${scParams}`);
        } else {
          params = restParams;
        }

        return {
          url: `ads-popup/search?${params}`,
          method: "GET",
        };
      },
      //@ts-ignore
      providesTags: ["GetPopups"],
    }),
    createPopup: builder.mutation({
      query: (data) => ({
        url: `ads-popup`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetPopups"],
    }),
    updatePopup: builder.mutation({
      query: (data) => ({
        url: `ads-popup/${data?.id}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["GetPopups"],
    }),
    deletePopup: builder.mutation({
      query: (data: { ids?: number[] }) => ({
        url: `ads-popup`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetPopups"],
    }),
  }),
});

export const { useGetPopupQuery, useCreatePopupMutation, useUpdatePopupMutation, useDeletePopupMutation } = popupApi;
