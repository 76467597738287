/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";
import queryString from "query-string";

export const settlementApi = createApi({
  reducerPath: "settlementApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    "GetShippers",
    "GetCarriers",
    "GetOrder",
    "getShippersHistory",
    "GetOrderDetail",
    "GetMemos",
    "GetGraphs",
    "GetSetlmentRequests",
    "GetOrderMonth",
    "GetProviderList",
  ],
  endpoints: (builder) => ({
    getProviderList: builder.query({
      query: (data) => ({
        url: `providers/${data?.id}/order-aggregate`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetProviderList"],
    }),
    aprrovedPayment: builder.mutation({
      query: (data) => {
        return {
          url: `providers/${data?.id}/order-month/settlement`,
          method: "POST",
          body: data?.body,
        };
      },
      invalidatesTags: ["GetOrder", "GetOrderMonth", "GetProviderList"],
    }),
    getOrderMonth: builder.query({
      query: (data) => ({
        url: `providers/${data?.id}/order-month`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetOrderMonth"],
    }),
    cancelSettlement: builder.mutation({
      query: (data) => {
        return {
          url: `orders/admin/cancel-settlement`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["GetOrder"],
    }),
    getCostList: builder.query({
      query: (data) => ({
        url: `/managers/settlement-cost`,
        method: "GET",
      }),
    }),
    updateMemoOrder: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}/memo`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["GetOrder", "GetOrderDetail", "GetProviderList"],
    }),
    deleteMemoOrder: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}/memo`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetOrder", "GetProviderList"],
    }),
    getOrderDetail: builder.query({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetOrderDetail"],
    }),
    getOrder: builder.query({
      query: (data) => ({
        url: `/orders`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetOrder"],
    }),
    getShipperDetail: builder.query({
      query: (data) => ({
        url: `/providers/` + data.id,
        method: "GET",
      }),
    }),
    getShippersHistory: builder.query({
      query: (data) => ({
        url: `/managers/settlement-providers-history`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["getShippersHistory"],
    }),
    getProviders: builder.query({
      query: (data) => ({
        url: `providers`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["getShippersHistory"],
    }),
    getShippers: builder.query({
      query: (data) => ({
        url: `/providers/`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetShippers"],
    }),
    getShipperById: builder.query({
      query: (data) => ({
        url: `/providers/${data.id}`,
        method: "GET",
      }),
    }),
    approvalMember: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}/status/approval`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    updateShipperStatus: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}/status`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    deleteShipper: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    getCarrier: builder.query({
      query: (data) => ({
        url: `/carriers/${data.id}`,
        method: "GET",
        params: data,
      }),
    }),
    getCarriers: builder.query({
      query: (data) => ({
        url: `/carriers/`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetCarriers"],
    }),
    updateCarrierStatus: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}/status`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetCarriers"],
    }),
    deleteCarrier: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCarriers"],
    }),
    getGraphs: builder.query({
      query: (data) => ({
        url: `/managers/graph?providerId=${data.providerId}`,
        method: "GET",
      }),
      providesTags: ["GetGraphs"],
    }),
    getProviderMemos: builder.query({
      query: (data) => {
        let params;
        const { searchAnd, ...rest } = data?.body;
        const restParams = queryString.stringify(rest);

        const scParams = queryString.stringify(
          { ["searchAnd"]: data?.body?.searchAnd },
          {
            arrayFormat: "bracket",
          },
        );

        if (!!scParams) {
          params = restParams?.concat(`&${scParams}`);
        } else {
          params = restParams;
        }

        return {
          url: `providers/${data.id}/memos?${params}`,
          method: "GET",
        };
      },
      providesTags: ["GetMemos"],
    }),
    createMemo: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/memos`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetMemos", "GetProviderList"],
    }),
    updateMemo: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/memos/${data.memoId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetMemos", "GetProviderList"],
    }),
    updateBill: builder.mutation({
      query: (data) => ({
        url: `orders/carrier/update-bill`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteMemo: builder.mutation({
      query: (data) => ({
        url: `providers/${data.providerId}/memos/${data.memoId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetMemos", "GetProviderList"],
      /*         params: data.providerId,
       */
    }),
    getCarrierSetlmentRequests: builder.query({
      query: (data) => ({
        url: `request-payment-tags`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetSetlmentRequests"],
    }),
    paymentRequest: builder.mutation({
      query: (data) => ({
        url: `request-payment-tags/${data.id}/payment`,
        method: "POST",
      }),
      invalidatesTags: ["GetSetlmentRequests"],
    }),
    unpaymentRequest: builder.mutation({
      query: (data) => ({
        url: `request-payment-tags/${data.id}/cancel-payment`,
        method: "POST",
      }),
      invalidatesTags: ["GetSetlmentRequests"],
    }),
    getOrdersInRequest: builder.query({
      query: (data) => ({
        url: `request-payment-tags/${data.id}`,
        method: "GET",
        params: data,
      }),
    }),
    updateOrderStatus: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}/orders/payment`,
        method: "POST",
      }),
      invalidatesTags: ["GetOrder"],
    }),
    updatePaybackStatus: builder.mutation({
      query: (data) => {
        return {
          url: `/providers/${data.provider_id}/status-payback/month/${data.month}/year/${data.year}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GetGraphs"],
    }),
    getTotalDistance: builder.query({
      query: (data) => ({
        url: `carriers/${data.id}/total-distance`,
        method: "GET",
      }),
    }),
    getPoints: builder.query({
      query: (data) => ({
        url: `/coupons/point/history`,
        method: "GET",
        params: data,
      }),
    }),
    getOrdersGraph: builder.query({
      query: (data) => ({
        url: `/orders/admin/graph`,
        method: "GET",
        params: data,
      }),
    }),
  }),
});

export const {
  useGetOrderMonthQuery,
  useAprrovedPaymentMutation,
  useGetProviderListQuery,
  useCancelSettlementMutation,
  useUpdateMemoOrderMutation,
  useDeleteMemoOrderMutation,
  useGetOrderDetailQuery,
  useGetCarrierQuery,
  useGetShippersQuery,
  useGetProvidersQuery,
  useGetShippersHistoryQuery,
  useGetCarriersQuery,
  useApprovalMemberMutation,
  useUpdateShipperStatusMutation,
  useUpdateCarrierStatusMutation,
  useDeleteShipperMutation,
  useDeleteCarrierMutation,
  useGetOrderQuery,
  useGetCostListQuery,
  useGetGraphsQuery,
  useGetShipperDetailQuery,
  useGetProviderMemosQuery,
  useCreateMemoMutation,
  useUpdateMemoMutation,
  useDeleteMemoMutation,
  useGetShipperByIdQuery,
  useUpdateOrderStatusMutation,
  useUpdatePaybackStatusMutation,
  useGetCarrierSetlmentRequestsQuery,
  usePaymentRequestMutation,
  useUnpaymentRequestMutation,
  useGetOrdersInRequestQuery,
  useGetTotalDistanceQuery,
  useGetPointsQuery,
  useGetOrdersGraphQuery,
  useUpdateBillMutation,
} = settlementApi;
