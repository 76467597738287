import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { vehicleRegisApi } from "./VehicleRegisApi";
interface VehicleRegistation {
  data: any;
  tempVehicle?: any;
  isReadAll?: boolean;
}

const initialState: VehicleRegistation = {
  data: undefined,
  tempVehicle: undefined,
  isReadAll: false,
};
const vehicleRegis = createSlice({
  name: "vehicleRegis",
  initialState,
  reducers: {
    toggleNotiStatus: (
      state: VehicleRegistation,
      action: PayloadAction<any>
    ) => {
      state.isReadAll = action.payload;
    },
    uploadCompletedOrder: (
      state: VehicleRegistation,
      action: PayloadAction<any>
    ) => {
      state = { ...state, data: action.payload };
    },
    saveTempVehicle: (
      state: VehicleRegistation,
      action: PayloadAction<any>
    ) => {
      state = { ...state, tempVehicle: action.payload };
    },
    clearVehicle: (state: VehicleRegistation) => {
      state = { ...state, tempVehicle: undefined };
    },
  },
  extraReducers: (builder: any) => {
    // login with RTK query
    builder
      .addMatcher(
        vehicleRegisApi.endpoints.registerDispatch.matchFulfilled,
        (state: VehicleRegistation, action: PayloadAction<any>) => { }
      )
      .addMatcher(
        vehicleRegisApi.endpoints.registerDispatch.matchRejected,
        (state: VehicleRegistation, action: PayloadAction<any>) => { }
      );
  },
});

const { reducer, actions } = vehicleRegis;
export const {
  uploadCompletedOrder,
  saveTempVehicle,
  clearVehicle,
  toggleNotiStatus,
} = actions;
export default reducer;
