export interface IOrder {
  id: string;
  providerId: number;
  carrierId: number;
  requestPaymentTagId: string;
  type: string;
  isFastShipping: boolean;
  info: string;
  quantity: number;
  impUid: null;
  isCombineGoods: boolean;
  combineGoods: string;
  tonnage: string;
  vehicleType: string;
  packingMethod: string;
  requirement: string;
  scoreRating: number;
  payback: boolean;
  receiptPlace: string;
  detailReceiptPlace: string;
  loadingPlace: string;
  detailLoadingPlace: string;
  loadingPlaceX: string;
  loadingPlaceY: string;
  receiptPlaceX: string;
  receiptPlaceY: string;
  loadingPlaceCoordX: string;
  loadingPlaceCoordY: string;
  receiptPlaceCoordX: string;
  receiptPlaceCoordY: string;
  transitPlaces: any[];
  additionalEndpoints: any[];
  docUrls: IDocURL[];
  expectedStartDate: Date;
  expectedEndDate: Date;
  actualStartDate: null;
  actualEndDate: null;
  status: string;
  adminPaidDate: null;
  reason: null;
  freightCost: number;
  couponDiscountAmount: number;
  pointDiscountAmount: number;
  laborUnemploymentInsurance: number;
  specialSale: number;
  vat: number;
  fax: string;
  sendFaxStatus: string;
  alarmTalkStatus: string;
  requestBillStatus: string;
  requestBillAt: null;
  approvedRequestBillAt: Date;
  carrierPaymentAmount: number;
  excludingFee: number;
  excludingFeeRate: number;
  contactSender: string;
  contactReceiver: string;
  estimatedDistance: number;
  estimatedTravelTime: number;
  isFavourite: boolean;
  receiptUrls: null;
  routeUrl: string;
  createdAt: Date;
  updatedAt: Date;
  carrierReceptionDate: null;
  matchingAt: null;
  deliveredAt: Date;
  requestPaymentAt: null;
  settlementAt: Date;
  waitedTime: null;
  otherFee: null;
  otherFeeStatus: null;
  otherPaymentMethod: null;
  otherReasonReject: null;
  isPickUpImmediately: null;
  isDropDownImmediately: null;
  deletedAt: null;
  provider: IProvider;
  carrier: ICarrier;
  carrierScoreRating: number;
  carrierPaymentAmountTax?: number;
  carrierSettlementAmount?: number;
}

export interface ICarrier {
  id: number;
  username: string;
  nickname: string;
  name: string;
  bank: string;
  phone: string;
  otherPhone: null;
  identityNumber: string;
  avatar: null;
  isEnableNotification: boolean;
  deviceToken: null;
  birthday: null;
  accountBank: string;
  businessLicenses: IBusinessLicenses;
  favouriteRoutes: any[];
  vehicleType: string;
  status: string;
  region: string;
  licensePlate: string;
  tonnage: string;
  width: number;
  height: number;
  length: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

export interface IBusinessLicenses {
  lisenceCar: string;
  identityDoc: string;
  lisenceBusi: string;
  lisenceTrans: string;
}

export interface IDocURL {
  url: string;
  name: string;
}

export interface IProvider {
  id: number;
  username: string;
  email: string;
  phoneContact: null;
  emergencyPhone: null;
  company: null;
  businessNumber: null;
  officeNumber: null;
  representationName: null;
  picName: null;
  picPhone: null;
  type: string;
  paylaterStatus: string;
  isEnableNotification: boolean;
  companyContacts: null;
  bank: null;
  accountBank: null;
  status: string;
  businessLicenses: null;
  webPushDetail: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

export enum EOrderType {
  ONE_WAY = "ONE_WAY",
  ROUND_TRIP = "ROUND_TRIP",
}
