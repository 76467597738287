import React, { Key, useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import icInfo from "../../assets/images/ic_info.svg";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useDeleteCompanyMutation, useGetCompaniesQuery, useSwapCompanyOrderMutation } from "./companyApi";
import { SEARCH_ORDER } from "../../utils/constants";
import moment from "moment-timezone";
import { getDateFormat } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { closeDialog, openDialog } from "../../components/customDialog/dialogSlice";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { MenuOutlined } from "@ant-design/icons";
import { CSS } from "@dnd-kit/utilities";

export const CompanyManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteCompany] = useDeleteCompanyMutation();
  const [allData, setAllData] = useState<any[]>([]);
  const [swapCompanyOrder] = useSwapCompanyOrderMutation();

  const { data } = useGetCompaniesQuery(`page=1&limit=10&${SEARCH_ORDER}`);

  useEffect(() => {
    if (data?.data) {
      setAllData(
        data.data?.map((item: any, index: number) => {
          return { ...item, index: index + 1 };
        }),
      );
    }
  }, [data?.data]);

  /* ----------------------------------- 등록 ----------------------------------- */
  const onRegister = (item: any) => {
    dispatch(
      openModal({
        template: "show-register-company-modal",
        width: "463px",
        data: {
          detail: item,
        },
      }),
    );
  };

  /* ----------------------------------- 생성 ----------------------------------- */
  const onCreate = (item: any) => {
    if (allData?.length >= 10) {
      dispatch(
        openDialog({
          type: "info",
          content: (
            <div style={{ textAlign: "center" }}>
              기업후기는 최대 10건까지 등록가능합니다
              <br />
              기존 후기를 삭제 후 등록해주세요.
            </div>
          ),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    } else {
      dispatch(
        openModal({
          template: "show-register-company-modal",
          width: "463px",
          data: {
            // detail: item,
          },
        }),
      );
    }
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteClick = async (id: Key) => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_delete"),
        },
        handleAction: async () => {
          const res = await deleteCompany({ id }); //@ts-ignore
          showResult(res?.error, t("deleted"));
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: !error?.data?.message ? msgSuccess : error?.data?.message,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          dispatch(closeDialog());
        },
      }),
    );
  };

  const columns: ColumnsType<any> = [
    {
      key: "sort",
      width: "10px",
    },
    {
      title: t("order"),
      align: "center",
      key: "index",
      dataIndex: "index",
      width: "6%",
    },
    {
      title: t("corporate_name"),
      align: "left",
      key: "name",
      dataIndex: "name",
      render(value, record) {
        return <div className={`${record.id === -1 ? "disabled" : ""}`}>{value}</div>;
      },
    },
    {
      title: t("registration_date"),
      align: "center",
      key: "createdAt",
      dataIndex: "createdAt",
      width: "25%",
      render(value) {
        return <div>{getDateFormat(value)}</div>;
      },
    },
    {
      title: t("management"),
      align: "center",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render(value, record) {
        const isNew = value === -1;

        return (
          <div className="actions">
            <div className={`btn-action ${isNew ? "" : "active"}`} onClick={() => onRegister(record)}>
              {isNew ? t("register") : t("modify")}
            </div>
            <div className={`btn-action ${isNew ? "disabled" : ""}`} onClick={() => onHandleDeleteClick(value)}>
              {t("delete")}
            </div>
          </div>
        );
      },
    },
  ];

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const cloneData = [...allData];
      const activeIndex = cloneData.findIndex((i) => i.id === active.id);
      const overIndex = cloneData.findIndex((i) => i.id === over?.id);
      arrayMove(cloneData, activeIndex, overIndex);
      const body = {
        companyId1: cloneData[overIndex]?.id,
        companyId2: cloneData[activeIndex]?.id,
      };

      await swapCompanyOrder(body)?.unwrap();
    }
  };

  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
  }

  const Row = ({ children, ...props }: RowProps) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
      id: props["data-row-key"],
    });
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS?.Transform?.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  return (
    <div className="company-management">
      <div className="title">{t("company_review_management")}</div>
      <div className="company-management-header">
        <div className="description-wrap">
          <img alt="" src={icInfo} />
          <div className="description">{t("company_management_desc")}</div>
        </div>
        <div className="post-btn">
          <div className="post-text" onClick={onCreate}>
            등록하기
          </div>
        </div>
      </div>
      <div className="image-note">{t("profile_image_recommended")}</div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={allData.map((i) => {
            console.log(i);

            return i.id;
          })}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            // className="table-company"
            columns={columns}
            pagination={false}
            dataSource={allData}
            locale={{ emptyText: t("no_data") }}
            rowKey="id"
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};
