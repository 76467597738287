import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useGetShippersQuery, useUpdatePaylaterStatusMutation } from "./memberApi";
import { useEffect, useMemo, useRef, useState } from "react";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { PAGE_SIZE, PER_PAGE, SEARCH_ORDER } from "../../utils/constants";
import moment from "moment";
import NewTable from "../../components/NewTable";
import { getDateFromData, getDateToData } from "../../utils/commonFnc";
import { useLocation } from "react-router";

interface DataType {
  id: number;
  representationName: string;
  company: string;
  businessNumber: string;
  bank: string;
  accountBank: string;
  officeNumber: string;
  status: string;
  phoneContact: string;
  username: string;
  email: string;
}

export default function MemberManagementShipper({ type = "NORMAL" }) {
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const newTableRef = useRef<any>(null);
  const { t } = useTranslation();
  const [updatePaylaterStatus] = useUpdatePaylaterStatusMutation();

  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState<any>({
    search: "",
    page: 1,
    status: type === "PAY_LATER" ? "all" : undefined,
    limit: PER_PAGE * 2,
    searchAnd: [
      {
        type,
      },
    ],
    endDate: undefined,
    startDate: undefined,
  });

  const [filters, setFilters] = useState<string[]>([]);
  const tableTitle = useMemo(() => {
    switch (type) {
      case "BUSINESS":
        return t("business_shipper");
      case "PAY_LATER":
        return t("paylater_shipper");
      default:
        return t("general_member");
    }
  }, [type]);

  useEffect(() => {
    const cloneParams = { ...params };
    const { keyword, ...rest } = cloneParams;
    setParams({
      ...rest,
      search: "",
      page: 1,
      status: type === "PAY_LATER" ? "all" : undefined,
      searchAnd: [
        {
          type,
        },
      ],
      endDate: undefined,
      startDate: undefined,
    });
    setFilters(["representationName"]);
    if (newTableRef.current) {
      newTableRef.current?.resetSearchInput();
    }
  }, [type]);

  const REQUEST_PARAMS = useMemo(() => {
    let query = `page=${params.page}&limit=${PER_PAGE * 2}&${SEARCH_ORDER}`;
    if (params.search) {
      query += `&keyword=${params.search}`;
      filters.forEach((e) => (query += `&searchColumn[]=${e}`));
    }
    if (params.searchAnd?.length > 0) {
      params.searchAnd.forEach(() => {
        query += `&searchAnd={"type":"${type}"}`;
      });
    }
    if (params.startDate) {
      query += `&from=${encodeURIComponent(getDateFromData(params.startDate))}`;
    }
    query += `&fieldSearchTime=createdAt`;

    if (params.endDate) {
      query += `&to=${encodeURIComponent(getDateToData(params.endDate))}`;
    }
    if (params.status && params.status !== "all") {
      query += `&searchAnd=${JSON.stringify({ status: params.status })}`;
    }
    return query;
  }, [params, filters, type]);

  const { data, error, isSuccess, isFetching } = useGetShippersQuery(REQUEST_PARAMS, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [arrData, setArrData] = useState<any>([]);

  useEffect(() => {
    if (isSuccess) {
      const arr = data?.data.map((item: any) => ({
        ...item,
        id: item.id,
        representationName: item.representationName,
        company: item.company,
        businessNumber: item.businessNumber,
        officeNumber: item.officeNumber,
        bank: item.bank,
        accountBank: item.accountBank,
        status: item.status,
      }));
      setArrData(arr);
      setMetaData(data?.meta);
    }
  }, [data]);

  useEffect(() => {
    if (error && "status" in error && Number(error?.status) >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const normalColumns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: "아이디",
        dataIndex: "username",
        key: "username",
        align: "center",
      },
      {
        title: t("name"),
        dataIndex: "representationName",
        key: "representationName",
        align: "center",
      },
      {
        title: t("created_at"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 112,
        align: "center",
        render(value) {
          const date = moment(value);
          return (
            <div className="dispatch_date">
              <div className="text">{date.isValid() ? date.format("YY.MM.DD") : ""}</div>
              <div className={`text`}>{date.isValid() ? date.format("HH:mm A") : ""}</div>
            </div>
          );
        },
      },
      {
        title: t("contact"),
        dataIndex: "phoneContact",
        key: "phoneContact",
        align: "center",
      },

      {
        title: t("email"),
        dataIndex: "email",
        key: "email",
        align: "center",
      },
      {
        title: t("more_information"),
        dataIndex: "id",
        align: "center",
        key: "id",
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (id, item) => (
          <a
            className="more-information"
            onClick={() => {
              onOpenShipperDetail(item);
            }}
          >
            {t("more_information")}
          </a>
        ),
      },
    ],
    [],
  );

  const businessColumns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: "아이디",
        dataIndex: "username",
        key: "username",
        align: "center",
      },
      {
        title: t("representative_name"),
        dataIndex: "representationName",
        key: "representationName",
        align: "center",
      },
      {
        title: t("created_at"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 112,
        align: "center",
        render(value) {
          const date = moment(value);
          return (
            <div className="dispatch_date">
              <div className="text">{date.isValid() ? date.format("YY.MM.DD") : ""}</div>
              <div className={`text`}>{date.isValid() ? date.format("HH:mm A") : ""}</div>
            </div>
          );
        },
      },
      {
        title: t("company_name"),
        dataIndex: "company",
        key: "company",
        align: "center",
      },
      {
        title: t("office_number"),
        dataIndex: "officeNumber",
        key: "officeNumber",
        align: "center",
      },
      {
        title: t("business_number"),
        dataIndex: "businessNumber",
        key: "businessNumber",
        align: "center",
      },
      {
        title: t("more_information"),
        dataIndex: "id",
        key: "id",
        align: "center",
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (id, item) => (
          <a
            className="more-information"
            onClick={() => {
              onOpenShipperDetail(item);
            }}
          >
            {t("more_information")}
          </a>
        ),
      },
    ],
    [],
  );

  const paylaterColumns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: "아이디",
        dataIndex: "username",
        key: "username",
        align: "center",
      },
      {
        title: t("representative_name"),
        dataIndex: "representationName",
        key: "representationName",
        align: "center",
      },
      {
        title: t("가입요청일자"),
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        width: 112,
        render(value) {
          const date = value ? moment(value) : undefined;
          return (
            <div className="dispatch_date">
              <div className="text">{date?.isValid() ? date?.format("YY.MM.DD") : ""}</div>
              <div className={`text`}>{date?.isValid() ? date?.format("HH:mm A") : ""}</div>
            </div>
          );
        },
      },
      {
        title: t("subscription_approval_at"),
        dataIndex: "approvedAt",
        key: "approvedAt",
        align: "center",
        width: 112,
        render(value) {
          const date = value ? moment(value) : undefined;
          return (
            <div className="dispatch_date">
              <div className="text">{date?.isValid() ? date?.format("YY.MM.DD") : ""}</div>
              <div className={`text`}>{date?.isValid() ? date?.format("HH:mm A") : ""}</div>
            </div>
          );
        },
      },
      {
        title: t("company_name"),
        dataIndex: "company",
        key: "company",
        align: "center",
      },
      {
        title: t("business_number"),
        dataIndex: "businessNumber",
        key: "businessNumber",
        align: "center",
      },
      {
        title: t("office_number"),
        dataIndex: "officeNumber",
        key: "officeNumber",
        align: "center",
      },
      {
        title: t("membership_status"),
        dataIndex: "status",
        key: "status",
        align: "center",
        render: (status) => (
          <div className={`${status === "ACTIVE" ? "approved" : status === "INACTIVE" ? "pending-approval" : ""}`}>
            {status === "ACTIVE" ? t("approved") : status === "INACTIVE" ? t("waiting_for_approval") : t("blocked")}
          </div>
        ),
      },
      {
        title: t("paylater_right"),
        dataIndex: "paylaterStatus",
        key: "paylaterStatus",
        align: "center",
        render: (paylaterStatus, item) => (
          <a
            className={`paylater_right ${
              item.status !== "ACTIVE" ? "disabled" : paylaterStatus === "INACTIVE" ? "no_permission" : ""
            }`}
            onClick={() => onOpenApprovePaylater(item)}
          >
            {item.status !== "ACTIVE"
              ? t("waiting_for_approval")
              : paylaterStatus === "ACTIVE"
              ? t("paylater_available")
              : t("no_permission")}
          </a>
        ),
      },
      {
        title: t("more_information"),
        dataIndex: "id",
        key: "id",
        align: "center",
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (id, item) => (
          <a
            className="more-information"
            onClick={() => {
              onOpenShipperDetail(item);
            }}
          >
            {t("more_information")}
          </a>
        ),
      },
    ],
    [],
  );

  const columns = useMemo(() => {
    switch (type) {
      case "BUSINESS":
        return businessColumns;
      case "PAY_LATER":
        return paylaterColumns;
      default:
        return normalColumns;
    }
  }, [type]);

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = useMemo(
    () =>
      type === "PAY_LATER"
        ? [
            { label: t("all"), value: "all" },
            { label: t("waiting_for_approval"), value: "INACTIVE" },
            { label: t("approved"), value: "ACTIVE" },
          ]
        : [],
    [type],
  );

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = useMemo(() => {
    if (type === "NORMAL") {
      return [
        { label: t("name"), value: "representationName" },
        { label: t("contact"), value: "phoneContact" },
        { label: t("email"), value: "email" },
      ];
    }
    return [
      {
        label: t("representative_name"),
        value: "representationName",
      },
      { label: t("contact_name"), value: "picName" },
      { label: t("company_name"), value: "company" },
    ];
  }, [type]);

  /* ------------------------------- 화주의 디테일 열기 ------------------------------- */
  const onOpenShipperDetail = (item: any) => {
    const modal = item.type === "PAY_LATER" ? "show-shipper-detail-modal" : "show-shipper-info-modal";
    if (item) {
      dispatch(
        openModal({
          template: modal,
          width: item.type === "PAY_LATER" ? "649px" : "302px",
          data: {
            info: item,
          },
          handleAction: () => {
            // callGetShippers();
          },
        }),
      );
    }
  };

  const onOpenApprovePaylater = (item: any) => {
    const isActive = item.paylaterStatus === "ACTIVE";
    const modal = isActive ? "show-cancel-paylater-right-modal" : "show-approve-paylater-right-modal";
    dispatch(
      openModal({
        template: modal,
        width: "610px",
        data: {
          info: item,
        },
        handleAction: async () => {
          const params = {
            id: item.id,
            paylaterStatus: isActive ? "INACTIVE" : "ACTIVE",
          };
          const res = await updatePaylaterStatus(params);
        },
      }),
    );
  };

  return (
    <div className="member-management-shipper">
      <NewTable
        isTotalVisible={true}
        ref={newTableRef}
        title={tableTitle}
        columns={columns}
        allData={arrData}
        showDateFilter={true}
        typeOptions={typeOptions}
        searchFilterOptions={searchFilterOptions}
        offsetHeight={333}
        metaData={metaData}
        perPage={PAGE_SIZE * 2}
        loading={isFetching}
        params={params}
        setParams={setParams}
        filters={filters}
        setFilters={setFilters}
        key={type}
      />
    </div>
  );
}
