/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const authApi = createApi({
  reducerPath: "authApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState, endpoint }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token && !["getListLocation", "getListLocationWithAddress"].includes(endpoint!)) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `auth/admin/sign-in`,
        method: "POST",
        body: data,
      }),
    }),
    getAdminInfo: builder.query({
      query: (data) => ({
        url: `managers/admin/info`,
        method: "GET",
      }),
    }),
    updateNotification: builder.mutation({
      query: (data) => ({
        url: `managers/notification`,
        method: "PUT",
        body: data,
      }),
    }),
    getListLocation: builder.mutation({
      query: (params) => ({
        url: `https://dapi.kakao.com/v2/local/search/keyword.json`,
        method: "GET",
        params: params,
        headers: {
          Authorization: "KakaoAK 11fec52b8ebe8ccc93c1a84d52b10b7b",
        },
      }),
    }),
    getListLocationWithAddress: builder.mutation({
      query: (params) => ({
        url: `https://dapi.kakao.com/v2/local/search/address.json`,
        method: "GET",
        params: params,
        headers: {
          Authorization: "KakaoAK 11fec52b8ebe8ccc93c1a84d52b10b7b",
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useGetAdminInfoQuery, useUpdateNotificationMutation, useGetListLocationMutation, useGetListLocationWithAddressMutation } = authApi;
