import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import icMore from "../../../../assets/images/ic_more.svg";
import icIronMedal from "../../../../assets/images/ic_iron_medal.svg";
import icBronzeMedal from "../../../../assets/images/ic_bronze_medal.svg";
import icSilverMedal from "../../../../assets/images/ic_silver_medal.svg";
import icGoldMedal from "../../../../assets/images/ic_gold_medal.svg";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import "./style.scss";
import {
  useGetCarrierQuery,
  useDeleteCarrierMutation,
  useUpdateCarrierStatusMutation,
  useDownloadCarrierBusinessLicensesMutation,
  useUpdateCarrierBusinessMutation,
} from "../../../../pages/MemberManagement/memberApi";
import { Button, Input, Form } from "antd";
import { downloadFileFromBlob } from "../../../../utils/commonFnc";
import DocumentItem from "../../../docItem";
import { formatPhoneNumber } from "../../../../utils/utils";

const businessNumberRegex = /(\d{3})(\d{2})(\d{5})/;

export default function CarrierDetail(props: any) {
  const [info, setInfo] = useState(props.data.info);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [updateCarrierStatus] = useUpdateCarrierStatusMutation();
  const [deleteCarrier] = useDeleteCarrierMutation();
  const [updateCarrierBusiness] = useUpdateCarrierBusinessMutation();
  const { data } = useGetCarrierQuery(
    {
      id: info?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );
  const [downloadBusinessLicenses] = useDownloadCarrierBusinessLicensesMutation();
  const [form] = Form.useForm();
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (props?.data?.info) {
      setIsShowDelete(false);
      setInfo(props?.data?.info);
    }
  }, [props]);

  useEffect(() => {
    if (data) {
      setInfo(data);
      setIsEditView(false);
      setIsEditMode(false);
      form.setFieldsValue({
        businessNumber: (data?.businessNumber || "").replace(businessNumberRegex, "$1-$2-$3"),
        businessAddress: data?.businessAddress ?? "",
        businessName: data?.businessName ?? "",
        businessType: data?.businessType ?? "",
        businessClassification: data?.businessClassification ?? "",
      });
      if (data?.businessNumber) {
        setIsEditView(true);
      }
    }
  }, [data]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const onHandleMoreClick = () => {
    setIsShowDelete(!isShowDelete);
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteClick = async () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_delete_member"),
          dialogContent: t("cancel_dispatch_note"),
        },
        handleAction: onHandleDeleteAction,
      }),
    );
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteAction = async () => {
    const params = {
      id: info.id,
    };
    const res = await deleteCarrier(params); //@ts-ignore
    showResult(res?.error, t("deletion_is_complete"));
  };

  const onHandleBlockClick = async () => {
    const params = {
      id: info.id,
      status: "BLOCK", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateCarrierStatus(params); //@ts-ignore
    showResult(res?.error, t("member_is_blocked"));
  };

  const onHandleUnblockClick = async () => {
    const params = {
      id: info.id,
      status: "ACTIVE", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateCarrierStatus(params); //@ts-ignore
    showResult(res?.error, t("member_is_released"));
  };

  const onHandleApproveClick = async () => {
    const params = {
      id: info.id,
      status: "ACTIVE", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateCarrierStatus(params); //@ts-ignore
    showResult(res?.error, t("approval_complete"));
  };

  const showResult = async (error: any, msgSuccess: string) => {
    if (!error?.data?.message) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: error?.data?.message,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  };

  /* ---------------------------------- 메모 저장 --------------------------------- */
  const onHandleSubmitClick = () => {
    if (info.status === "ACTIVE") {
      dispatch(
        openModal({
          template: "show-confirm-dialog-modal",
          width: "468px",
          data: {
            dialogTitle: t("block_member_confirm"),
          },
          handleAction: onHandleBlockClick,
        }),
      );
    } else if (info.status === "INACTIVE") {
      dispatch(
        openModal({
          template: "show-confirm-dialog-modal",
          width: "468px",
          data: {
            dialogTitle: t("confirm_approve_carrier"),
          },
          handleAction: onHandleApproveClick,
        }),
      );
    } else if (info.status === "BLOCK") {
      dispatch(
        openModal({
          template: "show-confirm-dialog-modal",
          width: "468px",
          data: {
            dialogTitle: t("unblock_member_confirm"),
          },
          handleAction: onHandleUnblockClick,
        }),
      );
    }
  };

  const openVehicleManage = () => {
    dispatch(
      openModal({
        template: "show-vehicle-manage-modal",
        width: "491px",
        data: {
          info: info,
        },
      }),
    );
  };

  const getTextSubmit = () => {
    if (info.status === "ACTIVE") {
      return t("member_block");
    } else if (info.status === "INACTIVE") {
      return t("sign_up_approval");
    } else {
      return t("unblock");
    }
  };

  const getMedal = () => {
    const scoreRating = data?.scoreRating ?? 0;
    if (scoreRating > 89) {
      return icGoldMedal;
    } else if (scoreRating > 79) {
      return icSilverMedal;
    } else if (scoreRating > 69) {
      return icBronzeMedal;
    }
    return icIronMedal;
  };

  const downloadAllHandler = async () => {
    if (info?.id) {
      let res = await downloadBusinessLicenses(info.id);
      if ("data" in res) {
        downloadFileFromBlob(res.data, "businessLicenses.zip");
      }
    }
  };

  const onFinish = async (values: any) => {
    const businessDataToUpdate = {
      id: info.id,
      businessNumber: values.businessNumber.replace(/-/g, ""),
      businessAddress: values.businessAddress,
      businessName: values.businessName,
      businessType: values.businessType,
      businessClassification: values.businessClassification,
    };
    const res = await updateCarrierBusiness(businessDataToUpdate);
    // if ("data" in res) {
    //   dispatch(closeModal());
    // }
    setIsEditMode(false);
  };

  return (
    <div className="carrier-detail-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="section-data">
          <div className="section-header">
            <span className="section-header-title">기본정보</span>
            <div className="btn-groups">
              <Button className="download-all" onClick={downloadAllHandler}>
                {t("모두 다운로드")}
              </Button>
              <Button className={`btn ${info.status}`} onClick={onHandleSubmitClick}>
                {getTextSubmit()}
              </Button>
            </div>
          </div>
          <div className="section-content basic-info">
            <div className="left-content">
              <div className="content-item">
                <div className="text-sub">{t("fullname")}</div>
                <div className="text-title">{info.name}</div>
              </div>
              {info.status === "ACTIVE" && (
                <div className="manner-wrapper">
                  <div className="manner-item">
                    <div className="text-sub">{t("manner_score")}</div>
                    <div className="text-title">
                      {data?.scoreRating ?? 0}
                      {t("points")}
                    </div>
                  </div>
                  <div className="manner-medal">
                    <img className="ic-medal" alt="medal" src={getMedal()} />
                  </div>
                </div>
              )}
              <div className="content-item">
                <div className="text-sub">{t("contact")}</div>
                <div className="text-title">{formatPhoneNumber(info.phone)}</div>
              </div>
              <div className="content-item">
                <div className="text-sub">{t("bank")}</div>
                <div className="text-title">{info.bank}</div>
              </div>
            </div>
            <div className="right-content">
              <div className="first-row">
                <div className="content-item">
                  <div className="text-sub">{t("nickname")}</div>
                  <div className="text-title">{info.nickname}</div>
                </div>
                <div className="more-wrapper">
                  <div className="more-btn" onClick={onHandleMoreClick}>
                    <img className="ic-more" alt="More" src={icMore} />
                    {isShowDelete && (
                      <div className="delete-btn" onClick={onHandleDeleteClick}>
                        <div className="delete-text">{t("delete")}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="content-item">
                <div className="text-sub">{t("car_number")}</div>
                <div className="text-title">
                  {info.region} {info.licensePlate}
                </div>
              </div>
              <div className="content-item">
                <div className="text-sub">{t("account_number")}</div>
                <div className="text-title">{info.accountBank}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-data">
          <div className="section-header">
            <span className="section-header-title">차량정보</span>
          </div>
          <div className="section-content vehicle-info">
            <div className="vehicle-item">
              <div className="text-sub">{t("tonnage")}</div>
              <div className="text-title">{info.tonnage}</div>
            </div>
            <div className="vehicle-item">
              <div className="text-sub">{t("car_type")}</div>
              <div className="text-title">{info.vehicleType}</div>
            </div>
            <div className="vehicle-item">
              <div className="btn-modify" onClick={openVehicleManage}>
                {t("modify")}
              </div>
            </div>
            <div className="vehicle-item">
              <div className="text-sub">{t("length")}</div>
              <div className="text-title">{Number(info.length).toFixed(2)}</div>
            </div>
            <div className="vehicle-item">
              <div className="text-sub">{t("width")}</div>
              <div className="text-title">{Number(info.width).toFixed(2)}</div>
            </div>
            <div className="vehicle-item">
              <div className="text-sub">{t("height")}</div>
              <div className="text-title">{Number(info.height).toFixed(2)}</div>
            </div>
          </div>
        </div>

        <div className="section-data">
          <div className="section-header">
            <span className="section-header-title">추가정보</span>
          </div>
          <div className="section-content additional-info">
            <Form form={form} onFinish={onFinish}>
              <Form.Item shouldUpdate>
                {() => {
                  const isValidFrom = form.getFieldsError().filter(({ errors }) => errors.length).length === 0;
                  return (
                    <div className="additional-edit">
                      {isEditView && !isEditMode ? (
                        <span className="btn-edit" onClick={() => setIsEditMode(true)}>
                          수정
                        </span>
                      ) : (
                        <Button
                          className={`btn-save ${!isValidFrom ? "invalid" : "valid"}`}
                          htmlType="submit"
                          disabled={!form.isFieldsTouched(true) || !isValidFrom}
                        >
                          저장
                        </Button>
                      )}
                    </div>
                  );
                }}
              </Form.Item>
              <Form.Item
                label={
                  <span className="item-input-label">
                    사업자 번호<span className="label-required">*</span>
                  </span>
                }
                name="businessNumber"
                className="item-input"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(new Error("정보를 입력해주세요!"));
                      }
                      if (!businessNumberRegex.test(value.replaceAll("-", ""))) {
                        return Promise.reject(new Error("올바른 형식을 입력하세요!"));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  placeholder={t("사업자 번호를 입력해주세요. (-없이 입력)")}
                  onChange={(event) => {
                    const value = event.target.value?.replaceAll(/\D/g, "");
                    const newValue = value?.replace(businessNumberRegex, "$1-$2-$3");
                    form.setFieldValue("businessNumber", newValue);
                  }}
                  maxLength={12}
                  disabled={isEditView && !isEditMode}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="item-input-label">
                    사업자 주소<span className="label-required">*</span>
                  </span>
                }
                name="businessAddress"
                className="item-input"
                rules={[
                  {
                    required: true,
                    message: "정보를 입력해주세요!",
                  },
                ]}
              >
                <Input
                  placeholder={t("사업자 주소를 입력해주세요.")}
                  maxLength={30}
                  disabled={isEditView && !isEditMode}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="item-input-label">
                    상호명<span className="label-required">*</span>
                  </span>
                }
                name="businessName"
                className="item-input"
                rules={[
                  {
                    required: true,
                    message: "정보를 입력해주세요!",
                  },
                ]}
              >
                <Input placeholder={t("상호명을 입력해주세요.")} disabled={isEditView && !isEditMode} />
              </Form.Item>

              <Form.Item
                label={
                  <span className="item-input-label">
                    업태<span className="label-required">*</span>
                  </span>
                }
                name="businessType"
                className="item-input"
                rules={[
                  {
                    required: true,
                    message: "정보를 입력해주세요!",
                  },
                ]}
              >
                <Input placeholder={t("업태 코드를 입력해주세요")} disabled={isEditView && !isEditMode} />
              </Form.Item>

              <Form.Item
                label={
                  <span className="item-input-label">
                    종목<span className="label-required">*</span>
                  </span>
                }
                name="businessClassification"
                className="item-input"
                rules={[
                  {
                    required: true,
                    message: "정보를 입력해주세요!",
                  },
                ]}
              >
                <Input
                  placeholder={t("종목(업종) 코드를 입력해주세요")}
                  maxLength={30}
                  disabled={isEditView && !isEditMode}
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="section-data">
          <div className="section-header">
            <span className="section-header-title">제출 서류</span>
          </div>
          <div className="section-content document">
            <DocumentItem title={t("business_registration")} link={info.businessLicenses?.lisenceBusi} />
            <DocumentItem title={t("vehicle_registration")} link={info.businessLicenses?.identityDoc} />
            <DocumentItem title={t("identification")} link={info.businessLicenses?.lisenceCar} />
            <DocumentItem title={t("transport_permit")} link={info.businessLicenses?.lisenceTrans} />
            <DocumentItem title={t("copy_of_bankbook")} link={info.businessLicenses?.copyOfBankbook} />
          </div>
        </div>
      </div>
    </div>
  );
}
