import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useGetCarriersQuery } from "./memberApi";
import { useEffect, useMemo, useState } from "react";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { PER_PAGE, SEARCH_ORDER } from "../../utils/constants";
import moment, { Moment } from "moment-timezone";
import { getDateFromData, getDateToData } from "../../utils/commonFnc";
import NewTable from "../../components/NewTable";
interface DataType {
  id: number;
  name: string;
  nickname: string;
  phone: string;
  licensePlate: string;
  region: string;
  tonnage: string;
  vehicleType: string;
  status: string;
}

interface IParams {
  search: string;
  page: number;
  limit: number;
  status: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  fieldSearchTime?: string;
}
export default function MemberManagementCarrier() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const [metaData, setMetaData] = useState<any>({});

  const [params, setParams] = useState<IParams>({
    search: "",
    page: 1,
    limit: PER_PAGE,
    status: "all",
  });
  const [filters, setFilters] = useState<string[]>(["name"]);

  const REQUEST_PARAMS = useMemo(() => {
    let query = `page=${params.page}&limit=${PER_PAGE}&${SEARCH_ORDER}`;
    if (params.search) {
      query += `&keyword=${params.search}`;
      filters.forEach((e) => (query += `&searchColumn[]=${e}`));
    }
    if (params.status && params.status !== "all") {
      query += `&searchAnd=${JSON.stringify({ status: params.status })}`;
    }
    if (params.startDate) {
      query += `&from=${encodeURIComponent(getDateFromData(params.startDate))}`;
    }
    query += `&fieldSearchTime=createdAt`;
    if (params.endDate) {
      query += `&to=${encodeURIComponent(getDateToData(params.endDate))}`;
    }
    return query;
  }, [params, filters]);

  const { data, error, isSuccess, isLoading, isFetching } = useGetCarriersQuery(REQUEST_PARAMS, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  const [arrData, setArrData] = useState<any>([]);
  useEffect(() => {
    if (isSuccess) {
      setArrData(data?.data);
      setMetaData(data?.meta);
    }

    return () => {};
  }, [data]);

  useEffect(() => {
    if (error && "status" in error && Number(error?.status) >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const columns: any = useMemo(() => {
    const formatColumn: any = [
      {
        title: t("name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("가입요청일자"),
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render(value: any) {
          const date = value ? moment(value) : undefined;
          return (
            <div className="dispatch_date">
              <div className="text">{date?.isValid() ? date?.format("YY.MM.DD") : ""}</div>
              <div className={`text`}>{date?.isValid() ? date?.format("HH:mm A") : ""}</div>
            </div>
          );
        },
      },
      {
        title: t("승인일자"),
        dataIndex: "approvedAt",
        key: "approvedAt",
        align: "center",
        render(value: any) {
          const date = value ? moment(value) : undefined;
          return (
            <div className="dispatch_date">
              <div className="text">{date?.isValid() ? date?.format("YY.MM.DD") : ""}</div>
              <div className={`text`}>{date?.isValid() ? date?.format("HH:mm A") : ""}</div>
            </div>
          );
        },
      },
      {
        title: t("nickname"),
        dataIndex: "nickname",
        key: "nickname",
      },
      {
        title: t("contact"),
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: t("vehicle_number"),
        key: "licensePlate",
        render: (_: any, record: any) => (
          <>
            {record?.region || "-"} {record?.licensePlate || "-"}
          </>
        ),
      },
      {
        title: t("tonnage"),
        dataIndex: "tonnage",
        key: "tonnage",
      },
      {
        title: t("car_type"),
        dataIndex: "vehicleType",
        key: "vehicleType",
      },
      {
        title: t("membership_status"),
        dataIndex: "status",
        key: "status",
        render: (status: any) => (
          <div className={`${status === "ACTIVE" ? "approved" : status === "INACTIVE" ? "pending-approval" : ""}`}>
            {status === "ACTIVE" ? t("approved") : status === "INACTIVE" ? t("waiting_for_approval") : t("blocked")}
          </div>
        ),
      },
      {
        title: t("more_information"),
        dataIndex: "id",
        key: "id",
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (_: any, record: any) => (
          <a
            className="more-information"
            onClick={() => {
              onOpenCarrierDetail(record);
            }}
          >
            {t("more_information")}
          </a>
        ),
      },
    ] as any;

    /* -------------------------------- 승인된 기록 검수 ------------------------------- */
    const approvedAtIndex = formatColumn?.findIndex((c: any) => c.key === "approvedAt");
    if (params?.status === "INACTIVE") {
      return formatColumn?.toSpliced(approvedAtIndex, 1);
    } else {
      return formatColumn;
    }
  }, [params?.status]);

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = useMemo(
    () => [
      { label: t("all"), value: "all" },
      { label: t("waiting_for_approval"), value: "INACTIVE" },
      { label: t("approved"), value: "ACTIVE" },
    ],
    [],
  );

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = useMemo(
    () => [
      { label: t("name"), value: "name", disabled: true },
      { label: t("nickname"), value: "nickname" },
      { label: t("tonnage"), value: "tonnage" },
      { label: t("car_type"), value: "vehicleType" },
    ],
    [],
  );

  /* ------------------------------- 운송인의 디테일 열기 ------------------------------ */
  const onOpenCarrierDetail = (info: any) => {
    if (info) {
      dispatch(
        openModal({
          template: "show-carrier-detail-modal",
          width: "649px",
          data: {
            info: info,
          },
          handleAction: (action: any) => {
            // callGetCarriers();
          },
        }),
      );
    }
  };

  return (
    <div className="member-management-carrier">
      <NewTable
        showDateFilter={true}
        isTotalVisible={true}
        title={"운송인"}
        columns={columns}
        metaData={metaData}
        allData={arrData}
        typeOptions={typeOptions}
        searchFilterOptions={searchFilterOptions}
        loading={isFetching}
        params={params}
        setParams={setParams}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
