import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import icIronMedal from "../../../../assets/images/ic_iron_medal.svg";
import icBronzeMedal from "../../../../assets/images/ic_bronze_medal.svg";
import icSilverMedal from "../../../../assets/images/ic_silver_medal.svg";
import icGoldMedal from "../../../../assets/images/ic_gold_medal.svg";
import "./style.scss";
import { useGetCarrierQuery } from "../../../../pages/MemberManagement/memberApi";
import { formatNumberToCurrency } from "../../../../utils/utils";
import { STATUS_DONE, STATUS_WAITING_PAYMENT } from "../../../../utils/constants";
import { ICarrierSettlement } from "../../../../pages/SettlementManagement/CarrierSettlement";
import { closeModal, openModal } from "../../modalSlice";
import { openDialog } from "../../../customDialog/dialogSlice";
import { useUpdateDoneMutation } from "../../../../pages/DispatchManagement/dispatchApi";
import { useCancelSettlementMutation } from "../../../../pages/SettlementManagement/settlementApi";

export default function CarrierDetailSettlement(props: any) {
  let info: ICarrierSettlement = props?.data?.info;
  let orderStatus = props?.data?.info?.settlementStatus;
  let orderId = props?.data?.info?.id;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updateOrderDone] = useUpdateDoneMutation();
  const [cancelSettlement] = useCancelSettlementMutation();

  const { data } = useGetCarrierQuery(
    {
      id: info?.carrier.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const getTextSubmit = () => {
    if (orderStatus === STATUS_WAITING_PAYMENT) {
      return t("to_settle");
    } else if (orderStatus === STATUS_DONE) {
      return "정산 취소";
    } else {
      return "";
    }
  };
  const getTextStatus = () => {
    if (orderStatus === STATUS_WAITING_PAYMENT) {
      return t("issuance_completed");
    } else if (orderStatus === STATUS_DONE) {
      return t("settlement_completed");
    } else {
      return "";
    }
  };

  const getMedal = () => {
    const scoreRating = data?.scoreRating ?? 0;
    if (scoreRating > 89) {
      return icGoldMedal;
    } else if (scoreRating > 79) {
      return icSilverMedal;
    } else if (scoreRating > 69) {
      return icBronzeMedal;
    }
    return icIronMedal;
  };

  /* ------------------------------- 완료된 오더 업데이트 ------------------------------ */
  const onHandleUpdateOrderDone = async () => {
    const res = await updateOrderDone({ id: data.id }).unwrap();
    const errMsg = res?.error?.data?.message;
    dispatch(
      openDialog({
        type: "info",
        content: errMsg ? errMsg : t("settlement_complete_notification"),
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          dispatch(closeModal());
        },
      }),
    );
  };

  const handleDoneOrder = () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_settlement_complete"),
        },
        handleAction: onHandleUpdateOrderDone,
      }),
    );
  };

  const handleSettelementCancel = () => {
    dispatch(
      openDialog({
        type: "confirm",
        content: `"정산 취소" 처리 하시겠습니까?`,
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          try {
            const body = {
              ids: [orderId],
            };
            const res = await cancelSettlement(body).unwrap();
            if (res.status === "success") {
              dispatch(
                openDialog({
                  type: "info",
                  content: "완료 되었습니다.",
                  confirmText: "확인",
                }),
              );
              props.handleAction("refresh");
            }
          } catch (error) {
            console.error(error);
          } finally {
            dispatch(closeModal);
          }
        },
      }),
    );
  };

  return (
    <div className="carrier-detail-settlement">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-left">
          <div className="top-left">
            <div className="representative-name">
              <div className="text-title">{info.carrier.name}</div>
              <div className="text-sub">{t("fullname")}</div>
            </div>
            <div className="more-wrapper"></div>
          </div>
          {info.carrier.status === "ACTIVE" && (
            <div className="manner-wrapper">
              <div className="manner-item">
                <div className="text-sub">{t("manner_score")}</div>
                <div className="text-title">
                  {data?.scoreRating ?? 0}
                  {t("points")}
                </div>
              </div>
              <div className="manner-medal">
                <img className="ic-medal" alt="medal" src={getMedal()} />
              </div>
            </div>
          )}

          <div className="content-item">
            <div className="text-sub">{t("nickname")}</div>
            <div className="text-title">{info.nickname}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("contact")}</div>
            <div className="text-title">{info.carrier.phone}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("vehicle_number")}</div>
            <div className="text-title">
              {info.carrier.region} {info.carrier.licensePlate}
            </div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("bank")}</div>
            <div className="text-title">{info.carrier.bank}</div>
          </div>
          <div className="content-item">
            <div className="text-sub">{t("account_number")}</div>
            <div className="text-title">{info.carrier.accountBank}</div>
          </div>
          <div className="content-contact">
            <div className="content-contact-item">
              <div className="text-sub">{t("tonnage")}</div>
              <div className="text-title">{info.carrier.tonnage}</div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("car_type")}</div>
              <div className="text-title">{info.carrier.vehicleType}</div>
            </div>
          </div>
          <div className="content-contact">
            <div className="content-contact-item">
              <div className="text-sub">{t("length")}</div>
              <div className="text-title">{info.carrier.length.toFixed(2)}M</div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("width")}</div>
              <div className="text-title">{info.carrier.width.toFixed(2)}M</div>
            </div>
            <div className="content-contact-item">
              <div className="text-sub">{t("height")}</div>
              <div className="text-title">{info.carrier.height.toFixed(2)}M</div>
            </div>
          </div>
        </div>
        <div className="content-right">
          <div className="content-right-header">
            <div className="action">
              <div className={`action-status ${orderStatus}`}>{getTextStatus()}</div>
              <div
                onClick={() => {
                  if (orderStatus === STATUS_WAITING_PAYMENT) {
                    handleDoneOrder();
                  } else if (orderStatus === STATUS_DONE) {
                    handleSettelementCancel();
                  }
                }}
                className={`action-fetch ${orderStatus}`}
              >
                {getTextSubmit()}
              </div>
            </div>
          </div>
          <div className="content-right-body">
            <div className="item-total">
              <div className="total-cases"></div>
              <div className="total-amount">
                <div className="amount">{formatNumberToCurrency(info?.order?.carrierSettlementAmount || 0)}</div>
                <div className="won">&nbsp;{t("won")}</div>
              </div>
            </div>
            <div className="list">
              <div className="item-row">
                <div className="item-col">
                  <div className="item-title">{t("loading_place")}</div>
                  <div className="item-sub text-ellipsis">
                    {info.order?.loadingPlace ?? ""} {info.order?.detailLoadingPlace ?? ""}
                  </div>
                </div>
                <div className="item-col">
                  <div className="item-title">{t("unloading_place")}</div>
                  <div className="item-sub text-ellipsis">
                    {info.order?.receiptPlace ?? ""} {info.order?.detailReceiptPlace ?? ""}
                  </div>
                </div>
                <div className="item-col basis-1 flex-end">
                  <div className="item-title">
                    {formatNumberToCurrency(info?.order?.carrierSettlementAmount || 0)}
                    {t("won")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
