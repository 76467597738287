import { Button, ButtonProps } from "antd";
import React from "react";
import "./style.scss";

interface Props extends ButtonProps {
  className?: string;
  btnType?: "success" | "failed" | "primary" | "disabled" | "dark" | "idle" | "gray";
  children?: string | React.ReactElement | React.ReactNode;
}

export enum ButtonType {
  SUCCESS = "success",
  FAILED = "failed",
  PRIMARY = "primary",
  DISABLED = "disabled",
  DARK = "dark",
  IDLE = "idle",
  GRAY = "gray",
}

function KitButton({ className, btnType, children, ...rest }: Props) {
  /* ------------------------------ class 이름 가져오기 ----------------------------- */
  const generateClassname = () => {
    let btnClass: string;
    switch (btnType) {
      case ButtonType.SUCCESS:
        btnClass = `btn-${ButtonType.SUCCESS}`;
        break;
      case ButtonType.FAILED:
        btnClass = `btn-${ButtonType.FAILED}`;
        break;
      case ButtonType.PRIMARY:
        btnClass = `btn-${ButtonType.PRIMARY}`;
        break;
      case ButtonType.DISABLED:
        btnClass = `btn-${ButtonType.DISABLED}`;
        break;
      case ButtonType.DARK:
        btnClass = `btn-${ButtonType.DARK}`;
        break;
      case ButtonType.IDLE:
        btnClass = `btn-${ButtonType.IDLE}`;
        break;
      case ButtonType.GRAY:
        btnClass = `btn-${ButtonType.GRAY}`;
        break;
      default:
        btnClass = `btn-${ButtonType.SUCCESS}`;
        break;
    }
    return btnClass;
  };

  return (
    <div className={`kit-btn ${className}  `}>
      <Button className={`btn-custom ${generateClassname()}`} {...rest}>
        {children}
      </Button>
    </div>
  );
}

export default KitButton;
