import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userLogout } from "../../pages/Auth/authSlice";
import { ACCESS_TOKEN } from "../../utils/constants";
import "./style.scss";
import { openDialog } from "../customDialog/dialogSlice";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserLogin } from "../../store/selector/RootSelector";
import { run, unregisterServiceWorker } from "../../subscription";
import { Button } from "antd";
import { useGetAdminInfoQuery, useUpdateNotificationMutation } from "../../pages/Auth/authApi";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const location = useLocation();
  const isUserLogin = useSelector(getUserLogin);
  const [initNotification, setinitNotification] = useState(false);
  const [updateNotification] = useUpdateNotificationMutation();

  const {
    data: adminInfo,
    isSuccess,
    refetch,
  } = useGetAdminInfoQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !isUserLogin,
    },
  );

  useLayoutEffect(() => {
    if (!initNotification && adminInfo?.isEnableNotification) {
      if ("serviceWorker" in navigator) {
        setinitNotification(true);
        run(localStorage.getItem(ACCESS_TOKEN));
      }
    }

    return () => {};
  }, [isUserLogin, initNotification, isSuccess, adminInfo]);

  /* -------------------------------- 알림 표시 확인 -------------------------------- */
  const onConfirmNotify = () => {
    dispatch(
      openDialog({
        type: "confirm-noti",
        content: "알림을 활성화 하시겠습니까?",
        closeText: "확인",
        confirmText: "닫기",
        isActionFlip: true,
        actionConfirm: () => {
          if ("serviceWorker" in navigator) {
            updateNotification({
              isEnableNotification: true,
            })
              .unwrap()
              .then((res) => {
                setinitNotification(true);
                refetch();

                run(localStorage.getItem(ACCESS_TOKEN));
              })
              .catch((error) => {});
          }
        },
      }),
    );
  };

  /* -------------------------------- 알림 취소 확인 -------------------------------- */
  const onCancelNotify = () => {
    dispatch(
      openDialog({
        type: "confirm-unnoti",
        content: "알림을 비 활성화 하시겠습니까?",
        closeText: "확인",
        confirmText: "닫기",
        isActionFlip: true,
        actionConfirm: () => {
          updateNotification({
            isEnableNotification: false,
          })
            .unwrap()
            .then((res) => {
              refetch();
              unregisterServiceWorker();
            })
            .catch((error) => {});
        },
      }),
    );
  };

  /* ---------------------------------- 로그아웃 ---------------------------------- */
  const onUserLogout = async () => {
    dispatch(
      openDialog({
        type: "logout",
        title: t("confirm_logout"),
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: () => {
          dispatch(userLogout());
          navigate("/login");
        },
      }),
    );
  };

  return (
    <div className="header">
      <div className="title">{t("admin_center")}</div>
      <div className="header-menu-group">
        <Button
          className={adminInfo?.isEnableNotification ? "notifi-status" : "disabled disabled-text"}
          onClick={() => {
            if (adminInfo?.isEnableNotification) {
              onCancelNotify();
            } else {
              onConfirmNotify();
            }
          }}
        >
          {adminInfo?.isEnableNotification ? "알림켜짐" : "알림꺼짐"}
        </Button>
        <div className="logout-button" onClick={onUserLogout}>
          {t("logout")}
        </div>
      </div>
    </div>
  );
};
export default Header;
