import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import icClose from "../../../../assets/images/ic_close.svg";
import icMore from "../../../../assets/images/ic_more.svg";
import {
  useDeleteShipperMutation,
  useGetShipperByIdQuery,
  useUpdateShipperStatusMutation,
} from "../../../../pages/MemberManagement/memberApi";
import { openDialog } from "../../../customDialog/dialogSlice";
import { closeModal, openModal } from "../../modalSlice";
import "./style.scss";
import { formatPhoneNumber } from "../../../../utils/utils";

export default function ShipperInfo(props: any) {
  const [info, setInfo] = useState(props.data.info);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { data: shipperDetail, isSuccess } = useGetShipperByIdQuery(
    { id: props.data.info?.id },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  const [updateShipperStatus] = useUpdateShipperStatusMutation();
  const [deleteShipper] = useDeleteShipperMutation();

  useEffect(() => {
    if (props?.data?.info) {
      setIsShowDelete(false);
      setInfo(shipperDetail);
    }
  }, [props, shipperDetail]);

  const onHandleCloseClick = () => {
    props.onClose();
  };

  const onHandleMoreClick = () => {
    setIsShowDelete(!isShowDelete);
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteClick = async () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_delete_member"),
          dialogContent: t("cancel_dispatch_note"),
        },
        handleAction: onHandleDeleteAction,
      }),
    );
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteAction = async () => {
    const params = {
      id: info.id,
    };
    const res = await deleteShipper(params); //@ts-ignore
    showResult(res?.error, t("deletion_is_complete"));
  };

  const onHandleBlockClick = async () => {
    const params = {
      id: info.id,
      status: "BLOCK", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateShipperStatus(params); //@ts-ignore
    showResult(res?.error, t("member_is_blocked"));
  };

  const onHandleUnblockClick = async () => {
    const params = {
      id: info.id,
      status: "ACTIVE", //BLOCK, ACTIVE, INACTIVE
    };
    const res = await updateShipperStatus(params); //@ts-ignore
    showResult(res?.error, t("member_is_released"));
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    if (!error?.data?.message) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: error?.data?.message,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  };

  /* ---------------------------------- 메모 저장 --------------------------------- */
  const onHandleSubmitClick = () => {
    if (info?.status) {
      if (info.status === "ACTIVE") {
        dispatch(
          openModal({
            template: "show-confirm-dialog-modal",
            width: "468px",
            data: {
              dialogTitle: t("block_member_confirm"),
            },
            handleAction: onHandleBlockClick,
          }),
        );
      } else {
        dispatch(
          openModal({
            template: "show-confirm-dialog-modal",
            width: "468px",
            data: {
              dialogTitle: t("unblock_member_confirm"),
            },
            handleAction: onHandleUnblockClick,
          }),
        );
      }
    }
  };

  const getTextSubmit = () => {
    if (info.status === "ACTIVE") {
      return t("member_block");
    } else {
      return t("unblock");
    }
  };

  return (
    <div
      className="normal-shipper-detail-wrapper"
      style={{
        minHeight: info?.type === "BUSINESS" ? "520px" : "458px",
      }}
    >
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="top-content">
          <div className="name-wrap">
            <div className="name">{info?.representationName}</div>
            <div className="name-title">{t("representative_name")}</div>
          </div>
          <div className="more-wrapper">
            <div className="more-btn" onClick={onHandleMoreClick}>
              <img className="ic-more" alt="More" src={icMore} />
            </div>
            {isShowDelete && (
              <div className="delete-btn" onClick={onHandleDeleteClick}>
                <div className="delete-text">{t("delete")}</div>
              </div>
            )}
          </div>
        </div>
        <div className="content">
          {info?.type === "BUSINESS" ? (
            <>
              <div className="info-wrap">
                <div className="title">{t("company_name")}</div>
                <div className="info">{info?.company}</div>
              </div>
              <div className="info-wrap">
                <div className="title">{t("office_number")}</div>
                <div className="info">{info?.officeNumber}</div>
              </div>
              <div className="info-wrap">
                <div className="title">{t("business_number")}</div>
                <div className="info">{info?.businessNumber}</div>
              </div>
              <div className="info-wrap">
                <div className="title">{t("contact_name")}</div>
                <div className="info">{info?.picName}</div>
              </div>{" "}
              <div className="info-wrap">
                <div className="title">{t("contact_person_number")}</div>
                <div className="info">{formatPhoneNumber(info?.picPhone)}</div>
              </div>
              <div className="info-wrap">
                <div className="title">{t("email")}</div>
                <div className="info">{info?.email}</div>
              </div>
              <div className="info-wrap">
                <div className="title">잔여포인트</div>
                <div className="info">{info?.points}</div>
              </div>
            </>
          ) : (
            <>
              <div className="info-wrap">
                <div className="title">{t("email")}</div>
                <div className="info">{info?.email}</div>
              </div>
              <div className="info-wrap">
                <div className="title">{t("contact")}</div>
                <div className="info">{info?.phoneContact}</div>
              </div>
              <div className="info-wrap">
                <div className="title">잔여포인트</div>
                <div className="info">{info?.points}</div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="btn-wrap">
        <div className={`submit-btn ${info?.status === "ACTIVE" ? "ACTIVE" : "BLOCK"}`} onClick={onHandleSubmitClick}>
          <div className={`submit-text ${info?.status === "ACTIVE" ? "ACTIVE" : "BLOCK"}`}>
            {info && getTextSubmit()}
          </div>
        </div>
      </div>
    </div>
  );
}
