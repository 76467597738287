import { Button, Checkbox, DatePicker, DatePickerProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useLocation } from "react-router-dom";
import { Moment } from "moment-timezone";

export default function PopupFilter({ onClose, data, handleAction }: any) {
  const location = useLocation();
  const [values, setValues] = useState<string[]>(data.values);
  const [showDateFilter, setShowDateFilter] = useState(data.showDateFilter);
  const [dateStart, setDateStart] = useState<Moment | undefined>(undefined);
  const [dateEnd, setDateEnd] = useState<Moment | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    setValues(data.values);
    setShowDateFilter(data.showDateFilter);
  }, [data]);

  useEffect(() => {
    setDateStart(undefined);
    setDateEnd(undefined);
  }, [location.pathname]);

  /* --------------------------------- 시작 일 변경 -------------------------------- */
  const onChangeStartDate: DatePickerProps["onChange"] = (date: any) => {
    setDateStart(date);
  };

  /* --------------------------------- 마감 일 변경 -------------------------------- */
  const onChangeEndDate: DatePickerProps["onChange"] = (date: any) => {
    setDateEnd(date);
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setValues((prev) => [...prev, e.target.value]);
    } else {
      const index = values.indexOf(e.target.value);
      const data = [...values];
      data.splice(index, 1);
      setValues(data);
    }
  };

  const onConfirm = () => {
    const filter = {
      filters: values,
      dateRange: {
        startDate: dateStart ? dateStart?.format("YYYY-MM-DD") : undefined,
        endDate: dateEnd ? dateEnd?.format("YYYY-MM-DD") : undefined,
      },
    };
    handleAction(filter);
    onClose();
  };

  return (
    <div className="search-filter-wrapper">
      <div className="title">{t("filtering")}</div>
      <div className="content">
        {showDateFilter && (
          <div className="date-picker-wrapper">
            <div className="start-date-picker">
              <div className="text-title-date">
                {data?.customRangPickerLabel?.from ? data?.customRangPickerLabel?.from : t("start_date")}
              </div>
              <DatePicker
                inputReadOnly={true}
                value={dateStart}
                size="middle"
                onChange={onChangeStartDate}
                style={{ width: 212 }}
                placeholder={t("please_select_a_date")}
              />
            </div>
            {!data?.isSingleDateFilter && (
              <>
                <div className="space"></div>
                <div className="end-date-picker">
                  <div className="text-title-date">
                    {data?.customRangPickerLabel?.to ? data?.customRangPickerLabel?.to : t("end_date")}
                  </div>
                  <DatePicker
                    inputReadOnly={true}
                    value={dateEnd}
                    size="middle"
                    onChange={onChangeEndDate}
                    style={{ width: 212 }}
                    placeholder={t("please_select_a_date")}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {data?.options?.map((e: any) => {
          return (
            <Checkbox
              key={e.value}
              value={e.value}
              checked={values.includes(e.value)}
              onChange={onChangeCheckbox}
              disabled={e.disabled}
            >
              {e.label}
            </Checkbox>
          );
        })}
      </div>
      <Button className="btn-confirm" onClick={onConfirm}>
        {t("dialog_btn_confirm")}
      </Button>
    </div>
  );
}
