/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Progress, Radio, Row, Select, Space, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import CustomSelect from "@/components/customSelect";
import itemInfos from "@/utils/localData/listItemInfo.json";
import parkingMethod from "@/utils/localData/listParkingMethod.json";
import listTonnas from "@/utils/localData/listTonna.json";
import listLabo from "@/utils/localData/listVehicleType.json";
import listWeights from "@/utils/localData/unitsWeight.json";
import "./styles.scss";
import { RcFile, UploadFile } from "antd/lib/upload";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "@/components/customDialog/dialogSlice";
import { API_LINK } from "@/utils/constants";
import cx from "classnames";
import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import useUpdateEffect from "@/hooks/useUpdateEffect";
import axios from "axios";
import { useWatch } from "antd/lib/form/Form";
import SelectAddOption from "@/components/SelectAddOption";
import { useGetPartnerListQuery } from "../VehicleRegisApi";
// import { getUserInfo } from "@/store/selector/RootSelector";
// import { useCallbackPrompt } from "@/hooks/useCallbackPrompt";

interface Props {
  onNextStep?: (e: any) => void;
  dataDetail?: any;
  form: any;
  onReset?: () => void;
  setIsBlocking?: (isBlocking: boolean) => void;
  method?: string | "one-way" | "two-way";
}

export default function Step1({ onNextStep, form, onReset, setIsBlocking, method, dataDetail }: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | undefined>(undefined);
  const queryString = window.location.search;
  const dispatch = useDispatch();
  // const userInfo: any = useSelector(getUserInfo);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const docUrlsInit = useWatch("docUrls", form);
  const partnerWatch = useWatch("providerPartnerId", form);
  const vehicleTypeData = useWatch("vehicleType", form);

  const tonnageData = useWatch("tonnage", form);
  const [uploadingFiles, setuploadingFiles] = useState(false);

  // const { data: partnerList } = useGetPartnerListQuery(
  //   {
  //     isFetchDeleted: false,
  //   },
  //   { refetchOnMountOrArgChange: true },
  // );

  const isNormalMember = dataDetail?.providerPartnerId;


  const { itemInfo } = itemInfos;
  const { parkingMethods } = parkingMethod;
  const { listTonna } = listTonnas;
  const { listLaboUpdate } = listLabo;
  const { units1T, units2535T, units525T } = listWeights;

  useEffect(() => {
    setTimeout(() => {
      form.setFieldValue("isEdit", true);
    }, 500);

    return () => { };
  }, [vehicleTypeData]);

  const handleChangeFile = async () => {
    const formData = new FormData();
    let uploadList = fileList?.filter((it) => it.lastModified);
    let notUploadList = fileList?.filter((it) => !it?.lastModified);

    if (uploadList.length > 0) {
      uploadList.forEach((item) => {
        formData.append("files", item as any);
      });
      setuploadingFiles(true);
      const response = await axios.post(API_LINK + "assets/uploads", formData, {
        headers: {
          "Content-type": `multipart/form-data`,
        },
      });
      setuploadingFiles(false);
      if (!isEmpty(response.data)) {
        const uploadedList = response.data.map((_item: any, index: number) => {
          const data = Array.from(formData) as any;
          return {
            uid: _item?.Key,
            name: data?.[index]?.[1]?.name,
            url: _item?.Location,
          };
        });

        let newList = [...notUploadList, ...uploadedList].filter((it) => it.url);
        form.setFieldValue("docUrls", newList);
      }
    }
  };

  const partnerOptions = [
    {
      label: "해당 없음",
      value: "해당 없음",
    },
  ];

  useUpdateEffect(() => {
    if (!isEmpty(fileList)) {
      handleChangeFile();
      // setIsBlocking?.(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (docUrlsInit?.length > 0 && fileList?.length !== docUrlsInit?.length) {
      setFileList(docUrlsInit);
    }

    return () => { };
  }, [docUrlsInit]);

  const formInitialValue = isNormalMember
    ? {}
    : {
      providerPartnerId: "해당 없음",
    };

  useEffect(() => {
    const isDamasOrLabo = ["라보", "다마스"].includes(tonnageData);
    if (isDamasOrLabo) {
      form.setFieldValue("vehicleType", "상관없음");
    }
  }, [tonnageData]);

  const beforeUpload = async (file: RcFile, fl: any[]) => {
    if (fl.length > 0 && fl[fl.length - 1]?.uid === file.uid) {
      let newList = [...fileList];
      if (newList.length >= 5) {
        dispatch(
          openDialog({
            type: "info",
            content: "첨부파일은 최대 5개 까지 가능합니다.",
          }),
        );
        return false;
      } else
        fl.forEach((fll) => {
          if (newList.length < 5) {
            if (newList?.find((it) => it.name === fll.name)?.name) {
              dispatch(
                openDialog({
                  type: "info",
                  content: "동일한 파일을 첨부할 수 없습니다.",
                }),
              );
              return;
            }
            if (fll?.size / 1024 / 1024 > 30) {
              dispatch(
                openDialog({
                  type: "info",
                  content: "30MB 이하만 첨부할 수 있습니다.",
                }),
              );

              return;
            }
            if (!/\.(hwp|pdf|xlsx|jpg|jpeg|png|bmp)$/i.test(fll?.name)) {
              dispatch(
                openDialog({
                  type: "info",
                  content: "지원하지 않는 파일 형식 입니다.",
                }),
              );

              return;
            }

            newList.push(fll);
          }
        });

      setFileList(newList);
    }

    return false;
  };

  const handleFinish = (values: any) => {
    onNextStep?.(2);
    console.log({ values });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // const [isBlocking, setIsBlocking] = useState(true);
  // if (useCallbackPrompt) {
  //   useCallbackPrompt(isBlocking);
  // }
  // const partnerField = form.getFieldValue("providerPartnerId");
  // const partnerOptionMemo = useMemo(() => {
  //   return [
  //     ...(partnerList?.map((p: { id: any; name: any; }) => ({
  //       value: p?.id,
  //       label: p?.name,
  //     })) || []),
  //     {
  //       value: "해당 없음",
  //       label: "해당 없음",
  //     },
  //   ];
  // }, [partnerList, partnerField, partnerWatch]);

  // const selectedPartner = partnerOptionMemo?.filter((p) => p?.value === partnerField)?.[0]?.label;

  return (
    <div className=" px-[222px]  w-[1440px] mx-auto step-first-dispatch">
      {/* <div className="flex justify-between items-center">
        <Space direction="horizontal" size={16}>
          <span className="text-primary-brandblue font-bold text-2xl capitalize">STEP 1</span>
          <span className="text-[#131416] text-2xl capitalize font-bold">물품 • 차량 정보 </span>
          {partnerField && partnerField !== "해당 없음" && selectedPartner && !isNormalMember && (
            <div className="text-[#FFB800] min-w-[80px] flex justify-center font-bold text-xs px-[11px] py-[6px] rounded-[20px] border-[#FFC93F] bg-[#FFFBD9] border-solid border-[1px]">
              {partnerOptionMemo?.filter((p) => p?.value === partnerField)?.[0]?.label}
            </div>
          )}
        </Space>
        <Button
          onClick={() => {
            form.resetFields([
              "type",
              "info",
              "packingMethod",
              "quantity",
              "docUrls",
              "requirement",
              "vehicleType",
              "fax",
              "tonnage",
            ]);
            form.setFieldValue("isCombineGoods", "unregister");
            onReset?.();
          }}
          className="border-[1.2px] border-solid border-[#DFE0E2] inline-flex justify-center items-center bg-white rounded-lg py-2 px-5"
        >
          {t("reset")}
        </Button>
      </div> */}
      {/* <div className="my-6">
        <Progress percent={25} strokeWidth={2} strokeColor="#5d98d1" showInfo={false} />
      </div> */}
      <Form
        onFinish={handleFinish}
        layout="horizontal"
        form={form}
        onValuesChange={(changedValues) => {
          // setIsBlocking?.(true);
          if (changedValues.isCombineGoods === "unregister") {
            form.setFieldValue("combineGoods", "");
          }
        }}
        initialValues={formInitialValue}
        labelAlign="left"
        labelCol={{
          span: 4,
          offset: 0,
        }}
        wrapperCol={{
          span: 16,
          offset: 2,
        }}
      >
        <Row>
          <Col span={10}>
            <Form.Item label="물품 종류" name="type">
              <Input
                className="rounded-lg border-[1.2px] border-solid bg-white h-[50px]"
                placeholder="물품명"
                maxLength={255}
              />
            </Form.Item>
            <Form.Item name="info" label="상하차방법">
              <Select
                placeholder={t("선택")}
                options={itemInfo}
                onChange={() => {
                  setTimeout(() => {
                    form.setFieldValue("isEdit", true);
                  }, 500);
                }}
              />
            </Form.Item>
            <Form.Item label="물품 수량">
              <div className="flex items-center space-x-4">
                <Form.Item noStyle name="quantity">
                  <Input
                    className="rounded-lg border-[1.2px] border-solid bg-white h-[50px]"
                    placeholder={t("수량")}
                    type="number"
                    min={0}
                    maxLength={255}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item noStyle name="packingMethod">
                  <SelectAddOption
                    hasCustomOption={true}
                    options={parkingMethods}
                    placeholder={t("포장 방법")}
                    width={"100%"}
                    onSelect={(value: string) => form.setFieldValue("packingMethod", value)}
                    // defaultValue={form.getFieldValue("packingMethod")}
                    value={value}
                    setValue={setValue}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="물품 서류"
              name="docUrls"
              className="label-docUrls"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <div className="text-[#A9ADB2] text-[10px] not-italic font-normal leading-4  mb-2">
                Hwp, Pdf, Xslx, Png, Jpg, Bmp 가능, 용량 제한 : 30MB
              </div>
              <Upload
                name="file"
                className="block w-full"
                beforeUpload={beforeUpload}
                accept=".hwp, .pdf, .xlsx, .jpg, .jpeg, .png, .bmp"
                method="POST"
                multiple={true}
                maxCount={5}
                showUploadList
                fileList={fileList}
                itemRender={(originNode, file, fileList, actions) => {
                  return (
                    <div className="flex items-center justify-between px-4 py-3 bg-[#CEE1F2] rounded-lg my-2">
                      <div className="text-sm text-[#484C51] whitespace-nowrap overflow-hidden text-ellipsis w-[70%]">
                        {file.name}
                      </div>
                      <button
                        className="text-xs text-primary-brandblue bg-transparent border-none cursor-pointer"
                        onClick={() => {
                          actions.remove();
                          form.setFieldValue(
                            "docUrls",
                            fileList.filter((item) => item.uid !== file.uid),
                          );
                          setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
                        }}
                        type="button"
                      >
                        삭제
                      </button>
                    </div>
                  );
                }}
              >
                <div className="flex items-center space-x-2 w-full">
                  <div className="border-[1.2px] border-solid rounded-lg text-sm py-[14px] border-[#DFE0E2] pl-[16px] pr-[57px] text-[#A9ADB2] w-[200px] flex-shrink-0">
                    물품 관련 문서.hwp
                  </div>
                  <Button className="bg-primary-brandblue text-white rounded-lg h-[50px] w-full">첨부</Button>
                </div>
              </Upload>
            </Form.Item>
            <Form.Item label="팩스번호" name="fax">
              <Input
                className="rounded-lg border-[1.2px] border-solid bg-white h-[50px]"
                placeholder="‘-’없이 입력해주세요"
                onInput={(e: any) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  e.target.value = sanitizedValue; // Set the input value to the sanitized version
                }}
                maxLength={255}
              />
            </Form.Item>
          </Col>
          <Col span={10} offset={4}>
            <div className="flex items-center justify-between mb-6 mr-3">
              <span>차량 정보</span>
              <div className="flex items-center space-x-2 gap-2">
                <Form.Item className="mb-0" name="tonnage">
                  <CustomSelect
                    hideCustomItem={true}
                    placeholder={t("톤수 선택")}
                    options={listTonna}
                    onChange={(e: string) => {
                      form.setFieldValue("combineGoods", "");

                      if (["라보", "다마스"].includes(e)) {
                        form.setFieldValue("isCombineGoods", "unregister");
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  shouldUpdate={(prevValues, currentValues) => prevValues.tonnage !== currentValues.tonnage}
                >
                  {({ getFieldValue }) => {
                    const isDamasOrLabo = ["라보", "다마스"].includes(getFieldValue("tonnage"));
                    const options = listLaboUpdate;

                    return (
                      <Form.Item noStyle name="vehicleType">
                        <CustomSelect
                          hideCustomItem={true}
                          placeholder={t("차종 선택")}
                          options={options}
                          disabled={isDamasOrLabo}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </div>
            </div>
            <div className="flex items-center justify-between mr-3">
              <span className="mb-[25px]">혼적</span>
              <div className="flex items-center">
                <Form.Item name="isCombineGoods" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                  <Radio.Group>
                    <Radio value="unregister">비해당</Radio>
                    <Radio value="registered" disabled={["라보", "다마스"].includes(tonnageData)}>
                      해당
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.isCombineGoods !== currentValues.isCombineGoods
                  }
                >
                  {({ getFieldValue }) => {
                    const isCombineGoods = getFieldValue("isCombineGoods") === "registered";
                    return (
                      <Form.Item noStyle name="combineGoods">
                        <CustomSelect
                          hideCustomItem={true}
                          placeholder={t("길이 선택")}
                          options={
                            ["1톤", "1.4톤"].includes(getFieldValue("tonnage"))
                              ? units1T
                              : ["2.5톤", "3.5톤"].includes(getFieldValue("tonnage"))
                                ? units2535T
                                : units525T
                          }
                          disabled={!isCombineGoods}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </div>
            </div>
            {/* {!isNormalMember && (
              <Form.Item name="providerPartnerId" label="거래처" labelCol={{ span: 7 }} wrapperCol={{ span: 18 }}>
                <Select
                  placeholder={t("선택")}
                  options={partnerOptionMemo}
                  onChange={(_: any, record) => {
                    setTimeout(() => {
                      form.setFieldValue("isEdit", true);
                    }, 500);
                  }}
                />
              </Form.Item>
            )} */}

            <Form.Item name="requirement" label="요청 사항" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <TextArea
                rows={8}
                className="rounded-lg resize-none"
                placeholder="B/L, 물품무게, 특이사항 또는 요청사항 있는 경우 입력 부탁드립니다."
                autoSize={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          shouldUpdate
          className="justify-center"
          wrapperCol={{
            offset: 0,
          }}
        >
          {({ getFieldsValue, getFieldValue }) => {
            const data = getFieldsValue([
              "type",
              "info",
              "quantity",
              "packingMethod",
              "tonnage",
              "vehicleType",
              "isCombineGoods",
            ]);
            let isEmptyValue = Object.keys(data).filter((key) => !data[key]);
            const isCombineGoods = getFieldValue("isCombineGoods") === "registered" && !getFieldValue("combineGoods");
            if (isCombineGoods) {
              isEmptyValue = ["combineGoods"];
            }
            return (
              <div className="flex justify-center gap-[36px]">
                <Button
                  className={cx(
                    "mt-[40px] mb-[80px] h-[64px] rounded-lg font-bold text-xl text-primary-brandblue border-primary-brandblue w-[300px] mr-0 ",
                  )}
                  onClick={() => onNextStep?.(0)}
                >
                  이전
                </Button>

                <Button
                  className={cx("mt-[40px] mb-[80px] h-[64px] rounded-lg font-bold text-xl text-white w-[300px] ", {
                    "bg-primary-brandblue": isEmptyValue.length === 0,
                    "bg-[#D9D9D9]": isEmptyValue.length > 0,
                  })}
                  block
                  disabled={isEmptyValue.length > 0}
                  htmlType="submit"
                  loading={uploadingFiles}
                >
                  {method === "two-way" ? "다음 단계로 이동" : "2단계로 이동"}
                </Button>
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
}
