import { createContext, ReactNode, useContext, useReducer } from "react";
import { RouterKakao } from "@/utils/types/kakao";

interface IProps {
  children: ReactNode;
}
const defaultFnc = () => console.log("");

enum RoleEnum {
  NORMAL = "NORMAL",
  BUSINESS = "BUSINESS",
  PAY_LATER = "PAY_LATER",
}

interface IContext {
  step1Data?: any;
  setStep1Data?: (payload: any) => void;
  startRouters: RouterKakao[];
  setStartRouters?: (payload: any) => void;
  endRouters: RouterKakao[];
  setEndRouters?: (payload: any) => void;
}

interface IAction {
  type: string;
  payload: any;
}

const initialState: IContext = {
  step1Data: {},
  setStep1Data: () => { },
  startRouters: [],
  endRouters: [],
};

const RegisterDispatchContext = createContext<IContext>(initialState);

const reducer = (state: IContext, { type, payload }: IAction) => {
  switch (type) {
    case "SET_STEP1_DATA": {
      return {
        ...state,
        step1Data: payload,
      };
    }
    case "START_ROUTERS": {
      return {
        ...state,
        startRouters: payload,
      };
    }
    case "END_ROUTERS": {
      return {
        ...state,
        endRouters: payload,
      };
    }
    default:
      return state;
  }
};

const RegisterDispatchProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const _handleSetStep1Data = (data: any) => {
    dispatch({
      type: "SET_STEP1_DATA",
      payload: data,
    });
  };

  const _handleSetStartRouters = (data: RouterKakao[]) => {
    dispatch({ type: "START_ROUTERS", payload: data });
  };
  const _handleSetEndRouters = (data: RouterKakao[]) => {
    dispatch({ type: "END_ROUTERS", payload: data });
  };

  return (
    <RegisterDispatchContext.Provider
      value={{
        ...state,
        setStep1Data: _handleSetStep1Data,
        setEndRouters: _handleSetEndRouters,
        setStartRouters: _handleSetStartRouters,
      }}
    >
      {children}
    </RegisterDispatchContext.Provider>
  );
};

const useRegisterDisPatch = () => useContext(RegisterDispatchContext);

export {
  RegisterDispatchContext,
  RegisterDispatchProvider,
  useRegisterDisPatch,
};
