import { Form, FormInstance } from "antd";
import React, { ChangeEventHandler, useState } from "react";
import KitButton from "../Button/Button";
import "./style.scss";
import { API_LINK } from "../../utils/constants";

interface Props {
  form: FormInstance;
  nameField: string;
  multiple?: boolean;
  label?: string;
  rules?: any;
}

function FormUpload({ form, nameField, multiple = false, label, rules }: Props) {
  const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
  const [uploadedFile, setUploadedFile] = useState<string | undefined>(undefined);
  const inputRef = React.useRef<React.LegacyRef<HTMLInputElement> | any>();

  const onFileChoosing = () => {
    inputRef?.current && inputRef?.current?.click();
  };

  /* --------------------------------- 파일명 변경 --------------------------------- */
  const convertFileName = (file: string) => {
    let truncatedName: string;
    const fileWithoutTypeRegex = /.+(?=\.png|\.jpg)/g;
    const maxFileNameLength = 10;
    const extension = file?.split(".")?.pop()?.toLowerCase();
    const name = file?.match(fileWithoutTypeRegex)?.[0] || "";
    if (name?.length > 10) {
      truncatedName = name?.substring(0, maxFileNameLength) + "..." + extension;
    } else {
      truncatedName = file;
    }
    return truncatedName;
  };

  /* -------------------------------- 파일 업로드 처리 ------------------------------- */
  const handleFileUploadChange = async (event: ChangeEventHandler<HTMLInputElement> | any) => {
    const file = event?.target?.files?.[0];

    if (file?.size > maxSizeInBytes || (file?.type !== "image/png" && file?.type !== "image/jpeg")) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          form.setFieldValue(nameField, data?.Location);
          const truncatedName = convertFileName(file?.name);
          setUploadedFile(truncatedName);

          form.setFieldValue(`${nameField}Src`, file?.name);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="file-upload" key={nameField}>
      <span>{label}</span>
      <Form.Item name={`${nameField}Src`} key={`${nameField}Src`} hidden></Form.Item>
      <Form.Item name={nameField} key={nameField}>
        <div className="file-upload-area">{uploadedFile || convertFileName(form.getFieldValue(`${nameField}Src`))}</div>
        <input
          accept="image/png, image/jpeg"
          multiple={multiple}
          type="file"
          className="file-upload-input"
          ref={inputRef}
          onChange={(event: ChangeEventHandler<HTMLInputElement> | any) => handleFileUploadChange(event)}
        />
        <KitButton btnType="gray" onClick={onFileChoosing}>
          파일 선택
        </KitButton>
      </Form.Item>
    </div>
  );
}

export default FormUpload;
