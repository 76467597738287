import { Button } from "antd";
import { useTranslation } from "react-i18next";
import "./style.scss";

export default function ConfirmDialog({ onClose, data, handleAction }: any) {
  const { t } = useTranslation();

  /* ------------------------------ dialog 확인 취소 ------------------------------ */
  const handleCancel = () => {
    onClose();
  };

  /* -------------------------------- dialog 확인 ------------------------------- */
  const handleConfirm = () => {
    handleAction();
  };

  return (
    <div className="confirm-dialog">
      <div className="title">{data.dialogTitle}</div>
      <div className="content">{data.dialogContent}</div>
      <Button className="bt-confirm" onClick={handleConfirm}>
        {data?.confirmBtnText ? data?.confirmBtnText : t("dialog_btn_confirm")}
      </Button>
      <Button className="bt-cancel" onClick={handleCancel}>
        {data?.cancelBtnText ? data?.cancelBtnText : t("dialog_btn_cancel")}
      </Button>
    </div>
  );
}
