/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const dispatchApi = createApi({
  reducerPath: "dispatchApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetOrders"],
  endpoints: (builder) => ({
    cancelSettlement: builder.mutation({
      query: (data) => {
        return {
          url: `orders/admin/cancel-settlement`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["GetOrders"],
    }),
    getExcelFile: builder.mutation({
      query: (data) => {
        return {
          url: `orders/admin/download-excel?${data?.params}`,
          method: "GET",
          headers: {
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseHandler(response) {
            return response.arrayBuffer();
          },
        };
      },
      invalidatesTags: ["GetOrders"],
    }),
    getOrders: builder.query({
      query: (data) => {
        return {
          url: `/orders?${data}`,
          method: "GET",
        };
      },
      //@ts-ignore
      providesTags: ["GetOrders"],
    }),
    updateOrders: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: "PATCH",
        body: data.data,
      }),
      invalidatesTags: ["GetOrders"],
    }),
    cancelOrder: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}/cancel`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetOrders"],
    }),
    updatePrices: builder.mutation({
      query: (data) => ({
        url: `orders/${data.id}/prices`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetOrders"],
    }),
    updateDone: builder.mutation({
      query: (data) => {
        return {
          url: `orders/${data.id}/done`,
          method: "POST",
        };
      },
      invalidatesTags: ["GetOrders"],
    }),
    approvedRequestbill: builder.mutation({
      query: (data) => ({
        url: "/orders/admin/approved-request-bill",
        method: "PATCH",
        body: data,
      }),
    }),
    updateDoneMultipleSettlement: builder.mutation({
      query: (data) => {
        return {
          url: `orders/done/bulk`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GetOrders"],
    }),
  }),
});

export const {
  useCancelSettlementMutation,
  useGetExcelFileMutation,
  useGetOrdersQuery,
  useCancelOrderMutation,
  useUpdatePricesMutation,
  useApprovedRequestbillMutation,
  useUpdateDoneMutation,
  useUpdateDoneMultipleSettlementMutation,
  useUpdateOrdersMutation,
} = dispatchApi;
