import "./style.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrderDetailQuery } from "./../settlementApi";
import { useDispatch } from "react-redux";
import { useApprovedRequestbillMutation, useUpdateDoneMutation } from "../../DispatchManagement/dispatchApi";
import { closeDialog, openDialog } from "../../../components/customDialog/dialogSlice";
import { downloadFile, formatMoney, formatPhoneNumber, getDateFormat } from "../../../utils/utils";
import "./style.scss";
import { closeModal } from "../../../components/customModal/modalSlice";
import { ECarrierSettlementStatus } from "../CarrierSettlement";
import { STATUS_DONE, STATUS_WAITING_PAYMENT } from "../../../utils/constants";
import { Button } from "antd";
import goldMedal from "../../../assets/images/goldMedal.png";
import silverMedal from "../../../assets/images/silverMedal.png";
import ironMedal from "../../../assets/images/ironMedal.png";
import cuMedal from "../../../assets/images/cuMedal.png";
import { useMemo } from "react";
import { wait } from "../../../utils/commonFnc";

const CarrierSettlementDetail = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const [approvedRequestBill] = useApprovedRequestbillMutation();

  const { data } = useGetOrderDetailQuery(
    { id: props?.data?.id ? props?.data?.id : id },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );
  const [updateOrderDone] = useUpdateDoneMutation();

  /* --------------------------------- 데이타 전환 --------------------------------- */
  const transformData = (item: any) => {
    if (!item) {
      return {};
    }
    return {
      ...item,
      expectedStartDate: getDateFormat(item.expectedStartDate, "yyyy.MM.DD A hh시 mm분"),
      expectedEndDate: getDateFormat(item.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
      actualStartDate: getDateFormat(item.actualStartDate, "yyyy.MM.DD A hh시 mm분"),
      actualEndDate: getDateFormat(item.actualEndDate, "yyyy.MM.DD A hh시 mm분"),
    };
  };

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBack = () => {
    dispatch(closeModal());
  };

  /* --------------------------------- 계산서 발행 --------------------------------- */
  const onPublish = () => {
    dispatch(
      openDialog({
        type: "confirm",
        title: t("publish_bill_confirm"),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          const res = await approvedRequestBill({
            ids: [id],
          }); //@ts-ignore
          showResult(res?.error, t("issued_2"));
        },
        actionConfirm: () => {
          dispatch(
            openDialog({
              type: "info",
              content: t("canceled"),
              actionConfirm: onBack,
            }),
          );
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: !error?.data?.message ? msgSuccess : error?.data?.message,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          if (!error?.data?.message) {
            onBack();
          } else {
            dispatch(closeDialog());
          }
        },
      }),
    );
  };

  /* ------------------------------- 완료된 오더 업데이트 ------------------------------ */
  const onHandleUpdateOrderDone = async () => {
    try {
      if (data?.status === STATUS_WAITING_PAYMENT) {
        const res = await updateOrderDone({ id: props?.data?.id || id }).unwrap();
        const errMsg = res?.error?.data?.message;
        dispatch(closeModal());
        await wait(300);
        dispatch(
          openDialog({
            type: "info",
            content: errMsg ? errMsg : t("settlement_complete_notification"),
            confirmText: t("dialog_btn_confirm"),
            actionConfirm: () => {
              if (props?.data?.id) {
                dispatch(closeModal());
              } else {
                navigate(-1);
              }
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* ---------------------------------- 메달 표시 --------------------------------- */
  const mappingMedal = useMemo(() => {
    const score = data?.carrierScoreRating;
    if (score < 70) {
      return <img width={35} height={30} src={ironMedal} alt="ironMedal" />;
    } else if (score >= 70 && score < 80) {
      return <img width={35} height={30} src={cuMedal} alt="cuMedal" />;
    } else if (score >= 80 && score < 90) {
      return <img width={35} height={30} src={silverMedal} alt="silverMedal" />;
    } else if (score >= 90) {
      return <img width={35} height={30} src={goldMedal} alt="goldMedal" />;
    } else {
      return "";
    }
  }, [data]);

  const statusOptions = {
    ALL: {
      label: t("all"),
      value: ECarrierSettlementStatus.ALL,
    },
    [STATUS_WAITING_PAYMENT]: {
      label: t("정산요청"),
      value: ECarrierSettlementStatus.ISSUANCE_COMPLETED,
    },
    [STATUS_DONE]: {
      label: t("settlement_completed"),
      value: ECarrierSettlementStatus.SETTLEMENT_COMPLETED,
    },
  };

  return (
    <div className="settlement-detail">
      <div className="title">
        <div className="title-left">
          <span>정산 상세</span>
          <span className={`${data?.status === `${STATUS_WAITING_PAYMENT}` ? "pending-approval" : "approved"}`}>
            {" "}
            {statusOptions?.[data?.status as keyof typeof statusOptions]?.["label"] ?? ""}
          </span>
        </div>
        <div className="title-right">
          <span className="title-right-text">{formatMoney(data?.carrierSettlementAmount)} 원</span>
          <Button
            className={`btn-request-custome ${
              data?.status !== STATUS_WAITING_PAYMENT && "btn-request-custome--disabled"
            }`}
            onClick={onHandleUpdateOrderDone}
          >
            {data?.status === STATUS_WAITING_PAYMENT ? "정산하기" : "정산취소"}
          </Button>
        </div>
      </div>

      <div className="shipping-info">
        <div className="container-wrap-detail">
          <div className="card-header">
            <div className="header-title">정산 정보</div>
          </div>
          <div className="card-content">
            <div className="info-section">
              <div className="column-info-custom">
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-400">
                      <span className="text-bold">화주 등록 운임</span>: {formatMoney(data?.freightCost)} 원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="item-400">
                      <span className="text-bold">배차킹 수수료</span>:{" "}
                      {formatMoney(data?.freightCost - (data?.carrierPaymentAmount - data?.carrierOtherFee))} 원
                    </div>
                  </div>
                </div>
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-400">
                      <span className="text-bold">운송인 앱 노출 금액: </span>
                      {formatMoney(data?.carrierPaymentAmount - data?.carrierOtherFee)} 원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="item-400">
                      <span className="text-bold">추가 결제 금액: </span>
                      {formatMoney(data?.otherFee)} 원
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-section">
              <div className="column-info-custom">
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-title">
                      운송인 부가세: {formatMoney(Math.round(data?.carrierPaymentAmount * 0.1))} 원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="item-title">
                      운송인 산재보험료 금액: {formatMoney(data?.carrierPaymentAmountInsurance)}원
                    </div>
                  </div>
                </div>
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-title">세금 계산서 금액: {formatMoney(data?.carrierPaymentAmountTax)}원 </div>
                  </div>
                  <div className="item-column">
                    <div className="item-title">정산 요청 금액: {formatMoney(data?.carrierSettlementAmount)} 원</div>
                  </div>
                </div>
              </div>
            </div>
            <span className="info-section-title">운송인 정보</span>
            <div className="info-section info-section-noBorder">
              <div className="column-info ">
                <div className="item-column">
                  <div className="item-title">운송인 성명</div>
                  <div className="item-sub">{data?.carrier?.name}</div>
                </div>
                <div className="item-column">
                  <div className="item-title">차량 번호</div>
                  <div className="item-sub">{data?.carrier?.licensePlate}</div>
                </div>
              </div>
              <div className="column-info ">
                <div className="item-column">
                  <div className="item-title">매너 메달</div>
                  <div className="item-sub"> {mappingMedal}</div>
                </div>
                <div className="item-column">
                  <div className="item-title">차량 정보</div>
                  <div className="item-sub">
                    {data?.carrier?.tonnage} {data?.carrier?.vehicleType}
                  </div>
                </div>
              </div>
              <div className="column-info ">
                <div className="item-column">
                  <div className="item-title">닉네임</div>
                  <div className="item-sub">{data?.carrier?.nickname}</div>
                </div>
                <div className="item-column">
                  <div className="item-title">은행</div>
                  <div className="item-sub">{data?.carrier?.bank}</div>
                </div>
              </div>
              <div className="column-info ">
                <div className="item-column">
                  <div className="item-title">연락처</div>
                  <div className="item-sub">{formatPhoneNumber(data?.carrier?.phone)}</div>
                </div>
                <div className="item-column">
                  <div className="item-title">계좌번호</div>
                  <div className="item-sub">{data?.carrier?.accountBank}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-wrap-detail">
        <div className="card-header">
          <div className="header-title">운송 정보</div>
        </div>
        <div className="card-content">
          <div className="card-content-wrap">
            <div className=" info-section-flex-ct">
              <div className="item-column">
                <div className="item-title">상차지 주소</div>
                <div className="item-sub">
                  {data?.loadingPlace} {data?.detailLoadingPlace}{" "}
                </div>
              </div>
              {data?.transitPlaces &&
                data?.transitPlaces?.length > 0 &&
                data?.transitPlaces.map((it: any, index: number) => {
                  return (
                    <div key={index} className="item-column">
                      <div className="item-title">경유지 주소</div>
                      <div className="item-sub">
                        {it?.fullAddress} {it?.detailAddress}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className=" info-section-flex-ct">
              <div className="item-column">
                <div className="item-title">하차지 주소</div>
                <div className="item-sub">
                  {data?.receiptPlace} {data?.detailReceiptPlace}
                </div>
              </div>{" "}
              {data?.additionalEndpoints &&
                data?.additionalEndpoints?.length > 0 &&
                data?.additionalEndpoints.map((it: any, index: number) => {
                  return (
                    <div key={index} className="item-wrapper">
                      <div className="item-title">경유지 주소</div>
                      <div className="item-sub">
                        {it?.fullAddress} {it?.detailAddress}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="info-section-fiveColumn">
            <div className="column-info ">
              <div className="item-column">
                <div className="item-title">물품 종류</div>
                <div className="item-sub">{data?.type}</div>
              </div>
              <div className="item-column">
                <div className="item-title">물품 서류</div>
                {data?.docUrls &&
                  data?.docUrls.map((it: any, index: number) => {
                    return (
                      <div key={index} onClick={() => downloadFile(it.url, it.name)} className="item-sub fileName">
                        {it.name}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="column-info ">
              <div className="item-column">
                <div className="item-title">상하차 방법</div>
                <div className="item-sub">{data?.info}</div>
              </div>
            </div>
            <div className="column-info ">
              <div className="item-column">
                <div className="item-title">물품 수량</div>
                <div className="item-sub">{data?.quantity}</div>
              </div>
            </div>
            <div className="column-info ">
              <div className="item-column">
                <div className="item-title">포장 방법</div>
                <div className="item-sub">{data?.packingMethod}</div>
              </div>
            </div>
          </div>
          <div className="info-section info-section-flex-last">
            <div className="item-column">
              <div className="item-title">상차 예정 시간</div>
              <div className="item-sub">{getDateFormat(data?.expectedStartDate, "yyyy.MM.DD A hh시 mm분")}</div>
            </div>
            <div className="item-column">
              <div className="item-title">하차 예정 시간</div>
              <div className="item-sub">{getDateFormat(data?.expectedEndDate, "yyyy.MM.DD A hh시 mm분")}</div>
            </div>
            <div className="item-column">
              <div className="item-title">실제 상차 시간</div>
              <div className="item-sub">{getDateFormat(data?.actualStartDate, "yyyy.MM.DD A hh시 mm분")}</div>
            </div>
            <div className="item-column">
              <div className="item-title">실제 하차 시간</div>
              <div className="item-sub">{getDateFormat(data?.actualEndDate, "yyyy.MM.DD A hh시 mm분")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-wrap">
        <div className="base-btn btn-cancel" onClick={onBack}>
          이전
        </div>
      </div>
    </div>
  );
};

export default CarrierSettlementDetail;
