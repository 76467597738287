import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useGetOrderQuery } from "./settlementApi";
import { useEffect, useState } from "react";
import { openDialog } from "../../components/customDialog/dialogSlice";
import moment from "moment";
import { STATUS_DONE, DATE_FORMAT_DOT, STATUS_WAITING_PAYMENT, PER_PAGE } from "../../utils/constants";
import { formatNumberToCurrency } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import NewTable from "../../components/NewTable";
import { getDateFromData, getDateToData } from "../../utils/commonFnc";
import queryString from "query-string";

interface DataType {
  id: string;
  dateOfApplication: string; //settlementAt
  name: string; //carrier.username
  nickname: string; //carrier.nickname
  loadingArea: string; //loadingPlace
  getOff: string; //receiptPlace
  carrierPaymentAmountTax: string;
  freightCost: string; //excludingFee
  settlementStatus: string; //status
  carrier: object; //carrier
}
export default function SettlementHistoryCarrier() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    status: "all",
    startDate: null,
    endDate: null,
  });
  const [searchParams, setSearchParams] = useState<string>("");
  const [filters, setFilters] = useState<string[]>(["carriername", "carriernickname"]);

  useEffect(() => {
    const queryParsed = queryString.parse(window.location.search);
    const newParams: any = {};
    if ("page" in queryParsed) {
      newParams["page"] = Number(queryParsed["page"]);
    }
    if ("limit" in queryParsed) {
      newParams["limit"] = Number(queryParsed["page"]);
    }
    if ("keyword" in queryParsed) {
      newParams["search"] = queryParsed["keyword"];
    }

    if ("searchColumn[]" in queryParsed) {
      setFilters(queryParsed["searchColumn[]"] as string[]);
    }

    if ("searchOr[]" in queryParsed) {
      if (Array.isArray(queryParsed["searchOr[]"])) {
        const searchOr = (queryParsed["searchOr[]"] as string[]).map((item) => {
          return JSON.parse(item);
        });
        if (searchOr.length === 2) {
          params.status = "all";
        }
      }
      if (typeof queryParsed["searchOr[]"] === "string") {
        let searchOr = JSON.parse(queryParsed["searchOr[]"] as string);
        if ("status" in searchOr) {
          params.status = searchOr["status"];
        }
      }
    }

    setParams({ ...params, ...newParams });
  }, []);

  useEffect(() => {
    setSearchParams(getSearchParams(params));
  }, [params]);

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: PER_PAGE,
      orderBy: "createdAt",
      order: "desc",
      fieldSearchTime: "settlementAt",
    };
    if (params.startDate) {
      _params.from = getDateFromData(params.startDate);
    }
    if (params.endDate) {
      _params.to = getDateToData(params.endDate);
    }

    let filtersQuery = "";
    if (params.search) {
      _params.keyword = params.search;
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }
    const paramQuery = queryString.stringify(_params);

    let searchOr = [JSON.stringify({ status: STATUS_DONE }), JSON.stringify({ status: STATUS_WAITING_PAYMENT })];
    if (params.status !== "all") {
      searchOr = [JSON.stringify({ status: params.status })];
    }
    const searchOrQuery = queryString.stringify(
      { ["searchOr"]: searchOr },
      {
        arrayFormat: "bracket",
      },
    );
    const searchAndQuery = queryString.stringify(
      { ["searchAnd"]: [JSON.stringify({ isCash: false })] },
      {
        arrayFormat: "bracket",
      },
    );
    return `${paramQuery}&${searchOrQuery}&${searchAndQuery}&${filtersQuery}`;
  };

  const { data, error, isSuccess, isFetching } = useGetOrderQuery(searchParams, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [arrData, setArrData] = useState<any>([]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      let arr: any = [];
      data?.data.map((item: any) => {
        let itemData: DataType = {
          id: item.id,
          dateOfApplication: item.settlementAt,
          name: item.carrier?.username,
          nickname: item.carrier?.nickname,
          loadingArea: item.loadingPlace,
          getOff: item.receiptPlace,
          carrierPaymentAmountTax: `${formatNumberToCurrency(item.carrierPaymentAmountTax || 0)} ${t("원")}`,
          settlementStatus: item.status,
          carrier: item.carrier,
          freightCost: item.freightCost,
        };
        arr.push(itemData);
      });
      arr.sort((a: any, b: any) =>
        a.dateOfApplication > b.dateOfApplication ? -1 : b.dateOfApplication > a.dateOfApplication ? 1 : 0,
      );
      setArrData(arr);
      setMetaData(data?.meta);
    }

    return () => { };
  }, [data]);

  const columns: ColumnsType<DataType> = [
    {
      title: t("date_of_application"),
      dataIndex: "dateOfApplication",
      key: "dateOfApplication",
      width: "10%",
      ellipsis: true,
      render: (dateOfApplication) => <div>{moment(dateOfApplication).format(DATE_FORMAT_DOT)}</div>,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: "10%",
      ellipsis: true,
    },
    {
      title: t("nickname"),
      dataIndex: "nickname",
      key: "nickname",
      width: "10%",
      ellipsis: true,
    },
    {
      title: t("loading_place"),
      dataIndex: "loadingArea",
      key: "loadingArea",
      width: "20%",
      ellipsis: true,
    },
    {
      title: t("unloading_place"),
      dataIndex: "getOff",
      key: "getOff",
      width: "20%",
      ellipsis: true,
    },
    {
      title: t("request_amount"),
      dataIndex: "carrierPaymentAmountTax",
      key: "carrierPaymentAmountTax",
      ellipsis: true,
    },
    {
      title: t("settlement_status"),
      dataIndex: "settlementStatus",
      key: "settlementStatus",
      width: "10%",
      ellipsis: true,
      render: (status) => (
        <div
          className={`${status === `${STATUS_WAITING_PAYMENT}` ? "pending-approval" : status === `${STATUS_DONE}` ? "approved" : ""
            }`}
        >
          {status === `${STATUS_WAITING_PAYMENT}`
            ? t("waiting_for_settlement")
            : status === `${STATUS_DONE}`
              ? t("settlement_completed")
              : t("blocked")}
        </div>
      ),
    },
    {
      title: t("more_information"),
      dataIndex: "id",
      key: "id",
      width: "10%",
      ellipsis: true,
      render: (id) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className="more-information"
          onClick={() => {
            dispatch(
              openModal({
                template: "show-settlement-history-order-detail",
                width: "818px",
                data: {
                  id,
                },
                handleAction: (status: any) => { },
              }),
            );
          }}
        >
          {t("more_information")}
        </a>
      ),
    },
  ];

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const filterOptions = [
    { label: t("all"), value: "all" },
    { label: t("waiting_for_settlement"), value: STATUS_WAITING_PAYMENT },
    { label: t("settlement_completed"), value: STATUS_DONE },
  ];

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    { label: t("name"), value: "carriername" },
    { label: t("nickname"), value: "carriernickname" },
  ];

  return (
    <div className="settlement-management-carrier">
      <NewTable
        title={t("settlement_management")}
        columns={columns}
        allData={arrData}
        metaData={metaData}
        typeOptions={filterOptions}
        params={params}
        setParams={setParams}
        loading={isFetching}
        searchFilterOptions={searchFilterOptions}
        filters={filters}
        setFilters={setFilters}
        showDateFilter={true}
      />
    </div>
  );
}
