import { Select } from "antd";
import { useMemo, useRef, useState } from "react";

const ADD_CUSTOM_OPTION = "직접입력";

const SelectAddOption = ({
  options,
  width = 138,
  placeholder,
  className,
  defaultValue,
  disabled,
  hasCustomOption = false,
  onSelect,
  value,
  setValue,
}: {
  options: { value: string; label: string }[];
  width?: number | string;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  hasCustomOption?: boolean;
  onSelect: (value: any) => void;
  value: string | undefined
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const [mode, setMode] = useState<"multiple" | "tags" | undefined>(undefined);
  const selectRef = useRef<any>(null);

  const listOptions = useMemo(() => {
    if (hasCustomOption) {
      return [
        ...options,
        {
          value: ADD_CUSTOM_OPTION,
          label: ADD_CUSTOM_OPTION,
        },
      ];
    }
    return options;
  }, [hasCustomOption, options]);


  const inAvailableOption = (selectedValue: any) => {
    return options.some((item) => item.value === selectedValue);
  };

  const onChangeHandler = (value: string) => {
    setMode(undefined);
    console.log('onChangeHandler', value)
    let selectedValue = value;
    if (Array.isArray(value) && value.length > 0) {
      selectedValue = value[value.length - 1];
    }

    if (selectedValue === ADD_CUSTOM_OPTION) {
      setMode("tags");
      onSelect(undefined);
      setValue(undefined);
      setTimeout(() => {
        selectRef.current?.focus();
      }, 300);
      return;
    }
    if (
      typeof selectedValue === "string" &&
      (inAvailableOption(selectedValue) || selectedValue !== ADD_CUSTOM_OPTION)
    ) {
      setValue(selectedValue);
      onSelect(selectedValue);
    }
  };

  /* ---------------------------------- 태그 표시 --------------------------------- */
  const tagRenderHandler = (props: any) => {
    const { label } = props;
    console.log(label)
    return <div style={{ marginRight: 3 }}>{label !== ADD_CUSTOM_OPTION ? label : undefined}</div>;
  };

  return (
    <Select
      ref={selectRef}
      style={{ width }}
      mode={mode}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      options={listOptions}
      onChange={onChangeHandler}
      tagRender={tagRenderHandler}
    />
  );
};

export default SelectAddOption;
