import { useState } from 'react';
import "./style.scss";
import icClose from '@/assets/images/ic_close.svg'
import { InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/components/customDialog/dialogSlice';
import { useUpdateDispatchMutation } from '@/pages/DispatchModify/VehicleRegisApi';
import { addCommas } from '@/utils/utils';

type Props = {
    data: any;
    handleAction: () => void;
    onClose: () => void;
}

const UpdateOtherFee = ({ data, handleAction, onClose }: Props) => {
    const [currentFreightCost, setCurrentFreightCost] = useState(data?.freightCost || 0);
    const [currentOtherfee, setCurrentOtherfee] = useState(data?.otherFee || '');
    const [updateDispatch] = useUpdateDispatchMutation();
    const dispatch = useDispatch();

    const handleOtherFee = async () => {
        if (currentFreightCost > data?.freightCost) {
            dispatch(
                openDialog({
                    type: "info",
                    content: "기존 운임보다 높게 수정하실 수 없습니다.",
                }),
            );
            return;
        }

        const bodyData: { freightCost?: string, otherFee?: string } = {};
        if (currentFreightCost !== data?.freightCost) {
            console.log('freightCost 수정!!')
            bodyData['freightCost'] = currentFreightCost;
        }
        if (currentOtherfee !== data?.otherFee || (currentOtherfee !== '' && data?.otherFee === null)) {
            console.log('otherfee 수정!!')
            bodyData['otherFee'] = currentOtherfee;
        }

        try {
            await updateDispatch({ id: data?.id, data: bodyData }).unwrap()

            dispatch(
                openDialog({
                    type: "info",
                    content: "운임수정이 완료되었습니다.",
                }),
            );
            handleAction();

        } catch (error: any) {
            dispatch(
                openDialog({
                    type: "info",
                    content: error?.data?.message || error,
                }),
            );
        }
    }

    return (
        <div className="fare-modification-wrapper">
            <div className="header-wrapper">
                <div className="title">운임 수정</div>
                <img className="ic-close" alt="Close" src={icClose} onClick={onClose} />
            </div>
            <div className="content-wrapper column-wrapper">
                <div className="panel-wrapper">
                    <div className="panel-body">
                        <div className="column-wrapper mt-26">
                            <div className="row-wrapper content-space-between mt-13">
                                <div className="cost-title">운임 수정</div>
                                <InputNumber className="input-value" min={0} value={addCommas(currentFreightCost)} onChange={(value) => setCurrentFreightCost(value || 0)} />
                            </div>
                            <div className="row-wrapper content-space-between mt-13">
                                <div className="cost-title">추가 운임 수정</div>
                                <InputNumber className="input-value" placeholder='입력' min={0} value={addCommas(currentOtherfee)} onChange={(value) => setCurrentOtherfee((value || 0))} />
                            </div>
                            <div className="row-wrapper content-space-between mt-13">
                                <div className="total-title">최종 결제 금액</div>
                                <span className="note-total">{addCommas(currentFreightCost + Number(currentOtherfee))} 원</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-wrapper'>
                <button
                    className="submit-btn"
                    onClick={handleOtherFee}
                >
                    저장
                </button>
            </div>
        </div>
    );
};

export default UpdateOtherFee;