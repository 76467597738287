/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const couponApi = createApi({
  reducerPath: "couponApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetCoupons", "GetCouponsDetail", "GetProviderDetail"],
  endpoints: (builder) => ({
    getCoupons: builder.query({
      query: (data) => ({
        url: `/coupons`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetCoupons"],
    }),
    createCoupon: builder.mutation({
      query: (data) => ({
        url: `/coupons`,
        method: "POST",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCoupons"],
    }),
    expiredCoupon: builder.mutation({
      query: (data) => ({
        url: `/coupons/expired`,
        method: "PATCH",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCoupons"],
    }),
    deleteBulkCoupon: builder.mutation({
      query: (data) => ({
        url: `/coupons/delete/bulk`,
        method: "DELETE",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCoupons"],
    }),
    getCouponsDetail: builder.query({
      query: (data) => ({
        url: `/coupons/${data?.id}/detail`,
        method: "GET",
      }),
      //@ts-ignore
      providesTags: ["GetCouponsDetail"],
    }),
    getProviderDetail: builder.query({
      query: (data) => ({
        url: `coupons/${data.id}/provider`,
        method: "GET",
        params: data?.stringParams,
      }),
      //@ts-ignore
      providesTags: ["GetProviderDetail"],
    }),
  }),
});

export const {
  useGetCouponsQuery,
  useCreateCouponMutation,
  useExpiredCouponMutation,
  useGetCouponsDetailQuery,
  useDeleteBulkCouponMutation,
  useGetProviderDetailQuery,
} = couponApi;
