import { Form, FormItemProps, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import classNames from "classnames";
import React from "react";

interface Props extends FormItemProps {
  nameInput: string;
  form: FormInstance<any>;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export default function InputPhoneKoreanEnd({
  nameInput,
  form,
  placeholder,
  className,
  disabled = false,
  ...props
}: Props) {
  const checkPhoneKorean = (phone: string) => {
    //BK-102
    const regex = /^(010|011|012|013|014|015|016|017|018|019)-[0-9]{4}-[0-9]{4}$/gm;
    return regex.test(phone);
  };
  return (
    <Form.Item
      name={nameInput}
      className={`w-full ${className ? "flex" : ""}`}
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            // if (!getFieldValue(nameInput)) {
            //   return Promise.reject(new Error("정보를 입력해주세요!"));
            // }
            if (checkPhoneKorean(getFieldValue(nameInput))) {
              return Promise.resolve("Valid");
            }
            return Promise.reject(new Error("휴대폰 번호 11자리를 정확히 입력해 주세요."));
          },
        }),
      ]}
      {...props}
    >
      <Input
        placeholder={placeholder}
        className={`${className ? className : "w-full"} h-[50px] rounded-lg `}
        style={{ width: "100%", height: "100%" }}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        disabled={disabled}
        // BK-102
        onChange={(event) => {
          const value = event.target.value;
          const regexp = /(\d{3})(\d{4})(\d{4})/;
          const newValue = value.replace(regexp, "$1-$2-$3");
          form.setFieldValue(nameInput, newValue);
        }}
        maxLength={13}
      />
    </Form.Item>
  );
}
