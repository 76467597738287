import "./style.scss";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { openDialog } from "../customDialog/dialogSlice";
import { useDispatch } from "react-redux";
import { getFileName } from "../../utils/utils";
import { API_LINK, TINYMCE_KEY } from "../../utils/constants";
import icClose from "../../assets/images/ic_close.svg";
import { Radio, RadioChangeEvent, Select } from "antd";
import { ENotice } from "../../pages/NoticeManagement";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

const EditPost = forwardRef((props: any, ref) => {
  const {
    pageType,
    titleInfo,
    contentInfo,
    titleMaxLength = 100,
    contentMaxLength = 5000,
    imageUrlInfo = null,
    files = [],
    options,
    onClose,
    onSubmit,
    data,
    pageActionType,
    faqInfo,
    action,
  } = props;
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [fileUrls, setFileUrls] = useState<{ url: string }[]>(files);
  const [category, setCategory] = useState<number | undefined>(undefined);
  const [content, setContent] = useState("");
  const imgInputRef = useRef();
  const fileInputRef = useRef();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const editorRef = useRef<any>(null);
  const [radioPage, setRadioPage] = useState<ENotice>(ENotice.ALL);
  const disableConfirm = useMemo(() => !title || !content, [title, content, options, pageType]);
  const [countContent, setCountContent] = useState<number>(0);

  useEffect(() => {
    if (pageActionType === "Edit" && data?.type) {
      if (data?.type === "CARRIER") {
        setRadioPage(ENotice.CARRIER);
      } else if (data?.type === "PROVIDER") {
        setRadioPage(ENotice.PROVIDER);
      }
    }
  }, [pageActionType, data?.type]);

  /* ---------------------------- 테스트 입력 영역 사이즈 수정 ---------------------------- */
  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  useEffect(() => {
    let categoriesIds: number[] = [];
    options?.forEach((option: { id: number }) => categoriesIds.push(option.id));
    if (faqInfo?.faqCategoryId && action === "Edit" && categoriesIds.includes(faqInfo.faqCategoryId)) {
      setCategory(faqInfo?.faqCategoryId);
    }
  }, [faqInfo, action]);

  useEffect(resizeTextArea, [content]);

  useEffect(() => {
    if (action !== "Add") {
      setTitle(titleInfo);
    }
  }, [titleInfo, action]);

  useEffect(() => {
    if (action !== "Add") {
      setContent(contentInfo);
    }
  }, [contentInfo, action]);

  useEffect(() => {
    if (action === "Add") {
      setFileUrls([]);
    }
  }, [action]);

  useEffect(() => {
    if (action !== "Add") {
      setImageUrl(imageUrlInfo);
    }
  }, [imageUrlInfo, action]);

  /* -------------------------------- 이미지 변경 처리 ------------------------------- */
  const handleChangeImage = async (event: any) => {
    // do something with event data
    if (event.target.files.length > 0) {
      const fileName = (event.target.files[0].name || "").toUpperCase();
      const isValidType = !fileName.endsWith(".JPG") && !fileName.endsWith(".PNG");
      if (event.target.files[0].size > 30720000 || isValidType) {
        dispatch(
          openDialog({
            type: "info",
            content: t("pls_attach_less_than_30mb_jpg"),
            confirmText: t("dialog_btn_confirm"),
          }),
        );
        event.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          setImageUrl(data.Location);
        });
    }
  };

  /* -------------------------------- 파일 변경 처리 -------------------------------- */
  const handleChangeFile = async (event: any) => {
    if (event.target.files.length > 0) {
      const fileName = (event.target.files[0].name || "").toUpperCase();
      const isInValidType =
        !fileName.endsWith(".HWP") &&
        !fileName.endsWith(".PDF") &&
        !fileName.endsWith(".XLSX") &&
        !fileName.endsWith(".JPG") &&
        !fileName.endsWith(".PNG");
      if (event.target.files[0].size > 30720000 || isInValidType) {
        dispatch(
          openDialog({
            type: "info",
            content: t("pls_attach_less_than_30mb_jpg"),
            confirmText: t("dialog_btn_confirm"),
          }),
        );
        event.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          const arr = [...fileUrls].concat({ url: data.Location });
          setFileUrls(arr);
        });
    }
  };

  const onHandleImageClick = () => {
    if (imgInputRef && imgInputRef.current) {
      //@ts-ignore
      imgInputRef.current.click();
    }
  };

  const onHandleFileClick = () => {
    if (fileInputRef && fileInputRef.current && fileUrls.length < 3) {
      //@ts-ignore
      fileInputRef.current.click();
    }
  };

  /* ---------------------------------- 메모 저장 --------------------------------- */
  const onHandleSubmitClick = async () => {
    if (disableConfirm) {
      return;
    }
    if (pageType === "shipper" && !category) {
      dispatch(
        openDialog({
          type: "info",
          content: "카테고리를 선택하세요.",
          confirmText: t("dialog_btn_confirm"),
        }),
      );
      return;
    }
    const data: any = {
      title,
      content,
    };
    if (imageUrl != null) {
      data.imageUrl = imageUrl;
    }
    if (fileUrls.length > 0) {
      data.fileUrls = fileUrls;
    }
    if (options && category) {
      data.category = category;
    }
    if (pageType === "notice") {
      data.radioVal = radioPage;
    }
    onSubmit(data);
  };

  /* -------------------------------- 메모 저장 취소 -------------------------------- */
  const onHandleCancelClick = () => {
    dispatch(
      openDialog({
        type: "confirm",
        title: t("confirm_cancel_writing"),
        content: t("note_data_not_be_saved"),
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: () => {
          onClose();
        },
      }),
    );
  };

  /* ---------------------------------- 메모 삭제 --------------------------------- */
  const onDeleteFile = (file: string) => {
    dispatch(
      openDialog({
        type: "confirm",
        content: t("confirm_delete_file"),
        actionConfirm: () => {
          const data = [...fileUrls].filter((e) => e.url !== file);
          setFileUrls(data);
        },
      }),
    );
  };

  /* ------------------------------- 입력한 데이타 삭제 ------------------------------- */
  const clearForm = () => {
    setTitle("");
    setFileUrls([]);
    setContent("");
    setCategory(undefined);
  };

  useImperativeHandle(ref, () => ({
    clearForm,
  }));

  const handleChangeCategory = (value: number) => {
    setCategory(value);
  };

  const handleRadioGroupChange = (e: RadioChangeEvent) => {
    setRadioPage(e?.target?.value);
  };

  /* ------------------------------- 사진 업로드 시 처리 ------------------------------ */
  const uploadImageHandler = async (file: any) => {
    return new Promise<string>(async (resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file.blob());
      await fetch(`${API_LINK}assets/upload`, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((data) => {
          resolve(data.Location);
        })
        .catch((err) => reject(err));
    });
  };

  const charCountContent = (editor: TinyMCEEditor) => editor.getContent({ format: "text" }).length;

  /* -------------------------------- 테스트 생성 처리 ------------------------------- */
  const handleInit = (_: any, editor: TinyMCEEditor) => {
    editorRef.current = editor;
    setCountContent(charCountContent(editor));
  };

  /* -------------------------------- 테스트 변경 처리 ------------------------------- */
  const handleEditorChange = (content: string, editor: TinyMCEEditor) => {
    const cCount = charCountContent(editor);
    if (cCount <= contentMaxLength) {
      setContent(content);
      setCountContent(cCount);
    }
  };

  /* ------------------------------- 테스트 undo 처리 ------------------------------ */
  const handleBeforeAddUndo = (evt: any, editor: TinyMCEEditor) => {
    if (charCountContent(editor) > contentMaxLength) {
      evt.preventDefault();
    }
  };

  return (
    <div className="edit-post">
      <div className="header-wrapper">
        {pageType === "notice" && (
          <Radio.Group onChange={handleRadioGroupChange} value={radioPage} style={{ marginBottom: "12px" }}>
            <Radio disabled={pageActionType === "Edit"} value={ENotice.ALL}>
              전체
            </Radio>
            <Radio value={ENotice.PROVIDER}>화주</Radio>
            <Radio value={ENotice.CARRIER}>운송인</Radio>
          </Radio.Group>
        )}
        <input
          className="input-title"
          type="text"
          placeholder={t("please_enter_a_subject")}
          value={title || ""}
          onChange={(e) => {
            setTitle(e.target.value.slice(0, titleMaxLength));
          }}
        />
      </div>
      <div className="image-wrapper row-wrapper">
        <div className="attach-intruction">{t("pls_attach_less_than_30mb_jpg")}</div>
        {/* <div className="base-btn" onClick={onHandleImageClick}>
          <div className="btn-text">{t("image_attached")}</div>
        </div> */}
        <input
          onChange={handleChangeImage}
          multiple={false}
          //@ts-ignore
          ref={imgInputRef}
          type="file"
          accept=".jpg,.png"
          hidden
        />
        <div className="base-btn" onClick={onHandleFileClick}>
          <div className="btn-text">{t("file_attached")}</div>
        </div>
        <input
          onChange={handleChangeFile}
          multiple={false}
          //@ts-ignore
          ref={fileInputRef}
          type="file"
          accept=".hwp,.pdf,.xlsx, .jpg, .png"
          hidden
        />
      </div>
      {fileUrls && fileUrls.length > 0 && (
        <div className="files">
          {fileUrls.map((file, index) => (
            <div className="file" key={index}>
              <a className="file-name">{getFileName(file.url)}</a>
              <div className="delete-file-btn" onClick={() => onDeleteFile(file.url)}>
                <img className="ic-close" alt="Close" src={icClose} />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="editor-wrapper">
        <Editor
          scriptLoading={{
            async: true,
          }}
          apiKey={TINYMCE_KEY}
          plugins="advlist autolink lists link image charmap preview
                            searchreplace visualblocks code fullscreen 
                               paste code help table wordcount"
          ref={editorRef}
          value={content}
          init={{
            min_height: 650,
            toolbar:
              " formatselect | bold italic backcolor forecolor fontfamily fontsize | \
                      alignleft aligncenter alignright alignjustify image | \
                      bullist numlist outdent indent |  table tablecellbackgroundcolor tablecellbordercolor ",
            images_upload_handler: uploadImageHandler,
            language: "ko_KR",
          }}
          onInit={handleInit}
          onEditorChange={handleEditorChange}
          onBeforeAddUndo={handleBeforeAddUndo}
        />
      </div>

      {/* <div className="content-wrapper">{imageUrl && <img className="attach-image" src={imageUrl} />}</div> */}
      {options && options?.length > 0 && pageType === "shipper" && (
        <Select
          options={options}
          className="options"
          placeholder={t("all")}
          value={category}
          onChange={handleChangeCategory}
        />
      )}
      <div className="footer-wrapper row-wrapper">
        <div className={`base-btn ${disableConfirm ? "disabled" : ""}`} onClick={onHandleSubmitClick}>
          <div className="btn-text">{t("completed")}</div>
        </div>
        <div className="base-btn ml-10" onClick={onHandleCancelClick}>
          <div className="btn-text cancel-text">{t("write_cancel")}</div>
        </div>
      </div>
    </div>
  );
});

export default EditPost;
