import { Button, Input, Pagination, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import EditPost from "../../components/editPost";
import PostDetail from "../../components/postDetail";
import { DATE_FORMAT, DATE_FORMAT_DOT, DEFAULT_PAGE_SIZE, PAGE_SIZE, PER_PAGE } from "../../utils/constants";

import { useCreatePopupMutation, useDeletePopupMutation, useGetPopupQuery, useUpdatePopupMutation } from "./popupApi";
import "./style.scss";
import { debounce, truncate } from "lodash";
import KitButton, { ButtonType } from "../../components/Button/Button";
import KitTableCustom from "../../components/kitTableCustom";
import { TableRowSelection } from "antd/lib/table/interface";
import { ECouponStatus } from "../CouponManagement/components/detail";
import FormUpload from "../../components/formUpload";

export enum ENotice {
  ALL = "ALL",
  PROVIDER = "PROVIDER",
  CARRIER = "CARRIER",
}
export enum EPopupStatus {
  ALL = "ALL",
  USE = "USE",
  NOT_USE = "NOT_USE",
}

export enum ActionType {
  EDIT = "edit",
  CREATE = "create",
}

export default function PopupManagement() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [noticeInfo, setNoticeInfo] = useState<any>();
  const [noticeId, setNoticeId] = useState<any>();
  const [allData, setAllData] = useState<any>();
  const [metaData, setMetaData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const initialFilters = {
    page: 1,
    order: "desc",
    orderBy: "createdAt",
    keyword: "",
    // searchAnd: [
    //   {
    //     target: ENotice.CARRIER,
    //   },
    //   {
    //     target: ENotice.PROVIDER,
    //   },
    //   {
    //     status: EPopupStatus.USE,
    //   },
    //   {
    //     status: EPopupStatus.NOT_USE,
    //   },
    // ],
  };
  const [action, setAction] = useState("List"); //List - Create - Edit - View
  const [deleteNotifications] = useDeletePopupMutation();
  const [createNotifications] = useCreatePopupMutation();
  const [updateNotifications] = useUpdatePopupMutation();
  const [filters, setFilters] = useState<any>(initialFilters);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [headerSelectOption, setHeaderSelectOption] = useState("전체");
  const [actionType, setActionType] = useState<ActionType>(ActionType.CREATE);

  const [params, setParams] = useState({
    keyword: "",
    page: 1,
    limit: PAGE_SIZE,
    target: ENotice.ALL,
    status: EPopupStatus.ALL,
  });

  const tableFilters = useMemo(() => {
    const cloneFilters = { ...filters };

    let formatSearchAnd = cloneFilters.searchAnd || [];

    // if (params.target !== ENotice.ALL) {
    //   formatSearchAnd = [...formatSearchAnd, `{"target": "${params.target}"}`];
    // }

    if (params.status !== EPopupStatus.ALL) {
      formatSearchAnd = [...formatSearchAnd, `{"status": "${params.status}"}`];
    }

    cloneFilters.searchAnd = formatSearchAnd || [];

    const query = {
      ...cloneFilters,
      ...params,
    };

    return query;
  }, [params, filters]);

  const { data, error } = useGetPopupQuery(tableFilters, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    if (data && data?.data) {
      let dataMap = data?.data.map((item: any, index: number) => ({
        ...item,
        id: item.id,
        number: index + 1 + ((filters.page || 1) - 1) * 10,
        noticeDate: getDateFormat(item.createdAt),
      }));
      setAllData(dataMap);
      setMetaData(data?.meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /* ---------------------------------- 상태 변경 --------------------------------- */
  const onPopupStatusSwitch = (record: any) => {
    const { createdAt, id, startDate, endDate, imagePc, imageSp, status, ...rest } = record;
    const cloneBody: any = {
      ...rest,
    };

    cloneBody["startDate"] = moment(startDate).format(DATE_FORMAT);
    cloneBody["endDate"] = moment(endDate).format(DATE_FORMAT);
    cloneBody["imagePc"] = JSON.parse(imagePc);
    cloneBody["imageSp"] = JSON.parse(imageSp);
    cloneBody["status"] = record?.status === ECouponStatus.USE ? ECouponStatus.NOT_USE : ECouponStatus.USE;

    dispatch(
      openDialog({
        type: "confirm",
        content: `‘${record?.status === ECouponStatus.USE ? "미사용" : "사용"}’ 상태로 전환 하시겠습니까?`,
        confirmText: "닫기",
        closeText: "확인",
        actionCancel: async () => {
          try {
            await updateNotifications({ id, body: cloneBody }).unwrap();
          } catch (error) {
            console.error(error);
          }
        },
      }),
    );
  };

  /* -------------------------------- 수정 화면 열기 -------------------------------- */
  const onPopupEdit = (record: DataType) => {
    dispatch(
      openModal({
        template: "show-popup-creation-modal",
        width: "448px",
        data: {
          type: "edit",
          title: "팝업 수정 ",
          data: record,
        },
        handleAction: () => {
          dispatch(
            openDialog({
              type: "confirm",
              content: `팝업을 수정 하시겠습니까?`,
              confirmText: "닫기",
              closeText: "닫기",
              actionConfirm: async () => {},
            }),
          );
        },
      }),
    );
  };

  /* -------------------------------- 생성 화면 열기 -------------------------------- */
  const onPopupCreation = () => {
    dispatch(
      openModal({
        template: "show-popup-creation-modal",
        width: "448px",
        data: {
          type: "create",
          title: "팝업 등록",
        },
        handleAction: () => {
          dispatch(
            openDialog({
              type: "confirm",
              content: `팝업을 등록 하시겠습니까? `,
              confirmText: "확인",
              closeText: "확인",
              actionConfirm: async () => {},
            }),
          );
        },
      }),
    );
  };

  /* -------------------------------- 삭제 화면 열기 -------------------------------- */
  const onPopupDeletion = () => {
    dispatch(
      openDialog({
        type: "confirm-delete",
        title: "선택한 팝업을 삭제하시겠습니까?",
        content: "더이상 유저 화면에서 노출되지 않습니다.",
        confirmText: "닫기",
        closeText: "팝업 삭제",
        actionCancel: async () => {
          try {
            const payload = {
              ids: selectedRowKeys,
            };
            await deleteNotifications(payload).unwrap();
            setSelectedRecords([]);
            setSelectedRowKeys([]);
            setParams({ ...params, page: 1 });
          } catch (error) {
            console.log(error);
          }
        },
      }),
    );
  };

  /* ------------------------------- 날짜 양식 가져오기 ------------------------------- */
  const getDateFormat = (dateStr: string, format: string = "YYYY.MM.DD") => {
    const date = moment(dateStr);
    if (date.isValid()) {
      return date.format(format);
    }
    return "";
  };

  interface DataType {
    createdAt: string;
    endDate: string;
    id: number;
    imagePc: { url: string; name: string };
    imageSp: { url: string; name: string };
    showType: string;
    startDate: string;
    status: string;
    target: string;
    targetLinkPC: string;
    targetLinkSP: string;
    title: string;
    updatedAt: string;
  }

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "구분",
    //   dataIndex: "target",
    //   key: "target",
    //   align: "center",
    //   width: "10%",
    //   render: (value: string) => {
    //     return value === "CARRIER" ? "운송인" : "화주";
    //   },
    // },
    {
      title: "팝업명",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "15%",
    },
    {
      title: "팝업 기간",
      dataIndex: "date",
      key: "date",
      width: "35%",
      align: "center",
      render: (value, record) => (
        <div>
          {moment(value).isValid() && (
            <>
              <span>{moment(record?.startDate).format(DATE_FORMAT_DOT)}</span> ~{" "}
              <span>{moment(record?.endDate).format(DATE_FORMAT_DOT)}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: "등록 일시",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "30%",
      align: "center",
      render: (value) => (
        <div>
          {moment(value).isValid() && (
            <>
              <span>{moment(value).format(DATE_FORMAT_DOT)}</span>
              <br />
              <span>{moment(value).format("hh:mm A")}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: "관리",
      dataIndex: "id",
      key: "id",
      width: "20%",
      align: "center",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id, record) => (
        <div className="popup-table-col-action">
          {record?.status === EPopupStatus.USE && (
            <KitButton btnType={ButtonType.SUCCESS} onClick={() => onPopupStatusSwitch(record)}>
              사용
            </KitButton>
          )}
          {record?.status === EPopupStatus.NOT_USE && (
            <KitButton btnType={ButtonType.IDLE} onClick={() => onPopupStatusSwitch(record)}>
              미사용
            </KitButton>
          )}

          <KitButton btnType={ButtonType.DARK} onClick={() => onPopupEdit(record)}>
            수정
          </KitButton>
        </div>
      ),
    },
  ];

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (res: any, msgSuccess: string, onComplete: any = null) => {
    const errMsg = res?.error?.data?.message;
    if (!errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: msgSuccess,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            if (onComplete) {
              onComplete(res);
            } else {
              if (action !== "List") {
                setAction("List");
                setNoticeId(null);
              }
            }
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
        }),
      );
    }
  };

  const onPost = () => {
    setAction("Add");
  };

  /* ----------------------------------- 저장 ----------------------------------- */
  const onHandleSubmit = async (rest: any) => {
    const { radioVal, ...data } = rest;

    if (isLoading) {
      return;
    } else if (action === "Add") {
      setIsLoading(true);
      if (radioVal === ENotice.ALL) {
        const res = await createNotifications({
          ...data,
          type: ENotice.PROVIDER,
        }); //@ts-ignore
        await createNotifications({
          ...data,
          type: ENotice.CARRIER,
        }); //@ts-ignore
        showResult(res, t("writing_is_complete"), (data) => {
          setNoticeId(data?.data?.id);
          setAction("View");
        });
        setIsLoading(false);
      } else {
        const res = await createNotifications({ ...data, type: radioVal }); //@ts-ignore
        showResult(res, t("writing_is_complete"), (data) => {
          setNoticeId(data?.data?.id);
          setAction("View");
        });
        setIsLoading(false);
      }
    } else if (action === "Edit") {
      setIsLoading(true);
      const res = await updateNotifications({
        ...data,
        id: noticeInfo?.id,
        type: radioVal === ENotice.PROVIDER ? ENotice.PROVIDER : ENotice.CARRIER,
      }); //@ts-ignore
      showResult(res, t("it_is_changed"), (data) => {
        setNoticeId(data?.data?.id);
        setAction("View");
      });
      setIsLoading(false);
    }
  };

  const couponExpireOption = [
    { label: "전체", value: EPopupStatus.ALL },
    { label: "사용", value: EPopupStatus.USE },
    { label: "미사용", value: EPopupStatus.NOT_USE },
  ];

  const onSelectChange = (newSelectedRowKeys: any[], selectedRecords: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRecords(selectedRecords);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  /* ---------------------------------- 타입 선택 --------------------------------- */
  // const typeOptions = [
  //   {
  //     label: "전체",
  //     value: ENotice.ALL,
  //     key: ENotice.ALL,
  //   },
  //   {
  //     label: "화주   ",
  //     value: ENotice.PROVIDER,
  //     key: ENotice.PROVIDER,
  //   },
  //   {
  //     label: "운송인",
  //     value: ENotice.CARRIER,
  //     key: ENotice.CARRIER,
  //   },
  // ];

  return (
    <div className="popup-management">
      <div className="body-content">
        <KitTableCustom
          tableTitle="팝업 관리 "
          // typeOptions={typeOptions}
          inputPlaceholder="팝업명을 검색해주세요"
          headerAction={
            <Select
              className="select-popup-expire"
              value={headerSelectOption}
              options={couponExpireOption}
              onChange={(e: any) => {
                setHeaderSelectOption(e);
                setParams?.({
                  ...params,
                  page: 1,
                  status: e,
                });
              }}
              defaultActiveFirstOption
            />
          }
          params={params}
          setParams={setParams}
          filters={filters}
          setFilters={setFilters}
          dataSource={allData}
          pagination={false}
          columns={columns}
          rowSelection={rowSelection}
          rowKey="id"
          metaData={metaData}
          onTabChangeCallback={(tab: string) => {
            setSelectedRecords([]);
            setSelectedRowKeys([]);
          }}
          footerAction={
            <div className="popup-action">
              <KitButton
                btnType={selectedRowKeys?.length > 0 ? ButtonType.FAILED : ButtonType.DISABLED}
                onClick={onPopupDeletion}
              >
                삭제
              </KitButton>
              <KitButton btnType={ButtonType.PRIMARY} onClick={onPopupCreation}>
                팝업 등록
              </KitButton>
            </div>
          }
        />
      </div>
    </div>
  );
}
