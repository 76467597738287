import { Avatar } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "../menuItem";

export default function LeftMenu() {
  const { t } = useTranslation();
  const menuList = [
    {
      name: t("member_management"),
      url: "/member-management",
      subMenu: [
        { name: t("general_member"), url: "/shipper" },
        { name: t("business_shipper"), url: "/business_shipper" },
        { name: t("paylater_shipper"), url: "/paylater_shipper" },
        { name: t("carrier"), url: "/carrier" },
      ],
    },
    {
      name: t("dispatch_management"),
      url: "/dispatch-management",
      subMenu: [],
    },
    {
      name: t("matching_management"),
      url: "/matching-management",
      subMenu: [],
    },
    {
      name: t("settlement_management"),
      url: "/settlement-management",
      subMenu: [
        { name: t("carrier_issuance_request"), url: "/carrier-request" },
        { name: t("carrier_settlement"), url: "/carrier-settlement" },
        { name: t("sales_graph"), url: "/sales-graph" },
        { name: t("carrier_settlement_history"), url: "/carrier-history" },
        { name: t("shipper_settlement_history"), url: "/shipper-history" },
      ],
    },
    {
      name: t("coupon_point_management"),
      url: "/coupon-management",
      subMenu: [
        { name: t("coupon_management"), url: "/coupon" },
        { name: t("point_usage_history"), url: "/point" },
      ],
    },
    // {
    //   name: t("notice_management"),
    //   url: "/notice-management",
    //   subMenu: [
    //     { name: t("shipper"), url: "/shipper" },
    //     { name: t("carrier"), url: "/carrier" },
    //   ],
    // },
    {
      name: t("notice_management"),
      url: "/notice-management",
      subMenu: [{ name: "공지사항", url: "" }],
    },
    {
      name: t("faq_management"),
      url: "/faq-management",
      subMenu: [
        { name: t("shipper"), url: "/shipper" },
        { name: t("carrier"), url: "/carrier" },
      ],
    },
    {
      name: t("ads_management"),
      url: "",
      subMenu: [
        { name: t("banner_video_management"), url: "/ads-management" },
        { name: t("company_management"), url: "/company-management" },
        { name: "팝업 관리", url: "/popup-management" },
      ],
    },
    {
      name: t("freight_cost"),
      url: "/freight-cost",
      subMenu: [],
    },
    {
      name: t("alarm_talk_management"),
      url: "/alarm-talk-management",
      subMenu: [],
    },
    {
      name: t("Google Analytics"),
      url: "https://analytics.google.com",
    },
    {
      name: t("운송인 게시판 관리"),
      url: "/carrier/posts",
      subMenu: [],
    },
  ];
  return (
    <div className="left-menu">
      <div className="avatar-group">
        <div className="user-name">{t("manager_name")}</div>
      </div>
      {menuList.map((it) => {
        return <MenuItem key={it.url} menuName={it.name} url={it.url} subMenu={it.subMenu} />;
      })}
    </div>
  );
}
