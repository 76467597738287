import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import bag from '@/assets/images/bag.svg'
import icClose from "@/assets/images/ic_close.svg";
import { Dispatch } from 'react';

type Props = {
    handleSubmit: () => void;
    deleteMemo: () => void;
    isLoading: boolean;
    onClose: () => void;
    memoText: string;
    setMemoText: Dispatch<any>;
}
const Memos = ({ handleSubmit, deleteMemo, onClose, memoText, setMemoText, isLoading }: Props) => {
    return (
        <div className="update-memo-wrapper">
            <div className="header-wrapper">
                <div className="title">메모</div>
                <div className="close-btn" onClick={onClose}>
                    <img className="ic-close" alt="Close" src={icClose} />
                </div>
            </div>
            <div className="content-wrapper">
                <div className="content">
                    <TextArea
                        autoFocus={true}
                        className="direct-input"
                        allowClear
                        onChange={(e) => setMemoText(e.target.value)}
                        value={memoText}
                        maxLength={254}
                    />
                </div>
                <div className="action">
                    <Button
                        className={`submit ${memoText == "" ? "disable" : ""}`}
                        loading={isLoading}
                        onClick={handleSubmit}
                    >
                        저장
                    </Button>
                    <img
                        className={`delete ${memoText == "" ? "disable" : ""}`}
                        alt="memo"
                        src={bag}
                        onClick={deleteMemo}
                    />
                </div>
            </div>
        </div>
    );
};

export default Memos;