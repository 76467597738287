import { Input, Pagination, Spin, Table, TableProps } from "antd";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import icSearch from "../../assets/images/ic_search.svg";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE, PER_PAGE } from "../../utils/constants";
import debounce from "lodash/debounce";
import { TableRowSelection } from "antd/lib/table/interface";
import { CheckOutlined } from "@ant-design/icons";

interface IKitTableCustomProps extends Partial<TableProps<any>> {
  className?: string;
  metaData?: {
    total?: number;
  } & any;
  footerAction?: React.ReactNode | React.ReactElement;
  headerAction?: React.ReactNode | React.ReactElement;
  typeOptions: {
    label: string;
    value: string;
    key: string;
  }[];
  tableTitle?: string;
  setParams?: (params: any) => void;
  params?: any;
  filters?: any;
  setFilter?: (filters: any) => void;
  maxLengthInput?: number;
  rowSelection?: TableRowSelection<any>;
  onTabChangeCallback?: (tab: string) => void;
  inputPlaceholder?: string;
  isCustomTab?: boolean;
  isShowSearch?: boolean;
  scroll?: any;
}

const KitTableCustom = forwardRef((props: Partial<IKitTableCustomProps> | any, ref) => {
  const {
    className,
    columns,
    dataSource,
    pagination,
    filters,
    metaData,
    footerAction,
    headerAction,
    typeOptions,
    tableTitle,
    setParams,
    params,
    rowSelection,
    onTabChangeCallback,
    maxLengthInput = 20,
    inputPlaceholder,
    isCustomTab = false,
    isShowSearch = true,
    scroll = { x: 1000 },
    ...rest
  } = props;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>("");

  const resetSearchInput = () => {
    setSearchInput("");
  };

  useImperativeHandle(ref, () => ({
    resetSearchInput,
  }));

  const debounceSearch = debounce((e) => {
    setParams?.({
      ...params,
      page: 1,
      keyword: e.target.value,
    });
  }, 500);

  return (
    <div className={`ktc ${className ? className : ""}`}>
      <div className="ktc-header">
        {tableTitle}
        {isShowSearch && (
          <div className="ktc-header-searchbar">
            <Input
              allowClear
              size="large"
              placeholder={inputPlaceholder ? inputPlaceholder : t("please_enter_a_search_term")}
              prefix={<img alt="Search Text" src={icSearch} />}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                debounceSearch(e);
              }}
              maxLength={maxLengthInput}
            />
          </div>
        )}
      </div>
      <div className="ktc-filter">
        <div className="ktc-filter-wrapper">
          {!!typeOptions &&
            typeOptions.map((opt: any, index: number) => {
              return (
                <div
                  key={opt.value}
                  className={`filter-item ${
                    params?.target === opt.value && isCustomTab
                      ? "active-custom"
                      : params?.target === opt.value && !isCustomTab
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    onTabChangeCallback?.(opt.value);
                    setParams?.({
                      ...params,
                      page: 1,
                      target: opt.value,
                    });
                  }}
                >
                  {params?.target === opt.value && isCustomTab && <CheckOutlined className="icon-active-custom" />}{" "}
                  {opt.label}
                </div>
              );
            })}
        </div>
        {headerAction ? headerAction : <div />}
      </div>
      <Table
        ref={ref}
        locale={{ emptyText: t("no_data") }}
        {...rest}
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        className="ktc-table"
        footer={() => (footerAction ? footerAction : null)}
        scroll={scroll}
        pagination={
          metaData?.total >= PAGE_SIZE
            ? {
                position: ["bottomCenter"],
                current: params.page || 1,
                pageSize: PAGE_SIZE,
                total: metaData?.total || 0,
                showSizeChanger: false,
                onChange: (page: number) => {
                  setParams?.({ ...params, page: page });
                },
              }
            : false
        }
      />
    </div>
  );
});

export default KitTableCustom;
