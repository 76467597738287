import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const carrierPostApi = createApi({
  reducerPath: "carrierPostApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetCarrierPosts", "GetCommentDetail", "GetCarrierPostDetail", "GetCommentsByPost"],
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: (params) => ({
        url: `/posts`,
        method: "GET",
        params,
      }),
      providesTags: ["GetCarrierPosts"],
    }),
    getPostDetail: builder.query({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "GET",
      }),
      providesTags: (result) => {
        return [{ type: "GetCarrierPostDetail", id: result?.id }];
      },
    }),
    updatePostStatus: builder.mutation({
      query: (body) => ({
        url: `/posts/${body.id}/status`,
        method: "PATCH",
        body: {
          status: body.status,
        },
      }),
      invalidatesTags: (result) => {
        return [{ type: "GetCarrierPostDetail", id: result?.id }];
      },
    }),
    getCommentsByPost: builder.query({
      query: ({ postId, params }) => ({
        url: `/posts/${postId}/comments`,
        method: "GET",
        params,
      }),
    }),
    getCommentDetail: builder.query({
      query: (params) => ({
        url: `/posts/${params.postId}/comments/${params.commentId}`,
        method: "GET",
      }),
      providesTags: (result) => {
        return [{ type: "GetCommentDetail", id: result?.id }];
      },
    }),
    updateCommentStatus: builder.mutation({
      query: (body) => ({
        url: `/posts/${body.postId}/comment/${body.commentId}/status`,
        method: "PATCH",
        body: {
          status: body.status,
        },
      }),
      invalidatesTags: (result) => {
        return [{ type: "GetCommentDetail", id: result?.id }];
      },
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetPostDetailQuery,
  useGetCommentsByPostQuery,
  useGetCommentDetailQuery,
  useUpdatePostStatusMutation,
  useUpdateCommentStatusMutation,
} = carrierPostApi;
