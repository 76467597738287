/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBlocker } from "./useBlocker";
import { useDispatch } from "react-redux";
import { openDialog } from "@/components/customDialog/dialogSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUserLogin } from "@/store/selector/RootSelector";

export function useCallbackPrompt(when: boolean) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const loginStatus = useSelector(getUserLogin);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      // in if condition we are checking next location and current location are equals or not
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location?.pathname + lastLocation.location?.search);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  useEffect(() => {
    if (!!showPrompt) {
      dispatch(
        openDialog({
          type: "confirm",
          title: t("페이지를 이동하시겠습니까?"),
          content: t("입력중인 정보는 저장되지 않습니다."),
          isContentBold: true,
          confirmText: t("확인"),
          closeText: "취소",
          maskClosable: false,
          actionCancel: () => {
            typeof cancelNavigation === "function" && cancelNavigation();
          },
          actionConfirm: () => {
            if (location.pathname === "/myInfo/dispatch-history") {
              loginStatus
                ? navigate(location?.pathname + location?.search)
                : navigate("/auth");
            } else {
              typeof confirmNavigation === "function" && confirmNavigation?.();
            }
          },
        })
      );
    }
  }, [showPrompt]);
}
