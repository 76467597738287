import { Key, useEffect, useMemo, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Button, Input, Pagination, Select, Table } from "antd";
import { useDeleteBulkCouponMutation, useExpiredCouponMutation, useGetCouponsQuery } from "./couponApi";
import { ColumnsType } from "antd/lib/table";
import CreateCoupon from "../../components/createCoupon";
import { checkCouponExpired, getDateFormat } from "../../utils/utils";
import DetailCoupon from "../../components/detailCoupon";
import { useDispatch } from "react-redux";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { closeModal } from "../../components/customModal/modalSlice";
import NewTable from "../../components/NewTable";
import { PER_PAGE } from "../../utils/constants";
import queryString from "query-string";
import { getDateFromData, getDateToData } from "../../utils/commonFnc";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const pages = [
  { label: "10개씩 보기", value: 10 },
  { label: "20개씩 보기", value: 20 },
];

type CouponStatusType = "ACTIVE" | "EXPIRED";

export const CouponManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [detail, setDetail] = useState();
  const [action, setAction] = useState("List"); //List - Create - Detail
  const [status, setStatus] = useState<CouponStatusType | undefined>(undefined);
  // const [search, setSearch] = useState("");
  const [filters, setFilters] = useState<string[]>(["name"]);
  const [searchParams, setSearchParams] = useState<string>("");
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    startDate: null,
    status,
    order: "DESC",
    // name: search,
  });

  const [metaData, setMetaData] = useState<any>({});
  const [selected, setSelected] = useState<Key[]>([]);
  const { data, isFetching } = useGetCouponsQuery(searchParams, {
    refetchOnMountOrArgChange: false,
    skip: false,
  });
  const navigate = useNavigate();
  const [expiredCoupon] = useExpiredCouponMutation();
  const [deleteBulkCoupon] = useDeleteBulkCouponMutation();

  const statuses = useMemo(
    () => [
      { label: t("issued"), value: "ACTIVE" },
      { label: t("expired"), value: "EXPIRED" },
    ],
    [],
  );
  useEffect(() => {
    if (data && data?.data) {
      setAllData(data.data);
      setMetaData(data?.meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onExpiredImmediatelyCoupon = () => {
    if (selected.length === 0) {
      dispatch(
        openDialog({
          type: "info",
          content: t("no_values_selected"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
      return;
    }

    /* -------------------------------- 활동 상태 확인 -------------------------------- */
    const hasSomeActiveCoupon = allData
      .filter((coupon: any) => selected.includes(coupon.id))
      .some((coupon: any) => coupon.status === "ACTIVE");

    dispatch(
      openDialog({
        type: "confirm",
        title: hasSomeActiveCoupon ? (
          <div>
            발행 상태의 쿠폰이 포함되어 있습니다.
            <br /> 모두 삭제하시겠습니까?
          </div>
        ) : (
          "선택된 쿠폰을 삭제하시겠습니까?"
        ),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("delete"),
        actionCancel: async () => {
          const res = await deleteBulkCoupon({
            couponIds: selected,
          });
          setSelected([]);
          showResult(res, "삭제 되었습니다.");
        },
      }),
    );
  };

  const onExpiredCoupon = () => {
    if (selected.length === 0) {
      dispatch(
        openDialog({
          type: "info",
          content: t("no_values_selected"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
      return;
    }

    dispatch(
      openDialog({
        type: "confirm",
        width: 500,
        title: t("expired_coupon_confirm"),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          const res = await expiredCoupon({
            couponIds: selected,
          });
          setSelected([]);
          showResult(res, "해당 쿠폰이 만료 되었습니다.");
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (res: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: res?.error?.data?.message ?? msgSuccess,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          dispatch(closeModal());
        },
      }),
    );
  };

  // const handleChangeLimit = (value: number) => {
  //   setLimit(value);
  // };
  const handleChangeStatus = (value: CouponStatusType) => {
    setStatus(value);
  };

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: params.limit,
    };

    if (params.startDate) {
      _params.couponDate = getDateFromData(params.startDate);
    }

    let filtersQuery = "";
    if (params.search) {
      _params.keyword = params.search;
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }

    const paramQuery = queryString.stringify(_params);

    return `${paramQuery}&${filtersQuery}`;
  };

  useEffect(() => {
    setSearchParams(getSearchParams(params));
  }, [params]);

  /* -------------------------------- 쿠폰의 상태 맵핑 ------------------------------- */
  const mappingCouponStatus = (value: string, record: any) => {
    console.log(moment(record?.startDate)?.local().isAfter(moment()));

    if (record?.expiredByAdmin) {
      return "강제 만료";
    } else if (record?.status === "EXPIRED") {
      return "기간 만료";
    } else if (moment(record?.startDate)?.local().isAfter(moment())) {
      return "사용 대기";
    } else if (
      moment()?.local()?.isSameOrAfter(moment(record?.startDate)) &&
      moment()?.local()?.isSameOrBefore(moment(record?.endDate))
    ) {
      return "사용 가능 ";
    } else {
      return "";
    }
  };

  const newColumns: any = [
    {
      title: "지급 대상",
      dataIndex: "isApplyAll",
      key: "isApplyAll",
      align: "center",
      render(value: any) {
        return value ? "전체" : "지정 회원";
      },
    },
    {
      title: "쿠폰 이름",
      dataIndex: "name",
      key: "name",
      align: "center",
      render(value: any, record: any) {
        return (
          <div
            className="coupon-name"
            onClick={() => {
              navigate(`/coupon-management/coupon/detail/${record?.id}`);
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: "할인율",
      dataIndex: "value",
      key: "value",
      align: "center",
      render(value: number) {
        return `${value} %`;
      },
    },
    {
      title: "사용 수",
      dataIndex: "usedTotal",
      key: "usedTotal",
      align: "center",
      render(value: any, record: any) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{value} 개</span>
            <span>
              [{Number.parseFloat(record?.total !== 0 ? (value / record?.total).toString() : "0").toFixed(2)} %]
            </span>
          </div>
        );
      },
    },

    {
      title: "발행 수",
      dataIndex: "total",
      key: "total",
      align: "center",
      render(value: number) {
        return `${value} 개`;
      },
    },
    {
      width: 200,
      title: "쿠폰 사용 기간",
      dataIndex: "date",
      key: "date",
      align: "center",
      render(_: any, record: any) {
        return <div>{`${getDateFormat(record.startDate)} ~  ${getDateFormat(record.endDate)}`}</div>;
      },
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      render(value: any, record: any) {
        console.log(mappingCouponStatus(value, record));

        return mappingCouponStatus(value, record);
      },
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: t("payment_range"),
      dataIndex: "isApplyAll",
      key: "isApplyAll",
      align: "center",
      width: "15%",
      render(value) {
        return <div>{value ? t("payment_all") : t("payment_designated")}</div>;
      },
    },
    {
      title: t("coupon_name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "20%",
      render(value, record) {
        return (
          <div
            className="coupon-name"
            onClick={() => {
              navigate(`/coupon-management/coupon/detail/${record?.id}`);
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: t("benefit"),
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "15%",
      render(value, record) {
        return <div>{`${value}${record.type === "RATE" ? "%" : ""} ${t("discount")}`}</div>;
      },
    },
    {
      title: t("period_of_use"),
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      width: "30%",
      render(_, record) {
        return <div>{`${getDateFormat(record.startDate)} - ${getDateFormat(record.endDate)}`}</div>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "10%",
      render(_, coupon) {
        const isExpired = checkCouponExpired(coupon);

        return (
          <div className={`coupon-status ${isExpired ? "expired" : ""}`}>{isExpired ? t("expired") : t("issued")}</div>
        );
      },
    },
  ];

  const onCouponPayment = () => {
    setAction("Create");
  };

  const onBackToList = () => {
    setAction("List");
  };

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    {
      label: "쿠폰명",
      value: "name",
    },
  ];

  // end update

  // switch (action) {
  // case "Create":
  //   return <CreateCoupon onBack={onBackToList} />;
  // case "Detail":
  //   return <DetailCoupon data={detail} goBack={onBackToList} />;
  // default:
  return (
    <div className="coupon-management point-management-custom">
      <NewTable
        title={"쿠폰 관리"}
        formAction={
          <div className="btn-wrap">
            <div className="base-btn btn-back" onClick={() => navigate("/coupon-management/coupon/create")}>
              쿠폰등록
            </div>
          </div>
        }
        customRangPickerLabel={{ from: "검색 기준일" }}
        isSingleDateFilter={true}
        columns={newColumns}
        metaData={metaData}
        allData={allData}
        params={params}
        setParams={setParams}
        loading={isFetching}
        searchFilterOptions={searchFilterOptions}
        filters={filters}
        setFilters={setFilters}
        showDateFilter={true}
      />
    </div>
    // <div className="coupon-management">
    //   <div className="top-content">
    //     <div className="title">{t("payment_status")}</div>
    //     <div className="find-coupon">
    //       <div className="title">{t("find_a_coupon")}</div>
    //       <div className="base-btn btn-payment" onClick={onCouponPayment}>
    //         {t("coupon_payment")}
    //       </div>
    //     </div>
    //     <div className="line" />
    //     <div className="search-item">
    //       <div className="search-title">{t("status")}</div>
    //       <Select className="selects" options={statuses} value={status} onChange={handleChangeStatus} />
    //     </div>
    //     <div className="search-item search-name">
    //       <div className="search-title">{t("coupon_name")}</div>
    //       <Input
    //         className="search-input"
    //         placeholder={t("please_enter_coupon_name")}
    //         value={search}
    //         maxLength={15}
    //         onChange={(e) => setSearch(e.target.value)}
    //       />
    //     </div>
    //     <div>
    //       <div className="base-btn btn-search" onClick={onSearch}>
    //         {t("search")}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="payment-status">{t("coupon_payment_status")}</div>
    //   <div className="page">
    //     <div className="total">{`총 ${total ?? 0}개`}</div>
    //     <Select className="selects" options={pages} value={limit} onChange={handleChangeLimit} />
    //   </div>
    //   <Table
    //     columns={columns}
    //     pagination={false}
    //     dataSource={allData}
    //     locale={{ emptyText: t("no_data") }}
    //     rowSelection={{
    //       type: "checkbox",
    //       selectedRowKeys: selected,
    //       onChange: (selectedRowKeys) => {
    //         setSelected(selectedRowKeys);
    //       },
    //     }}
    //     rowKey="id"
    //     className="table-coupon"
    //   />
    //   <div className="btn-group">
    //     <div className="base-btn btn-expires" onClick={onExpiredCoupon}>
    //       {t("expire_btn")}
    //     </div>
    //     <div className="base-btn btn-expires-immediately" onClick={onExpiredImmediatelyCoupon}>
    //       삭제
    //     </div>
    //   </div>
    //   <div className="pagination">
    //     <Pagination
    //       current={page}
    //       defaultCurrent={1}
    //       defaultPageSize={10}
    //       pageSize={limit}
    //       total={total}
    //       showSizeChanger={false}
    //       onChange={onChangePage}
    //     />
    //   </div>
    // </div>
  );
  // }
};
