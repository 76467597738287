import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { DATE_FORMAT_DOT, PER_PAGE } from "../../utils/constants";
import { useGetOrdersQuery } from "../DispatchManagement/dispatchApi";
import { formatNumberToCurrency, formatPhoneNumber, getDateFormat } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getDateFromData, getDateToData, wait } from "../../utils/commonFnc";
import NewTable from "../../components/NewTable";
import moment from "moment-timezone";

export default function SettlementCarrierRequest() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState({
    page: 1,
    search: "",
    limit: PER_PAGE,
    status: "all",
    startDate: null,
    order: "desc",
    endDate: null,
  });

  const [filters, setFilters] = useState<string[]>(["carriername"]);

  const REQUEST_PARAMS = useMemo(() => {
    let query = `page=${params.page}&limit=${PER_PAGE}&${"order=desc&orderBy=expectedStartDate"}`;
    if (params.search) {
      query += `&keyword=${params.search}`;
      filters.forEach((e) => (query += `&searchColumn[]=${e}`));
    }
    if (params.status === "all") {
      query += `&searchOr={"requestBillStatus": "WAITING_REQUEST"}&searchOr={"requestBillStatus": "REQUESTING"}&searchOr={"requestBillStatus": "APPROVED"}&searchAnd={"status": {"in": ["DELIVERED", "WAITING_PAYMENT"]} }`;
    } else if (params.status === "REQUESTING") {
      query += `&searchOr={"requestBillStatus": "WAITING_REQUEST"}&searchOr={"requestBillStatus": "REQUESTING"}`;
    } else if (params.status === "APPROVED") {
      query += `&searchAnd={"requestBillStatus": "APPROVED"}&searchAnd={"status": {"in": ["DELIVERED", "WAITING_PAYMENT"]} }`;
    }
    if (params.startDate) {
      query += `&from=${encodeURIComponent(getDateFromData(params.startDate))}`;
    }
    if (params.endDate) {
      query += `&to=${encodeURIComponent(getDateToData(params.endDate))}`;
    }
    query += `&fieldSearchTime=expectedStartDate`;
    return query;
  }, [params, filters]);

  const {
    data: setlmentRequest,
    isSuccess: getSetlmentSuccess,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetOrdersQuery(REQUEST_PARAMS, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [arrData, setArrData] = useState<any>([]);

  useEffect(() => {
    if (error && "status" in error && error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (getSetlmentSuccess) {
      setArrData(setlmentRequest?.data);
      setMetaData(setlmentRequest?.meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setlmentRequest]);

  const goToDetail = (id: number, record: any) => {
    // navigate(`/settlement-management/carrier-request/tranport-detail/${id}`);
    dispatch(
      openModal({
        template: "show-transport-detail",
        width: "818px",
        data: {
          id,
          fromPage: "settlement-management/carrier-request",
          isCancelDispatch: record?.isCompensation,
        },
        handleAction: () => {
          refetch();
        },
      }),
    );
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: t("issuance_request_time"),
    //   dataIndex: "requestBillAt",
    //   key: "requestBillAt",
    //   width: "10%",
    //   align: "center",
    //   ellipsis: true,
    //   render: (value, record) =>
    //     value ? (
    //       <div onClick={() => goToDetail(record.id, record)}>
    //         <div>{getDateFormat(value, DATE_FORMAT_DOT)}</div>
    //         <div>{moment(value).format("hh:mm A")}</div>
    //       </div>
    //     ) : null,
    // },
    {
      title: t("상차일자"),
      dataIndex: "expectedStartDate",
      key: "expectedStartDate",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (value, record) =>
        value && moment(value).isValid() ? (
          <div onClick={() => goToDetail(record.id, record)}>
            <div>{getDateFormat(value, DATE_FORMAT_DOT)}</div>
            <div>{moment(value).format("hh:mm A")}</div>
          </div>
        ) : null,
    },
    {
      title: t("fullname"),
      dataIndex: ["carrier", "name"],
      key: "name",
      align: "center",
      width: "10%",
      ellipsis: true,
      render: (value, record) => <div onClick={() => goToDetail(record.id, record)}>{value}</div>,
    },
    {
      title: t("nickname"),
      dataIndex: ["carrier", "nickname"],
      key: "nickname",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (value, record) => <div onClick={() => goToDetail(record.id, record)}>{value}</div>,
    },
    {
      title: t("contact"),
      dataIndex: ["carrier", "phone"],
      key: "contact",
      width: "13%",
      ellipsis: true,
      align: "center",
      render: (value, record) => <div onClick={() => goToDetail(record.id, record)}>{formatPhoneNumber(value)}</div>,
    },
    {
      title: t("vehicle_number"),
      dataIndex: ["carrier", "licensePlate"],
      key: "vehicleNumber",
      width: "12%",
      ellipsis: true,
      align: "center",
      render: (value, record) => (
        <div onClick={() => goToDetail(record.id, record)}>
          {record?.carrier?.region} {record?.carrier?.licensePlate}
        </div>
      ),
    },
    {
      title: t("distance_traveled"),
      dataIndex: "estimatedDistance",
      key: "estimatedDistance",
      width: "8%",
      align: "center",
      render: (value, record) => <div onClick={() => goToDetail(record.id, record)}>{value} km</div>,
    },
    {
      title: t("requested_amount"),
      dataIndex: "carrierPaymentAmountTax",
      key: "carrierPaymentAmountTax",
      width: "12%",
      align: "center",
      render: (value, record) => {
        return (
          <div onClick={() => goToDetail(record.id, record)}>
            <span style={{ fontSize: "14px", color: "#151E2E", fontWeight: "400" }}>
              {record?.isCompensation ? "[회차]" : ""}
            </span>{" "}
            {formatNumberToCurrency((record?.isCompensation ? record?.carrierSettlementAmount : value) || 0)} {t("won")}
          </div>
        );
      },
    },
    {
      title: t("status_classification"),
      dataIndex: "requestBillStatus",
      key: "requestBillStatus",
      width: "9%",
      align: "center",
      render: (value, record) => {
        const isComplete = record?.requestBillStatus === "APPROVED";
        return (
          <div className={`request-status ${isComplete ? "complete" : ""}`}>
            {isComplete ? t("publication_complete") : t("publication_request")}
          </div>
        );
      },
    },
    // {
    //   title: t('publish'),
    //   dataIndex: 'requestBillStatus',
    //   key: 'requestBillStatus',
    //   width: '9%',
    //   align: 'center',
    //   render: (value, order: any) => {
    //     const isComplete = value === 'APPROVED';
    //     return (
    //       <div
    //         className={`request-status ${isComplete ? 'disable' : 'publish'}`}
    //         onClick={() => {
    //           if (!isComplete) {
    //             onPublish(order.id);
    //           }
    //         }}
    //       >
    //         {isComplete ? t('publication_complete') : t('publish')}
    //       </div>
    //     );
    //   },
    // },
  ];

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = useMemo(
    () => [
      { label: t("all"), value: "all" },
      { label: t("publication_request"), value: "REQUESTING" },
      { label: t("publication_complete"), value: "APPROVED" },
    ],
    [],
  );

  const searchFilterOptions = useMemo(
    () => [
      { label: "성명", value: "carriername" },
      { label: "닉네임", value: "carriernickname" },
      { label: "연락처", value: "carrierphone" },
      { label: "차량번호", value: "carrierlicensePlate" },
    ],
    [],
  );

  return (
    <div className="settlement-management-carrier">
      <NewTable
        showDateFilter={true}
        title={t("carrier_issuance_request")}
        columns={columns}
        allData={arrData}
        metaData={metaData}
        typeOptions={typeOptions}
        searchFilterOptions={searchFilterOptions}
        loading={isFetching}
        params={params}
        setParams={setParams}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
