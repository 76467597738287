import React, { useEffect, useMemo, useState } from "react";
import NewTable from "../../../../components/NewTable";
import { DATE_TIME_FORMAT_24DOT, PER_PAGE } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./style.scss";
import queryString from "query-string";
import { getDateFromData, getDateToData } from "../../../../utils/commonFnc";
import { useGetPointsQuery } from "../../../SettlementManagement/settlementApi";
import { formatNumberToCurrency, formatPhoneNumber, getDateFormat } from "../../../../utils/utils";
import { CouponManagement } from "../..";
import { useExpiredCouponMutation, useGetCouponsDetailQuery, useGetProviderDetailQuery } from "../../couponApi";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../../components/customDialog/dialogSlice";
import { closeModal } from "../../../../components/customModal/modalSlice";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

enum EPointType {
  ALL = "all",
  USED = "used",
  ADD = "add",
}
/* ---------------------------------- 시간 표시 --------------------------------- */
export const formatTime = (timestamp: string) => {
  const date = new Date(timestamp);

  // Get individual components

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  return (
    <>
      <p>
        {year}.{month}.{day}
      </p>
      <p>
        {formattedHours?.toString()?.padStart(2, "0")}:{minutes} {period}
      </p>
    </>
  );
};

export enum ECouponStatus {
  USE = "USE",
  NOT_USE = "NOT_USE",
}

const CouponManagementDetail = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    status: ECouponStatus.USE,
    order: "DESC",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<string[]>(["username"]);
  const [metaData, setMetaData] = useState<any>({});
  const [allData, setAllData] = useState<any>([]);
  const [searchTableParams, setSearchTableParams] = useState<any>("");
  const [expiredCoupon] = useExpiredCouponMutation();
  const { id } = useParams();

  const { data: detailData } = useGetCouponsDetailQuery(
    { id: id },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    },
  );

  /* -------------------------------- 쿠폰 만료일 표시 ------------------------------- */
  const onExpireCoupon = () => {
    dispatch(
      openDialog({
        type: "confirm",
        title: t("expired_coupon_confirm"),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          if (id) {
            const res = await expiredCoupon({
              couponIds: [id],
            }); //@ts-ignore
            showResult(res.error, "해당 쿠폰이 만료 되었습니다.");
          }
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        isBorderRadius: "no",
        type: "info-square",
        content: error?.data?.message ?? msgSuccess,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          dispatch(closeModal());
          if (!error?.data?.message) {
            navigate(-1);
          }
        },
      }),
    );
  };

  const { data, isFetching } = useGetProviderDetailQuery(
    { id, stringParams: searchTableParams },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: params.limit,
      // fieldSearchTime: "createdAt",
    };

    if (params.startDate) {
      _params.from = getDateFromData(params.startDate);
    }
    if (params.endDate) {
      _params.to = getDateToData(params.endDate);
    }

    if (params.order) {
      _params.order = params.order;
    }
    if (params.status) {
      _params.providerCouponStatus = params.status === "USE" ? "USED" : "UNUSED";
    }

    let filtersQuery = "";
    if (params.search) {
      _params.keyword = params.search;
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }

    const paramQuery = queryString.stringify(_params);

    return `${paramQuery}&${filtersQuery}`;
  };

  useEffect(() => {
    setSearchTableParams(getSearchParams(params));
  }, [params]);

  useEffect(() => {
    setAllData(data?.data?.map((item: any) => ({ ...item, id: item?.id })) ?? []);

    setMetaData(data?.meta);
  }, [data]);

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = [
    {
      label: "사용",
      value: ECouponStatus.USE,
    },
    {
      label: "미사용",
      value: ECouponStatus.NOT_USE,
    },
  ];

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    {
      label: "쿠폰명",
      value: "name",
    },
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "이름/대표자명",
      value: "representationName",
    },
  ];

  const newColumns: any = [
    {
      title: "가입 일자",
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      render(_: any, record: any) {
        return (
          <span style={{ color: "#2395FF" }}>
            {record?.provider?.createdAt ? moment(record?.provider?.createdAt).format(DATE_TIME_FORMAT_24DOT) : ""}
          </span>
        );
      },
    },
    {
      title: "이름",
      dataIndex: "username",
      align: "center",
      key: "username",
      render(_: any, record: any) {
        const type = record?.provider?.type;
        return type === "NORMAL"
          ? record?.provider?.representationName
          : type === "BUSINESS"
          ? record?.provider?.representationName
          : type === "PAY_LATER"
          ? record?.provider?.representationName
          : "";
      },
    },
    {
      title: "연락처",
      dataIndex: "phoneContact",
      align: "center",
      key: "phoneContact",
      render(_: any, record: any) {
        return formatPhoneNumber(record?.provider?.phoneContact || record?.provider?.picPhone);
      },
    },
    {
      title: "회원 구분",
      dataIndex: "company",
      align: "center",
      key: "company",
      render(_: any, record: any) {
        const type = record?.provider?.type;
        return type === "NORMAL"
          ? "일반회원"
          : type === "BUSINESS"
          ? "기업회원"
          : type === "PAY_LATER"
          ? "후불회원"
          : "";
      },
    },
    {
      title: "사용 유무",
      dataIndex: "status",
      align: "center",
      key: "status",
      render(_: any, record: any) {
        return record?.status === "UNUSED" ? "미사용" : "사용";
      },
    },
  ];

  const columns: any = [
    // 아이디, 회원구분, 회사명, 이름/대표자명, 결제금액, 사용/지급 포인트, 일시
    {
      title: "아이디",
      dataIndex: "id",
      key: "id",
      align: "center",
      render(_: any, record: any) {
        // if (record?.provider?.type === "NORMAL") {
        //   return "일반 회원";
        // }
        return record?.provider?.username;
      },
    },
    {
      title: "회원구분",
      dataIndex: "type",
      align: "center",
      key: "type",
      render(_: any, record: any) {
        const type = record?.provider?.type;
        return type === "NORMAL"
          ? "일반회원"
          : type === "BUSINESS"
          ? "기업회원"
          : type === "PAY_LATER"
          ? "후불회원"
          : "";
      },
    },
    {
      title: "회사명",
      dataIndex: "company",
      align: "center",
      key: "company",
      render(_: any, record: any) {
        return record?.provider?.company ?? "";
      },
    },
    {
      title: "이름/대표자명",
      align: "center",
      dataIndex: "representationName",
      key: "representationName",
      render: (_: any, record: any): any => {
        return record?.provider?.representationName ?? "";
      },
    },
    {
      title: "결제금액",
      align: "center",
      dataIndex: "firstNicepayAmt",
      key: "firstNicepayAmt",
      render(firstNicepayAmt: any) {
        return (
          <div className="content-right">
            {" "}
            {firstNicepayAmt && firstNicepayAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"}
          </div>
        );
      },
    },
    {
      align: "center",
      title: "사용/지급 포인트",
      dataIndex: "point",
      key: "point",
      render: (value: number) => {
        return (
          <div className="content-right">
            <span className={Number(value) > 0 ? "point-add" : "point-used"}>
              {Number(value) > 0 ? "+" : ""} {formatNumberToCurrency(value)} {t("won")}
            </span>
          </div>
        );
      },
    },
    {
      align: "center",
      title: "일시",
      dataIndex: "createdAt",
      key: "createdAt",
      render(item: any, record: any) {
        return (
          moment(item).isValid() && (
            <div className={Number(record?.point || 0) > 0 ? "point-add" : "point-used"}>
              <span>{moment(item)?.local()?.format("YYYY.MM.DD")}</span> <br />
              <span>{moment(item)?.local()?.format("hh:mm A")}</span>
            </div>
          )
        );
      },
    },
  ];

  /* -------------------------------- 쿠폰의 상태 맵핑 ------------------------------- */
  const mappingCouponStatus = (value: string, record: any) => {
    if (record?.expiredByAdmin) {
      return "강제 만료";
    } else if (record?.status === "EXPIRED") {
      return "기간 만료";
    } else if (moment(record?.startDate).startOf("day") > moment()) {
      return "사용 대기";
    } else if (
      moment(record?.startDate).startOf("day") <= moment() &&
      moment() <= moment(record?.endDate).endOf("day")
    ) {
      return "사용 가능";
    } else {
      return "";
    }
  };

  const info = useMemo(() => {
    if (detailData) {
      console.log(mappingCouponStatus("", detailData));

      return [
        {
          label: "쿠폰 이름",
          value: detailData?.name,
        },
        {
          label: "쿠폰 설명",
          value: detailData?.description,
        },
        {
          label: "상태",
          value: mappingCouponStatus("", detailData),
        },
        {
          label: "쿠폰 사용 기간",
          value: `${getDateFormat(detailData.startDate)} ~  ${getDateFormat(detailData.endDate)}`,
        },
        {
          label: "할인율",
          value: detailData?.value + "%",
        },
        {
          label: "최소 결제 금액",
          value: detailData?.minPayAmount + "원",
        },
        {
          label: "지급 대상",
          value: detailData?.isApplyAll ? "전체" : "지정 회원",
        },
        {
          children: [
            {
              label: "사용수",
              value: `${detailData?.usedTotal}  [${Number.parseFloat(
                (detailData?.usedTotal / detailData?.total).toString(),
              ).toFixed(2)} %]`,
            },
            { label: "발행수", value: `${detailData?.total} 개` },
          ],
        },
      ];
    }
    return [];
  }, [detailData]);

  const InfoItem = ({ data }: any) => {
    const { label, value } = data;

    return (
      <div className="pmd-info-item">
        <span className="pmd-info-item-label">{label} : </span>
        <span className="pmd-info-item-value">{value}</span>
      </div>
    );
  };

  return (
    <div className="pmd">
      <div className="pmd-header">
        <span className="title">쿠폰 상세보기</span>

        <div className="pmd-btn pmd-btn-expire" onClick={onExpireCoupon}>
          즉시 만료
        </div>
      </div>
      <div className="pmd-info">
        {info?.map((item) => {
          if (!item?.children) {
            return <InfoItem data={item} />;
          } else {
            return (
              <div className="pmd-info-box">
                {item?.children?.map((item) => (
                  <InfoItem data={item} />
                ))}
              </div>
            );
          }
        })}
      </div>
      <div className="pmd-table-title">사용자 목록</div>
      <NewTable
        columns={newColumns}
        metaData={metaData}
        allData={allData}
        params={params}
        isTotalVisible={false}
        setParams={setParams}
        loading={isFetching}
        typeOptions={typeOptions}
        showDateFilter={false}
      />
      <div className="pmd-footer">
        <div className="pmd-btn pmd-btn-back " onClick={() => navigate(-1)}>
          뒤로가기
        </div>
      </div>
    </div>
  );
};

export default CouponManagementDetail;
