import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./routers";
import Layout from "./components/layout";
import CustomModal from "./components/customModal";
import CustomDialog from "./components/customDialog";
import { useSelector } from "react-redux";
import { getUserLogin } from "./store/selector/RootSelector";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN, REGISTER_NOTIFICATION } from "./utils/constants";
import { run } from "./subscription";

function App() {
  const isUserLogin = useSelector(getUserLogin);
  const navigate = useNavigate();
  const location = useLocation();
  const [initNotification, setinitNotification] = useState(false);

  useEffect(() => {
    if (!isUserLogin && location.pathname !== "/login") {
      navigate("/login");
    } else if (location.pathname === "/") {
      navigate("/member-management/shipper");
    }
  }, [isUserLogin, location.pathname, navigate, initNotification]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          {PrivateRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element} />
          ))}
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Page not found!</p>
              </main>
            }
          />
        </Route>
        <Route path="login">
          {PublicRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element} />
          ))}
        </Route>
      </Routes>
      <CustomModal />
      <CustomDialog />
    </div>
  );
}

export default App;
