import {
  ACCESS_TOKEN,
  API_LINK,
  NOTIFY_AUTH,
  NOTIFY_ENDPOINT,
  NOTIFY_P256DH,
  REGISTER_NOTIFICATION,
} from "./utils/constants";

const publicVapidKey = "BLlwNGUV7-yPvXxSkscAPLZmDlvMu58cZi4lVn32xJ1Z2RqOCmGbzr8XoTGYTmUnLeG6Yrh3zTgQ9ylJSlmg2zQ";

export async function run(accessToken) {
  if ("serviceWorker" in navigator) {
    try {
      console.log("Registereing...");
      await navigator.serviceWorker.register("/notification-worker.js", { scope: "/" });
      // Listen for the controllerchange event to detect when service worker becomes active
      navigator.serviceWorker.addEventListener("controllerchange", handleControllerChange(accessToken));

      console.log("Sent push");
    } catch (e) {
      console.log("Error while registering service worker", e);
    }
  } else {
    console.log("Not support service worker");
  }
}

const handleControllerChange = (accessToken) => {
  // Service worker is now active
  console.log("Service worker active");
  if (!localStorage.getItem(REGISTER_NOTIFICATION)) {
    localStorage.setItem(REGISTER_NOTIFICATION, accessToken);
    // Confirm user permission for notification
    Notification.requestPermission()
      .then(async (permission) => {
        if (permission === "granted" && navigator?.serviceWorker?.ready) {
          console.log("granted!!!!!", navigator?.serviceWorker);
          //If notification is allowed
          //   let intervalRequestPermission = setInterval(async () => {
          if (!localStorage.getItem(NOTIFY_ENDPOINT)) {
            navigator.serviceWorker.ready
              .then((p) => {
                console.log("data serivce worker ->", p);
                // if (p?.active?.state === "activated") {
                //   clearInterval(intervalRequestPermission);
                p.pushManager.getSubscription().then(async (sub) => {
                  console.log("getSubscription ->", sub);
                  if (sub === null) {
                    //If there is no notification subscription, register.
                    const subscription = await p.pushManager.subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                    });
                    console.log("Registered push! data -> ", JSON.parse(JSON.stringify(subscription)));

                    let endpoint = JSON.parse(JSON.stringify(subscription))?.endpoint;
                    let auth = JSON.parse(JSON.stringify(subscription))?.keys?.auth;
                    let p256dh = JSON.parse(JSON.stringify(subscription))?.keys?.p256dh;

                    if (endpoint) localStorage.setItem(NOTIFY_ENDPOINT, endpoint);
                    if (auth) localStorage.setItem(NOTIFY_AUTH, auth);
                    if (p256dh) localStorage.setItem(NOTIFY_P256DH, p256dh);
                    console.log("Subscribling push");
                    await fetch(`${API_LINK}managers/subscribe`, {
                      method: "POST",
                      body: JSON.stringify(subscription),
                      headers: {
                        "content-type": "application/json",
                        authorization: `Bearer ${accessToken}`,
                      },
                    });
                    console.log("Subscribled!!!!!");
                  }
                });
                // }
              })
              .catch((error) => {
                console.log("error when registering notify1", error);
              });
          }
          //   }, 1000);
        } else {
          //If notification is not allowed
          console.log(permission);
        }
      })
      .catch((error) => {});
  }
};

// Boilerplate borrowed from https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  /*   console.log("outputArray: ", outputArray);
   */ return outputArray;
}

// export function unRegisterNotification() {
//   if ("serviceWorker" in navigator) {
//     navigator.serviceWorker.ready.then((registration) => {
//       registration.unregister().then(async () => {
//         console.log("localStorage =>", localStorage);
//         await fetch(`${API_LINK}managers/unsubscribe`, {
//           method: "POST",
//           body: JSON.stringify({
//             endpoint: localStorage.getItem(NOTIFY_ENDPOINT),
//             keys: {
//               p256dh: localStorage.getItem(NOTIFY_P256DH),
//               auth: localStorage.getItem(NOTIFY_AUTH),
//             },
//           }),
//           headers: {
//             "content-type": "application/json",
//             authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
//           },
//         });
//         localStorage.removeItem(NOTIFY_AUTH);
//         localStorage.removeItem(NOTIFY_ENDPOINT);
//         localStorage.removeItem(NOTIFY_P256DH);
//         localStorage.removeItem(REGISTER_NOTIFICATION);
//         window.location.reload();
//       });

//       console.log("Unregistered notification!");
//     });
//   }
// }

export const unregisterServiceWorker = async () => {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    registrations.forEach((registration, id) => {
      registration.unregister().then(async () => {
        if (id === registrations.length - 1) {
          console.log("localStorage =>", localStorage);
          await fetch(`${API_LINK}managers/unsubscribe`, {
            method: "POST",
            body: JSON.stringify({
              endpoint: localStorage.getItem(NOTIFY_ENDPOINT),
              keys: {
                p256dh: localStorage.getItem(NOTIFY_P256DH),
                auth: localStorage.getItem(NOTIFY_AUTH),
              },
            }),
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
            },
          });
          localStorage.removeItem(NOTIFY_AUTH);
          localStorage.removeItem(NOTIFY_ENDPOINT);
          localStorage.removeItem(NOTIFY_P256DH);
          localStorage.removeItem(REGISTER_NOTIFICATION);
          console.log("Unregistered notification!");
          navigator.serviceWorker.removeEventListener("controllerchange", () => {});
          window.location.reload();
        }
      });
      console.log("Service Worker unregistered:", registration);
    });
  } catch (error) {
    console.error("Failed to unregister Service Worker:", error);
  }
};

export const unregisterServiceWorkerLogout = async () => {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    registrations.forEach(async (registration, id) => {
      registration.unregister();
      console.log("Service Worker unregistered:", registration);

      if (id === registrations.length - 1) {
        console.log("localStorage =>", localStorage);
        await fetch(`${API_LINK}managers/unsubscribe`, {
          method: "POST",
          body: JSON.stringify({
            endpoint: localStorage.getItem(NOTIFY_ENDPOINT),
            keys: {
              p256dh: localStorage.getItem(NOTIFY_P256DH),
              auth: localStorage.getItem(NOTIFY_AUTH),
            },
          }),
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        });
        localStorage.clear();
        navigator.serviceWorker.removeEventListener("controllerchange", () => {});
        console.log("Unregistered notification and logout!");
        window.location.reload();
      }
    });
  } catch (error) {
    console.error("Failed to unregister Service Worker:", error);
  }
};
