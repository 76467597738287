import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";

import { Line } from "@ant-design/plots";
import memo from "../../assets/images/memo.svg";
import unmemo from "../../assets/images/unmemo.svg";
import { Input } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import moment from "moment";
import { MONTH_FORMAT_DOT, PAGE_SIZE, PAYBACK_RATE } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { coverMonth } from "../../utils/utils";
import KitTableCustom from "../../components/kitTableCustom";
import { useEffect, useMemo, useState } from "react";
import {
  useGetProviderListQuery,
  useGetProviderMemosQuery,
  useGetShipperByIdQuery,
  useGetShipperDetailQuery,
} from "./settlementApi";
import { mergeArrays } from "../../utils/commonFnc";

export default function SHS2() {
  let month = new Date().getMonth() + 1;
  month = coverMonth(month);
  const location = useLocation();
  const year = new Date().getFullYear();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = urlParams.get("id");
  const navigate = useNavigate();
  const data: any = [];
  const dataOrder: any = [];
  const state = location.state;
  const [tab, setTab] = useState("waitingSettlement");

  const [params, setParams] = useState({
    id,
    target: tab,
  });
  const [monthStart, setMonthStart] = useState(1);
  const [monthEnd, setMonthEnd] = useState(12);
  const [isMonthStart, setIsMonthStart] = useState(false);
  const [isMonthEnd, setIsMonthEnd] = useState(false);
  const [metaData, setMetaData] = useState<any>({});

  const initArr = () => {
    let arr = [];
    for (let k = 1; k < 13; k++) {
      let item_provider = {
        year: `${year}.${coverMonth(k)}`,
        value: 0,
        category: "화주 매출",
      };
      arr.push(item_provider);
    }
    return arr;
  };

  const [graphData, setGraphData] = useState(initArr());

  const { data: shipperDetail } = useGetShipperByIdQuery(
    { id: id },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  useEffect(() => {
    if (state?.data) {
      onOpenShipperSettlements(state?.data);
    }
  }, [state]);

  const detailPayload = useMemo(() => {
    let params: any = { id, settlementStatus: tab };
    if (isMonthStart) {
      params["from"] = `${year}-${monthStart}`;
    } else {
      delete params.from;
    }
    if (isMonthEnd) {
      params["to"] = `${year}-${monthEnd}`;
    } else {
      delete params.to;
    }
    return params;
  }, [tab, id, isMonthEnd, isMonthStart, monthStart, monthEnd]);

  const [memoParams, setMemoParams] = useState<any>({});

  const { data: tableData } = useGetProviderListQuery(detailPayload, { skip: !id });

  const { data: waitingSettlementData } = useGetProviderListQuery(
    { id, settlementStatus: "waitingSettlement" },
    { skip: !id },
  );

  const { data: doneSettlementData } = useGetProviderListQuery(
    { id, settlementStatus: "settlementCompleted" },
    { skip: !id },
  );

  const { data: dataMemos } = useGetProviderMemosQuery(memoParams, {
    refetchOnMountOrArgChange: true,
  });

  const onOpenShipperSettlements = (item: any) => {
    dispatch(
      openModal({
        template: "show-provider-detail-modal",
        width: "1070px",
        isActionFlip: true,
        data: {
          header: {
            cost: item?.other_fee_nicepay_amt + item.first_nicepay_amt,
          },
          body: {
            id,
            settlementStatus: tab,
            month: item?.month,
          },
          item,
        },
        handleAction: (action: any) => {
          // callGetCarriers();
        },
      }),
    );
  };

  const newColumns: ColumnsType<any> = [
    {
      title: "정산 기준 (월)",
      dataIndex: "month",
      key: "month",
      width: "10%",
      ellipsis: true,
      align: "left",
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ellipsis: true,
      align: "center",
      render() {
        return (
          <span className={tab === "waitingSettlement" ? "column-red-color" : "column-green-color"}>
            {tab === "waitingSettlement" ? "정산 대기" : "정산 완료"}
          </span>
        );
      },
    },
    {
      title: "후불 운송 건수",
      dataIndex: "total_order",
      key: "total_order",
      width: "10%",
      ellipsis: true,
      align: "center",
      render(value) {
        return `${value || "00"} 건`;
      },
    },
    {
      title: (
        <div>
          운임{" "}
          <span
            style={{
              fontSize: 10,
            }}
          >
            (부가세포함)
          </span>
        </div>
      ),
      dataIndex: "first_nicepay_amt",
      key: "first_nicepay_amt",
      width: "12%",
      ellipsis: true,
      align: "center",
      render(value) {
        return `${new Intl.NumberFormat().format(Number(value))} 원`;
      },
    },
    {
      title: (
        <div>
          추가 결제{" "}
          <span
            style={{
              fontSize: 10,
            }}
          >
            (부가세포함)
          </span>
        </div>
      ),
      dataIndex: `other_fee_nicepay_amt`,
      key: "other_fee_nicepay_amt",
      ellipsis: true,
      align: "center",
      render: (value) => `${new Intl.NumberFormat().format(Number(value))} ${t("원")}`,
    },
    {
      title: "총 운임",
      dataIndex: "payback",
      key: "payback",
      ellipsis: true,
      align: "center",
      render: (payback, record) =>
        `${new Intl.NumberFormat().format(Number(record?.other_fee_nicepay_amt + record.first_nicepay_amt))} ${t(
          "원",
        )}`,
    },

    {
      title: "메모",
      dataIndex: "memo",
      key: "memo",
      width: "8%",
      ellipsis: true,
      align: "center",
      render: (_, item: any) => {
        return (
          <div
            className="flex-center"
            onClick={() => {
              const searchAnd = [
                JSON.stringify({ providerId: Number(id) }),
                JSON.stringify({ year: item?.month?.split(".")?.[0] }),
                JSON.stringify({ month: item?.month?.split(".")?.[1] }),
              ];

              setMemoParams({ ...memoParams, id: Number(id), body: { limit: 1, searchAnd } });

              dispatch(
                openModal({
                  template: "show-update-memo-modal",
                  width: "512px",
                  data: {
                    info: {
                      ...item.memo,
                      providerId: Number(id),
                      month: Number(item?.month?.split(".")?.[1]),
                      year: Number(item?.month?.split(".")?.[0]),
                    },
                  },
                  handleAction: (action: any) => {},
                }),
              );
            }}
          >
            {item?.memo?.content == null || item?.memo?.content == "" ? (
              <img
                alt="memo"
                src={unmemo}
                style={{
                  cursor: "pointer",
                }}
              />
            ) : (
              <img
                alt="memo"
                src={memo}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: "상세 정보",
      dataIndex: "id",
      key: "id",
      width: "10%",
      ellipsis: true,
      align: "center",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id, item) => {
        return (
          <a
            className="more-information"
            onClick={() => {
              let date = moment(item.date).format(MONTH_FORMAT_DOT);
              date = date + ".01";
              onOpenShipperSettlements(item);
            }}
          >
            {t("more_information")}
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    if (waitingSettlementData && doneSettlementData && graphData) {
      let allSetlement = mergeArrays(waitingSettlementData, doneSettlementData);

      const cloneGraphData = [...graphData];
      for (let i = 0; i < cloneGraphData?.length; i++) {
        for (let j = 0; j < allSetlement?.length; j++) {
          if (allSetlement[j]?.month === cloneGraphData[i].year) {
            cloneGraphData[i] = {
              ...cloneGraphData[i],
              value: allSetlement[j].first_nicepay_amt + allSetlement[j].other_fee_nicepay_amt,
            };
          }
        }
      }

      setGraphData(cloneGraphData);
    }

    // setGraphData(cloneGraphData);
  }, [waitingSettlementData, doneSettlementData]);

  /* ------------------------------- 화주의 디테일 열기 ------------------------------- */
  const onOpenShipperDetail = (id: any) => {
    const info = dataOrder?.data?.filter((val: any) => val.providerId == id)[0];
    const provider = info?.provider;
    dispatch(
      openModal({
        template: "show-shipper-detail-modal",
        width: "649px",
        data: {
          info: shipperDetail,
        },
        handleAction: (action: any) => {},
      }),
    );
  };

  /* --------------------------------- 그래프의 구성 -------------------------------- */
  const DemoLine = () => {
    const config: any = {
      data: graphData.slice(graphData.length - 100, graphData.length).filter((item: any) => {
        return item.category === "화주 매출";
      }),
      xField: "year",
      yField: "value",
      seriesField: "category",
      colorField: "category", // or seriesField in some cases

      color: (item: any) => {
        if (item.category === "화주 매출") {
          return "#ff5c5c";
        } else {
          return "#2395ff";
        }
      },
      xAxis: {
        nice: true,
        label: {
          // autoRotate: false,
          //rotate: Math.PI / 6,
          offset: 20,
          style: {
            fill: "#aaa",
            fontSize: 12,
          },
          formatter: (name: any) => {
            return name;
          },
        },
        /*       title: {
          text: "day la bieu do",
          style: {
            fontSize: 20,
          },
        }, */
        line: {
          style: {
            stroke: "#aaa",
          },
        },
        tickLine: {
          style: {
            lineWidth: 2,
            stroke: "#aaa",
          },
          length: 5,
        },
        grid: {
          line: {
            style: {
              stroke: "#ddd",
              lineDash: [0, 0],
            },
          },
          //      alternateColor: "rgba(0,0,0,0.05)",
        },
      },
      yAxis: {
        label: {
          autoRotate: false,
          style: {
            fill: "#aaa",
            fontSize: 12,
          },
          // 数值格式化为千分位
          formatter: (v: any) =>
            `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => {
              return `${s},`;
            }),
        },
        /*         title: {
          text: "cong hoa",
          style: {
            fontSize: 16,
          },
        }, */
        // 坐标轴线的配置项 null 表示不展示
        line: {
          style: {
            stroke: "#aaa",
          },
        },
        tickLine: {
          style: {
            lineWidth: 2,
            stroke: "#aaa",
          },
          length: 5,
        },
        grid: {
          line: {
            style: {
              stroke: "#ddd",
              lineDash: [0, 0],
            },
          },
          /*           alternateColor: "rgba(0,0,0,0.05)",
           */
        },
      },
      // label
      /*       label: {
        layout: [
          {
            type: "hide-overlap",
          },
        ],
        // 隐藏重叠label
        style: {
          textAlign: "center",
        },
        formatter: (item: any) => {
          return item.value;
        },
      }, */
      // point
      point: {
        size: 5,
        style: {
          lineWidth: 1,
          fillOpacity: 1,
        },
        shape: (item: any) => {
          return "circle";
        },
      },
      annotations: [
        // 辅助线
        /*      {
          type: "line",
          start: ["0%", "10%"],
          end: ["100%", "10%"],
          top: true,
          style: {
            stroke: "l(0) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
            lineWidth: 2,
          },
        }, // 辅助区域
      {
          type: "region",
          start: ["0%", "0%"],
          end: ["20%", "10%"],
          top: true,
          style: {
            fill: "#1890ff",
            fillOpacity: 1,
            opacity: 1,
          },
        },  */
        {
          type: "text",
          position: ["90%", "-8%"],
          content: "단위 : 1 = 100만원",
          style: {
            /* fontSize: 12,
             fill: "#fff",
            textAlign: "center",
            textBaseline: "middle",
            shadowColor: "#fff",
            shadowOffsetX: 12,
            shadowOffsetY: 12,
            shadowBlur: 2, */
          },
        },
        /*      {
          type: "line",
          start: ["min", "median"],
          end: ["max", "median"],
          style: {
            stroke: "Turquoise",
            lineDash: [4, 2],
          },
        }, */
      ],
      legend: {
        position: "top-left",
        layout: "vertical",
        marker: (a: any, b: any, c: any, d: any) => {
          return {
            symbol: "circle",
            style: {
              fill: c.name === "화주 매출" ? "#ff5c5c" : "#2395ff",
              r: 4,
            },
          };
        },
        itemName: {
          style: {
            fill: "#000",

            fontSize: 16,
          },
          formatter: (name: any) => {
            let title = "";
            if (name == "배차킹 매출") {
              title = t("allocationKing_sales");
            }
            if (name == "화주 매출") {
              title = t("shipper_sales");
            }
            return title;
          },
        },
      },
      // 度量相关配置
      meta: {
        // year 对应 xField || yField
        year: {
          range: [0, 1],
        },
      },
    };
    //@ts-ignore
    return <Line {...config} />;
  };

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = [
    {
      label: t("waiting_for_settlement"),
      value: "waitingSettlement",
      key: "waitingSettlement",
    },
    {
      label: t("settlement_completed"),
      value: "settlementCompleted",
      key: "settlementCompleted",
    },
  ];

  /* --------------------------------- 시간 업데이트 -------------------------------- */
  const onHandleFilterDate = (monthSelect: any, yearSelect: any, typeDate: any) => {
    if (typeDate === "startDate") {
      setIsMonthStart(true);
      setMonthStart(Number(monthSelect));
    }

    if (typeDate === "endDate") {
      setIsMonthEnd(true);
      setMonthEnd(Number(monthSelect));
    }
  };

  return (
    <div className="settlement-management-shipper">
      <div className="navigate">
        <div
          className="back"
          onClick={() => {
            navigate("/settlement-management/shipper-history");
          }}
        >
          {t("back")}
        </div>
        <div
          className="shipper-details"
          onClick={() => {
            onOpenShipperDetail(id);
          }}
        >
          {t("shipper_details")}
        </div>
      </div>
      <div className="graph">{<DemoLine></DemoLine>}</div>

      {/* <TableManagement
        title={t("settlement_management")}
        columns={newColumns}
        allData={providerSetlmentHis || []}
        filterOptions={filterOptions}
        searchFilterOptions={searchFilterOptions}
        filterCheckFunc={filterCheckFunc}
        offsetHeight={500}
      /> */}
      <KitTableCustom
        isShowSearch={false}
        tableTitle="팝업 관리 "
        typeOptions={typeOptions}
        onTabChangeCallback={(tab: string) => {
          setTab(tab);
        }}
        isCustomTab={true}
        headerAction={
          <div className="date-picker-wrapper">
            <div
              onClick={() => {
                dispatch(
                  openModal({
                    template: "show-set-date-modal",
                    width: "344px",
                    data: {
                      year: year,
                      month: month,
                      typeDate: "startDate",
                    },
                    handleAction: (monthSelect: any, yearSelect: any, typeDate: any) => {
                      onHandleFilterDate(monthSelect, yearSelect, typeDate);
                    },
                  }),
                );
              }}
              className="start-date-picker"
            >
              <CalendarOutlined rev="" />
              <Input
                className="input-date"
                size="middle"
                readOnly={true}
                value={isMonthStart ? `${year}-${coverMonth(monthStart)}` : ""}
                style={{ width: 212 }}
                placeholder={t("please_select_a_date")}
              />
            </div>
            <div className="space"></div>
            <div
              onClick={() => {
                dispatch(
                  openModal({
                    template: "show-set-date-modal",
                    width: "344px",
                    data: {
                      year: year,
                      month: month,
                      typeDate: "endDate",
                    },
                    handleAction: (monthSelect: any, yearSelect: any, typeDate: any) => {
                      onHandleFilterDate(monthSelect, yearSelect, typeDate);
                    },
                  }),
                );
              }}
              className="end-date-picker"
            >
              <CalendarOutlined rev="" />
              <Input
                className="input-date"
                size="middle"
                readOnly={true}
                value={isMonthEnd ? `${year}-${coverMonth(monthEnd)}` : ""}
                style={{ width: 212 }}
                placeholder={t("please_select_a_date")}
              />
            </div>
          </div>
        }
        params={params}
        setParams={setParams}
        inputPlaceholder="팝업명을 검색해주세요"
        dataSource={tableData}
        pagination={false}
        columns={newColumns}
        rowKey="id"
        metaData={metaData}
        footerAction={null}
      />
    </div>
  );
}
