import "./style.scss";
import { useTranslation } from "react-i18next";
import { useGetOrderDetailQuery, useGetTotalDistanceQuery } from "./settlementApi";
import { useEffect, useState } from "react";
import moment from "moment";
import { ACCESS_TOKEN, DATE_TIME_FORMAT_DOT } from "../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import { formatNumberToCurrency } from "../../utils/utils";
interface AttachFileInfo {
  name: string;
  file: any;
  url: any;
}
export default function SettlementHistoryOrderDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const splitRedirect = queryString.split("redirect=");
  const redirect = splitRedirect?.[1] || "";

  const downloadFile = async (link: string) => {
    const filename = getFileName(link);
    axios
      .get(link, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  /* -------------------------------- 파일명 가져오기 -------------------------------- */
  const getFileName = (link: string | undefined) => {
    if (!link) {
      return "";
    }
    const split = link.split("/");
    return split[split.length - 1];
  };
  const token = localStorage.getItem(ACCESS_TOKEN);
  const { data, isSuccess } = useGetOrderDetailQuery({ id: id });

  const { data: dataTotalDistance } = useGetTotalDistanceQuery({
    id: Number(data?.carrierId),
  });

  const [transportData, setTransportData] = useState({
    //step 1
    itemType: "",
    itemInfo: "",
    itemQuantity: 0,
    itemQuantityType: "",
    vehicleInfoWeight: "",
    vehicleInfoType: "",
    vehicleInfoRequest: "",
    itemAttachments: new Array<AttachFileInfo>(),
    itemReceipts: new Array<AttachFileInfo>(),
    //step 2
    //start router
    startRouters: [{ province: "", detail: "" }],
    transitPlaces: [],
    startContact: "",
    startTimeEstimate: "",
    startTimeActual: "",
    // startTimeEstimateType: "",
    //end router
    endRouter: [{ province: "", detail: "" }],
    additionalEndpoints: [],
    endContact: "",
    endTimeEstimate: "",
    estimatedTravelTime: 0,
    endTimeActual: "",
    // endTimeEstimateType: "",

    //step 4
    routerUrl: "",
    estimateDistance: "",
    estimateCost: "",
    pointDiscountAmount: "",
    couponDiscountAmount: "",
    carrierPaymentAmount: "",
    excludingFee: "",
    vat: "",
  });
  useEffect(() => {
    if (token && !transportData?.itemType && isSuccess) {
      setTransportData({
        //step 1
        itemType: data.type,
        itemInfo: data.info,
        itemQuantity: data.quantity,
        itemQuantityType: data.packingMethod,
        vehicleInfoWeight: data.tonnage,
        vehicleInfoType: data.vehicleType,
        vehicleInfoRequest: data.requirement,
        itemAttachments: data.docUrls,
        itemReceipts: data.receiptUrls?.filter((it: any) => it.name),

        //step 2
        //start router
        startRouters: [
          {
            province: data.loadingPlace,
            detail: data.detailLoadingPlace,
          },
        ],
        transitPlaces: data.transitPlaces,
        startContact: data.contactSender,
        startTimeEstimate: data.expectedStartDate,
        startTimeActual: data.actualStartDate,
        // startTimeEstimateType:  data.,
        //end router
        endRouter: [
          {
            province: data.receiptPlace,
            detail: data.detailReceiptPlace,
          },
        ],
        additionalEndpoints: data.additionalEndpoints,
        endContact: data.contactReceiver,
        endTimeEstimate: data.expectedEndDate,
        endTimeActual: data.actualEndDate,
        // endTimeEstimateType: data.,

        //step 4
        routerUrl: data.routeUrl,
        estimateDistance: data.estimatedDistance,
        estimatedTravelTime: data.estimatedTravelTime,
        estimateCost: data.freightCost,
        couponDiscountAmount: data.couponDiscountAmount,
        pointDiscountAmount: data.pointDiscountAmount,
        carrierPaymentAmount: data.carrierPaymentAmount,
        excludingFee: data.excludingFee,

        vat: data.vat,
      });
    }
    return () => {};
  }, [isSuccess]);

  /* ------------------------ 상차 시간 및 하차 시간 간의 시간 가져오기 ------------------------ */
  const getDuration = (expectedEndDate: any, expectedStartDate: any, name: string) => {
    var endTime = moment(expectedEndDate);
    var startTime = moment(expectedStartDate);
    let hour = endTime.diff(startTime, "hours");
    let min = endTime.diff(startTime, "minutes") - endTime.diff(startTime, "hours") * 60;
    if (hour < 0 || Number.isNaN(hour)) hour = 0;
    if (min < 0 || Number.isNaN(min)) min = 0;
    let duration = hour + " " + t("시간") + " " + min + " " + t("분");
    if (name === "table-time") {
      duration = hour + " " + t("시간") + " " + min + " " + t("분전.");
    }
    return duration;
  };

  /* --------------------------------- 기간 가져오기 -------------------------------- */
  const getDurationUpdate = (
    expectedEndDate: any,
    expectedStartDate: any,
    name: string,
    estimatedTravelTime: number,
  ) => {
    let second = estimatedTravelTime;
    let hour = Math.floor(second / 3600);
    let min = Math.floor((second % 3600) / 60);
    let duration =
      name === "table-time"
        ? hour + " " + t("시간") + " " + min + " " + t("분.")
        : hour + " " + t("시간") + " " + min + " " + t("분전.");
    if (hour <= 0 && min > 0) {
      duration = name === "table-time" ? min + " " + t("분전.") : min + " " + t("분.");
    }

    if (hour <= 0 && min <= 0 && second > 0) {
      duration = `1 ${t("분.")}`;
    }

    if (hour <= 0 && min <= 0 && second <= 0) {
      duration = getDuration(expectedStartDate, expectedEndDate, name);
    }

    if (hour > 0 && min == 0) {
      duration = hour + " " + t("시간");
    }

    return duration;
  };

  return (
    <div className="settlement-management-shipper">
      <div className="transportCompletedDetail-main">
        <div className="transport-top margin-bottom-48">
          <div className="transport-title ">{t("운송 완료 상세")}</div>
        </div>
        <div className="transport-content">
          <div className="transport card-content margin-bottom-48">
            <div className="info-title">{t("운송 정보")}</div>
            <div className="info-body">
              <div className="row-info">
                <div className="col-info width-40">
                  <div className="item-wrapper">
                    <div className="item-title">{t("상차지 주소")}</div>
                    <div className="item-sub mt-10">
                      {transportData.startRouters[0].province} {transportData.startRouters[0].detail}
                    </div>
                  </div>
                </div>
                <div className="col-info width-60">
                  {transportData.transitPlaces != undefined &&
                    transportData.transitPlaces?.length != 0 &&
                    transportData.transitPlaces.map((it: any, index: number) => {
                      return (
                        <div key={index} className="item-wrapper">
                          <div className="item-title">{t("경유지 주소")}</div>
                          <div className="item-sub mt-10">
                            {it?.province} {it?.detail}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="row-info">
                <div className="col-info width-40">
                  <div className="item-wrapper">
                    <div className="item-title">{t("하차지 주소")}</div>
                    <div className="item-sub mt-10">
                      {transportData.endRouter[0].province} {transportData.endRouter[0].detail}
                    </div>
                  </div>
                </div>
                <div className="col-info width-60">
                  {transportData.additionalEndpoints != undefined &&
                    transportData.additionalEndpoints?.length != 0 &&
                    transportData.additionalEndpoints.map((it: any, index: number) => {
                      return (
                        <div key={index} className="item-wrapper">
                          <div className="item-title">{t("경유지 주소")}</div>
                          <div className="item-sub mt-10">
                            {it?.province} {it?.detail}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="line-wrapper" />

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="item-title">{t("상하차방법")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-title">{t("물품 종류")}</div>
                  </div>
                </div>
                <div className="col-info width-60 direction-row">
                  <div className="col-info">
                    <div className="item-title">{t("물품 수량")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-title">{t("포장 방법")}</div>
                  </div>
                </div>
              </div>

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="item-sub">{transportData.itemQuantityType}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-sub">{transportData.itemType}</div>
                  </div>
                </div>
                <div className="col-info width-60 direction-row">
                  <div className="col-info">
                    <div className="item-sub">{data?.quantity || 0}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-sub">{transportData.itemQuantityType}</div>
                  </div>
                </div>
              </div>
              <div className="row-info">
                <div className="col-info width-40 ">
                  <div className="item-title">{t("물품 서류")}</div>
                </div>
                <div className="col-info width-60 ">
                  <div className="item-title ">{t("운송 서류")}</div>
                </div>
              </div>
              <div className="row-attach">
                <div className="attach-loading">
                  {transportData?.itemAttachments?.map((it: any, index) => {
                    return (
                      <div className="file-item">
                        <div className="name text-ellipsis">{it.name}</div>
                        <div className="download-btn">
                          <div
                            className="transport-button-download"
                            onClick={() => {
                              {
                                downloadFile(it.file);
                              }
                            }}
                          >
                            {t("다운로드")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="attach-receipt">
                  {transportData?.itemReceipts?.map((it: any, index) => {
                    return (
                      <div className="file-item">
                        <div className="name text-ellipsis">{it.name}</div>
                        <div className="download-btn">
                          <div
                            className="transport-button-download"
                            onClick={() => {
                              {
                                downloadFile(it.url);
                              }
                            }}
                          >
                            {t("다운로드")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="line-wrapper" />
              <div className="row-info">
                <div className="col-info width-40">
                  <div className="item-title">{t("상차 예정 시간")}</div>
                </div>
                <div className="col-info width-60">
                  <div className="item-title">{t("하차 예정 시간")}</div>
                </div>
              </div>
              <div className="row-info">
                <div className="col-info width-40">
                  <div className="item-sub">
                    {transportData?.startTimeEstimate != null
                      ? moment(transportData?.startTimeEstimate).format(DATE_TIME_FORMAT_DOT)
                      : "-"}
                  </div>
                </div>
                <div className="col-info width-60">
                  <div className="item-sub">
                    {transportData?.endTimeEstimate != null
                      ? moment(transportData?.endTimeEstimate).format(DATE_TIME_FORMAT_DOT)
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="row-info">
                <div className="col-info width-40">
                  <div className="item-title">{t("실제 상차 시간")}</div>
                </div>
                <div className="col-info width-60">
                  <div className="item-title">{t("실제 하차 시간")}</div>
                </div>
              </div>
              <div className="row-info">
                <div className="col-info width-40">
                  <div className="item-sub">
                    {transportData?.startTimeActual != null
                      ? moment(transportData?.startTimeActual).format(DATE_TIME_FORMAT_DOT)
                      : "-"}
                  </div>
                </div>
                <div className="col-info width-60">
                  <div className="item-sub">
                    {transportData?.endTimeActual != null
                      ? moment(transportData?.endTimeActual).format(DATE_TIME_FORMAT_DOT)
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="line-wrapper" />

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info ">
                    <div className="item-title">{t("운송 차량")}</div>
                  </div>
                  <div className="col-info ">
                    <div className="item-title">{t("이동 시간")}</div>
                  </div>
                </div>
                <div className="col-info width-60">
                  <div className="item-title">{t("이동 거리")}</div>
                </div>
              </div>

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info ">
                    <div className="item-sub">{transportData?.vehicleInfoWeight}</div>{" "}
                  </div>
                  <div className="col-info">
                    <div className="item-sub">
                      {getDurationUpdate(
                        transportData?.endTimeActual,
                        transportData?.startTimeActual,
                        "",
                        transportData?.estimatedTravelTime,
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-info width-60">
                  <div className="item-sub">{transportData?.estimateDistance} km</div>
                </div>
              </div>
            </div>
          </div>
          <div className="carrier card-content margin-bottom-48">
            <div className="info-title">{t("운송인 정보")}</div>
            <div className="info-body">
              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="item-title">{t("운송 기사")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-title">{t("운송인 연락처")}</div>
                  </div>
                </div>
                <div className="col-info width-60 direction-row opacity-0"></div>
              </div>

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="item-sub">{data?.carrier?.username || "-"}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-sub">{data?.carrier?.phone || "-"}</div>
                  </div>
                </div>
                <div className="col-info width-60 direction-row opacity-0"></div>
              </div>
              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="item-title">{t("차량 정보")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-title">{t("차량 번호")}</div>
                  </div>
                </div>
                <div className="col-info width-60 direction-row ">
                  <div className="col-info">
                    <div className="item-title">{t("총 운행거리")}</div>
                  </div>
                  <div className="col-info opacity-0"></div>
                </div>
              </div>

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="item-sub">
                      {t(`${data?.carrier?.tonnage}`) || "-"}
                      {t(`${data?.carrier?.vehicleType}`) || "-"}
                    </div>
                  </div>
                  <div className="col-info">
                    <div className="item-sub">
                      {data?.carrier?.region || "-"} {data?.carrier?.licensePlate || "-"}
                    </div>
                  </div>
                </div>
                <div className="col-info width-60 direction-row ">
                  <div className="col-info">
                    <div className="item-sub">{Number(dataTotalDistance?.totalDistance).toFixed(2) || 0} km</div>
                  </div>
                  <div className="col-info opacity-0"></div>
                </div>
              </div>
            </div>
            {/*         {data?.scoreRating != 0 && (
                <div className="carrier-rating">
                  <div className="rating-title">{t("운송인 평가")}</div>
                  <div className="rating-sub">
                    {data?.scoreRating} {t("점")}
                  </div>
                </div>
              )} */}
          </div>
          <div className="payment card-content margin-bottom-48">
            <div className="info-title">{t("결제 정보")}</div>
            <div className="info-body">
              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="payment-title">{t("운임 비용")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-info padding-right-0">
                      <div className="payment-sub float-right">
                        {formatNumberToCurrency(parseInt(transportData?.estimateCost))} {t("원")}
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="col-info width-60 direction-row opacity-0"></div>
              </div>
              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="payment-title">{t("배차킹수수료")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-info padding-right-0">
                      <div className="payment-sub float-right">
                        {formatNumberToCurrency(
                          transportData?.excludingFee ? parseInt(transportData?.excludingFee) : 0,
                        )}{" "}
                        {t("원")}
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="col-info width-60 direction-row opacity-0"></div>
              </div>

              <div className="row-info">
                <div className="col-info width-40 direction-row">
                  <div className="col-info">
                    <div className="total-title">{t("최종 결제 금액")}</div>
                  </div>
                  <div className="col-info">
                    <div className="item-info padding-right-0">
                      <div className="total-sub float-right">
                        {formatNumberToCurrency(parseInt(transportData?.carrierPaymentAmount))} {t("원")}
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="col-info width-60 direction-row opacity-0"></div>
              </div>
            </div>
          </div>

          <div
            className="transport-button"
            onClick={() => {
              navigate(`/settlement-management/carrier-history${redirect ? `?${redirect}` : ""}`);
            }}
          >
            {t("목록")}
          </div>
        </div>
      </div>
    </div>
  );
}
