import "./style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
import { coverMonth } from "../../../../utils/utils";

export default function SetDate(props: any) {
  let year = props.data.year;
  const month = props.data.month;
  let typeDate = props.data.typeDate;
  const yearArr = [];
  yearArr.push({ value: year, label: year });

  const monthArr = [];
  for (let i = 1; i <= 12; i++) {
    let item = { value: coverMonth(i), label: coverMonth(i) };
    monthArr.push(item);
  }

  const { t } = useTranslation();

  const [monthSelect, setMonthSelect] = useState(month);
  useEffect(() => {
    /*     setInfo(props.data.info);
     */ if (props?.data?.info) {
    }
    /* return () => {
      setMemoText("");
    }; */
  }, [props]);

  const handleChange = (value: string) => {
    setMonthSelect(value);
  };
  return (
    <div className="set-date-wrapper">
      <div className="set-date">
        <Space wrap>
          <Select
            style={{ width: 150 }}
            onChange={handleChange}
            value={monthSelect}
            options={monthArr}
          />
          <Select
            defaultValue={year}
            style={{ width: 150 }}
            /*             onChange={handleChange}
             */ options={yearArr}
          />
        </Space>
      </div>
      <div className="button">
        <div
          className="btn-cancel"
          onClick={() => {
            props.onClose();
          }}
        >
          {t("dialog_btn_cancel")}
        </div>
        <div
          className="btn-ok"
          onClick={() => {
            props.handleAction(monthSelect, year, typeDate);
            props.onClose();
            setMonthSelect(month);
          }}
        >
          {t("btn_apply")}
        </div>
      </div>
    </div>
  );
}
