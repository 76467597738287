import "./style.scss";
import icClose from "../../../../assets/images/ic_close.svg";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import KitTableCustom from "../../../kitTableCustom";
import { useMemo, useState } from "react";
import { DATE_FORMAT_DOT, PAGE_SIZE } from "../../../../utils/constants";
import { ColumnsType } from "antd/lib/table";
import {
  useAprrovedPaymentMutation,
  useGetOrderMonthQuery,
  useUpdatePaybackStatusMutation,
} from "../../../../pages/SettlementManagement/settlementApi";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../customDialog/dialogSlice";
import { formatDateRange } from "../../../../utils/commonFnc";
import exportExcel from "../../../../utils/excel";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../../modalSlice";

export default function ProviderDetailModal(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleAction, onClose, data } = props;
  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState({
    page: 1,
    limit: PAGE_SIZE,
    ...data?.body,
  });

  const { data: tableData }: any = useGetOrderMonthQuery(params);
  const [approvePayment] = useAprrovedPaymentMutation();
  const navigate = useNavigate();

  const onHandleCloseClick = () => {
    onClose();
  };

  const columns: ColumnsType<any> = [
    {
      title: <div className="col-title">매칭일자</div>,
      dataIndex: "matchingAt",
      key: "matchingAt",
      width: 110,
      render(_, record) {
        return moment(record?.matchingAt)?.format(DATE_FORMAT_DOT);
      },
    },
    {
      title: <div className="col-title">거래처명</div>,
      dataIndex: "partner",
      key: "partner",
      ellipsis: true,
      width: 100,
      render(_, record) {
        return record?.providerPartner?.name || "해당 없음";
      },
    },
    {
      title: <div className="col-title">상차지</div>,
      dataIndex: "loadingPlace",
      key: "loadingPlace",
      render(_, record) {
        return `${record?.loadingPlace} ${record?.detailLoadingPlace}`;
      },
    },
    {
      title: <div className="col-title">하차지</div>,
      dataIndex: "receiptPlace",
      key: "receiptPlace",
      render(_, record) {
        return `${record?.receiptPlace} ${record?.detailReceiptPlace}`;
      },
    },
    {
      title: <div className="col-title">정산 상태</div>,
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 100,
      render(_, record) {
        return (
          <div
            className={data?.body?.settlementStatus === "waitingSettlement" ? "column-red-color" : "column-green-color"}
          >
            {data?.body?.settlementStatus === "waitingSettlement" ? "정산 대기" : "정산 완료"}
          </div>
        );
      },
    },
    {
      title: (
        <div className="col-title">
          운임 <span className="col-subtitle">(부가세 포함)</span>
        </div>
      ),
      dataIndex: "firstNicepayAmt",
      key: "firstNicepayAmt",
      ellipsis: true,
      render(value, record) {
        return `${record?.isCompensation ? "[회차]" : ""} ${new Intl.NumberFormat().format(Number(value))} 원`;
      },
    },
    {
      title: (
        <div className="col-title">
          추가결제 <span className="col-subtitle">(부가세 포함)</span>
        </div>
      ),
      dataIndex: "otherFeeNicepayAmt",
      key: "otherFeeNicepayAmt",
      ellipsis: true,
      render(value) {
        return `${new Intl.NumberFormat().format(Number(value))} 원`;
      },
    },
    {
      title: <div className="col-title">상세 정보</div>,
      dataIndex: "action",
      key: "action",
      width: 100,
      ellipsis: true,
      render(_, item) {
        return (
          <div
            className="column-blue-color"
            onClick={() => {
              dispatch(closeModal());
              navigate("/matching-management?id=" + item?.id, {
                state: {
                  prev: `/settlement-management/shipper-history/detail?id=${data?.body?.id}`,
                  id: data?.body?.id,
                  data: data?.item,
                  month: data?.body?.month,
                },
              });
            }}
          >
            상세 정보
          </div>
        );
      },
    },
  ];

  const onPayment = () => {
    dispatch(
      openDialog({
        type: "confirm",
        isActionFlip: true,
        content:
          data?.body?.settlementStatus === "waitingSettlement"
            ? "일괄 정산 하시겠습니까?"
            : "일괄 정산 취소 하시겠습니까?",
        confirmText: "닫기",
        closeText: "확인",
        actionConfirm: () => {
          try {
            const payload = {
              id: data?.body?.id,
              body: {
                settlementAction: data?.body?.settlementStatus === "waitingSettlement" ? "settlement" : "cancel",
                month: data?.body?.month,
              },
            };
            approvePayment(payload)
              .then((res) => {
                props.onClose();
              })
              .catch((error) => {
                dispatch(
                  openDialog({
                    type: "info",
                    content: error?.data?.message,
                    confirmText: t("dialog_btn_confirm"),
                  }),
                );
              });
          } catch (error) {
            console.log(error);
          }
        },
      }),
    );
  };

  const exportExcelColumn = [
    {
      title: `날짜`,
      key: "date",
      dataIndex: "date",
      align: "center",
    },
    {
      title: `거래처`,
      key: "company",
      dataIndex: "company",
      align: "center",
    },
    {
      title: `상차지`,
      key: "loadingPlace",
      dataIndex: "loadingPlace",
      align: "center",
    },
    {
      title: `하차지`,
      key: "receiptPlace",
      dataIndex: "receiptPlace",
      align: "center",
    },
    {
      title: `기사 정보`,
      key: "carrierInfo",
      dataIndex: "carrierInfo",
      align: "center",
    },
    {
      title: `차량`,
      key: "vehicleWeight",
      dataIndex: "vehicleWeight",
      align: "center",
    },
    {
      title: `차량`,
      key: "vehicleType",
      dataIndex: "vehicleType",
      align: "center",
    },
    {
      title: `상하차 방법`,
      key: "dischargeMethod",
      dataIndex: "dischargeMethod",
      align: "center",
    },
    {
      title: `배차 차량`,
      key: "carrierVehicleWeight",
      dataIndex: "carrierVehicleWeight",
      align: "center",
    },
    {
      title: `결제운임`,
      key: "cost",
      dataIndex: "cost",
      align: "center",
    },
    {
      title: `추가운임`,
      key: "additionalCost",
      dataIndex: "additionalCost",
      align: "center",
    },
    {
      title: `총운임`,
      key: "finalCost",
      dataIndex: "finalCost",
      align: "center",
    },
    {
      title: `부가세포함`,
      key: "costIncludeVAT",
      dataIndex: "costIncludeVAT",
      align: "center",
    },
    {
      title: `비고`,
      key: "note",
      dataIndex: "note",
      align: "center",
    },
  ];

  const exportData = useMemo(() => {
    return tableData?.data?.map((item: any) => {
      const carrierInfo = JSON.parse(item?.carrierInfo);
      return {
        date: `${item?.actualStartDate ? moment(item?.actualStartDate).format("YYYY.MM.DD") : ""} - ${
          item?.actualEndDate ? moment(item?.actualEndDate).format("YYYY.MM.DD") : ""
        }`,
        company: item?.provider?.company,
        carrierInfo: `${carrierInfo?.region || ""} ${carrierInfo?.licensePlate || ""} ${carrierInfo?.name || ""} ${
          carrierInfo?.phone || ""
        }`,
        loadingPlace: `${item?.loadingPlace} ${item?.detailLoadingPlace} `,
        receiptPlace: `${item?.receiptPlace} ${item?.detailReceiptPlace} `,
        cost: new Intl.NumberFormat().format(item?.freightCost),
        vehicleType: carrierInfo?.vehicleType,
        vehicleWeight: item?.tonnage,
        dischargeMethod: item?.packingMethod,
        carrierVehicleWeight: carrierInfo?.tonnage,
        costIncludeVAT: new Intl.NumberFormat().format(item?.firstNicepayAmt + item?.otherFeeNicepayAmt),
        additionalCost: item?.otherFee ? Math.abs(item?.otherFee) : 0,
        finalCost: new Intl.NumberFormat().format(
          Number(
            item?.freightCost +
              (item?.couponDiscountAmount ? item?.couponDiscountAmount : 0) +
              (item?.pointDiscountAmount ? item?.pointDiscountAmount : 0) +
              item?.otherFee,
          ),
        ),
        note: item?.requirement,
      };
    });
  }, [tableData?.data]);

  const onExcelDownload = async () => {
    try {
      await exportExcel(exportExcelColumn, exportData, "후불 화주 정산내역", "시트 1");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="provider-detail-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="content-left">
            <span className="content-left-child content-left-1">총 {tableData?.totalOrder}건</span>
            <span className="content-left-child content-left-2">총 운임</span>
            <span className="content-left-child content-left-3">
              {new Intl.NumberFormat().format(Number(data?.header?.cost))}
              <span className="money-unit">원</span>
            </span>{" "}
            <span className="content-left-child content-left-4">{formatDateRange(data?.body?.month)}</span>
          </div>
          <div className="content-right">
            <Button className="content-right-btn" onClick={onExcelDownload}>
              엑셀 다운로드
            </Button>
            <Button
              className={`content-right-btn ${
                data?.body?.settlementStatus === "waitingSettlement" ? "" : "cancel-setlement"
              }`}
              onClick={onPayment}
            >
              {data?.body?.settlementStatus === "waitingSettlement" ? "정산하기" : "정산 취소"}
            </Button>
          </div>
        </div>
        <div className="content-table">
          <KitTableCustom
            scroll={{ x: 1000, y: 500 }}
            className="provider-detail-table"
            isShowSearch={false}
            params={params}
            setParams={setParams}
            dataSource={tableData?.data}
            columns={columns}
            rowKey="id"
            metaData={metaData}
            footerAction={null}
          />
        </div>
      </div>
    </div>
  );
}
