import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./style.scss";
import { Input, Pagination, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useGetShippersQuery } from "../../pages/MemberManagement/memberApi";
import { SEARCH_ORDER } from "../../utils/constants";
import { openDialog } from "../customDialog/dialogSlice";
import { useCreateCouponMutation } from "../../pages/CouponManagement/couponApi";
import { closeModal } from "../customModal/modalSlice";
import { formatPhoneNumber } from "../../utils/utils";
import { debounce } from "lodash";

const limits = [
  { label: "10개씩 보기", value: 10 },
  { label: "20개씩 보기", value: 20 },
];

interface Props {
  params: any;
  onBack: () => void;
}

const FindMember: FC<Props> = ({ params, onBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createCoupon] = useCreateCouponMutation();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [debounceSearchInput, setDebounceSearchInput] = useState("");
  const [allData, setAllData] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useState("");
  const [selectedProviders, setSelectedProviders] = useState<any>({});

  const { data, isSuccess, isFetching } = useGetShippersQuery(searchParams, {
    refetchOnMountOrArgChange: true,
    skip: searchParams.length > 0 ? false : true,
  });

  useEffect(() => {
    let searchStr = `page=${page}&limit=${limit}&${SEARCH_ORDER}`;
    if (debounceSearchInput.length > 0) {
      searchStr +=
        "&searchColumn[]=representationName&searchColumn[]=phoneContact&searchColumn[]=company&searchColumn[]=picPhone";
      searchStr += `&keyword=${debounceSearchInput}`;
    }
    setSearchParams(searchStr);
  }, [page, limit, debounceSearchInput]);

  useEffect(() => {
    if (isSuccess) {
      setAllData(data?.data);
      setTotal(data?.meta?.total);
    }
  }, [data]);

  /* ------------------------------- 유저의 정보 가져오기 ------------------------------ */
  const getMemberType = (type: string) => {
    switch (type) {
      case "BUSINESS":
        return t("business_shipper");
      case "PAY_LATER":
        return t("paylater_shipper");
      default:
        return t("general_member");
    }
  };

  /* ---------------------------------- 검색 처리 --------------------------------- */
  const debouncedSearchHandler = useCallback(
    debounce((value) => {
      setDebounceSearchInput(value);
      setPage(1);
    }, 500),
    [],
  );

  /* -------------------------------- 글자 제한 변경 -------------------------------- */
  const onChangeLimit = (value: number) => {
    setLimit(value);
    setPage(1);
  };

  /* ------------------------------- 선택한 페이지 변경 ------------------------------- */
  const onChangePage = (page: number) => {
    setPage(page);
  };

  const columns: ColumnsType<any> = [
    {
      title: t("assortment"),
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "25%",
      render(value) {
        return <div>{getMemberType(value)}</div>;
      },
    },
    {
      title: t("회사명/이름"),
      dataIndex: "username",
      key: "username",
      align: "center",
      width: "33%",
      render(_, record) {
        return <div className="name">{record.type === "NORMAL" ? record?.representationName : record?.company}</div>;
      },
    },
    {
      title: t("contact"),
      dataIndex: "phoneContact",
      key: "phoneContact",
      align: "center",
      width: "33%",
      render(_, record) {
        return formatPhoneNumber(record?.phoneContact || record?.picPhone);
      },
    },
  ];

  const getRowKeys = () => {
    return Object.keys(selectedProviders).map((id) => Number(id));
  };
  const selected = getRowKeys();

  /* -------------------------------- 모두 선택 처리 -------------------------------- */
  const onSelectAllHandler = (checked: boolean, selectedRows: any[], changeRows: any[]) => {
    const changeRowsIds = changeRows.map((row) => row.id);
    const changeRowsObject: { [key: string]: any } = changeRows.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    let newSelected = { ...selectedProviders };
    if (checked) {
      newSelected = { ...selectedProviders, ...changeRowsObject };
    } else {
      changeRowsIds.forEach((key: string) => {
        if (newSelected.hasOwnProperty(key)) {
          delete newSelected[key as keyof typeof newSelected];
        }
      });
    }
    setSelectedProviders(newSelected);
  };

  /* ------------------------------- 각 항목별 선택 처리 ------------------------------ */
  const onSelectHandler = (record: any, checked: boolean) => {
    const deletedProviderId = record.id;
    let newSelected = { ...selectedProviders };
    if (checked) {
      newSelected = { ...selectedProviders, ...{ [record.id]: record } };
    } else {
      if (newSelected.hasOwnProperty(deletedProviderId)) {
        delete newSelected[deletedProviderId as keyof typeof newSelected];
      }
    }
    setSelectedProviders(newSelected);
  };

  const removeSelected = (id: string | number) => {
    const newSelected = { ...selectedProviders };
    if (newSelected.hasOwnProperty(id)) {
      delete newSelected[id as keyof typeof newSelected];
    }
    setSelectedProviders(newSelected);
  };

  const resetSelected = () => {
    setSelectedProviders({});
  };

  const onPaymentCoupon = () => {
    if (selected.length > 0) {
      dispatch(
        openDialog({
          type: "confirm",
          title: t("pay_coupon_content"),
          content: t("pay_coupon_title"),
          confirmText: t("dialog_btn_cancel"),
          closeText: t("dialog_btn_confirm"),
          actionCancel: async () => {
            const body = { ...params };
            body.providerIds = selected;
            const res = await createCoupon(body); //@ts-ignore
            showResult(res.error, t("coupon_issued"));
          },
        }),
      );
    }
  };

  const selectedCouponList = useMemo(() => {
    const groupedData: any = [];
    let formatArray: any = [];

    Object.entries(selectedProviders)?.map(([key, value]: any) => {
      formatArray?.push({ [key]: value });
    });

    for (let i = 0; i < formatArray?.length; i += 6) {
      groupedData?.push(formatArray?.slice(i, i + 6));
    }

    return groupedData;
  }, [selectedProviders]);

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: error?.data?.message ?? msgSuccess,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          dispatch(closeModal());
          if (!error?.data?.message) {
            onBack();
          }
        },
      }),
    );
  };

  return (
    <div className="find-member">
      <div className="top-content">
        <div className="title">{t("payment_designated")}</div>
        <div className="sub-title">{t("find_member")}</div>
        <div className="search-wrap">
          <div className="search-title">{t("shipper_name_or_contact")}</div>
          <Input
            className="search-input"
            placeholder={t("shipper_name_or_contact")}
            maxLength={15}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debouncedSearchHandler(e.target.value);
            }}
          />
          {/* <div className="base-btn btn-search" onClick={onSearchName}>
            {t("search")}
          </div> */}
        </div>
      </div>
      <div className="body">
        <div className="selected-wrap">
          <div className="selected-row">
            {selectedCouponList?.map((row: any) => {
              return (
                <div className="row-item">
                  {row?.map((rowItem: any) => {
                    const value: any = Object?.values(rowItem)[0];
                    return (
                      <div className="selected" key={`item-${Object?.keys(rowItem)[0]}`}>
                        <div>{value?.type === "NORMAL" ? value?.representationName : value?.company}</div>
                        <div className="remove" onClick={() => removeSelected(Object?.keys(rowItem)[0])}>
                          (X)
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="button-wrap">
          <div className={`base-btn button ${selected.length > 0 ? "" : "disabled"}`} onClick={resetSelected}>
            {t("reset")}
          </div>
          <div className={`base-btn button ${selected.length > 0 ? "" : "disabled"}`} onClick={onPaymentCoupon}>
            {t("make_payment")}
          </div>
        </div>

        <div className="table-wrap">
          <div className="table-title">{t("full_list_shipper")}</div>
          <div className="table-limit-wrap">
            <div className="table-total">{`총 ${total} 명`} </div>
            <Select className="limit-select" options={limits} value={limit} onChange={onChangeLimit} />
          </div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={allData}
            locale={{ emptyText: t("no_data") }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selected,
              onSelectAll: onSelectAllHandler,
              onSelect: onSelectHandler,
            }}
            rowKey="id"
            className="table-shipper"
            loading={isFetching}
          />
          {total > limit && (
            <div className="pagination">
              <Pagination
                current={page}
                total={total}
                pageSize={limit}
                onChange={onChangePage}
                showSizeChanger={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindMember;
