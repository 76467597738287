import { downloadFileLink } from "../../utils/commonFnc";
import { getFileName } from "../../utils/utils";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { truncate } from "lodash";
import { ENotice } from "../../pages/NoticeManagement";
import moment from "moment-timezone";

export default function PostDetail({
  title,
  date,
  content,
  imageUrl = null,
  fileUrls = [],
  isShowNext,
  isShowPrev,
  onBack,
  onPrev,
  onNext,
  onEdit,
  onDelete,
  fromType,
  faqInfo,
}: any) {
  const { t } = useTranslation();

  const onHandleEditClick = () => {
    onEdit();
  };

  /* ----------------------------------- 삭제 ----------------------------------- */
  const onHandleDeleteClick = () => {
    onDelete();
  };

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBackClick = () => {
    onBack();
  };

  const onPrevPostClick = () => {
    if (isShowPrev) {
      onPrev();
    }
  };

  const onNextPostClick = () => {
    if (isShowNext) {
      onNext();
    }
  };

  return (
    <div className="post-detail">
      <div className="header-wrapper column-wrapper">
        <div className="header-container">
          <div className="header-left">
            {fromType && (
              <div className="post-btn">
                <div className="post-text">{fromType === ENotice.PROVIDER ? "화주" : "운송인"}</div>
              </div>
            )}
            <div className="title-text">
              {truncate(title, {
                length: 25,
                separator: " ",
              })}
            </div>
            <div className="date-text">{moment(date).isValid() && moment(date).format("YYYY.MM.DD hh:mm:ss A")}</div>
          </div>
          <div className="header-right">
            <div className="button-wrapper row-wrapper">
              <div className="base-btn" onClick={onBackClick}>
                <div className="btn-text">{t("list")}</div>
              </div>
              <div className={`base-btn ml-12 ${isShowPrev ? "" : "disabled"}`} onClick={onPrevPostClick}>
                <div className={`btn-text ${isShowPrev ? "" : "disabled"}`}>{t("previous_post")}</div>
              </div>
              <div className={`base-btn ml-12 ${isShowNext ? "" : "disabled"}`} onClick={onNextPostClick}>
                <div className={`btn-text ${isShowNext ? "" : "disabled"}`}>{t("next_post")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="files">
          {fileUrls?.map((file: any, index: number) => (
            <div
              key={index}
              className="file text-ellipsis"
              onClick={() => {
                downloadFileLink(file.url, file.url);
              }}
            >
              {getFileName(file?.url)}
            </div>
          ))}
        </div>
      </div>
      <div className="content-wrapper">
        {/* {imageUrl && <img className="image" src={imageUrl} alt="detail" />} */}
        <div className="content-text" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
      <div className="footer-wrapper">
        <div className="row-wrapper buttons">
          <div className="base-btn" onClick={onHandleEditClick}>
            <div className="btn-text">{t("modify")}</div>
          </div>
          <div className="base-btn" onClick={onHandleDeleteClick}>
            <div className="btn-text cancel-text">{t("delete")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
