/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";
import queryString from "query-string";

export const notificationApi = createApi({
  reducerPath: "notificationApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetNotifications"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (data) => {
        let params;
        const { searchOr, ...rest } = data;
        const restParams = queryString.stringify(rest);
        const scParams = queryString.stringify(
          { ["searchOr"]: searchOr },
          {
            arrayFormat: "bracket",
          }
        );

        if (!!scParams) {
          params = restParams?.concat(`&${scParams}`);
        } else {
          params = restParams;
        }

        return {
          url: `/notifications?${params}`,
          method: "GET",
        };
      },
      //@ts-ignore
      providesTags: ["GetNotifications"],
    }),
    createNotifications: builder.mutation({
      query: (data) => ({
        url: `notifications`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetNotifications"],
    }),
    updateNotifications: builder.mutation({
      query: (data) => ({
        url: `notifications/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetNotifications"],
    }),
    deleteNotifications: builder.mutation({
      query: (data) => ({
        url: `notifications/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetNotifications"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useCreateNotificationsMutation,
  useUpdateNotificationsMutation,
  useDeleteNotificationsMutation,
} = notificationApi;
