import { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Radio, Select } from "antd";
import FormUpload from "../../../formUpload";
import { ENotice } from "../../../../pages/NoticeManagement";
import moment from "moment-timezone";
import { useCreatePopupMutation, useUpdatePopupMutation } from "../../../../pages/PopupManagement/popupApi";
import { closeDialog, openDialog } from "../../../customDialog/dialogSlice";
import { useDispatch } from "react-redux";
import { DATE_FORMAT } from "../../../../utils/constants";
import { closeModal } from "../../modalSlice";

export enum EPopup {
  TARGET = "target",
  TITLE = "title",
  IMAGE_PC = "imagePc",
  IMAGE_SP = "imageSp",
  START_DATE = "startDate",
  END_DATE = "endDate",
  SHOW_TYPE = "showType",
  STATUS = "status",
  TARGET_LINK_PC = "targetLinkPC",
  TARGET_LINK_SP = "targetLinkSP",
  CLOSE = "close",
}

const closeOption = [
  { label: "하루동안 안보기", value: "DAY" },
  { label: "일주일동안 안보기", value: "WEEK" },
];

const useOption = [
  { label: "사용", value: "USE" },
  { label: "미사용", value: "NOT_USE" },
];

export default function PopupAction(props: any) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const target = Form.useWatch(EPopup.TARGET, form);
  const titleWatch = Form.useWatch(EPopup.TITLE, form);
  const imageSpWatch = Form.useWatch(EPopup.IMAGE_SP, form);
  const imagePcWatch = Form.useWatch(EPopup.IMAGE_PC, form);
  const startDateWatch = Form.useWatch(EPopup.START_DATE, form);
  const endDateWatch = Form.useWatch(EPopup.END_DATE, form);

  const { onClose, handleAction, data } = props;
  const isCreateForm = data?.type === "create";
  const isEditForm = data?.type === "edit";
  const [createNotifications] = useCreatePopupMutation();
  const [updateNotifications] = useUpdatePopupMutation();

  /* ------------------------------ dialog 확인 취소 ------------------------------ */
  const handleCancel = () => {
    onClose();
  };

  const isValidForm = useMemo(() => {
    if (target === ENotice.PROVIDER) {
      return !!(titleWatch && (imageSpWatch || imagePcWatch) && startDateWatch && endDateWatch);
    } else if (target === ENotice.CARRIER) {
      return !!(titleWatch && imageSpWatch && startDateWatch && endDateWatch);
    }
    return true;
  }, [target, titleWatch, imagePcWatch, imageSpWatch, startDateWatch, endDateWatch]);

  useEffect(() => {
    if (isEditForm) {
      const {
        createdAt,
        status,
        startDate,
        endDate,
        imagePc,
        imageSp,
        showType,
        title,
        target,
        targetLinkPC,
        targetLinkSP,
      } = data.data;

      form.setFieldsValue({
        [EPopup.TITLE]: title,
        targetLinkPC,
        targetLinkSP,
        [EPopup.IMAGE_PC]: JSON.parse(imagePc)?.url,
        [EPopup.IMAGE_PC.concat("Src")]: JSON.parse(imagePc)?.name,
        [EPopup.IMAGE_SP]: JSON.parse(imageSp)?.url,
        [EPopup.IMAGE_SP.concat("Src")]: JSON.parse(imageSp)?.name,
        [EPopup.START_DATE]: moment(startDate),
        [EPopup.END_DATE]: moment(endDate),
        [EPopup.TARGET]: target,
        [EPopup.SHOW_TYPE]: showType,
        [EPopup.STATUS]: status,
      });
    }
  }, [isEditForm]);

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };

  const disabledDate = (current: any) => {
    if (form.getFieldValue(EPopup.START_DATE)) {
      return current && current < form.getFieldValue(EPopup.START_DATE);
    }
  };

  const onFinish = async (values: any) => {
    if (isValidForm) {
      const { imagePc, imageSp, imagePcSrc, imageSpSrc, startDate, endDate, ...rest } = values;

      const cloneValues: any = { ...rest };
      const imagePcFormat = {
        name: form.getFieldValue("imagePcSrc"),
        url: imagePc,
      };
      const imageSpFormat = {
        name: form.getFieldValue("imageSpSrc"),
        url: imageSp,
      };
      cloneValues["imagePc"] = imagePcFormat;
      cloneValues["imageSp"] = imageSpFormat;
      cloneValues["startDate"] = moment(startDate).format(DATE_FORMAT);
      cloneValues["endDate"] = moment(endDate).format(DATE_FORMAT);
      dispatch(closeModal());
      if (isCreateForm) {
        dispatch(
          openDialog({
            type: "confirm",
            content: "팝업을 등록 하시겠습니까?",
            confirmText: "닫기",
            closeText: "확인",
            actionCancel: () => {
              createNotifications(cloneValues)
                .unwrap()
                .then()
                .finally(() => dispatch(closeDialog()));
            },
          }),
        );
      } else if (isEditForm) {
        dispatch(
          openDialog({
            type: "confirm",
            content: "팝업을 수정 하시겠습니까?",
            confirmText: "닫기",
            closeText: "확인",
            actionCancel: () => {
              updateNotifications({ id: data?.data?.id, body: cloneValues })
                .unwrap()
                .then()
                .finally(() => dispatch(closeDialog()));
            },
          }),
        );
      }
    }
  };

  return (
    <div className="modal-popup-action">
      <div className="popup-header">
        {data?.title} <CloseOutlined onClick={handleCancel} />
      </div>
      <div className="popup-form">
        <Form
          className="popup-form--customize"
          {...layout}
          form={form}
          name="control-hooks"
          labelAlign="left"
          onFinish={onFinish}
          colon={false}
          initialValues={{
            [EPopup.TARGET]: ENotice.PROVIDER,
            [EPopup.START_DATE]: moment(),
            [EPopup.END_DATE]: moment(),
            [EPopup.SHOW_TYPE]: "DAY",
            [EPopup.STATUS]: "USE",
          }}
        >
          <Form.Item name={EPopup.TARGET} hidden>
            <Radio.Group className="popup-form-radio-group" defaultValue={1}>
              <Radio value={ENotice.PROVIDER}>
                <div className="radio-button">화주</div>
              </Radio>
              <Radio value={ENotice.CARRIER}>
                <div className="radio-button">운송인</div>
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={EPopup.TITLE} label="팝업명">
            <Input placeholder="팝업명 입력" min={1} maxLength={20} />
          </Form.Item>
          <Form.Item label="팝업 이미지">
            {target !== ENotice.CARRIER && <FormUpload label="PC" form={form} nameField={EPopup.IMAGE_PC} />}

            <FormUpload
              label={target !== ENotice.CARRIER ? "Mobile" : undefined}
              form={form}
              nameField={EPopup.IMAGE_SP}
            />
          </Form.Item>
          <Form.Item label="팝업 기간">
            <div className="date-range-picker">
              <Form.Item name={EPopup.START_DATE}>
                <DatePicker />
              </Form.Item>
              ~
              <Form.Item name={EPopup.END_DATE}>
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item name={EPopup.SHOW_TYPE} label="닫기 옵션">
            <Select options={closeOption} className="select-close-field" />
          </Form.Item>
          <Form.Item name={EPopup.STATUS} label="사용 여부">
            <Select options={useOption} className="select-use-status" />
          </Form.Item>
          {target !== ENotice.CARRIER && (
            <Form.Item label="팝업 링크" className="ant-form-item--noMargin">
              <span>PC</span>
              <Form.Item name={EPopup.TARGET_LINK_PC} className="ant-form-item--nested">
                <Input disabled={!imagePcWatch && !imageSpWatch} placeholder="링크를 붙어 넣어주세요" />
              </Form.Item>
            </Form.Item>
          )}
          {/* <Form.Item label={target !== ENotice.CARRIER ? <>&nbsp;</> : "팝업 링크"}>
            {target !== ENotice.CARRIER && <span>Mobile</span>}
            <Form.Item name={EPopup.TARGET_LINK_SP} className="form-item--nested">
              <Input disabled={!imageSpWatch} placeholder="링크를 붙어 넣어주세요 " />
            </Form.Item>
          </Form.Item> */}
          <div className="popup-modal-footer">
            <Button
              className={`popup-btn--submit ${isValidForm ? "popup-modal-submit" : "popup-modal-submit--disabled"}`}
              onClick={() => form.submit()}
            >
              {isEditForm ? "수정 완료" : "등록"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
