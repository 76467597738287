/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const faqApi = createApi({
  reducerPath: "faqApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetFaqs"],
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: (data) => ({
        url: `/faqs`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetFaqs"],
    }),
    createFaqs: builder.mutation({
      query: (data) => ({
        url: `faqs`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetFaqs"],
    }),
    updateFaqs: builder.mutation({
      query: (data) => ({
        url: `faqs/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetFaqs"],
    }),
    deleteFaqs: builder.mutation({
      query: (data) => ({
        url: `faqs/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetFaqs"],
    }),
  }),
});

export const {
  useGetFaqsQuery,
  useCreateFaqsMutation,
  useUpdateFaqsMutation,
  useDeleteFaqsMutation
} = faqApi;
