/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const fapCategoryApi = createApi({
  reducerPath: "faqCategoryApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetFaqCategories"],
  endpoints: (builder) => ({
    getFaqCategories: builder.query({
      query: (data) => ({
        url: `/faqs/category/list`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetFaqCategories"],
    }),
    swapFaqCategoryOrder: builder.mutation({
      query: (data) => ({
        url: `faqs/category/swap-order`,
        method: "PATCH",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetFaqCategories"],
    }),
    createFaqCategory: builder.mutation({
      query: (data) => ({
        url: `faqs/category/create`,
        method: "POST",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetFaqCategories"],
    }),
    editFaqCategory: builder.mutation({
      query: (data) => ({
        url: `faqs/category/update/${data.id}`,
        method: "PUT",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetFaqCategories"],
    }),
    deleteFaqCategory: builder.mutation({
      query: (data) => ({
        url: `faqs/category/delete/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetFaqCategories"],
    }),
  }),
});

export const {
  useSwapFaqCategoryOrderMutation,
  useGetFaqCategoriesQuery,
  useCreateFaqCategoryMutation,
  useEditFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
} = fapCategoryApi;
