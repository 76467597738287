import CarrierSettlementDetail from "../../../../pages/SettlementManagement/CarrierSettlementDetail";
import icClose from "../../../../assets/images/ic_close.svg";
import { useTranslation } from "react-i18next";
import "./style.scss";

function CarrierSettlementDetailModal(props: any) {
  const { data } = props;
  const { t } = useTranslation();
  const onHandleCloseClick = () => {
    props.onClose();
  };

  return (
    <div className="detail-settlement-wrapper">
      <div className="header-wrapper">
        <div className="title">{t("more_information")}</div>
        <div className="close-btn" onClick={onHandleCloseClick}>
          <img className="ic-close" alt="Close" src={icClose} />
        </div>
      </div>
      <div className="content-wrapper">
        <CarrierSettlementDetail data={data} />
      </div>
    </div>
  );
}

export default CarrierSettlementDetailModal;
