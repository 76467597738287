/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { STATUS_WAITING_PAYMENT, STATUS_DONE, DATE_FORMAT_DOT, PER_PAGE } from "../../utils/constants";
import { formatNumberToCurrency, formatPhoneNumber, objectToQueryString } from "../../utils/utils";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { ICarrier, IOrder } from "../../utils/types/order";
import {
  useCancelSettlementMutation,
  useGetExcelFileMutation,
  useGetOrdersQuery,
  useUpdateDoneMultipleSettlementMutation,
} from "../DispatchManagement/dispatchApi";
import { getDateFromData, getDateToData } from "../../utils/commonFnc";
import NewTable from "../../components/NewTable";
import { useNavigate } from "react-router-dom";

export interface ICarrierSettlement {
  id: string;
  completeDateAndTime?: string | null;
  adminPaidDate?: string | null;
  name: string; //carrier.username
  nickname: string; //carrier.nickname
  contact: string; //carrier.phone
  vehicleNumber: string; //carrier.licensePlate
  region: string; //carrier.region
  bank?: string; // carrier.bank
  bankAccount: string; // carrier.accountBank
  settlementRequestAmount?: string; //freightCost
  carrierPaymentAmountTax?: string;
  carrierSettlementAmount?: string;
  settlementStatus: string; //status
  carrier: ICarrier; //carrier
  order: IOrder;
}

export enum ECarrierSettlementStatus {
  ALL,
  ISSUANCE_COMPLETED,
  SETTLEMENT_COMPLETED,
}

export interface IParams {
  page: number;
  limit: number;
  status: ECarrierSettlementStatus;
  search: string;
  order: string;
  orderBy: string;
  startDate: null;
  endDate: null;
}

export default function SettlementCarrierSettlement() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const statusOptions = {
    ALL: {
      label: t("all"),
      value: ECarrierSettlementStatus.ALL,
    },
    [STATUS_WAITING_PAYMENT]: {
      label: t("정산요청"),
      value: ECarrierSettlementStatus.ISSUANCE_COMPLETED,
    },
    [STATUS_DONE]: {
      label: t("settlement_completed"),
      value: ECarrierSettlementStatus.SETTLEMENT_COMPLETED,
    },
  };

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    { label: t("name"), value: "carriername" },
    { label: t("nickname"), value: "carriernickname" },
    { label: t("contact"), value: "carrierphone" },
    { label: t("vehicle_number"), value: "carrierlicensePlate" },
  ];

  const [filters, setFilters] = useState<string[]>([
    "carriername",
    "carriernickname",
    "carrierphone",
    "carrierlicensePlate",
  ]);
  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: PER_PAGE,
    status: ECarrierSettlementStatus.ALL,
    search: "",
    order: "desc",
    orderBy: JSON.stringify([{ requestBillAt: "desc" }, { adminPaidDate: "desc" }]),
    startDate: null,
    endDate: null,
  });
  const [paramsString, setParamsString] = useState("");

  const [arrData, setArrData] = useState<ICarrierSettlement[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [selectedRowkeys, setSelectedRowkeys] = useState<number[]>([]);
  const [cancelSettlement] = useCancelSettlementMutation();
  const [updateDoneMultipleSettlement] = useUpdateDoneMultipleSettlementMutation();

  /* ------------------------------- 운송인의 디테일 열기 ------------------------------ */
  const onOpenCarrierDetail = (request: any) => {
    if (request) {
      dispatch(
        openModal({
          template: "show-carrier-detail-settlement-modal",
          width: "818px",
          data: {
            info: request,
          },
          handleAction: (status: any) => {
            setParams({ ...params, page: 1 });
            refetch();
            resetSelect();
            // callGetCarriers();
          },
        }),
      );
    }
  };

  /* --------------------------------- 검색 값 변경 -------------------------------- */
  const transformParams = (params: Partial<IParams>, downloadExcel = false) => {
    const newFilters: any = { ...params };
    let querySearchColumn = "";

    // search Column
    if (params.search) {
      newFilters["keyword"] = params.search;
      // newFilters['searchColumn'] = filters;
      filters.forEach((e) => (querySearchColumn += `&searchColumn[]=${e}`));
    } else {
      delete newFilters.keyword;
    }

    newFilters["searchAnd"] = [];
    newFilters["searchOr"] = [];
    // search by status
    if (params.status === ECarrierSettlementStatus.ALL) {
      [STATUS_WAITING_PAYMENT, STATUS_DONE].forEach((val) => {
        newFilters["searchOr"].push(JSON.stringify({ status: val }));
      });
    } else if (params.status === ECarrierSettlementStatus.ISSUANCE_COMPLETED) {
      newFilters["searchAnd"] = [];
      newFilters["searchAnd"].push(JSON.stringify({ status: STATUS_WAITING_PAYMENT }));
    } else if (params.status === ECarrierSettlementStatus.SETTLEMENT_COMPLETED) {
      newFilters["searchAnd"] = [];
      newFilters["searchAnd"].push(JSON.stringify({ status: STATUS_DONE }));
    }
    newFilters["searchAnd"].push(JSON.stringify({ isCash: false }));

    if (
      [ECarrierSettlementStatus.ALL, ECarrierSettlementStatus.SETTLEMENT_COMPLETED].includes(
        params.status as ECarrierSettlementStatus,
      )
    ) {
      newFilters["orderBy"] = JSON.stringify([{ requestBillAt: "desc" }, { adminPaidDate: "desc" }]);
    } else {
      newFilters["orderBy"] = "requestBillAt";
    }

    if (params.startDate) {
      newFilters["from"] = getDateFromData(params.startDate);
      newFilters["fieldSearchTime"] = "requestBillAt";
    } else {
      delete newFilters.from;
    }
    if (params.endDate) {
      newFilters["to"] = getDateToData(params.endDate);
      newFilters["fieldSearchTime"] = "requestBillAt";
    } else {
      delete newFilters.to;
    }
    delete newFilters.status;
    delete newFilters.search;
    delete newFilters.startDate;
    delete newFilters.endDate;
    if (downloadExcel) {
      newFilters["ids[]"] = selectedRowkeys;
    }
    return objectToQueryString(newFilters) + querySearchColumn;
  };

  useEffect(() => {
    setParamsString(transformParams(params));
  }, [params]);

  const { data, error, isLoading, isSuccess, isFetching, refetch } = useGetOrdersQuery(paramsString, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    if (error && "status" in error && !isNaN(+error?.status) && +error?.status >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      let arr: ICarrierSettlement[] = [];
      data?.data.map((item: IOrder) => {
        let itemData: ICarrierSettlement = {
          ...item,
          id: item.id,
          name: item.carrier?.name,
          nickname: item.carrier?.nickname,
          contact: formatPhoneNumber(item.carrier?.phone ?? ""),
          vehicleNumber: item.carrier?.licensePlate,
          region: item.carrier?.region,
          bank: item.carrier?.bank,
          bankAccount: item.carrier?.accountBank,
          carrierPaymentAmountTax: `${formatNumberToCurrency(item.carrierPaymentAmountTax)} ${t("won")}`,
          carrierSettlementAmount: `${formatNumberToCurrency(item.carrierSettlementAmount)} ${t("won")}`,
          settlementStatus: item.status,
          carrier: item.carrier,
          order: item,
        };
        arr.push(itemData);
      });
      setArrData(arr);
      setMetaData(data?.meta);
    } else {
      setArrData([]);
    }
  }, [isSuccess, isLoading, JSON.stringify(data)]);

  const columns: ColumnsType<ICarrierSettlement> = useMemo(() => {
    const cols: ColumnsType<ICarrierSettlement> = [
      {
        title: "발행일시",
        dataIndex: "requestBillAt",
        key: "requestBillAt",
        width: "10%",
        ellipsis: true,
        render: (requestBillAt) => (
          <div>
            {moment(requestBillAt).isValid() && (
              <>
                <span>{moment(requestBillAt).format(DATE_FORMAT_DOT)}</span>
                <br />
                <span>{moment(requestBillAt).format("hh:mm A")}</span>
              </>
            )}
          </div>
        ),
      },
      {
        title: t("name"),
        dataIndex: "name",
        key: "name",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("nickname"),
        dataIndex: "nickname",
        key: "nickname",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("contact"),
        dataIndex: "contact",
        key: "contact",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("vehicle_number"),
        dataIndex: "vehicleNumber",
        key: "vehicleNumber",
        width: "10%",
        ellipsis: true,
        render: (_, record: ICarrierSettlement) => (
          <>
            {record?.region || "-"} {record?.vehicleNumber || "-"}
          </>
        ),
      },
      {
        title: t("bank"),
        dataIndex: "bank",
        key: "bank",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("account_number"),
        dataIndex: "bankAccount",
        key: "bankAccount",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("계산서 금액"),
        dataIndex: "carrierPaymentAmountTax",
        key: "carrierPaymentAmountTax",
        width: "10%",
        ellipsis: true,
      },
      {
        title: "정산 최종 금액",
        dataIndex: "carrierSettlementAmount",
        key: "carrierSettlementAmount",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("settlement_status"),
        dataIndex: "settlementStatus",
        key: "settlementStatus",
        width: "10%",
        ellipsis: true,
        render: (status) => (
          <div className={`${status === `${STATUS_WAITING_PAYMENT}` ? "pending-approval" : "approved"}`}>
            {statusOptions?.[status as keyof typeof statusOptions]?.["label"] ?? ""}
          </div>
        ),
      },
      {
        title: t("more_information"),
        dataIndex: "carrier",
        key: "carrier",
        width: "10%",
        ellipsis: true,
        render: (_, order: ICarrierSettlement) => (
          <span
            className="more-information"
            style={{ color: "#2395FF", cursor: "pointer" }}
            // onClick={() => {
            //   onOpenCarrierDetail(order);
            // }}

            onClick={() => {
              // navigate(`/settlement-management/carrier-settlement/${order?.id}`);
              dispatch(
                openModal({
                  template: "show-new-carrier-detail-settlements-modal",
                  width: "1128px",
                  data: {
                    id: order?.id,
                  },
                }),
              );
            }}
          >
            {t("more_information")}
          </span>
        ),
      },
    ];

    if ([ECarrierSettlementStatus.ALL, ECarrierSettlementStatus.SETTLEMENT_COMPLETED].includes(params.status)) {
      cols.unshift({
        title: t("정산완료일시"),
        dataIndex: "adminPaidDate",
        key: "adminPaidDate",
        width: "10%",
        ellipsis: true,
        render: (adminPaidDate, item: any) => {
          return item.status === STATUS_DONE ? (
            <div>
              {moment(adminPaidDate).isValid() && (
                <span className="complete-date-time">
                  <span>{moment(adminPaidDate).format(DATE_FORMAT_DOT)}</span>
                  <br />
                  <span>{moment(adminPaidDate).format("hh:mm A")}</span>
                </span>
              )}
            </div>
          ) : (
            <></>
          );
        },
      });
    }
    return cols;
  }, [params.status]);

  const [getExcelFileMutation] = useGetExcelFileMutation();

  const handleExcelDownload = async () => {
    try {
      const { page, limit, ...rest } = params;
      const res = await getExcelFileMutation({
        params: transformParams(rest, true),
      }).unwrap();

      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");

      downloadLink.href = blobUrl;

      downloadLink.download = "dispatch.xlsx";

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(blobUrl);
    } catch (error) { }
  };

  const handleSelectChange = (key: number[], records: any[]) => {
    setSelectedRecords(records);
    setSelectedRowkeys(key);
  };

  useEffect(() => {
    setSelectedRecords([]);
  }, [params.status]);

  const resetSelect = () => {
    setSelectedRecords([]);
    setSelectedRowkeys([]);
  };

  const handleSettlementCancel = () => {
    if (selectedRowkeys?.length === 0) {
      dispatch(
        openDialog({
          type: "info",
          content: "선택된 건이 없습니다.",
          confirmText: "확인",
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "confirm",
          content: `정산취소 처리하시겠습니까?`,
          confirmText: t("dialog_btn_cancel"),
          closeText: t("dialog_btn_confirm"),
          actionCancel: async () => {
            try {
              const body = {
                ids: selectedRowkeys,
              };
              const res = await cancelSettlement(body).unwrap();
              if (res.status === "success") {
                dispatch(
                  openDialog({
                    type: "info",
                    content: "완료 되었습니다.",
                    confirmText: "확인",
                  }),
                );
                refetch();
                resetSelect();
              }
            } catch (error) {
              console.error(error);
            } finally {
              dispatch(closeModal);
            }
          },
        }),
      );
    }
  };

  const handleSettlementDone = () => {
    if (selectedRowkeys?.length === 0) {
      dispatch(
        openDialog({
          type: "info",
          content: "선택된 건이 없습니다.",
          confirmText: "확인",
        }),
      );
      return;
    }

    dispatch(
      openDialog({
        type: "confirm",
        content: `정산완료 처리하시겠습니까?`,
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          try {
            const body = {
              ids: selectedRowkeys,
            };
            const res = await updateDoneMultipleSettlement(body).unwrap();
            if (res.status === "success") {
              dispatch(
                openDialog({
                  type: "info",
                  content: "완료 되었습니다.",
                  confirmText: "확인",
                }),
              );
              refetch();
              resetSelect();
            }
          } catch (error) {
            console.error(error);
          } finally {
            dispatch(closeModal);
          }
        },
      }),
    );
  };

  return (
    <div id="carrier-settlement">
      <NewTable
        resetSelect={resetSelect}
        callbackCurrentTab={(tab: any) => setCurrentTab(tab)}
        rowSelection={{
          selectedRowKeys: selectedRowkeys,
          onChange: handleSelectChange,
        }}
        title={t("carrier_settlement")}
        columns={columns}
        allData={arrData}
        metaData={metaData}
        typeOptions={Object.values(statusOptions)}
        searchFilterOptions={searchFilterOptions}
        loading={isFetching}
        params={params}
        setParams={setParams}
        filters={filters}
        setFilters={setFilters}
        showDateFilter
        maxLengthInput={15}
      />
      <div className="action-button">
        {params.status === ECarrierSettlementStatus.ISSUANCE_COMPLETED && (
          <div className="wrapper-btn" onClick={handleSettlementDone}>
            <div className="btn-text">정산완료</div>
          </div>
        )}

        {params.status === ECarrierSettlementStatus.SETTLEMENT_COMPLETED && (
          <div className="wrapper-btn" onClick={handleSettlementCancel}>
            <div className="btn-text">정산취소</div>
          </div>
        )}

        <div className="wrapper-btn fill" onClick={handleExcelDownload}>
          <div className="btn-text fill">엑셀 다운로드</div>
        </div>
      </div>
    </div>
  );
}
