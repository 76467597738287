import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetCommentsByPostQuery, useGetPostDetailQuery, useUpdatePostStatusMutation } from "../carrierPostApi";
import { Button, Skeleton } from "antd";
import "../styles/postDetail.scss";
import { formatPhoneNumber } from "../../../utils/utils";
import { DATE_FORMAT_DOT, PER_PAGE } from "../../../utils/constants";
import moment from "moment-timezone";
import Table, { ColumnsType } from "antd/lib/table";
import { EStatus, IComment } from "../type";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../components/customDialog/dialogSlice";
import { wait } from "../../../utils/commonFnc";
import { useState } from "react";

const PostDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [currentCommentPage, setCurrentCommentPage] = useState<number>(+(searchParams.get("page") || 1));

  const { data: postDetail, isFetching: isFetchingPostDetail } = useGetPostDetailQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const { data: commentData, isFetching: isFetchingComments } = useGetCommentsByPostQuery(
    {
      postId: id,
      params: { page: currentCommentPage, order: "desc", orderBy: "createdAt" },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  const [updateCarrierPostStatus] = useUpdatePostStatusMutation();

  if (!id || !postDetail) {
    return <>{t("no_data")}</>;
  }

  /* ---------------------------------- 상태 변경 --------------------------------- */
  const toggleStatus = (status: EStatus) => {
    const confirmMessage = status === EStatus.PUBLIC ? "숨김 처리 하시겠습니까?" : "노출 처리 하시겠습니까?";
    const changeStatusSuccess = status === EStatus.PUBLIC ? "숨김 처리 되었습니다." : "노출 처리 되었습니다.";
    dispatch(
      openDialog({
        type: "confirm",
        content: confirmMessage,
        confirmText: t("dialog_btn_confirm"),
        closeText: t("dialog_btn_cancel"),
        actionConfirm: async () => {
          const res: any = await updateCarrierPostStatus({
            id,
            status: status === EStatus.PUBLIC ? EStatus.UNPUBLIC : EStatus.PUBLIC,
          });
          await wait(300);
          dispatch(
            openDialog({
              type: "info",
              content: res?.error?.data?.message || changeStatusSuccess,
              confirmText: t("dialog_btn_confirm"),
            }),
          );
        },
      }),
    );
  };

  const commentColumns: ColumnsType<IComment> = [
    {
      title: t("작성자 아이디"),
      dataIndex: "phone",
      key: "phone",
      width: "20%",
      ellipsis: true,
      align: "center",
      render: (_, record) => formatPhoneNumber(record?.carrier?.phone || ""),
    },
    {
      title: t("내용"),
      dataIndex: "content",
      key: "content",
      width: "45%",
      ellipsis: true,
      align: "center",
      render: (content) => <span>{content}</span>,
    },

    {
      title: t("작성일자"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      ellipsis: true,
      align: "center",
      render: (_, record: IComment) => <>{moment(record?.createdAt).format(DATE_FORMAT_DOT)}</>,
    },
    {
      title: t("상태 구분"),
      dataIndex: "status",
      key: "status",
      width: "15%",
      ellipsis: true,
      align: "center",
      render: (_, record: IComment) => <>{record?.status === EStatus.PUBLIC ? "노출" : "숨김"}</>,
    },
  ];

  return (
    <div id="post-detail-page">
      <div className="post-detail-header">
        <h2 className="title">게시글 상세보기</h2>
        <div className="btn-wrapper">
          <Button className="btn-status" onClick={() => toggleStatus(postDetail.status)}>
            {postDetail.status === EStatus.PUBLIC ? "숨김 처리" : "노출 처리"}
          </Button>
        </div>
      </div>
      <div className="post-detail-content">
        {isFetchingPostDetail ? (
          <Skeleton />
        ) : (
          <>
            <div className="post-item">
              <label className="item-label">작성자 아이디: </label>
              <span className="item-value">{formatPhoneNumber(postDetail?.carrier?.phone)}</span>
            </div>
            <div className="post-item">
              <label className="item-label">작성일자: </label>
              <span className="item-value">
                {moment(postDetail?.createdAt).isValid() && moment(postDetail?.createdAt).format(DATE_FORMAT_DOT)}
              </span>
            </div>
            <div className="post-item">
              <label className="item-label">상태 구분: </label>
              <span className="item-value">{postDetail?.status === EStatus.PUBLIC ? "노출" : "숨김"}</span>
            </div>
            <div className="post-item">
              <label className="item-label">내용: </label>
              <p className="item-value">{postDetail?.content || ""}</p>
            </div>
          </>
        )}
      </div>
      <div className="post-detail-comments">
        <h2 className="title">댓글 내역</h2>
        <div className="total-comments">
          <label className="item-label">댓글 수: </label>
          <span className="item-value">{postDetail?.comments?.length || 0}개</span>
        </div>
        <div className="table-comments">
          <Table
            loading={isFetchingComments}
            locale={{ emptyText: t("no_data") }}
            dataSource={commentData?.data || []}
            columns={commentColumns}
            rowKey="id"
            rowClassName="comment-item"
            onRow={(record: IComment) => {
              return {
                onClick: () => {
                  navigate(`/carrier/posts/${id}/comments/${record.id}`);
                },
              };
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
            }}
            pagination={
              commentData?.meta?.lastPage > 1
                ? {
                    position: ["bottomCenter"],
                    current: currentCommentPage,
                    pageSize: commentData?.meta?.perPage || PER_PAGE,
                    total: commentData?.meta?.total || 0,
                    showSizeChanger: false,
                    onChange: (page: number) => {
                      setCurrentCommentPage(page);
                    },
                  }
                : false
            }
          />
        </div>
      </div>
      <Button className="back-btn" onClick={() => navigate(-1)}>
        목록
      </Button>
    </div>
  );
};

export default PostDetail;
