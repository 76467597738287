import { Form, FormItemProps, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import classNames from "classnames";
import React from "react";

interface Props extends FormItemProps {
  nameInput: string;
  form: FormInstance<any>;
  placeholder?: string;
  className?: string;
}

export default function InputKrPhoneDispatch({ nameInput, form, placeholder, className, ...props }: Props) {
  const checkPhoneKorean = (phone: string) => {
    const regex = /^\d{8,12}$/gm;
    return regex.test(phone);
  };
  return (
    <Form.Item
      name={nameInput}
      className={`w-full input-kr-phone-dispatch ${className ? "flex" : ""}`}
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (checkPhoneKorean(getFieldValue(nameInput))) {
              return Promise.resolve("Valid");
            }
            return Promise.reject(new Error("전화번호 8~12자리를 정확히 입력해 주세요."));
          },
        }),
      ]}
      {...props}
    >
      <Input
        placeholder={placeholder}
        className={`${className ? className : "w-full"} h-[50px] rounded-lg `}
        style={{ width: "100%", height: "100%" }}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        // BK-102
        onChange={(event) => {
          if (event.target.value?.length === 13 && !event.target.value?.includes("-")) {
            form.setFieldValue(nameInput, event.target?.value?.slice(0, 12));
          } else {
            const value = event.target.value?.replaceAll("-", "");
            form.setFieldValue(nameInput, value);
          }
        }}
        maxLength={13}
      />
    </Form.Item>
  );
}
