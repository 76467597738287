/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetCompanies"],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: (data) => ({
        url: `/company`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetCompanies"],
    }),
    swapCompanyOrder: builder.mutation({
      query: (data) => ({
        url: `company/swapOrder`,
        method: "PATCH",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCompanies"],
    }),
    createCompany: builder.mutation({
      query: (data) => ({
        url: `company`,
        method: "POST",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCompanies"],
    }),
    deleteCompany: builder.mutation({
      query: (data) => ({
        url: `company/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCompanies"],
    }),
    updateCompany: builder.mutation({
      query: (data) => ({
        url: `company/${data.id}`,
        method: "PUT",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCompanies"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useSwapCompanyOrderMutation,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companyApi;
