import "./style.scss";
import { IAlarmTalkDataType } from "./@type";
import { ColumnsType } from "antd/lib/table/Table";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { API_LINK, PER_PAGE } from "../../utils/constants";
import { useResendOrderMutation } from "./atmApi";
import moment from "moment-timezone";
import {
  checkPhoneKorean,
  formatPhoneNumberOnChange,
  getDateFromData,
  getDateToData,
  wait,
} from "../../utils/commonFnc";
import { formatTime } from "../PointManagement";
import NewTable from "../../components/NewTable";
import queryString from "query-string";
import { useGetOrderQuery } from "../SettlementManagement/settlementApi";
import { formatNumberToCurrency, formatPhoneNumber } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { closeModal } from "../../components/customModal/modalSlice";

export default function AlarmTalkManagement() {
  const [resendForm] = Form.useForm();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isResentModalVisible, setIsResentModalVisible] = useState(false);
  const [payload, setPayload] = useState<any>({});
  const [metaData, setMetaData] = useState<any>({});
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    startDate: null,
    endDate: null,
  });
  const [searchParams, setSearchParams] = useState<string>("");
  const [filters, setFilters] = useState<string[]>(["representationName"]);
  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch();

  const [isConfirmResentModalVisible, setisConfirmResentModalVisible] = useState(false);

  useEffect(() => {
    setSearchParams(getSearchParams(params));
  }, [params]);

  /* -------------------------------- 검색 값 가져오기 ------------------------------- */
  const getSearchParams = (params: any) => {
    const _params: any = {
      page: params.page,
      limit: params.limit,
      orderBy: "alarmSendAt",
      order: "desc",
      fieldSearchTime: "expectedEndDate",
    };
    if (params.startDate) {
      _params.from = getDateFromData(params.startDate);
    }
    if (params.endDate) {
      _params.to = getDateToData(params.endDate);
    }

    let filtersQuery = "";
    if (params.search && typeof params.search === "string") {
      _params.keyword = params.search.replaceAll("-", "");
      if (filters.length > 0) {
        filtersQuery = queryString.stringify(
          { ["searchColumn"]: filters },
          {
            arrayFormat: "bracket",
          },
        );
      }
    }
    const paramQuery = queryString.stringify(_params);

    let searchOr = [JSON.stringify({ alarmTalkStatus: "DONE" }), JSON.stringify({ alarmTalkStatus: "FAILED" })];
    const searchOrQuery = queryString.stringify(
      { ["searchOr"]: searchOr },
      {
        arrayFormat: "bracket",
      },
    );
    return `${paramQuery}&${searchOrQuery}&${filtersQuery}`;
  };

  const {
    data: orderData,
    isSuccess,
    isFetching,
    refetch: reFetchGetOrders,
  } = useGetOrderQuery(searchParams, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setData(orderData.data);
      setMetaData(orderData?.meta);
    }

    return () => { };
  }, [orderData]);

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    {
      label: t("atm.representation_name"),
      value: "representationName",
    },
    {
      label: t("atm.company"),
      value: "company",
    },
    {
      label: t("atm.phone_contact"),
      value: "contactReceiver",
    },
  ];

  useEffect(() => {
    resendForm.setFieldsValue({
      contactReceiver: formatPhoneNumberOnChange(selectedRow?.contactReceiver),
      date: moment(selectedRow.expectedEndDate).format("YYYY.MM.DD"),
      gpsLink: ` ${API_LINK}/public/shared-map?id=${selectedRow.id}&carrierId=${selectedRow.carrierId ?? ""}`,
    });
  }, [selectedRow]);

  const [resendOrder] = useResendOrderMutation();

  const columns: ColumnsType<Partial<IAlarmTalkDataType>> = [
    {
      title: "발송요청 일시",
      dataIndex: "alarmSendAt",
      key: "alarmSendAt",
      width: "15%",
      render: (item: any, record: Partial<IAlarmTalkDataType>) => {
        return formatTime(item);
      },
    },
    {
      title: "하차 예정 시각",
      dataIndex: "expectedEndDate",
      key: "expectedEndDate",
      width: "15%",
      render: (item: any, record: Partial<IAlarmTalkDataType>) => {
        return formatTime(item);
      },
    },
    {
      title: t("atm.representation_name"),
      dataIndex: "representationName",
      key: "representationName",
      width: "7%",
      render: (_: any, record: Partial<IAlarmTalkDataType>): any => {
        return record?.provider?.representationName ?? "";
      },
    },
    {
      title: t("atm.company"),
      dataIndex: "company",
      key: "company",
      width: 110,
      render: (_: any, record: Partial<IAlarmTalkDataType>): any => {
        if (record?.provider?.type === "NORMAL") {
          return "일반 회원";
        }
        return record?.provider?.company ?? "";
      },
    },
    {
      title: "하차지 담당자 연락처",
      dataIndex: "contactReceiver",
      key: "contactReceiver",
      width: "16%",
      render: (_: any, record: Partial<IAlarmTalkDataType>): any => {
        return formatPhoneNumberOnChange(record?.contactReceiver ?? "");
      },
    },
    {
      title: t("atm.loading_place"),
      dataIndex: "loadingPlace",
      key: "loadingPlace",
      width: "19%",
    },
    {
      title: t("atm.receipt_place"),
      dataIndex: "receiptPlace",
      key: "receiptPlace",
      width: "19%",
    },
    {
      title: t("atm.freight_cost"),
      dataIndex: "freightCost",
      key: "freightCost",
      width: "120px",
      render: (item) => {
        return `${formatNumberToCurrency(item || "")}원`;
      },
    },
    {
      title: "발송여부",
      dataIndex: "id",
      key: "id",
      width: "94px",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (_, record: Partial<IAlarmTalkDataType>) => {
        return <span className="btn-action btn-send text-nowrap">{t("atm.act_send")}</span>;
      },
    },
    {
      title: "재발송",
      dataIndex: "id",
      key: "id",
      width: "94px",
      render(_: any, record: any) {
        return (
          <span
            className="btn-action btn-unsend"
            onClick={() => {
              setSelectedRow(record);
              setIsResentModalVisible(!isResentModalVisible);
            }}
          >
            {t("atm.act_unsent")}
          </span>
        );
      },
    },
  ];

  /* --------------------------------- 양식 재제출 --------------------------------- */
  const handleResendFormSubmit = async () => {
    const { date, time, contactReceiver, ...rest } = resendForm.getFieldsValue();
    // check valid contactReceiver
    if (!checkPhoneKorean(contactReceiver)) {
      setIsResentModalVisible(false);
      await wait(300);
      dispatch(
        openDialog({
          type: "info",
          content: "휴대폰 번호 11자리를 정확히 입력해 주세요.",
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
      return;
    }

    const payload = {
      id: selectedRow.id,
      body: {
        ...rest,
        contactReceiver: contactReceiver?.replaceAll("-", ""),
        // expectedEndDate: combineDateTime(date, time),
      },
    };
    setPayload(payload);
    setisConfirmResentModalVisible(!isConfirmResentModalVisible);
    setIsResentModalVisible(false);
    resendForm.resetFields();
  };

  /* ----------------------------------- 재확인 ---------------------------------- */
  const handleResentCheck = async () => {
    setisConfirmResentModalVisible(false);
    try {
      const res = await resendOrder(payload)?.unwrap();
      if (res.isSuccess) {
        dispatch(
          openDialog({
            type: "info",
            content: "재발송이 완료 되었습니다.",
            confirmText: t("dialog_btn_confirm"),
            actionConfirm: () => {
              dispatch(closeModal());
            },
          }),
        );
      }
      reFetchGetOrders();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="atm">
      <NewTable
        title={t("alarm_talk_management")}
        columns={columns}
        allData={data}
        metaData={metaData}
        params={params}
        setParams={setParams}
        loading={isFetching}
        searchFilterOptions={searchFilterOptions}
        filters={filters}
        setFilters={setFilters}
        showDateFilter={true}
      />

      <div className="post-btn" onClick={() => window.open("https://www.popbill.com/SmsFax/SendATS", "_blank")}>
        <div className="post-text">{t("템플릿 관리")}</div>
      </div>

      {isResentModalVisible && (
        <Modal
          afterClose={() => {
            resendForm.resetFields();
          }}
          destroyOnClose={true}
          open={isResentModalVisible}
          title=""
          wrapClassName="modal-filter--crud"
          onCancel={() => setIsResentModalVisible(false)}
          footer={null}
          width={463}
        >
          <Form
            layout="vertical"
            initialValues={{
              contactReceiver: formatPhoneNumberOnChange(selectedRow?.contactReceiver),
              date: moment(selectedRow.expectedEndDate).format("YYYY.MM.DD"),
              gpsLink: ` ${API_LINK}/public/shared-map?id=${selectedRow.id}&carrierId=${selectedRow.carrierId ?? ""}`,
            }}
            onFinish={handleResendFormSubmit}
            form={resendForm}
            className="form-filter"
          >
            <Form.Item name={"contactReceiver"} label="연락처(예시. 01012341234)">
              <Input
                placeholder="담당자 연락처를 입력해주세요"
                onChange={(e) => {
                  resendForm.setFieldValue("contactReceiver", formatPhoneNumber(e.target.value));
                }}
                maxLength={18}
              />
            </Form.Item>
            {/* <Form.Item name={"date"} label="하차 날짜(예시. 2020.01.01)">
              <Input maxLength={15} placeholder="날짜를 입력해주세요" />
            </Form.Item>
            <Form.Item
              name={"time"}
              label="하차 시간(예시. 00:30 AM OR 13:05 PM)"
              rules={[
                {
                  pattern: /^(0[0-9]|1[0-2])(\s)*:(\s)*[0-5][0-9](\s)*(?:AM|PMZ)$/g,
                  message: "00:00 AM 또는 00:00 PMZ 형식을 따르세요.",
                },
              ]}
            >
              <Input maxLength={15} placeholder="시간을 입력해주세요 " />
            </Form.Item>
            <Form.Item name={"gpsLink"} label="GPS 링크">
              <Input />
            </Form.Item> */}
            <Button htmlType="submit" className="filter-button--submit">
              {t("atm.btn-resent")}
            </Button>
          </Form>
        </Modal>
      )}
      {isConfirmResentModalVisible && (
        <Modal
          destroyOnClose={true}
          afterClose={() => {
            setSelectedRow({});
            resendForm.resetFields();
          }}
          open={isConfirmResentModalVisible}
          title=""
          wrapClassName="modal-filter--confirm"
          onCancel={() => setisConfirmResentModalVisible(false)}
          footer={null}
          width={468}
        >
          <div className="confirm-wrapper">
            <div className="confirm-title"> {t("atm.confirm-resent-title")}</div>
            <div className="confirm-subtitle">{formatPhoneNumberOnChange(payload?.body?.contactReceiver || "")}</div>
            <div className="confirm-action">
              <Button className="confirm-action-btn--cancel" onClick={() => setisConfirmResentModalVisible(false)}>
                {t("atm.btn-cancel")}
              </Button>
              <Button className="confirm-action-btn--submit" onClick={handleResentCheck}>
                {t("atm.btn-resent")}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
