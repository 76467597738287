import React from "react";
import "./style.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApprovedRequestbillMutation } from "../DispatchManagement/dispatchApi";
import { useDispatch } from "react-redux";
import { closeDialog, openDialog } from "../../components/customDialog/dialogSlice";

const PreviewBill = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [approvedRequestBill] = useApprovedRequestbillMutation();

  const id = searchParams.get("id");

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBack = () => {
    navigate("/settlement-management/carrier-request");
  };

  /* --------------------------------- 계산서 발행 --------------------------------- */
  const onPublish = () => {
    dispatch(
      openDialog({
        type: "confirm",
        title: t("publish_bill_confirm"),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          const res = await approvedRequestBill({
            ids: [id],
          }); //@ts-ignore
          showResult(res?.error, t("issued_2"));
        },
        actionConfirm: () => {
          dispatch(
            openDialog({
              type: "info",
              content: t("canceled"),
              actionConfirm: onBack,
            }),
          );
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: !error?.data?.message ? msgSuccess : error?.data?.message,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          if (!error?.data?.message) {
            onBack();
          } else {
            dispatch(closeDialog());
          }
        },
      }),
    );
  };

  return (
    <div className="page-container">
      <div className="title">{t("preview_before_publication")}</div>
      <div className="card-wrap">
        <div className="card-header">
          <div className="header-title">{t("tax_bill_info")}</div>
        </div>
        <div className="card-content">
          <div className="preview-wrap"></div>
        </div>
      </div>
      <div className="button-wrap">
        <div className="base-btn btn-cancel" onClick={onBack}>
          {t("dialog_btn_cancel")}
        </div>
        <div className="base-btn" onClick={onPublish}>
          {t("publish2")}
        </div>
      </div>
    </div>
  );
};

export default PreviewBill;
