import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "../../locales/config";
import { dialogObj } from "../../utils/types/modal";

const initialState: dialogObj = {
  content: null,
  title: null,
  isOpen: false,
  width: 468,
  confirmText: i18n.t("dialog_btn_confirm"),
  closeText: i18n.t("dialog_btn_cancel"),
  type: "info", // info, confirm
  isBorderRadius: "yes",
  actionConfirm: () => {},
  actionCancel: () => {},
  isActionFlip: false,
};

const customDialog = createSlice({
  name: "customModal",
  initialState,
  reducers: {
    openDialog: (state: dialogObj, action: PayloadAction<any>) => {
      state.isBorderRadius = action.payload.isBorderRadius || initialState.isBorderRadius;
      state.isOpen = true;
      state.content = action.payload.content || initialState.content;
      state.title = action.payload.title || initialState.title;
      state.type = action.payload.type || initialState.type;
      state.confirmText = action.payload.confirmText || initialState.confirmText;
      state.closeText = action.payload.closeText || initialState.closeText;
      state.actionConfirm = action.payload.actionConfirm || initialState.actionConfirm;
      state.actionCancel = action.payload.actionCancel || initialState.actionCancel;
      state.width = action.payload.width || initialState.width;
      state.isActionFlip = action.payload.isActionFlip || initialState.isActionFlip;
    },
    closeDialog: (state: dialogObj, action: PayloadAction) => {
      state.isOpen = false;
    },
  },
});

const { reducer, actions } = customDialog;
export const { openDialog, closeDialog } = actions;
export default reducer;
