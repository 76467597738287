/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const memberApi = createApi({
  reducerPath: "memberApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetShippers", "GetCarriers", "GetShipperById"],
  endpoints: (builder) => ({
    getShippers: builder.query({
      query: (data) => ({
        url: `/providers/`,
        method: "GET",
        params: data,
      }),
      //@ts-ignore
      providesTags: ["GetShippers"],
    }),
    approvalMember: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}/status/approval`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    updatePaylaterStatus: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}/paylaterStatus`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    updateShipperStatus: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}/status`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    deleteShipper: builder.mutation({
      query: (data) => ({
        url: `/providers/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["GetShippers"],
    }),
    getCarrier: builder.query({
      query: (data) => ({
        url: `/carriers/${data.id}`,
        method: "GET",
        params: data,
      }),
    }),
    getCarriers: builder.query({
      query: (data) => ({
        url: `/carriers/`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetCarriers"],
    }),
    updateCarrierStatus: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}/status`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetCarriers"],
    }),
    updateCarrier: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetCarriers"],
    }),
    deleteCarrier: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      //@ts-ignore
      invalidatesTags: ["GetCarriers"],
    }),
    getShipperById: builder.query({
      query: (data) => ({
        url: `/providers/${data.id}`,
        method: "GET",
      }),
      //@ts-ignore
      providesTags: ["GetShipperById"],
    }),
    downloadBusinessLicenses: builder.mutation({
      query: (providerId) => ({
        url: `/providers/${providerId}/download-business-licenses`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
    }),
    downloadCarrierBusinessLicenses: builder.mutation({
      query: (carrierId) => ({
        url: `/carriers/${carrierId}/download-business-licenses`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
    }),
    updateCarrierBusiness: builder.mutation({
      query: (data) => ({
        url: `/carriers/${data.id}/admin/update-business`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["GetCarriers"],
    }),
  }),
});

export const {
  useGetCarrierQuery,
  useGetShippersQuery,
  useGetShipperByIdQuery,
  useGetCarriersQuery,
  useApprovalMemberMutation,
  useUpdateShipperStatusMutation,
  useUpdateCarrierStatusMutation,
  useDeleteShipperMutation,
  useDeleteCarrierMutation,
  useUpdatePaylaterStatusMutation,
  useUpdateCarrierMutation,
  useDownloadBusinessLicensesMutation,
  useDownloadCarrierBusinessLicensesMutation,
  useUpdateCarrierBusinessMutation
} = memberApi;
