import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

export default function MenuItem({
  menuName,
  url,
  subMenu,
  autoFirstSub = true,
  showSubMenu = null,
  onClickMenu = null,
  onClickSubMenu = null,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowSub, setIsShowSub] = useState(showSubMenu);

  const checkHasSubMenu = () => {
    return !!subMenu && subMenu.length > 0;
  };

  const handleCheckActiveMenu = (menu: string) => {
    return location.pathname.includes(menu);
  };

  const checkPath = (menu: string) => {
    return location.pathname.startsWith(menu);
  };

  const checkShowSub = (url: string) => {
    return isShowSub === true || (isShowSub == null && checkPath(url));
  };

  const onHandleClickMenu = () => {
    if (onClickMenu) {
      onClickMenu();
    } else {
      if (checkHasSubMenu() && autoFirstSub) {
        if (!isShowSub) {
          setIsShowSub(true);
        }
        navigate(`${url}${subMenu[0].url}`);
      } else {
        if (url === "https://analytics.google.com") {
          window.open(
            url,
            "_blank",
            "location=yes,height=800,width=1200,scrollbars=yes,status=yes"
          );
        } else {
          navigate(url);
        }
      }
    }
  };

  const onHandleClicSubMenu = (sub: any) => {
    if (onClickMenu) {
      onClickSubMenu();
    } else {
      navigate(`${url}${sub.url}`);
    }
  };

  return (
    <div className="menu-item">
      <div className="menu-content">
        <div
          className={`menu-title ${
            handleCheckActiveMenu(`${url}`) && subMenu?.length === 0
              ? "active"
              : ""
          }`}
          onClick={onHandleClickMenu}
        >
          {menuName}
        </div>
        <div
          className="menu-icon"
          onClick={() => {
            if (!checkHasSubMenu()) {
              onHandleClickMenu();
            } else {
              setIsShowSub(!isShowSub);
            }
          }}
        >
          {checkShowSub(url) ? "-" : "+"}
        </div>
      </div>
      {checkShowSub(url) && (
        <div className="sub-menu">
          {subMenu.map((sub: any) => {
            return (
              <div
                key={sub.url}
                className={`sub-menu-content ${
                  handleCheckActiveMenu(`${url}${sub.url}`) ? "active" : ""
                }`}
                onClick={() => {
                  onHandleClicSubMenu(sub);
                }}
              >
                {sub.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
