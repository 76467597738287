import { FC, useMemo } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { formatNumberToCurrency } from "../../utils/utils";

interface Props {
  data: any;
}

export const CancelDispatch: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="container-wrap">
      <div className="card-header">
        <div className="header-title">결제 정보</div>
        <div className="header-badge">회차료</div>
      </div>
      <div className="cancel-cost-content">
        <div className="left">
          <div className="left-item">
            <div className="left-item-label">결제방식</div>
            <div className="left-item-value">
              {" "}
              {
                data?.isCash
                  ? "선착불"
                  : data?.firstNicepayMethod === "card"
                    ? "신용카드"
                    : data?.firstNicepayMethod === "bank"
                      ? "계좌이체"
                      : "배차킹 페이(카드등록결제)"}
            </div>
          </div>
          <div className="left-item">
            <div className="left-item-label">회차료</div>
            <div className="left-item-value"> {new Intl.NumberFormat().format(Number(data?.freightCost))}원</div>
          </div>
        </div>
        <div className="right">
          <div className="right-item">
            <div className="right-item-label">
              최종 결제 금액<span className="label--sub">(VAT 포함)</span>
            </div>
            <div className="right-item-value">{new Intl.NumberFormat().format(Number(data?.firstNicepayAmt))}원</div>
          </div>
        </div>
      </div>
    </div>
  );
};
