import { FC, useMemo, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { DatePicker, DatePickerProps, Input, Select } from "antd";
import { Moment } from "moment-timezone";
import { useDispatch } from "react-redux";
import { openDialog } from "../customDialog/dialogSlice";
import { useCreateCouponMutation } from "../../pages/CouponManagement/couponApi";
import { closeModal } from "../customModal/modalSlice";
import FindMember from "../findMember";
import { checkDecimal } from "../../utils/commonFnc";
import { useNavigate } from "react-router-dom";

const CreateCoupon = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createCoupon] = useCreateCouponMutation();
  const [selectProvider, setSelectProvider] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [minPayAmount, setMinPayAmount] = useState("");
  const [value, setValue] = useState("");
  const [range, setRange] = useState("ALL");
  const [startDate, setStartDate] = useState<Moment | undefined | any>(undefined);
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined);

  const activeBtn = useMemo(() => {
    return (
      !!name &&
      !!description &&
      !!value &&
      !!startDate &&
      !!endDate &&
      (minPayAmount === "" || checkDecimal(minPayAmount, 5))
    );
  }, [name, description, value, startDate, endDate, minPayAmount]);

  const ranges = useMemo(
    () => [
      { label: t("payment_all"), value: "ALL" },
      { label: t("payment_designated"), value: "ASSIGN" },
      { label: "후불회원 외 지급", value: "NONPAYLATER_PAYMENT" },
    ],
    [],
  );

  /* --------------------------------- 시작 일 변경 -------------------------------- */
  const onChangeStartDate: DatePickerProps["onChange"] = (date: any) => {
    setStartDate(date);
  };

  /* --------------------------------- 마감 일 변경 -------------------------------- */
  const onChangeEndDate: DatePickerProps["onChange"] = (date: any) => {
    setEndDate(date);
  };

  const onChangeRange = (page: string) => {
    setRange(page);
  };

  /* ---------------------------------- 결제 처리 --------------------------------- */
  const onMakePayment = () => {
    if (activeBtn) {
      if (range === "ALL" || range === "NONPAYLATER_PAYMENT") {
        onPaymentCoupont();
      } else {
        setSelectProvider(true);
      }
    }
  };

  /* ------------------------------- 예전 화면으로 유도 ------------------------------- */
  const onBack = () => {
    navigate(-1);
  };

  /* ---------------------------------- 쿠폰 등록 --------------------------------- */
  const onPaymentCoupont = () => {
    dispatch(
      openDialog({
        type: "confirm",
        title: t("pay_coupon_content"),
        content: t("pay_coupon_title"),
        confirmText: t("dialog_btn_cancel"),
        closeText: t("dialog_btn_confirm"),
        actionCancel: async () => {
          const res = await createCoupon({
            name,
            value: Number(value),
            description,
            startDate,
            endDate,
            minPayAmount: Number(minPayAmount),
            type: "RATE",
            isExcludePaylater: range === "NONPAYLATER_PAYMENT" ? true : false,
            isApplyAll: range === "ALL" ? true : false,
          }); //@ts-ignore
          showResult(res.error, t("coupon_issued"));
        },
      }),
    );
  };

  /* ---------------------------------- 결과 표시 --------------------------------- */
  const showResult = async (error: any, msgSuccess: string) => {
    dispatch(
      openDialog({
        type: "info",
        content: error?.data?.message ?? msgSuccess,
        confirmText: t("dialog_btn_confirm"),
        actionConfirm: () => {
          dispatch(closeModal());
          if (!error?.data?.message) {
            onBack();
          }
        },
      }),
    );
  };

  if (selectProvider) {
    return (
      <FindMember
        params={{
          name,
          value: Number(value),
          description,
          startDate,
          endDate,
          minPayAmount: Number(minPayAmount),
          type: "RATE",
          isExcludePaylater: range === "NONPAYLATER_PAYMENT" ? true : false,
          isApplyAll: range === "ALL" ? true : false,
        }}
        onBack={() => navigate(-1)}
      />
    );
  }

  return (
    <div className="detail-coupon">
      <div className="top-content">
        <div className="title">{t("coupon_payment")}</div>
        <div className="btn-wrap">
          <div className="base-btn btn-back" onClick={onBack}>
            {t("payment_status")}
          </div>
        </div>
      </div>
      <div className="body">
        <div className="row-item">
          <div className="row-title">{t("coupon_name")}</div>
          <Input
            className="base-input name-input"
            placeholder={t("please_enter_coupon_name")}
            maxLength={15}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="row-item mt-24">
          <div className="row-title">{t("coupon_description")}</div>
          <Input.TextArea
            className="base-input description-input"
            placeholder={t("please_write_description_coupon")}
            maxLength={50}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="line" />
        <div className="row-item">
          <div className="row-title">{t("discount_rate")}</div>
          <Input
            suffix="%"
            className="base-input minimum-input"
            placeholder={t("percent_auto")}
            maxLength={3}
            value={value}
            onChange={(e) => {
              if (/^[1-9][0-9]?$|^100$/.test(e.target.value) || e.target.value === "") {
                setValue(e.target.value);
              }
            }}
          />
        </div>
        <div className="rate-note">
          <div className="rate-note-icon">※ </div>할인금액의 소수점 첫째자리 금액은 절삭됩니다.
        </div>
        <div className="row-item mt-24">
          <div className="row-title">{t("minimum_payment")}</div>
          <Input
            className="base-input minimum-input"
            placeholder={t("enter_number_only")}
            value={minPayAmount}
            onChange={(e) => {
              if (checkDecimal(e.target.value, 5) || e.target.value === "") {
                setMinPayAmount(e.target.value);
              }
            }}
          />
        </div>
        <div className="row-item mt-24">
          <div className="row-title">{t("payment_range")}</div>
          <Select className="select-range" options={ranges} value={range} onChange={onChangeRange} />
        </div>
        <div className="row-item mt-24">
          <div className="row-title">{t("period_of_use")}</div>
          <DatePicker
            inputReadOnly={true}
            size="middle"
            style={{ width: 152, height: 36 }}
            value={startDate}
            onChange={onChangeStartDate}
            placeholder={t("start_date")}
          />
          <div className="tilde">~</div>
          <DatePicker
            inputReadOnly={true}
            size="middle"
            style={{ width: 152, height: 36 }}
            value={endDate}
            disabledDate={(current) => current && current < startDate}
            onChange={onChangeEndDate}
            placeholder={t("end_date")}
          />
        </div>
      </div>
      <div className="bottom-content">
        <div className={`base-btn btn-submit ${!activeBtn ? "disabled" : ""}`} onClick={onMakePayment}>
          {t("make_payment")}
        </div>
      </div>
    </div>
  );
};

export default CreateCoupon;
