import { Button, Input } from 'antd';
import "./style.scss";
import { useEffect, useRef, useState } from 'react';
import { useUpdateOrdersMutation } from '@/pages/DispatchManagement/dispatchApi';
import { getUrlParams } from '@/utils/commonFnc';
import { useUpdateDispatchMutation } from '@/pages/DispatchModify/VehicleRegisApi';
import { useDispatch } from 'react-redux';
import { closeDialog, openDialog } from '@/components/customDialog/dialogSlice';
import { useNavigate } from 'react-router-dom';
import { useGetCarriersQuery } from '@/pages/MemberManagement/memberApi';
import { ICarrier } from '@/utils/types/order';
import { useGetOrderDetailQuery } from '@/pages/SettlementManagement/settlementApi';

const ChangeCarrier = ({ data, onClose }: any) => {
    const { setInfoDetail } = data;
    const [searchValue, setSearchValue] = useState('');
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [triggerQuery, setTriggerQuery] = useState(false);
    const [carrierList, setCarrierList] = useState([]);
    const submitBtnRef = useRef(null);
    const idDispatch = getUrlParams("id");
    const [updateDispatch] = useUpdateDispatchMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const parameters = `order=desc&orderBy=createdAt&searchColumn[]=name&keyword=${searchValue}`
    const { data: carrierData, isSuccess } = useGetCarriersQuery(parameters, {
        refetchOnMountOrArgChange: true,
        skip: !triggerQuery,
    });
    const { data: infoData, refetch: handleRefreshDetail } = useGetOrderDetailQuery(
        { id: idDispatch },
        {
            refetchOnMountOrArgChange: true,
            skip: !idDispatch,
        },
    );

    useEffect(() => {
        if (searchValue) setTriggerQuery(true);
    }, [triggerSearch]);

    useEffect(() => {
        if (isSuccess) setCarrierList(carrierData?.data);

        return () => { };
    }, [carrierData]);

    const handleSearch = () => {
        setTriggerSearch(true);
    }

    const handleConfirm = async (selectedCarrierInfo: ICarrier) => {
        try {
            updateDispatch({ id: idDispatch, data: { carrierId: selectedCarrierInfo?.id } })
                .unwrap()
                .then(() => {
                    handleRefreshDetail();
                    setInfoDetail(infoData);
                    dispatch(
                        openDialog({
                            type: "info",
                            content: "기사님이 변경되었습니다.",
                            actionConfirm: () => {
                                onClose();
                                navigate("/matching-management?id=" + idDispatch);
                            },
                        }),
                    );
                })
                .catch((error) => {
                    dispatch(
                        openDialog({
                            type: "info",
                            content: error?.data?.message || "알 수 없는 문제",
                        }),
                    );
                })

        } catch (error: any) {
            dispatch(
                openDialog({
                    type: "info",
                    content: error?.data?.message || "알 수 없는 문제",
                }),
            );
        }
    };

    return (
        <div className="change-carrier-dialog">
            <div className='title-container'>
                운송인 찾기
            </div>
            <div className='body-container'>
                <div className="search-carrier-container">
                    <Input
                        className="search-carrier"
                        placeholder="이름으로 검색해주세요."
                        value={searchValue}
                        onPressEnter={handleSearch}
                        type="text"
                        enterKeyHint="done"
                        onChange={(e) => {
                            setSearchValue(e?.target?.value);
                        }}
                    />
                    <Button
                        ref={submitBtnRef}
                        className={`w-[80px] h-[50px] bt-search-direct rounded-lg text-[white] ${searchValue?.trim()?.length > 0 ? "bg-[#1f67ad]" : ""
                            }`}
                        onClick={handleSearch}
                    >
                        검색
                    </Button>
                </div>
                {carrierList.length !== 0
                    &&
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>이름</th>
                                    <th>차량 번호</th>
                                    <th>톤 수</th>
                                    <th>차량 종류</th>
                                </tr>
                            </thead>
                            <tbody>
                                {carrierList.map((carrier: ICarrier) => {
                                    const { id, name, licensePlate, tonnage, vehicleType } = carrier;
                                    return (
                                        <tr key={id} onClick={() => handleConfirm(carrier)}>
                                            <td>{name}</td>
                                            <td>{licensePlate}</td>
                                            <td>{tonnage}</td>
                                            <td>{vehicleType}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>
                }
            </div>
        </div>
    );
};

export default ChangeCarrier;