import { Outlet, useLocation } from "react-router-dom";
import Header from "../header";
import LeftMenu from "../header/LeftMenu";
import "./style.scss";
import { useMemo } from "react";

function Layout() {
  const location = useLocation();
  const isWhiteBg = useMemo(() => {
    if (location.pathname.startsWith("/carrier/posts/")) {
      return true;
    }
    return location.pathname.includes('coupon')
  }, [location])

  return (
    <>
      <div className="layout">
        <div>
          <Header />
        </div>
        <main className={`main-content ${isWhiteBg ? 'white' : ''}`}>
          <LeftMenu />
          <div className="content">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}

export default Layout;
