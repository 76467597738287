/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";
import queryString from "query-string";

export const pointApi = createApi({
  reducerPath: "pointApis",
  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["point"],
  endpoints: (builder) => ({
    getPoints: builder.query({
      query: (params) => ({
        url: "coupons/point/history",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetPointsQuery } = pointApi;
